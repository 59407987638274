import React from 'react';
import FazeLanding from '../FazeLanding';
import { connect } from 'react-redux';
import { setCurrentPage } from '../../../actions/index';
import PreviousChapter from '../../common/PreviousChapter';
import NextChapter from '../../common/NextChapter';

const Dictionary_intro = props => {
    const mainHeading = 'RIJEČNIK';

    return (
        <FazeLanding notLanding setPage={{ page:6, subpage: 0 }} className="pageFazeLanding">
            <div className="d-flex-s-12 d-flex d-flex-jcfe">
                <h1 className="faze_landing_heading faze_landing_heading--horizontal">{mainHeading}</h1>
            </div>


            <div className="faze_landing_underheading d-flex d-flex-jcfe"></div>
            <PreviousChapter varient="white" />
            <NextChapter varient="white" />
        </FazeLanding>
    )
}

export default connect(null, { setCurrentPage })(Dictionary_intro)
