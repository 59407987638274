import React from 'react';
import { connect } from 'react-redux';
import TopNavBar from '../../common/TopNavBar';
import { hideDropdowns } from '../../utiles';
import { setCurrentPage } from '../../../actions';

class FazeLanding extends React.Component {
    componentDidMount() {
        if(this.props.notLanding) {
            if(this.props.setPage) {
                const { setPage } = this.props;
                this.props.setCurrentPage(setPage.page, setPage.subpage)
            } else {
                const link = window.location.href.split('/').pop().split('&');
                let page = undefined;
                let subPage = undefined;
    
                page = link[0].split('=');
                subPage = link[1].split('=');
    
                this.props.setCurrentPage(parseInt(page[1]), parseInt(subPage[1]));
            }
        }
    }

    render() {
        return (
            <div id="faze_landing" className={`d-flex d-flex-jcc d-flex-aac ${this.props.className}`}>
                <TopNavBar {...this.props} />
                <div className="faze_landing_wrap" onClick={hideDropdowns}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

FazeLanding.defaultProps = {
    notLanding: false
}

export default connect(null, { setCurrentPage })(FazeLanding);