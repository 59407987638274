import React from 'react';
import TopNavBar from '../../common/TopNavBar';
import { connect } from 'react-redux';
import { Dots } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import Footer from '../../common/Footer';
import NextChapter from '../../common/NextChapter';
import PreviousChapter from '../../common/PreviousChapter';
import { setCurrentPage, stopLoader } from '../../../actions';
import { hideDropdowns } from '../../utiles';

class ContentPage extends React.Component {
    componentDidMount() {
        const scrollLink = window.location.href;

        if(scrollLink === scrollLink.split('#')[0]) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }

        if(this.props.page) {
            const link = window.location.href.split('/').pop().split('&');
            let page = undefined;
            let subPage = undefined;

            page = link[0].split('=');
            subPage = link[1].split('=');

            this.props.setCurrentPage(parseInt(page[1]), parseInt(subPage[1]));
        } else {
            const { setPage } = this.props;
            this.props.setCurrentPage(setPage.page, setPage.subpage)
        }

        setTimeout(() => {
            this.props.stopLoader();
        }, 500)
    }

    render() {
        return (
            <div className="content_page" >
                <div className="content_page_pic"><img src="../images/content_page_pic.png" alt="Content Pic" /></div>
                <TopNavBar notLanding />
                {!this.props.hideButtons && <NextChapter />}

                {!this.props.hideButtons && <PreviousChapter />}
                <div className="content_wrap" onClick={hideDropdowns}>
                    {this.props.children}   
                </div>
                {this.props.activity && (
                <div className="activity_indicator">
                    <Dots animating={true} color="#2C4A88" size={32}/>
                </div>
                )}

           
                <Footer></Footer>
            </div>
        )
    }
}

ContentPage.defaultProps = {
    page: true,
    hideButtons: false
}

const mapStateToProps = state => {
    return{
        activity: state.currentPage.loading
    }
}

export default connect(mapStateToProps, { setCurrentPage, stopLoader })(ContentPage);