import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const AdditionalMenu = () => {
    const closeMenu = () => {
        const menu = document.querySelector('.additional_menu');
        return menu.classList.remove('active_menu');
    }

    return (
        <div className="additional_menu">
            <div className="additional_menu_images">
                <img src={`${process.env.PUBLIC_URL}/images/swiss_agency.svg`} alt="swiss agency" /> 
                <img src={`${process.env.PUBLIC_URL}/images/undp.svg`} className="undp" alt="swiss agency" />
            </div>

            <h3>DODATNO:</h3>
            <Link to={`${process.env.PUBLIC_URL}/additional-tools`} className="d-flex d-flex-aac" onClick={closeMenu}>
                <img src={`${process.env.PUBLIC_URL}/images/settings.png`} alt="icon" /> 
                <span>Alati i obrasci</span>
            </Link>
            <Link to={`${process.env.PUBLIC_URL}/additional-tools-2`} className="d-flex d-flex-aac" onClick={closeMenu}>
                <img src={`${process.env.PUBLIC_URL}/images/documents.png`} alt="icon" />
                <span>Studije slučaja</span>
            </Link>
            <Link to={`${process.env.PUBLIC_URL}/dictionary`} className="d-flex d-flex-aac" onClick={closeMenu}>
                <img src={`${process.env.PUBLIC_URL}/images/flag.png`} alt="icon" />
                <span>Rječnik ključnih pojmova</span>
            </Link>

            <div className="download d-flex d-flex-jcc">
                <a href={`${process.env.PUBLIC_URL}/pdf/izrada-javnih-grant-shema-prirucnik-za-prakticare-iz-javnog-sektora.pdf`} download className="button">Preuzmi<br></br> dokument</a>
                <a href={`${process.env.PUBLIC_URL}/pdf/izrada-javnih-grant-shema-prirucnik-za-prakticare-iz-javnog-sektora.pdf`} download className="button ml-15">Download <br></br>document</a>
            </div>

            <div className="additional_menu_footer">
                <h3>DODATNO</h3>
                <img 
                    src={`${process.env.PUBLIC_URL}/images/open_menu_icon.svg`} 
                    alt="menu_open" 
                    onClick={closeMenu}
                />
            </div>
        </div>
    )
}

export default AdditionalMenu;