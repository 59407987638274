import React from 'react';

import MenuButtons from './MenuButtons';

const ResponsiveMenu = () => {
    const openAdditionalMenu = () => {
        const menu = document.querySelector('.additional_menu');
        return menu.classList.add('active_menu');
    }   

    return (
        <div className="responsive_menu">
            <MenuButtons />
            <img 
                src={`${process.env.PUBLIC_URL}/images/open_menu_icon.svg`} 
                alt="menu_open" 
                onClick={openAdditionalMenu}
            />
        </div>
    )
}

export default ResponsiveMenu;