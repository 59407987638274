import React from 'react';
import ContentPage from '../ContentPage/';
import { HashLink as Link } from 'react-router-hash-link';

import Toolpit from '../../common/Toolpit';

const EntryPage = () => {
    return (
        <ContentPage page={false} setPage={{ page:0, subpage: -1 }}>
            <div className="content">
                <p id="svrha-prirucnika-i-osnovne-definicije" style={{ opacity: 0 }} className="content_text">asdasdasd sad sa dasd asd sqwdasd wad</p>
                <h2 className="blue_overlay_heading">
                    SVRHA PRIRUČNIKA I OSNOVE DEFINICIJE
                </h2>

                <p className="content_text">Ovaj je priručnik napravljen kao vodič za one koji su uključeni u izradu grant šema na konkurentskoj osnovi za budžetske korisnike svih nivoa vlasti u Bosni i Hercegovini (BiH). To će, u većini slučajeva, biti državni službenici u organima uprave na državnom, entitetskom, kantonalnom i nivou jedinica lokalne samouprave. Ključni cilj ovog priručnika je:</p>

                <p className="content_text"><b>> Pomoći državnim službenicima da, poštujući redoslijed koraka, efikasno pripreme grant šeme ili slične pozive za dostavu projektnih prijedloga na konkurentskoj osnovi, kako bi osigurali da te šeme poluče željene rezultate, u skladu s ciljevima politike na kojima se temelje i načelima dobrog finansijkog upravljanja.</b></p>

                <p className="content_text pos-rel">Grant podrazumijeva bespovratna finansijska sredstva iz javnih budžeta dodijeljena za posebnu namjenu ili kako bi se olakšalo ostvarivanje određenih rezultata, odnosno, kako bi se pojasnili i kvantificirali rezultati/ishodi (indikatori) šeme koji će dovesti do željenih ishoda kako aplikanta tako i organizacije koja daje bespovratna sredstva. Davanjem grantova javna institucija nastoji javnim sredstvima potaknuti druge (tj. korisnike bespovratnih sredstava) da djeluju na određeni način i tako doprinesu ciljevima javne politike. Grant šema je mehanizam kojim se to može postići na način koji je u skladu s “dobrim finansijskim upravljanjem”, drugim riječima, na transparentan, ekonomičan, efikasan i efektivan način. <Toolpit>Vidi npr. Zakon o reviziji institucija Bosne i Hercegovine: dokument 2.1 na <a href='http://bit.ly/izvornidokumenti' target="_blank" rel="noopener noreferrer">http://bit.ly/izvornidokumenti;</a> Zakon o budžetima u Federaciji Bosne i Hercegovine: dokument 2.2 na <a href="http://bit.ly/izvornidokumenti" target="_blank" rel="noopener noreferrer">http://bit.ly/izvornidokumenti</a> ili Zakon o budžetskom sistemu Republike Srpske: dokument 2.3 na <a href="http://bit.ly/izvornidokumenti" target="_blank" rel="noopener noreferrer">http://bit.ly/izvornidokumenti</a></Toolpit></p>

                <p id="cemu-sluzi-ovaj-prirucnik" className="content_text">Priprema grant šeme podrazumijeva proces analize i planiranja prije izrade i obavještavanja potencijalnih korisnika o šemi.</p>

                <h2 className="blue_overlay_heading">
                    ČEMU SLUŽI OVAJ PRIRUČNIK
                </h2>

                <p className="content_text">Ovaj se priručnik temelji na četiri ključne odrednice: prvo, prema zakonu, svi javni budžeti u Bosni i Hercegovini moraju se trošiti uz puno uvažavanje načela transparentnosti, ekonomičnosti, efikasnosti i efektivnosti. Drugo, u sadašnjoj praksi pripreme grant šema ova se načela nisu uvijek u potpunosti uvažavala. Treće, javna potrošnja u Bosni i Hercegovini ide u općem smjeru, koji podrazumijeva da bi, u idealnom slučaju, izrada grant šema, kao i izrada bilo kojeg finansijskog instrumenta, trebala biti ugrađena u širi ciklus planiranja, odnosno, izrade javnih politika kao i njihovog budžetiranja, a koji država stalno nastoji unaprijediti. Konačno, sposobnost izrade i provedbe jasnih grant šema usmjerenih na rezultate ključni je preduvjet za pripremu, upravljanje i realizaciju pretpristupne finansijske podrške EU i postpristupnih strukturnih i investicijskih fondova u kasnijoj fazi. Upravo zbog toga, u Priručniku su navedeni brojni primjeri iskustava iz pretpristupnog i ranog postpristupnog perioda nekoliko zemalja (naročito Bugarske i Češke Republike).</p>

                <p id="regulatorni-okvir" className="content_text">U tom smislu, Priručnik je praktičan i nudi mnoge obrasce i alate <b>koji su korišteni u grant šemama drugih zemalja, a koji su, u manjoj ili većoj mjeri, prilagođeni situaciji u Bosni i Hercegovini. Kao takav, ovaj priručnik nudi osnovne obrasce koje institucije (koje trenutno koriste različite formate, obrace i procedure, od kojih samo neki zadovoljavaju standarde EU) mogu dodatno prilagođavati.</b></p>

                <h2 className="blue_overlay_heading">
                    REGULATORNI OKVIR ZA IZRADU JAVNIH GRANT ŠEMA U BOSNI I HERCEGOVINI
                </h2>

                <p className="content_text pos-rel">Dodjela bespovratnih sredstava u Bosni i Hercegovini generalno je definirana državnim i entitetskim zakonima o izvršenju budžeta, dok su kriteriji za dodjelu posebnih grantova razrađeni u programima budžetskih rashoda relevantnih sektorskih ministarstava ili drugim podzakonskim aktima koje definira vlada. Metodologije za pripremu šema za dodjelu javnih sredstava date su u obliku podzakonskih akata koji propisuju minimalne standarde i elemente za izradu grant šeme, uključujući i zahtjev da se šema poveže s relevantnim strateškim okvirom. <Toolpit>Tj. Smjernice o minimalnim standardima dodjele budžetskih sredstava putem transfera i subvencija u Federaciji BiH(Službene novine FBiH, broj 15/18); Metodologija upravljanja grantovima za programe koji se finansiraju ili sufinansiraju sredstvima budžeta RS (dokument 3.1 i dokument 3.2 na <a href="http://bit.ly/izvornidokumenti" target="_blank" rel="noopener noreferrer">http://bit.ly/izvornidokumenti</a>)</Toolpit> </p>

                <p className="content_text">Pored toga, nadležna ministarstva mogu propisati konkretnije postupke kojima će regulirati pripremu grant šeme za određeni vremenski period (tj. jednu godinu). U načelu, proces raspodjele sredstava u okviru grant šeme vrši se putem javnih poziva i mora biti što transparentniji. To znači da grant šema i pripadajući kriteriji prihvatljivosti i odabira, kao i specifične pojedinosti (kvalitet), moraju biti javno objavljeni. Štaviše, neuspješni aplikanti moraju imati pristup žalbenom postupku, u kojem mogu ispitati razloge odbijanja njihovog projektnog prijedloga.</p>

                <p className="content_text pos-rel">U nastojanju da osnaže svoje interne procedure planiranja, praćenja i izvještavanja, vlasti na državnom i entitetskom nivou uvele su obavezno srednjoročno i kratkoročno planiranje za ministarstva i druge institucije. <Toolpit text="Tj. Odluka o postupku srednjoročnog planiranja, praćenja i izvještavanja u institucijama Bosne i Hercegovine i Odluka o godišnjem planiranju rada i načinu praćenja i izvještavanja o radu u institucijama Bosne i Hercegovine; Zakon o razvojnom planiranju i upravljanju razvojem Federacije BiH; Odluka o postupku planiranja, praćenja i izvještavanja o realizaciji usvojenih strategija i planova Vlade RS i republičkih organa uprave."></Toolpit> Namjera ovih podzakonskih akata je da, između ostalog, sadašnju praksu pripreme i izrade grant šema vremenom sve više nasloni na dugoročno strateško planiranje i srednjoročno operativno i finansijsko planiranje.</p>

                <p className="content_text">Ovaj se priručnik temelji na pretpostavci sve veće potrebe za izradom grant šema: one nisu jednokratne, izolirane javne intervencije, već instrument koji pomaže pri realizaciji prioriteta koji su sastavni dio dugoročnog strateškog planiranja i okvira politika. </p>
                
                <p id="izazovi_i_problemi_u_izradi_sema" className="content_text">Jasno je da se ova veza s dugoročnim strateškim planiranjem još uvijek razvija te da sadašnji izazovi i problemi u pripremi grant šema u Bosni i Hercegovini ukazuju da postoji određeni jaz između sadašnje, još uvijek nedovoljno razvijene prakse, i naprednih mogućnosti koje bi se mogle iskoristiti u budućnosti.</p>

                <h2 className="blue_overlay_heading">
                    IZAZOVI I PROBLEMI U IZRADI GRANT ŠEMA U BOSNI I HERCEGOVINI
                </h2>

                <p className="content_text pos-rel">U okviru analize konteksta, na kojoj se ovaj priručnik temelji, regulatorni okvir (pretežno podzakonski akti koje donose vlada, ministarstva ili jednica lokalne samouprave, zavisno od toga ko pokreće grant šemu) <Toolpit text="Podzakonski akti: opći akti koje donose vlade, ministarstva u vladama ili jedinice lokalne samouprave. Oni mogu biti u formi odluka, pravilnika, smjernica i sl."></Toolpit>, koji definira kriterije za odabir i provedbu javnih grantova, javnih poziva i prateću dokumentaciju, razmatran je u odnosu na određene kriterije u pogledu potpunosti. Uz neke iznimke, ovo istraživanje i analiza otkrivaju sljedeće probleme:</p>

                <div className="custom_tables mb-25">
                    <img src={`${process.env.PUBLIC_URL}/images/problem_diagram.png`} className="d-block" alt="Problem diagram" />
                </div>

                <h3 className="content_heading blue">U GRANT ŠEMI SE NAVODE:</h3>

                <div className="custom_tables mb-25">
                    <img src={`${process.env.PUBLIC_URL}/images/grant_sema.png`} className="d-block" alt="Problem diagram" />
                </div>

                <p className="content_text"><b>Tabela A:</b> Grant šeme u BiH - sadržaj</p>

                <h2 className="blue_overlay_heading">
                    ZAKLJUČAK
                </h2>

                <p className="content_text">Glavni nedostaci sadašnjeg sistema dodjele bespovratnih sredstava, a time i provedbe grant šema u BiH, su sljedeći:</p>

                <ul>
                    <li className="content_text mb-10"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="Arrow"></img> Šema se priprema na osnovu neodgovarajućeg planiranja tokom izrade šeme i izrađuje se u suviše kratkom vremenskom okviru: ovo je dodatno usložnjeno nastojanjem da se previše toga precizira podzakonskim aktima;</li>
                    <li className="content_text mb-10"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="Arrow"></img> Fokus je na aktivnostima, a ne na rezultatima (u smislu ishoda ili koristi);</li>
                    <li className="content_text mb-10"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="Arrow"></img> Ishodi, kada su definirani, ne navode šta pojedinačni projekti i šema u cjelini mogu realno omogućiti u okviru finansijskih i vremenskih ograničenja i raspoloživih kapaciteta;</li>
                    <li className="content_text mb-10"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="Arrow"></img> Općenito gledano, šeme se ne temelje na jasnoj i jednostavnoj logici intervencije koja koherentno i realno povezuje inpute/resurse s aktivnostima, aktivnosti s izlaznim rezultatima, izlazne rezultate s krajnjim rezultatima (ishodima) i ishodima koji doprinose realizaciji politike ili strateških ciljeva.</li>
                </ul>

                <p className="content_text">To su pitanja koja ovaj priručnik nastoji riješiti.</p>

                <p className="content_text"><span className="blue">1. </span>U Bosni i Hercegovini se grantovi dodjeljuju unutar budžetskog okvira javnih finansija, što nosi posebne izazove:</p>
                <p className="content_text pl-30"><span className="blue">1. </span> To trenutno funkcionira na principu jednogodišnjeg budžeta, čime se onemogućavaju šeme srednjeg trajanja i ograničava vrijeme za pripremu i realizaciju šema. To znači:</p>

                <p className="content_text pl-60"><span className="blue">a. </span> da projekti moraju biti gotovo spremni za realizaciju u vrijeme podnošenja prijave, a u svakom slučaju, moraju se realizirati u roku od 12 mjeseci. U suprotnom, prijeti stvarna opasnost od problema s apsorpcijom sredstava, odnosno finansijskom realizacijom predviđenih sredstava u zadatom roku;</p>
                <p className="content_text pl-60"><span className="blue">b. </span> da će stepen složenosti, a u mnogim slučajevima, i stepen učinkovitosti svakog projekta biti ograničen;</p>
                <p className="content_text pl-60"><span className="blue">c. </span> da postoji opasnost da projektima koji se finansiraju gotovo i neće trebati grant (alokativno neefikasna šema), budući da su već spremni (i vjerovatno mogu zadovoljiti i stroge uvjete banaka);</p>
                <p className="content_text pl-60"><span className="blue">d. </span> s obzirom na vrlo kratke rokove i izvjesna odgađanja, potom pripremu i objavljivanje, neminovno je da postoji očekivanje da će se jednom realizirana šema ponoviti i sljedeće godine, uz neke manje dorade: ovo će olakšati proces manje-više modificirane replikacije iste šeme i naredne godine u okvirima dostupnih resursa ili zadatih rokova;</p>

                <p className="content_text pl-30"><span className="blue">2. </span> Dokumentacija u procesu grant šema pretjerano je fokusirana na pravna i administrativna pitanja. To aplikantima daje vrlo malo smjernica, direktno ili indirektno, o tome koje su to vrste dobrih projekata koji će imati prednost kod finansiranja. Smjernice za potencijalne aplikante nisu dosljedne niti su adekvatno fokusirane na ono što se zaista želi naspram onoga što je moguće;</p>

                <p id="kako-je-prirucnik-strukturiran" className="content_text"><span className="blue">2. </span>U tom kontekstu, namjera ovog priručnika je da posluži kao podsjetnik onima koji su uključeni u postupak pripreme grant šema kako bi se osigurale kritične informacije i upute, koje će rezultirati izradom što kvalitetnije šeme, koja će poticati na izradu kvalitetnijih projekata. Na taj se način mogu osigurati kvalitetniji ishodi;</p>

                <h2 className="blue_overlay_heading">
                    KAKO JE PRIRUČNIK STRUKTURIRAN
                </h2>

                <p className="content_text">Ovaj vas priručnik vodi, <b>korak po korak, kroz pažljivo razmotren postupak izrade grant šema, koji obuhvata alate i metode kao i relevantne aktere i one koji će ubuduće raditi na njihovoj provedbi</b>. Karakteriziraju ga koraci, od kojih vas svaki vodi stepen dalje na putu izrade dobre grant šeme. Svakim korakom, dodajete veći ili manji dio slagalice koji odgovara ključnim faktorima uspjeha jedne dobre grant šeme;</p>

                <p className="content_text">Priručnik se temelji na razvojnom procesu podijeljenom u tri faze, koji je prilagođen potrebama praktičara u BiH. To su:</p>

                <p class="content_text ParaOverride-7 ml-0"><span class="CharOverride-17 number_big">1.</span><span class="CharOverride-18 text-big"> faza</span> <span class="CharOverride-19 content_text"> Konceptualizacija. </span> Ona obuhvata provjeru izvodljivosti grant šeme i utvrđivanje općih ciljeva i željenih ishoda. Ova je faza pandan donošenju vladinih ili ministarskih pravilnika o kriterijima za odabir politika i realizaciju fondova resornih ministarstava za njihovo provođenje (ili njihovih ekvivalenata) u BiH;</p>
                <p className="content_text"><img className="_idGenObjectAttribute-2" src="./image/2.png" alt="" /></p>
                <p className="content_text ParaOverride-8"><span className="CharOverride-17 number_big">2. </span><span className="CharOverride-18 text-big"> faza</span> <span className="CharOverride-19 content_text"> (Skiciranje) izrada grant šeme. </span> Ona podrazumijeva definiranje aktivnosti, rezultata i ishoda, prihvatljivih aplikanata i procjenu učinkovitosti korištenja sredstava (vrijednost za novac). Ova je faza pandan detaljnom planiranju sadržaja javnog poziva za dodjelu bespovratnih sredstava u BiH; </p>
                <p className="content_text ParaOverride-1"><img className="_idGenObjectAttribute-2" src="./image/2.png" alt="" /></p>
                <p className="content_text ParaOverride-8"><span className="CharOverride-17 number_big">3.</span> <span className="CharOverride-18 text-big"> faza</span> <span className="CharOverride-19 content_text"> Razrada i finalizacija šeme i ključnih dokumenata.  </span>Ova faza obuhvata izradu obrasca prijave i smjernica za aplikante te objavljivanje šeme. Ova se faza podudara s finalizacijom i objavljivanjem javnog poziva za dodjelu bespovratnih sredstava i popratnih dokumenata (Smjernice za aplikante i obrazac prijave, te drugi obrasci prema potrebi) u BiH;</p>

                <p className="content_text mt-25"><b>Međutim, prije nego počnete raditi na prvoj fazi, priručnik vas vodi kroz nultu fazu</b>. U toj se fazi navode <b>perspektiva i kontekst</b>, možda čak i usmjerenje u pravcu određene politike u skladu s kojom bi grant šeme trebale biti pripremljene. U mjeri u kojoj je to moguće, ova faza <b>pravi vezu između tekućeg i budućeg planiranja javnih politika koje će se realizirati kroz grant šeme</b>. U nultoj su fazi, također, predstavljeni primjeri tipične upotrebe grant šema, karakteristike dobre šeme i neki uobičajeni problemi s kojima se oni koji izrađuju šeme mogu suočiti. Također, u nultoj fazi nastojimo trostepeni razvojni proces staviti u širi kontekst sadašnjih i budućih praksi kod izrade grant šema u BiH;</p>

                <p className="content_text">Dijagram u nastavku prikazuje cijeli proces izrade šeme i ključni sadržaj svake od <b>faza na koje je ovaj priručnik podijeljen te njihovu podudarnost s praksama u BiH</b>. Ovo je ilustracija cjelokupnog procesa na jednom prikazu.</p>

                <div className="custom_tables mb-25">
                    <table id="table004" class="Basic-Table TableOverride-1">
                        <colgroup>
                            <col class="_idGenTableRowColumn-17" />
                            <col class="_idGenTableRowColumn-18" />
                            <col class="_idGenTableRowColumn-19" />
                            <col class="_idGenTableRowColumn-20" />
                        </colgroup>
                        <tbody>
                            <tr class="Basic-Table _idGenTableRowColumn-21">
                                <td class="Basic-Table CellOverride-15">
                                    <p class="nalovi-u-tabelama ParaOverride-2">Faza</p>
                                </td>
                                <td class="Basic-Table CellOverride-16">
                                    <p class="nalovi-u-tabelama ParaOverride-2"><span class="CharOverride-12">PRVA faza </span></p>
                                    <p class="nalovi-u-tabelama ParaOverride-2"><span class="CharOverride-22">› </span><span class="CharOverride-23">3-4 sedmice </span><span class="CharOverride-22">›</span></p>
                                </td>
                                <td class="Basic-Table CellOverride-17">
                                    <p class="nalovi-u-tabelama ParaOverride-2"><span class="CharOverride-12">druga faza </span></p>
                                    <p class="nalovi-u-tabelama ParaOverride-2"><span class="CharOverride-22">› </span><span class="CharOverride-23">4-5 sedmica </span><span class="CharOverride-22">›</span></p>
                                </td>
                                <td class="Basic-Table CellOverride-18">
                                    <p class="nalovi-u-tabelama ParaOverride-2"><span class="CharOverride-12">treća faza</span></p>
                                    <p class="nalovi-u-tabelama ParaOverride-2"><span class="CharOverride-22">› </span><span class="CharOverride-23">2 Sedmice </span><span class="CharOverride-22">›</span><span class="CharOverride-23">  </span></p>
                                </td>
                            </tr>
                            <tr class="Basic-Table _idGenTableRowColumn-22">
                                <td class="Basic-Table CellOverride-15">
                                    <p class="nalovi-u-tabelama ParaOverride-2">Proces</p>
                                </td>
                                <td class="Basic-Table CellOverride-16">
                                    <p class="nalovi-u-tabelama ParaOverride-2"><span class="CharOverride-13">Konceptualizacija</span></p>
                                </td>
                                <td class="Basic-Table CellOverride-17">
                                    <p class="nalovi-u-tabelama ParaOverride-2"><span class="CharOverride-13">Izrada nacrta</span></p>
                                </td>
                                <td class="Basic-Table CellOverride-18">
                                    <p class="nalovi-u-tabelama ParaOverride-2"><span class="CharOverride-13">Razrada i finalizacija</span></p>
                                </td>
                            </tr>
                            <tr class="Basic-Table _idGenTableRowColumn-23">
                                <td class="Basic-Table CellOverride-15">
                                    <p class="nalovi-u-tabelama ParaOverride-2">Mjere</p>
                                </td>
                                <td class="Basic-Table CellOverride-16">
                                    <p class="tekst-u-tabelama">1. Prvi koraci;</p>
                                    <p class="tekst-u-tabelama">2. Formiranje tima za izradu grant šema;</p>
                                    <p class="tekst-u-tabelama">3. Provjera relevantnosti politike;</p>
                                    <p class="tekst-u-tabelama">4. Uključivanje relevantnih aktera;</p>
                                    <p class="tekst-u-tabelama">5. Analiza problema, potreba, zahtjeva, prilika;</p>
                                    <p class="tekst-u-tabelama">6. Jasan pravac djelovanja;</p>
                                    <p class="tekst-u-tabelama">7. Ispitivanje, predizvodljivost;</p>
                                    <p class="tekst-u-tabelama">8. Strukturiranje koncepta šeme;</p>
                                    <p class="tekst-u-tabelama">9. Postavljanje parametara za kriterije odabira;</p>
                                    <p class="tekst-u-tabelama">10. Razrada odluke;</p>
                                </td>
                                <td class="Basic-Table CellOverride-17">
                                    <p class="tekst-u-tabelama">1. Definiranje izlaznih rezultata šeme, pojašnjenje i kvantifikacija rezultata/ishoda šeme;</p>
                                    <p class="tekst-u-tabelama">2. Definiranje prihvatljivih aplikanata, aktivnosti, troškova;</p>
                                    <p class="tekst-u-tabelama">3. Omogućаvanje optimalnog odgovora aplikanata na šemu;</p>
                                    <p class="tekst-u-tabelama">4. Kriteriji odabira;</p>
                                    <p class="tekst-u-tabelama">5. Scenarij realizacije;</p>
                                    <p class="tekst-u-tabelama">6. Opća procjena izvodljivosti šeme;</p>
                                    <p class="tekst-u-tabelama">7. Izrada plana praćenja i izvještavanja;</p>
                                    <p class="tekst-u-tabelama">8. Dodatna i konačna konsolidacija relevantnih aktera;</p>
                                </td>
                                <td class="Basic-Table CellOverride-18">
                                    <p class="tekst-u-tabelama">1. Razrada javnog poziva;</p>
                                    <p class="tekst-u-tabelama">2. Razrada obrasca prijave;</p>
                                    <p class="tekst-u-tabelama">3. Razrada smjernica za aplikante;</p>
                                    <p class="tekst-u-tabelama">4. Objava javnog poziva;</p>
                                </td>
                            </tr>
                            <tr class="Basic-Table _idGenTableRowColumn-8">
                                <td class="Basic-Table CellOverride-15">
                                    <p class="nalovi-u-tabelama ParaOverride-2">Proizvod</p>
                                </td>
                                <td class="Basic-Table CellOverride-16">
                                    <p class="tekst-u-tabelama ParaOverride-2"><span class="CharOverride-13">ODLUKA</span></p>
                                </td>
                                <td class="Basic-Table CellOverride-17">
                                    <p class="tekst-u-tabelama ParaOverride-2"><span class="CharOverride-13">NACRT JAVNOG POZIVA</span></p>
                                </td>
                                <td class="Basic-Table CellOverride-18">
                                    <p class="tekst-u-tabelama ParaOverride-2"><span class="CharOverride-13">JAVNI POZIV OBJAVLJEN</span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <p className="content_text">Ovaj priručnik ilustrira tri ključne faze izrade grant šeme: <b>konceptualizacija šeme, izrada šeme, razrada i finalizacija</b> pripadajućih dokumenata. Priručnik ne govori o tome kako realizirati grant šemu, ali čitatelja vodi kroz postupak od izrade pa sve do objavljivanja šeme.</p>

                <p className="content_text">Vodeći čitatelja kroz svaku od faza, Priručnik se referira na brojne alate i obrasce koji su dati kako bi vam pomogli da uspješno okončate svaki dio postupka izrade šeme i navodi praktične primjere grant šema iz drugih evropskih zemalja.</p>

                <p className="content_text d-flex no-wrap"><img src={`${process.env.PUBLIC_URL}/images/search_yellow.svg`} alt="search" className="mr-15 image_width" /> <span>Poglavlje pod nazivom <Link to={`${process.env.PUBLIC_URL}/additional-tools-intro`}>Alati i obrasci</Link> obuhvata razne alate koji vam stoje na raspolaganju i koji se popunjavaju na osnovu vaše šeme. Neki od razvojnih alata, uključujući obrasce i kontrolne liste, kolektivno su navedeni u ovom poglavlju, a neki su navedeni u glavnom tekstu ovog dokumenta. Svim alatima i obrascima može se pristupiti putem poveznica.</span></p>

                <div className="custom_tables mb-25">
                    <table id="table005" class="No-Table-Style TableOverride-1">
                        <colgroup>
                            <col class="_idGenTableRowColumn-24" />
                            <col class="_idGenTableRowColumn-25" />
                            <col class="_idGenTableRowColumn-26" />
                        </colgroup>
                        <tbody>
                            <tr class="No-Table-Style _idGenTableRowColumn-7">
                                <td class="No-Table-Style CellOverride-19">
                                    <p class="nalovi-u-tabelama ParaOverride-2">Faza</p>
                                </td>
                                <td class="No-Table-Style CellOverride-19">
                                    <p class="nalovi-u-tabelama ParaOverride-2">Alat</p>
                                </td>
                                <td class="No-Table-Style CellOverride-19">
                                    <p class="nalovi-u-tabelama ParaOverride-2">Mjesto u priručniku</p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-7">
                                <td class="No-Table-Style CellOverride-20" rowspan="6">
                                    <p class="nalovi-u-tabelama ParaOverride-2"><span class="CharOverride-12">Prva faza</span></p>
                                </td>
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/page=1&subpage=3`}>Provjera relevantnosti politike/strategije </Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U tekstu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-1-provjera-relevantnosti-politike.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-7">
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/additional-tools#anchor_alat2`}>Struktura izjave o politici</Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U prilogu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-2-struktura-izjave-o-politici.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-7">
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/page=1&subpage=4`}>Alat za utvrđivanje relevantnih aktera</Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U tekstu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-3-određivanje-ključnih-aktera.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-27">
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/additional-tools#anchor_alat4`}>Analiza problema i potražnje</Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U prilogu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-4-analiza-problema-i-potražnje.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-28">
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/page=1&subpage=6#anchor_alat5`}>Kontrolna lista mogućih korisnika i aplikanata, moguće vrste radnji, rezultata i ishoda </Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U tekstu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-5-kontrolna-lista-mogucih-korisnika-i-aplikanata-vrsta-aktivnosti-izlaznih-rezultata-i-ishoda.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-7">
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/page=1&subpage=8#anchor_alat5`}>Matrica za planiranje grant šeme</Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U tekstu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-6-matrica-za-planiranje-grant-sheme.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-9">
                                <td class="No-Table-Style CellOverride-20" rowspan="4">
                                    <p class="nalovi-u-tabelama ParaOverride-2"><span class="CharOverride-12">Druga faza</span></p>
                                </td>
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/page=2&subpage=4#anchor_2_4`}>Provjera prihvatljivosti</Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U tekstu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-7-pitanja-o-usklađenosti.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-9">
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/page=2&subpage=4#anchor_2_4_2`}>Tablice za procjenu (relevantnosti) </Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U tekstu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-8-tablica-bodovanja-relevantnost.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-7">
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/page=2&subpage=5`}>Scenarij realizacije</Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U tekstu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-9-scenarij-za-implementaciju.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-5">
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/page=2&subpage=6`}>Kontrolna lista za provjeru izvodljivosti</Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U tekstu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-10-kontrolna-lista-za-provjeru-izvodljivosti.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-10">
                                <td class="No-Table-Style CellOverride-20" rowspan="2">
                                    <p class="nalovi-u-tabelama ParaOverride-2"><span class="CharOverride-12">Treća faza</span></p>
                                </td>
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={'/page=3&subpage=2#anchor_tools_11'}>Obrazac prijave</Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U tekstu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-11-primjer-obrasca-prijave-primjer-1.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-11">
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={'/additional-tools#anchor_alat12'}>Matrica za planiranje projekata</Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U prilogu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-12-matrica-za-planiranje-projekta-grant-sheme.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <h2 id="kako-koristiti-ovaj-prirucnik" className="blue_overlay_heading">
                    KAKO KORISTITI OVAJ PRIRUČNIK
                </h2>

                <p className="content_text">Ovaj je priručnik sveobuhvatan dokument za samostalni rad. On direktno nudi ili se poziva na izvore gdje se mogu naći ključne informacije i saznanja potrebna za izradu grant šeme.</p>

                <p className="content_text">Priručnik je dizajniran kao set alata i kao takav on:</p>

                <ul className="d-flex-s-9">
                    <li className="content_text mb-10"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="Arrow"></img> slijedi praktičan i logičan redoslijed, kao kod građenja kuće - temelji, zidovi, krov;</li>
                    <li className="content_text"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="Arrow"></img> daje praktične informacije, savjete i primjere koji vas vode kroz faze i korake.</li>
                </ul>

                <p className="content_text">Prema tome, Priručnik možete koristiti barem na dva načina:</p>
                <ul className="d-flex-s-9">
                    <li className="content_text mb-10"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="Arrow"></img> Možete ga proučavati kao bilo koji udžbenik - time ćete unaprijediti svoje znanje i razumijevanje o tome kako postupiti;</li>
                    <li className="content_text"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="Arrow"></img> Možete ga praktično koristiti kao vodič dok radite na izradi vaše šeme, prolazeći kroz njega fazu po fazu i korak po korak. Na ovaj ćete način unaprijediti svoje vještine i ujedno izraditi bolju šemu.</li>
                </ul>

                <p className="content_text">Ako ga koristite na potonji način, bitno je da faze pratite onim redoslijedom kojim su prikazane jer taj redoslijed korespondira s logičnim razvojem šeme.</p>
                <p className="content_text"> U slučajevima gdje su bila potrebna detaljnija pojašnjenja, čije bi umetanje narušilo tok ključne metodologije Priručnika, korištene su fusnote.</p>
            </div>
        </ContentPage>
    )
}

export default EntryPage;
 