import React from 'react';
import ContentPage from './';

export const Page_3_4 = () => {
    return (
        <ContentPage page={true}>
            <div className="content">
            <div className="yellow_overlay_text">Lokacija: <b>TREĆA FAZA ( Razrada dokumenata u okviru šeme ) - Objava javnog poziva za dostavljanje projekata</b></div>
            <h2 className="blue_overlay_heading">
                    KORAK 3.4 OBJAVA JAVNOG POZIVA ZA DOSTAVLJANJE PROJEKATA
                </h2>
                
                <div className="custom_tables blue_yellow_table_2 d-flex">
                    <div className="d-flex-s-12 d-flex bb-white">
                        <div className="d-flex-s-3 d-flex d-flex-aac bg-darkblue p-10">
                            <h3 className="white">KADA?</h3>
                        </div>
                        <div className="d-flex-s-9 bg-yellow">
                            <p className="content_text mb-0 p-10">13/14 - 15/16 sedmica</p>
                        </div>
                    </div>
                    <div className="d-flex-s-12 d-flex bb-white">
                        <div className="d-flex-s-3 d-flex d-flex-aac bg-darkblue p-10">
                            <h3 className="white">KOLIKO DUGO?</h3>
                        </div>
                        <div className="d-flex-s-9 bg-yellow">
                            <p className="content_text mb-0 p-10">1 dan</p>
                        </div>
                    </div>
                </div>
            
                <p className="content_text">Sada, kada ste razradili cijeli paket dokumentacije, spremni ste da ga objavite i pokrenete Poziv
                za dostavljanje projekata. Prije toga, predlažemo da <b>izvršite finalnu kontrolu kvaliteta</b>i da se
                naročito pobrinete da <b>dokumenti u paketu budu međusobno dosljedni.</b></p>

                <p className="content_text">Ako ne treba vršiti nikakve izmjene,<b> postavljate paket dokumentacije na internetsku stranicu</b>
                vaše organizacije, odakle će aplikanti moći <b>preuzeti sve ključne dokumente</b>, a naročito obrazac
                prijave. Poziv se često objavljuje i u <b>službenim novinama i postavlja na javnim mjestima</b> (ponekad
                je to zakonski uvjet). U <b>lokalnim novinama i na internetskim stranicama</b>se može objaviti
                <b> saopćenje za štampu</b> koje sadrži informacije o predmetu granta i organizaciji (ili više njih) koja
                osigurava sredstva, rokovima za dostavljanje prijava i o tome gdje se mogu pronaći svi dokumente
                iz paketa, kako bi se došlo do šir e grupe potencijalnih aplikanata.</p>
                
                <p className="content_text d-flex no-wrap"><img src={`${process.env.PUBLIC_URL}/images/document.svg`} alt="document" className="mr-15 image_width" /> <span><b>Do kraja ovog koraka(kraj Faze 3):</b> <br></br> <br />
                Osmislit ćete i finalizirati cijeli paket dokumentacije za vašu šemu i objaviti poziv.</span></p>
                
                <p className="content_text d-flex no-wrap"><img src={`${process.env.PUBLIC_URL}/images/finish_flags.svg`} alt="document" className="mr-15 class_flags" /><span><b>Čestitamo!</b> To znači da ste završili proces dizajniranja šeme i da ste spremni
                da zaprimite prijave za finansiranje i pokrenete fazu implementacije granta (koja nije obuhvaćena ovim priručnikom).</span> </p>

            </div>
        </ContentPage>
    )
}
 