import React from 'react';

export const FazeThreeSvg = props => (
    <svg
        id="prefix__Group_2186"
        x={0}
        y={0}
        viewBox="0 0 267 243.1"
        xmlSpace="preserve"
        {...props}
    >
        <style>{'.prefix__st74{fill:#abdaed}'}</style>
        <linearGradient
        id="prefix__Path_2798_1_"
        gradientUnits="userSpaceOnUse"
        x1={-170.438}
        y1={517.157}
        x2={-171.067}
        y2={516.281}
        gradientTransform="matrix(237.2993 0 0 -172.4465 40649.75 89238.953)"
        >
        <stop offset={0} stopColor="#8fcbe1" />
        <stop offset={1} stopColor="#9dd7e9" />
        </linearGradient>
        <path
        id="prefix__Path_2798"
        className="faze_one_animate_2"
        d="M189 56.6s-10.7-41.3-36-44c-30.5-3.2-46 12-75.3 45.3S7.2 104.6 6.3 141.3c-.7 25.3 33.7 58.2 78 36 61.3-30.7 129.4 17.1 152.7-18.7 18.7-28.6-4-82-48-102z"
        fill="url(#prefix__Path_2798_1_)"
        />
        <g transform="translate(0 11.74)" id="prefix__Group_2177" opacity={0.59}>
        <defs>
            <path
            id="prefix__SVGID_1_"
            d="M156.6 43.2S126.1-4.1 97 .3 75.9 31.5 46.8 45.4s-72.2 45.3-27.5 70.5c96.2 54.4 58.2 92.4 119.8 87.2 54.7-4.6 42.2-92.3 42.2-92.3l-24.7-67.6z"
            />
        </defs>
        <clipPath id="prefix__SVGID_2_">
            <use xlinkHref="#prefix__SVGID_1_" overflow="visible" />
        </clipPath>
        <g id="prefix__Group_2176" clipPath="url(#prefix__SVGID_2_)">
            <g id="prefix__Group_2105" transform="translate(30.727 -.206)">
            <linearGradient
                id="prefix__Path_2799_1_"
                gradientUnits="userSpaceOnUse"
                x1={-201.588}
                y1={495.583}
                x2={-200.588}
                y2={495.583}
                gradientTransform="matrix(150.981 0 0 -23.9822 30435.916 11897.14)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2799"
                d="M.8 24L0 22.8c.4-.3 10.7-7.6 34.6-13.9C56.5 3.1 94.5-2.9 151 1.6l-.1 1.4c-56.2-4.5-94 1.4-115.8 7.2C11.4 16.5 1 23.9.8 24z"
                fill="url(#prefix__Path_2799_1_)"
            />
            </g>
            <g id="prefix__Group_2106" transform="translate(26.676 3.75)">
            <linearGradient
                id="prefix__Path_2800_1_"
                gradientUnits="userSpaceOnUse"
                x1={-197.479}
                y1={492.934}
                x2={-196.475}
                y2={492.934}
                gradientTransform="matrix(150.365 0 0 -27.0942 29701.916 13369.22)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2800"
                d="M.9 27.1L0 25.9c.4-.3 10.5-7.9 34.2-14.8C56 4.7 93.8-2.3 150.4.7l-.1 1.5C42.6-3.6 1.3 26.8.9 27.1z"
                fill="url(#prefix__Path_2800_1_)"
            />
            </g>
            <g id="prefix__Group_2107" transform="translate(34.363 9.245)">
            <linearGradient
                id="prefix__Path_2801_1_"
                gradientUnits="userSpaceOnUse"
                x1={-205.224}
                y1={486.131}
                x2={-204.224}
                y2={486.131}
                gradientTransform="matrix(150.981 0 0 -23.9817 30984.883 11670.242)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2801"
                d="M.8 24L0 22.8c.4-.3 10.7-7.6 34.6-13.9C56.5 3.1 94.5-2.9 151 1.6l-.1 1.4c-56.2-4.5-94 1.4-115.8 7.2C11.4 16.5 1 23.9.8 24z"
                fill="url(#prefix__Path_2801_1_)"
            />
            </g>
            <g id="prefix__Group_2108" transform="translate(29.927 13.257)">
            <linearGradient
                id="prefix__Path_2802_1_"
                gradientUnits="userSpaceOnUse"
                x1={-200.735}
                y1={483.491}
                x2={-199.731}
                y2={483.491}
                gradientTransform="matrix(150.7505 0 0 -27.2668 30268.594 13196.903)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2802"
                d="M.7 27.3c-.2 0-.4-.1-.5-.3-.2-.3-.1-.8.2-1h.1-.1v-.1s.1-.1.2-.1C9.1 20.7 56.1-4.4 150.8.7l-.1 1.5C56.4-2.9 9.8 22 1.4 27c-.2.1-.5.2-.7.3z"
                fill="url(#prefix__Path_2802_1_)"
            />
            </g>
            <g id="prefix__Group_2109" transform="translate(32.294 18.069)">
            <linearGradient
                id="prefix__Path_2803_1_"
                gradientUnits="userSpaceOnUse"
                x1={-203.09}
                y1={478.817}
                x2={-202.085}
                y2={478.817}
                gradientTransform="matrix(150.251 0 0 -27.647 30523.373 13251.65)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2803"
                d="M.9 27.6L0 26.5c.4-.3 10.5-7.9 34.1-15C55.9 5 93.7-2.2 150.3.6l-.1 1.5C42.4-3.2 1.3 27.3.9 27.6z"
                fill="url(#prefix__Path_2803_1_)"
            />
            </g>
            <g id="prefix__Group_2110" transform="translate(-8.495 21.83)">
            <linearGradient
                id="prefix__Path_2804_1_"
                gradientUnits="userSpaceOnUse"
                x1={-162.504}
                y1={479.127}
                x2={-161.491}
                y2={479.127}
                gradientTransform="matrix(186.4739 0 0 -47.0491 30317.412 22566.03)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2804"
                d="M.8 47L0 45.8c.2-.1 19.6-12.2 52.2-23.8C82.2 11.3 129.9-1.2 186.5.1v1.5C130.1.3 82.6 12.7 52.6 23.4 20.3 34.9 1 46.9.8 47z"
                fill="url(#prefix__Path_2804_1_)"
            />
            </g>
            <g id="prefix__Group_2111" transform="translate(-11.092 27.655)">
            <linearGradient
                id="prefix__Path_2805_1_"
                gradientUnits="userSpaceOnUse"
                x1={-159.997}
                y1={473.493}
                x2={-158.99}
                y2={473.493}
                gradientTransform="matrix(197.5642 0 0 -48.6467 31617.662 23058.215)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2805"
                d="M.7 48.6c-.2 0-.5-.1-.6-.4-.2-.4-.1-.8.3-1 .1 0 .1-.1.3-.1 6.4-3.4 29.7-15.5 62.9-26.5C110.4 5.1 155.4-1.7 197.6.4l-.1 1.5C99.8-3 11.2 43.1 1.4 48.4c-.1 0-.2.1-.3.1-.1.1-.3.1-.4.1zm.1-.7l.4.6-.4-.6z"
                fill="url(#prefix__Path_2805_1_)"
            />
            </g>
            <g id="prefix__Group_2112" transform="translate(-15.821 33.365)">
            <linearGradient
                id="prefix__Path_2806_1_"
                gradientUnits="userSpaceOnUse"
                x1={-155.262}
                y1={468.375}
                x2={-154.249}
                y2={468.375}
                gradientTransform="matrix(202.572 0 0 -54.377 31465.496 25495.97)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2806"
                d="M.9 54.4L0 53.2c.2-.1 17.2-12.9 50.5-25.8C81.3 15.5 132.6 1.1 202.5 0v1.5C132.9 2.6 81.7 16.9 51.1 28.7 18 41.5 1 54.3.9 54.4z"
                fill="url(#prefix__Path_2806_1_)"
            />
            </g>
            <g id="prefix__Group_2113" transform="translate(-3.405 40.007)">
            <linearGradient
                id="prefix__Path_2807_1_"
                gradientUnits="userSpaceOnUse"
                x1={-167.599}
                y1={460.95}
                x2={-166.587}
                y2={460.95}
                gradientTransform="matrix(186.4729 0 0 -47.0481 31266.398 21710.363)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2807"
                d="M.8 47L0 45.8c.2-.1 19.6-12.2 52.2-23.8C82.2 11.3 129.9-1.2 186.5.1v1.5C130.1.3 82.6 12.7 52.7 23.4 20.3 34.9 1 46.9.8 47z"
                fill="url(#prefix__Path_2807_1_)"
            />
            </g>
            <g id="prefix__Group_2114" transform="translate(-5.573 45.81)">
            <linearGradient
                id="prefix__Path_2808_1_"
                gradientUnits="userSpaceOnUse"
                x1={-165.517}
                y1={455.324}
                x2={-164.51}
                y2={455.324}
                gradientTransform="matrix(197.135 0 0 -48.5237 32636.588 22118.254)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2808"
                d="M.7 48.5L0 47.3c.2-.1 22.3-13.2 57.5-25.5C90 10.5 140.6-2.4 197.1.4l-.1 1.5C140.7-.9 90.3 11.9 58 23.2 23 35.4 1 48.4.7 48.5z"
                fill="url(#prefix__Path_2808_1_)"
            />
            </g>
            <g id="prefix__Group_2115" transform="translate(-10.73 50.814)">
            <linearGradient
                id="prefix__Path_2809_1_"
                gradientUnits="userSpaceOnUse"
                x1={-160.358}
                y1={450.925}
                x2={-159.345}
                y2={450.925}
                gradientTransform="matrix(202.5688 0 0 -54.376 32496.28 24546.7)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2809"
                d="M.9 54.4L0 53.2c.2-.1 17.2-12.9 50.5-25.8C81.3 15.5 132.6 1.1 202.5 0v1.5C132.8 2.6 81.7 16.9 51.1 28.8 17.9 41.6 1 54.3.9 54.4z"
                fill="url(#prefix__Path_2809_1_)"
            />
            </g>
            <g id="prefix__Group_2116" transform="translate(-1.952 58.909)">
            <linearGradient
                id="prefix__Path_2810_1_"
                gradientUnits="userSpaceOnUse"
                x1={-169.058}
                y1={442.049}
                x2={-168.046}
                y2={442.049}
                gradientTransform="matrix(186.4739 0 0 -47.0491 31537.51 20821.498)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2810"
                d="M.8 47L0 45.8c.2-.1 19.6-12.2 52.2-23.8C82.2 11.3 129.9-1.2 186.5.1v1.5C130.1.3 82.6 12.7 52.7 23.4 20.3 34.9 1 46.9.8 47z"
                fill="url(#prefix__Path_2810_1_)"
            />
            </g>
            <g id="prefix__Group_2117" transform="translate(-4.119 65.44)">
            <linearGradient
                id="prefix__Path_2811_1_"
                gradientUnits="userSpaceOnUse"
                x1={-166.974}
                y1={435.694}
                x2={-165.966}
                y2={435.694}
                gradientTransform="matrix(197.136 0 0 -48.5244 32923.387 21166.057)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2811"
                d="M.7 48.5L0 47.3c.2-.1 22.3-13.2 57.5-25.5C90 10.5 140.6-2.4 197.1.4l-.1 1.5C140.7-.9 90.4 11.9 58 23.2 23 35.4 1 48.4.7 48.5z"
                fill="url(#prefix__Path_2811_1_)"
            />
            </g>
            <g id="prefix__Group_2118" transform="translate(-9.277 70.444)">
            <linearGradient
                id="prefix__Path_2812_1_"
                gradientUnits="userSpaceOnUse"
                x1={-161.817}
                y1={431.295}
                x2={-160.804}
                y2={431.295}
                gradientTransform="matrix(202.571 0 0 -54.376 32790.969 23479.3)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2812"
                d="M.9 54.4L0 53.2c.2-.1 17.2-12.9 50.5-25.8C81.3 15.5 132.6 1.1 202.5 0v1.5C132.9 2.6 81.7 16.9 51.1 28.7 18 41.5 1 54.3.9 54.4z"
                fill="url(#prefix__Path_2812_1_)"
            />
            </g>
            <g id="prefix__Group_2119" transform="translate(3.137 77.814)">
            <linearGradient
                id="prefix__Path_2813_1_"
                gradientUnits="userSpaceOnUse"
                x1={-174.153}
                y1={423.143}
                x2={-173.141}
                y2={423.143}
                gradientTransform="matrix(186.4739 0 0 -47.0479 32486.477 19931.512)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2813"
                d="M.8 47L0 45.8c.2-.1 19.6-12.2 52.2-23.8C82.2 11.3 129.9-1.2 186.5.1v1.5C130.1.3 82.6 12.7 52.6 23.4 20.3 34.9 1 46.9.8 47z"
                fill="url(#prefix__Path_2813_1_)"
            />
            </g>
            <g id="prefix__Group_2120" transform="translate(.971 84.344)">
            <linearGradient
                id="prefix__Path_2814_1_"
                gradientUnits="userSpaceOnUse"
                x1={-172.066}
                y1={416.79}
                x2={-171.06}
                y2={416.79}
                gradientTransform="matrix(197.134 0 0 -48.5232 33926.469 20248.234)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2814"
                d="M.7 48.5L0 47.3c.2-.1 22.3-13.2 57.5-25.5C90 10.5 140.6-2.4 197.1.4l-.1 1.5C140.7-.9 90.3 11.9 58 23.2 22.9 35.4 1 48.4.7 48.5z"
                fill="url(#prefix__Path_2814_1_)"
            />
            </g>
            <g id="prefix__Group_2121" transform="translate(-4.187 88.62)">
            <linearGradient
                id="prefix__Path_2815_1_"
                gradientUnits="userSpaceOnUse"
                x1={-166.912}
                y1={413.119}
                x2={-165.899}
                y2={413.119}
                gradientTransform="matrix(202.571 0 0 -54.376 33822.055 22490.959)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2815"
                d="M.9 54.4L0 53.2c.2-.1 17.2-12.9 50.5-25.8C81.3 15.5 132.6 1.1 202.5 0v1.5C132.9 2.6 81.7 16.9 51.1 28.7 18 41.5 1 54.3.9 54.4z"
                fill="url(#prefix__Path_2815_1_)"
            />
            </g>
            <g id="prefix__Group_2122" transform="translate(30.727 -3.115)">
            <linearGradient
                id="prefix__Path_2816_1_"
                gradientUnits="userSpaceOnUse"
                x1={-201.588}
                y1={498.492}
                x2={-200.588}
                y2={498.492}
                gradientTransform="matrix(150.981 0 0 -23.9827 30435.916 11967.152)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2816"
                d="M.8 24L0 22.8c.4-.3 10.7-7.6 34.6-13.9C56.5 3.1 94.5-2.9 151 1.6l-.1 1.4C43.3-5.6 1.3 23.7.8 24z"
                fill="url(#prefix__Path_2816_1_)"
            />
            </g>
            <g id="prefix__Group_2123" transform="translate(26.676 1.569)">
            <linearGradient
                id="prefix__Path_2817_1_"
                gradientUnits="userSpaceOnUse"
                x1={-197.479}
                y1={495.115}
                x2={-196.475}
                y2={495.115}
                gradientTransform="matrix(150.365 0 0 -27.0952 29701.916 13428.806)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2817"
                d="M.9 27.1L0 25.9c.4-.3 10.5-7.9 34.2-14.8C56 4.7 93.8-2.3 150.4.7l-.1 1.5C42.6-3.6 1.3 26.8.9 27.1z"
                fill="url(#prefix__Path_2817_1_)"
            />
            </g>
            <g id="prefix__Group_2124" transform="translate(34.363 6.336)">
            <linearGradient
                id="prefix__Path_2818_1_"
                gradientUnits="userSpaceOnUse"
                x1={-205.224}
                y1={489.041}
                x2={-204.224}
                y2={489.041}
                gradientTransform="matrix(150.981 0 0 -23.9836 30984.883 11740.982)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2818"
                d="M.8 24L0 22.8c.4-.3 10.7-7.6 34.6-13.9C56.5 3.1 94.5-2.9 151 1.6l-.1 1.4C43.3-5.6 1.3 23.7.8 24z"
                fill="url(#prefix__Path_2818_1_)"
            />
            </g>
            <g id="prefix__Group_2125" transform="translate(30.312 11.021)">
            <linearGradient
                id="prefix__Path_2819_1_"
                gradientUnits="userSpaceOnUse"
                x1={-201.116}
                y1={485.663}
                x2={-200.112}
                y2={485.663}
                gradientTransform="matrix(150.365 0 0 -27.0938 30248.643 13171.975)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2819"
                d="M.9 27.1L0 25.9c.4-.3 10.5-7.9 34.2-14.8C56 4.7 93.8-2.3 150.4.7l-.1 1.5C94-.8 56.4 6.1 34.7 12.5 11.3 19.3 1 27 .9 27.1z"
                fill="url(#prefix__Path_2819_1_)"
            />
            </g>
            <g id="prefix__Group_2126" transform="translate(31.913 15.207)">
            <linearGradient
                id="prefix__Path_2820_1_"
                gradientUnits="userSpaceOnUse"
                x1={-202.711}
                y1={481.743}
                x2={-201.706}
                y2={481.743}
                gradientTransform="matrix(150.6338 0 0 -27.8296 30544.188 13420.633)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2820"
                d="M.7 27.8c-.2 0-.4-.1-.5-.3-.2-.3-.1-.8.2-1h.1l.1.1v-.1s.1-.1.2-.1C9.2 21.3 56.1-4 150.8.6l-.2 1.4C56.3-2.6 9.8 22.5 1.4 27.6c-.2.1-.5.2-.7.2z"
                fill="url(#prefix__Path_2820_1_)"
            />
            </g>
            <g id="prefix__Group_2127" transform="translate(-8.495 18.922)">
            <linearGradient
                id="prefix__Path_2821_1_"
                gradientUnits="userSpaceOnUse"
                x1={-162.503}
                y1={482.036}
                x2={-161.49}
                y2={482.036}
                gradientTransform="matrix(186.4739 0 0 -47.0491 30317.412 22702.85)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2821"
                d="M.8 47L0 45.8c.2-.1 19.6-12.2 52.2-23.8C82.2 11.3 129.9-1.2 186.5.1v1.5C130.1.3 82.6 12.7 52.7 23.4 20.3 34.9 1 46.9.8 47z"
                fill="url(#prefix__Path_2821_1_)"
            />
            </g>
            <g id="prefix__Group_2128" transform="translate(-10.662 24.725)">
            <linearGradient
                id="prefix__Path_2822_1_"
                gradientUnits="userSpaceOnUse"
                x1={-160.424}
                y1={476.409}
                x2={-159.417}
                y2={476.409}
                gradientTransform="matrix(197.134 0 0 -48.5237 31633.21 23141.375)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2822"
                d="M.7 48.5L0 47.3c.2-.1 22.3-13.2 57.5-25.5C90 10.5 140.6-2.4 197.1.4l-.1 1.5C140.7-.9 90.3 11.9 58 23.2 22.9 35.4 1 48.4.7 48.5z"
                fill="url(#prefix__Path_2822_1_)"
            />
            </g>
            <g id="prefix__Group_2129" transform="translate(-15.821 29.729)">
            <linearGradient
                id="prefix__Path_2823_1_"
                gradientUnits="userSpaceOnUse"
                x1={-155.261}
                y1={472.01}
                x2={-154.248}
                y2={472.01}
                gradientTransform="matrix(202.572 0 0 -54.375 31465.496 25692.752)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2823"
                d="M.9 54.4L0 53.2c.2-.1 17.2-12.9 50.5-25.8C81.3 15.5 132.6 1.1 202.5 0v1.5C132.8 2.6 81.7 16.9 51.1 28.8 17.9 41.6 1 54.3.9 54.4z"
                fill="url(#prefix__Path_2823_1_)"
            />
            </g>
            <g id="prefix__Group_2130" transform="translate(-3.405 37.098)">
            <linearGradient
                id="prefix__Path_2824_1_"
                gradientUnits="userSpaceOnUse"
                x1={-167.598}
                y1={463.86}
                x2={-166.586}
                y2={463.86}
                gradientTransform="matrix(186.4729 0 0 -47.0491 31266.398 21847.685)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2824"
                d="M.8 47L0 45.8c.2-.1 19.6-12.2 52.2-23.8C82.2 11.3 129.9-1.2 186.5.1v1.5C130.1.3 82.6 12.7 52.7 23.4 20.3 34.9 1 46.9.8 47z"
                fill="url(#prefix__Path_2824_1_)"
            />
            </g>
            <g id="prefix__Group_2131" transform="translate(-5.573 42.902)">
            <linearGradient
                id="prefix__Path_2825_1_"
                gradientUnits="userSpaceOnUse"
                x1={-165.517}
                y1={458.232}
                x2={-164.51}
                y2={458.232}
                gradientTransform="matrix(197.135 0 0 -48.5244 32636.588 22259.7)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2825"
                d="M.7 48.5L0 47.3c.2-.1 22.3-13.2 57.5-25.5C90 10.5 140.6-2.4 197.1.4l-.1 1.5C140.7-.9 90.4 11.9 58 23.2 23 35.4 1 48.4.7 48.5z"
                fill="url(#prefix__Path_2825_1_)"
            />
            </g>
            <g id="prefix__Group_2132" transform="translate(-10.73 47.906)">
            <linearGradient
                id="prefix__Path_2826_1_"
                gradientUnits="userSpaceOnUse"
                x1={-160.358}
                y1={453.834}
                x2={-159.344}
                y2={453.834}
                gradientTransform="matrix(202.5688 0 0 -54.3799 32496.28 24706.615)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2826"
                d="M.9 54.4L0 53.2l.1-.1c4.4-3.4 21.1-15.3 51.8-27C95 9.7 145.7.9 202.5 0v1.5c-56.7.9-107.2 9.7-150.1 26C22 39.1 5.4 50.9 1 54.3l-.1.1z"
                fill="url(#prefix__Path_2826_1_)"
            />
            </g>
            <g id="prefix__Group_2133" transform="translate(-1.952 55.991)">
            <linearGradient
                id="prefix__Path_2827_1_"
                gradientUnits="userSpaceOnUse"
                x1={-169.057}
                y1={444.968}
                x2={-168.045}
                y2={444.968}
                gradientTransform="matrix(186.4729 0 0 -47.0588 31537.344 20963.195)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2827"
                d="M.8 47.1L0 45.8l.2-.2c12.6-8 81-47.9 186.2-45.6v1.5C81.6-.8 13.6 38.9 1 46.9l-.2.2z"
                fill="url(#prefix__Path_2827_1_)"
            />
            </g>
            <g id="prefix__Group_2134" transform="translate(-4.119 61.805)">
            <linearGradient
                id="prefix__Path_2828_1_"
                gradientUnits="userSpaceOnUse"
                x1={-166.974}
                y1={439.329}
                x2={-165.966}
                y2={439.329}
                gradientTransform="matrix(197.136 0 0 -48.5247 32923.387 21342.55)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2828"
                d="M.7 48.5L0 47.3c.2-.1 22.3-13.2 57.5-25.5C90 10.5 140.6-2.4 197.1.4l-.1 1.5C140.7-.9 90.4 11.9 58 23.2 23 35.4 1 48.4.7 48.5z"
                fill="url(#prefix__Path_2828_1_)"
            />
            </g>
            <g id="prefix__Group_2135" transform="translate(-9.277 67.535)">
            <linearGradient
                id="prefix__Path_2829_1_"
                gradientUnits="userSpaceOnUse"
                x1={-161.816}
                y1={434.204}
                x2={-160.803}
                y2={434.204}
                gradientTransform="matrix(202.5708 0 0 -54.376 32790.93 23637.48)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2829"
                d="M.9 54.4L0 53.2c.2-.1 17.2-12.9 50.5-25.8C81.3 15.5 132.6 1.1 202.5 0v1.5C132.8 2.6 81.7 16.9 51 28.8 17.9 41.6 1 54.3.9 54.4z"
                fill="url(#prefix__Path_2829_1_)"
            />
            </g>
            <g id="prefix__Group_2136" transform="translate(3.137 74.905)">
            <linearGradient
                id="prefix__Path_2830_1_"
                gradientUnits="userSpaceOnUse"
                x1={-174.152}
                y1={426.053}
                x2={-173.14}
                y2={426.053}
                gradientTransform="matrix(186.4739 0 0 -47.0525 32486.477 20070.375)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2830"
                d="M.8 47L0 45.8c.2-.1 19.6-12.2 52.2-23.8C82.2 11.3 129.9-1.2 186.5.1v1.5C130.1.3 82.6 12.7 52.6 23.4 20.3 34.9 1 46.9.8 47z"
                fill="url(#prefix__Path_2830_1_)"
            />
            </g>
            <g id="prefix__Group_2137" transform="translate(.971 80.708)">
            <linearGradient
                id="prefix__Path_2831_1_"
                gradientUnits="userSpaceOnUse"
                x1={-172.066}
                y1={420.426}
                x2={-171.06}
                y2={420.426}
                gradientTransform="matrix(197.134 0 0 -48.5239 33926.469 20424.977)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2831"
                d="M.7 48.5L0 47.3c.2-.1 22.3-13.2 57.5-25.5C90 10.5 140.6-2.4 197.1.4l-.1 1.5C140.7-.9 90.3 11.9 58 23.2 22.9 35.4 1 48.4.7 48.5z"
                fill="url(#prefix__Path_2831_1_)"
            />
            </g>
            <g id="prefix__Group_2138" transform="translate(-4.187 85.712)">
            <linearGradient
                id="prefix__Path_2832_1_"
                gradientUnits="userSpaceOnUse"
                x1={-166.911}
                y1={416.027}
                x2={-165.898}
                y2={416.027}
                gradientTransform="matrix(202.571 0 0 -54.376 33822.055 22649.09)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2832"
                d="M.9 54.4L0 53.2c.2-.1 17.2-12.9 50.5-25.8C81.3 15.5 132.6 1.1 202.5 0v1.5C132.9 2.6 81.7 16.9 51.1 28.7 18 41.5 1 54.3.9 54.4z"
                fill="url(#prefix__Path_2832_1_)"
            />
            </g>
            <g id="prefix__Group_2139" transform="translate(8.178 94.408)">
            <linearGradient
                id="prefix__Path_2833_1_"
                gradientUnits="userSpaceOnUse"
                x1={-179.227}
                y1={406.163}
                x2={-178.218}
                y2={406.163}
                gradientTransform="matrix(187.2151 0 0 -44.1091 33560.04 17937.543)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2833"
                d="M.8 44.1L0 42.9c.2-.2 19.8-11.9 52.6-23C82.8 9.7 130.6-1.9 187.2.3l-.1 1.5C130.8-.5 83.1 11.2 53 21.3 20.4 32.3.9 44 .8 44.1z"
                fill="url(#prefix__Path_2833_1_)"
            />
            </g>
            <g id="prefix__Group_2140" transform="translate(5.878 100.957)">
            <linearGradient
                id="prefix__Path_2834_1_"
                gradientUnits="userSpaceOnUse"
                x1={-177.001}
                y1={399.807}
                x2={-175.998}
                y2={399.807}
                gradientTransform="matrix(197.8931 0 0 -45.5269 35028.824 18224.7)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2834"
                d="M.7 45.5L0 44.3c.2-.1 22.6-12.8 57.9-24.5C90.6 9 141.4-3 197.9.7l-.1 1.4C141.5-1.6 91 10.4 58.4 21.1 23.2 32.8.9 45.4.7 45.5z"
                fill="url(#prefix__Path_2834_1_)"
            />
            </g>
            <g id="prefix__Group_2141" transform="translate(.536 106.273)">
            <linearGradient
                id="prefix__Path_2835_1_"
                gradientUnits="userSpaceOnUse"
                x1={-171.664}
                y1={395.133}
                x2={-170.655}
                y2={395.133}
                gradientTransform="matrix(203.4131 0 0 -50.9861 34924.078 20171.756)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2835"
                d="M.9 51L0 49.8c.2-.1 17.4-12.6 51-25C81.8 13.5 133.3 0 203.1 0h.4v1.5h-.4c-69.5 0-120.8 13.4-151.5 24.7C18.1 38.4 1 50.9.9 51z"
                fill="url(#prefix__Path_2835_1_)"
            />
            </g>
            <g id="prefix__Group_2142" transform="translate(12.949 113.395)">
            <linearGradient
                id="prefix__Path_2836_1_"
                gradientUnits="userSpaceOnUse"
                x1={-184.002}
                y1={387.176}
                x2={-182.994}
                y2={387.176}
                gradientTransform="matrix(187.2139 0 0 -44.1077 34453.016 17099.467)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2836"
                d="M.8 44.1L0 42.9c.2-.2 19.8-11.9 52.6-23C82.8 9.7 130.6-1.9 187.2.3l-.1 1.5C130.8-.5 83.1 11.2 53 21.3 20.4 32.3.9 44 .8 44.1z"
                fill="url(#prefix__Path_2836_1_)"
            />
            </g>
            <g id="prefix__Group_2143" transform="translate(10.649 119.943)">
            <linearGradient
                id="prefix__Path_2837_1_"
                gradientUnits="userSpaceOnUse"
                x1={-181.774}
                y1={380.821}
                x2={-180.771}
                y2={380.821}
                gradientTransform="matrix(197.894 0 0 -45.5278 35973.148 17360.705)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2837"
                d="M.7 45.5L0 44.3c.2-.1 22.6-12.8 57.9-24.5C90.6 9 141.4-3 197.9.7l-.1 1.5C141.5-1.6 91 10.4 58.4 21.1 23.2 32.8.9 45.4.7 45.5z"
                fill="url(#prefix__Path_2837_1_)"
            />
            </g>
            <g id="prefix__Group_2144" transform="translate(5.321 124.532)">
            <linearGradient
                id="prefix__Path_2838_1_"
                gradientUnits="userSpaceOnUse"
                x1={-176.453}
                y1={376.874}
                x2={-175.444}
                y2={376.874}
                gradientTransform="matrix(203.4109 0 0 -50.9861 35897.023 19240.803)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2838"
                d="M.9 51L0 49.8c.2-.1 17.4-12.6 51-25C81.8 13.5 133.3 0 203.1 0h.4v1.5h-.4C133.5 1.5 82.3 15 51.5 26.3 18.1 38.5 1 50.9.9 51z"
                fill="url(#prefix__Path_2838_1_)"
            />
            </g>
            <g id="prefix__Group_2145" transform="translate(5.939 97.322)">
            <linearGradient
                id="prefix__Path_2839_1_"
                gradientUnits="userSpaceOnUse"
                x1={-177.062}
                y1={403.442}
                x2={-176.059}
                y2={403.442}
                gradientTransform="matrix(197.894 0 0 -45.5278 35041.066 18390.59)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2839"
                d="M.7 45.5L0 44.3c.2-.1 22.6-12.8 57.9-24.5C90.6 9 141.4-3 197.9.7l-.1 1.5C141.5-1.6 91 10.4 58.4 21.1 23.2 32.8.9 45.4.7 45.5z"
                fill="url(#prefix__Path_2839_1_)"
            />
            </g>
            <g id="prefix__Group_2146" transform="translate(.585 103.365)">
            <linearGradient
                id="prefix__Path_2840_1_"
                gradientUnits="userSpaceOnUse"
                x1={-171.712}
                y1={398.041}
                x2={-170.703}
                y2={398.041}
                gradientTransform="matrix(203.4131 0 0 -50.9849 34934.047 20319.533)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2840"
                d="M.9 51L0 49.8c.2-.1 17.4-12.6 51-25C81.8 13.5 133.3 0 203.1 0h.4v1.5h-.4C133.5 1.5 82.3 15 51.5 26.3 18.1 38.5 1 50.9.9 51z"
                fill="url(#prefix__Path_2840_1_)"
            />
            </g>
            <g id="prefix__Group_2147" transform="translate(12.999 110.487)">
            <linearGradient
                id="prefix__Path_2841_1_"
                gradientUnits="userSpaceOnUse"
                x1={-184.051}
                y1={390.084}
                x2={-183.043}
                y2={390.084}
                gradientTransform="matrix(187.2141 0 0 -44.1082 34462.422 17227.924)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2841"
                d="M.8 44.1L0 42.9c.2-.2 19.8-11.9 52.6-23C82.8 9.7 130.6-1.9 187.2.3l-.1 1.5C130.8-.5 83.1 11.2 53 21.3 20.4 32.3.9 44 .8 44.1z"
                fill="url(#prefix__Path_2841_1_)"
            />
            </g>
            <g id="prefix__Group_2148" transform="translate(10.71 116.309)">
            <linearGradient
                id="prefix__Path_2842_1_"
                gradientUnits="userSpaceOnUse"
                x1={-181.834}
                y1={384.455}
                x2={-180.831}
                y2={384.455}
                gradientTransform="matrix(197.8931 0 0 -45.5273 35985.043 17525.96)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2842"
                d="M.7 45.5L0 44.3c.2-.1 22.6-12.8 57.9-24.5C90.6 9 141.4-3 197.9.7l-.1 1.4C141.5-1.6 91 10.4 58.4 21.1 23.2 32.8.9 45.4.7 45.5z"
                fill="url(#prefix__Path_2842_1_)"
            />
            </g>
            <g id="prefix__Group_2149" transform="translate(5.369 121.625)">
            <linearGradient
                id="prefix__Path_2843_1_"
                gradientUnits="userSpaceOnUse"
                x1={-176.5}
                y1={379.781}
                x2={-175.491}
                y2={379.781}
                gradientTransform="matrix(203.4131 0 0 -50.9851 35907.176 19388.643)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2843"
                d="M.9 51L0 49.8c.2-.1 17.4-12.6 51-25C81.8 13.5 133.3 0 203.1 0h.4v1.5h-.4C133.5 1.5 82.3 15 51.5 26.3 18.1 38.5 1 50.9.9 51z"
                fill="url(#prefix__Path_2843_1_)"
            />
            </g>
            <g id="prefix__Group_2150" transform="translate(5.321 130.348)">
            <linearGradient
                id="prefix__Path_2844_1_"
                gradientUnits="userSpaceOnUse"
                x1={-176.454}
                y1={371.058}
                x2={-175.445}
                y2={371.058}
                gradientTransform="matrix(203.4109 0 0 -50.9861 35897.023 18944.268)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2844"
                d="M.9 51L0 49.8c.2-.1 17.4-12.6 51-25C81.8 13.5 133.3 0 203.1 0h.4v1.5h-.4C133.5 1.5 82.3 15 51.5 26.3 18.1 38.5 1 50.9.9 51z"
                fill="url(#prefix__Path_2844_1_)"
            />
            </g>
            <g id="prefix__Group_2151" transform="translate(5.369 127.44)">
            <linearGradient
                id="prefix__Path_2845_1_"
                gradientUnits="userSpaceOnUse"
                x1={-176.501}
                y1={373.966}
                x2={-175.492}
                y2={373.966}
                gradientTransform="matrix(203.4131 0 0 -50.9861 35907.176 19092.535)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2845"
                d="M.9 51L0 49.8c.2-.1 17.4-12.6 51-25C81.8 13.5 133.3 0 203.1 0h.4v1.5h-.4C133.5 1.5 82.3 15 51.5 26.3 18.1 38.5 1 50.9.9 51z"
                fill="url(#prefix__Path_2845_1_)"
            />
            </g>
            <g id="prefix__Group_2152" transform="translate(17.678 130.887)">
            <linearGradient
                id="prefix__Path_2846_1_"
                gradientUnits="userSpaceOnUse"
                x1={-188.711}
                y1={370.07}
                x2={-187.698}
                y2={370.07}
                gradientTransform="matrix(186.4729 0 0 -47.0481 35197.809 17434.629)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2846"
                d="M.8 47L0 45.8c.2-.1 19.6-12.2 52.2-23.8C82.2 11.3 129.9-1.2 186.5.1v1.5C130.1.3 82.6 12.7 52.7 23.4 20.3 34.9 1 46.9.8 47z"
                fill="url(#prefix__Path_2846_1_)"
            />
            </g>
            <g id="prefix__Group_2153" transform="translate(15.511 136.691)">
            <linearGradient
                id="prefix__Path_2847_1_"
                gradientUnits="userSpaceOnUse"
                x1={-186.615}
                y1={364.443}
                x2={-185.608}
                y2={364.443}
                gradientTransform="matrix(197.135 0 0 -48.5242 36792.984 17708.553)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2847"
                d="M.7 48.5L0 47.3c.2-.1 22.3-13.2 57.5-25.5C90 10.5 140.6-2.4 197.1.4l-.1 1.5C140.7-.9 90.3 11.9 58 23.2 22.9 35.4 1 48.4.7 48.5z"
                fill="url(#prefix__Path_2847_1_)"
            />
            </g>
            <g id="prefix__Group_2154" transform="translate(10.354 141.694)">
            <linearGradient
                id="prefix__Path_2848_1_"
                gradientUnits="userSpaceOnUse"
                x1={-181.468}
                y1={360.046}
                x2={-180.455}
                y2={360.046}
                gradientTransform="matrix(202.5708 0 0 -54.377 36767.594 19605.367)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2848"
                d="M.9 54.4L0 53.2c.2-.1 17.2-12.9 50.5-25.8C81.3 15.5 132.6 1.1 202.5 0v1.5C132.9 2.6 81.7 16.9 51.1 28.7 17.9 41.5 1 54.3.9 54.4z"
                fill="url(#prefix__Path_2848_1_)"
            />
            </g>
            <g id="prefix__Group_2155" transform="translate(22.768 149.063)">
            <linearGradient
                id="prefix__Path_2849_1_"
                gradientUnits="userSpaceOnUse"
                x1={-193.806}
                y1={351.894}
                x2={-192.794}
                y2={351.894}
                gradientTransform="matrix(186.4739 0 0 -47.0488 36147.145 16579.748)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2849"
                d="M.8 47L0 45.8c.2-.1 19.6-12.2 52.2-23.8C82.2 11.3 129.9-1.2 186.5.1v1.5C130.1.3 82.6 12.7 52.6 23.4 20.3 34.9 1 46.9.8 47z"
                fill="url(#prefix__Path_2849_1_)"
            />
            </g>
            <g id="prefix__Group_2156" transform="translate(20.6 154.866)">
            <linearGradient
                id="prefix__Path_2850_1_"
                gradientUnits="userSpaceOnUse"
                x1={-191.707}
                y1={346.268}
                x2={-190.701}
                y2={346.268}
                gradientTransform="matrix(197.136 0 0 -48.5244 37796.395 16826.713)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2850"
                d="M.7 48.5L0 47.3c.2-.1 22.3-13.2 57.5-25.5C90 10.5 140.6-2.4 197.1.4l-.1 1.5C140.7-.9 90.3 11.9 58 23.2 22.9 35.4 1 48.4.7 48.5z"
                fill="url(#prefix__Path_2850_1_)"
            />
            </g>
            <g id="prefix__Group_2157" transform="translate(15.443 160.597)">
            <linearGradient
                id="prefix__Path_2851_1_"
                gradientUnits="userSpaceOnUse"
                x1={-186.563}
                y1={341.142}
                x2={-185.549}
                y2={341.142}
                gradientTransform="matrix(202.571 0 0 -54.376 37798.523 18577.143)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2851"
                d="M.9 54.4L0 53.2c.2-.1 17.2-12.9 50.5-25.8C81.3 15.5 132.6 1.1 202.5 0v1.5C132.9 2.6 81.7 16.9 51.1 28.7 18 41.5 1 54.3.9 54.4z"
                fill="url(#prefix__Path_2851_1_)"
            />
            </g>
            <g id="prefix__Group_2158" transform="translate(32.22 166.511)">
            <linearGradient
                id="prefix__Path_2852_1_"
                gradientUnits="userSpaceOnUse"
                x1={-203.264}
                y1={334.447}
                x2={-202.251}
                y2={334.447}
                gradientTransform="matrix(186.4729 0 0 -47.0491 37909.496 15758.927)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2852"
                d="M.8 47.1L0 45.8c.2-.1 19.6-12.2 52.2-23.8C82.2 11.3 129.9-1.2 186.5.1v1.5C130.1.3 82.6 12.7 52.6 23.4 20.3 34.9 1 46.9.8 47.1z"
                fill="url(#prefix__Path_2852_1_)"
            />
            </g>
            <g id="prefix__Group_2159" transform="translate(30.052 172.315)">
            <linearGradient
                id="prefix__Path_2853_1_"
                gradientUnits="userSpaceOnUse"
                x1={-201.161}
                y1={328.819}
                x2={-200.155}
                y2={328.819}
                gradientTransform="matrix(197.135 0 0 -48.5244 39659.523 15980.01)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2853"
                d="M.7 48.5L0 47.3c.2-.1 22.3-13.2 57.5-25.5C90 10.5 140.6-2.4 197.1.4l-.1 1.5C140.7-.9 90.3 11.9 58 23.2 23 35.4 1 48.4.7 48.5z"
                fill="url(#prefix__Path_2853_1_)"
            />
            </g>
            <g id="prefix__Group_2160" transform="translate(24.895 177.32)">
            <linearGradient
                id="prefix__Path_2854_1_"
                gradientUnits="userSpaceOnUse"
                x1={-196.019}
                y1={324.419}
                x2={-195.006}
                y2={324.419}
                gradientTransform="matrix(202.5708 0 0 -54.376 39713.18 17667.818)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2854"
                d="M.9 54.4L0 53.2c.2-.1 17.2-12.9 50.5-25.8C81.3 15.5 132.6 1.1 202.5 0v1.5C132.9 2.6 81.7 16.9 51.1 28.7 17.9 41.5 1 54.3.9 54.4z"
                fill="url(#prefix__Path_2854_1_)"
            />
            </g>
            <g id="prefix__Group_2161" transform="translate(37.309 184.688)">
            <linearGradient
                id="prefix__Path_2855_1_"
                gradientUnits="userSpaceOnUse"
                x1={-208.358}
                y1={316.269}
                x2={-207.346}
                y2={316.269}
                gradientTransform="matrix(186.4729 0 0 -47.0481 38858.457 14903.398)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2855"
                d="M.8 47L0 45.8c.2-.1 19.6-12.2 52.2-23.8C82.2 11.3 129.9-1.2 186.5.1v1.5C130.1.3 82.6 12.7 52.7 23.4 20.3 34.9 1 46.9.8 47z"
                fill="url(#prefix__Path_2855_1_)"
            />
            </g>
            <g id="prefix__Group_2162" transform="translate(35.141 190.492)">
            <linearGradient
                id="prefix__Path_2856_1_"
                gradientUnits="userSpaceOnUse"
                x1={-206.254}
                y1={310.642}
                x2={-205.246}
                y2={310.642}
                gradientTransform="matrix(197.135 0 0 -48.5237 40662.742 15097.749)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2856"
                d="M.7 48.5L0 47.3c.2-.1 22.3-13.2 57.5-25.5C90 10.5 140.6-2.4 197.1.4l-.1 1.5C140.7-.9 90.4 11.9 58 23.2 23 35.4 1 48.4.7 48.5z"
                fill="url(#prefix__Path_2856_1_)"
            />
            </g>
            <g id="prefix__Group_2163" transform="translate(29.984 195.495)">
            <linearGradient
                id="prefix__Path_2857_1_"
                gradientUnits="userSpaceOnUse"
                x1={-201.114}
                y1={306.244}
                x2={-200.1}
                y2={306.244}
                gradientTransform="matrix(202.571 0 0 -54.376 40744.11 16679.531)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2857"
                d="M.9 54.4L0 53.2c.2-.1 17.2-12.9 50.5-25.8C81.3 15.5 132.6 1.1 202.5 0v1.5C132.9 2.6 81.7 16.9 51.1 28.7 17.9 41.5 1 54.3.9 54.4z"
                fill="url(#prefix__Path_2857_1_)"
            />
            </g>
            <g id="prefix__Group_2164" transform="translate(17.467 132.535)">
            <linearGradient
                id="prefix__Path_2858_1_"
                gradientUnits="userSpaceOnUse"
                x1={-188.48}
                y1={368.765}
                x2={-187.464}
                y2={368.765}
                gradientTransform="matrix(185.7009 0 0 -49.999 35012.2 18462.885)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2858"
                d="M.8 50L0 48.8c.2-.1 19.4-12.5 51.8-24.6C81.6 12.9 129.1-.3 185.7 0v1.5c-45.6-.1-90.8 8.1-133.4 24C20.1 37.6 1 49.9.8 50z"
                fill="url(#prefix__Path_2858_1_)"
            />
            </g>
            <g id="prefix__Group_2165" transform="translate(15.419 138.33)">
            <linearGradient
                id="prefix__Path_2859_1_"
                gradientUnits="userSpaceOnUse"
                x1={-186.5}
                y1={363.131}
                x2={-185.489}
                y2={363.131}
                gradientTransform="matrix(196.3411 0 0 -51.5173 36626.047 18733.291)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2859"
                d="M.8 51.5L0 50.3c.2-.1 22.1-13.6 57.1-26.4C89.4 12 139.7-1.7 196.3.2v1.5C139.9-.2 89.8 13.5 57.6 25.2 22.8 38 1 51.4.8 51.5z"
                fill="url(#prefix__Path_2859_1_)"
            />
            </g>
            <g id="prefix__Group_2166" transform="translate(10.442 143.116)">
            <linearGradient
                id="prefix__Path_2860_1_"
                gradientUnits="userSpaceOnUse"
                x1={-181.54}
                y1={358.91}
                x2={-180.522}
                y2={358.91}
                gradientTransform="matrix(201.7012 0 0 -57.6721 36626.766 20727.957)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2860"
                d="M.9 57.7L0 56.5c.2-.1 17-13.2 50.1-26.6C80.6 17.5 131.7 2.3 201.7 0v1.5C132 3.7 81.1 18.9 50.7 31.2 17.8 44.6 1.1 57.5.9 57.7z"
                fill="url(#prefix__Path_2860_1_)"
            />
            </g>
            <g id="prefix__Group_2167" transform="translate(22.854 150.626)">
            <linearGradient
                id="prefix__Path_2861_1_"
                gradientUnits="userSpaceOnUse"
                x1={-193.875}
                y1={350.674}
                x2={-192.858}
                y2={350.674}
                gradientTransform="matrix(185.7 0 0 -49.9971 36012.379 17557.656)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2861"
                d="M.8 50L0 48.8c.2-.1 19.4-12.5 51.8-24.6C81.6 12.9 129.1-.3 185.7 0v1.5C129.3 1.1 82 14.3 52.3 25.5 20.1 37.6 1 49.9.8 50z"
                fill="url(#prefix__Path_2861_1_)"
            />
            </g>
            <g id="prefix__Group_2168" transform="translate(20.127 159.556)">
            <linearGradient
                id="prefix__Path_2862_1_"
                gradientUnits="userSpaceOnUse"
                x1={-191.249}
                y1={341.305}
                x2={-190.245}
                y2={341.305}
                gradientTransform="matrix(197.7061 0 0 -46.2803 37812.672 15818.837)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2862"
                d="M.7 46.3L0 45c.2-.1 22.5-12.9 57.8-24.7C90.4 9.4 141.2-2.9 197.7.6l-.1 1.4C141.3-1.4 90.8 10.8 58.3 21.7 23.1 33.4.9 46.2.7 46.3z"
                fill="url(#prefix__Path_2862_1_)"
            />
            </g>
            <g id="prefix__Group_2169" transform="translate(15.827 161.933)">
            <linearGradient
                id="prefix__Path_2863_1_"
                gradientUnits="userSpaceOnUse"
                x1={-186.932}
                y1={340.093}
                x2={-185.914}
                y2={340.093}
                gradientTransform="matrix(201.6992 0 0 -57.6721 37712.559 19642.74)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2863"
                d="M.9 57.7L0 56.5c.2-.1 17-13.2 50.1-26.6C80.6 17.5 131.7 2.3 201.7 0v1.5C132 3.7 81.1 18.9 50.6 31.2 17.7 44.6 1.1 57.5.9 57.7z"
                fill="url(#prefix__Path_2863_1_)"
            />
            </g>
            <g id="prefix__Group_2170" transform="translate(32.59 167.918)">
            <linearGradient
                id="prefix__Path_2864_1_"
                gradientUnits="userSpaceOnUse"
                x1={-203.618}
                y1={333.382}
                x2={-202.601}
                y2={333.382}
                gradientTransform="matrix(185.699 0 0 -49.998 37820.152 16693.438)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2864"
                d="M.8 50L0 48.8c.2-.1 19.4-12.5 51.8-24.6C93.9 8.3 138.5.2 183.6 0h2.1v1.5c-45.5-.1-90.7 8-133.4 24C20.1 37.6 1 49.9.8 50z"
                fill="url(#prefix__Path_2864_1_)"
            />
            </g>
            <g id="prefix__Group_2171" transform="translate(30.541 173.712)">
            <linearGradient
                id="prefix__Path_2865_1_"
                gradientUnits="userSpaceOnUse"
                x1={-201.631}
                y1={327.749}
                x2={-200.62}
                y2={327.749}
                gradientTransform="matrix(196.3401 0 0 -51.519 39594.918 16911.072)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2865"
                d="M.8 51.5L0 50.3c.2-.1 22.1-13.6 57.1-26.4C89.4 12 139.7-1.7 196.3.2v1.5C139.9-.2 89.8 13.4 57.6 25.2 22.8 38 1 51.4.8 51.5z"
                fill="url(#prefix__Path_2865_1_)"
            />
            </g>
            <g id="prefix__Group_2172" transform="translate(25.564 178.498)">
            <linearGradient
                id="prefix__Path_2866_1_"
                gradientUnits="userSpaceOnUse"
                x1={-196.675}
                y1={323.528}
                x2={-195.657}
                y2={323.528}
                gradientTransform="matrix(201.7002 0 0 -57.6721 39676.7 18687.4)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2866"
                d="M.9 57.7L0 56.5c.2-.1 17-13.2 50.1-26.6C80.6 17.5 131.7 2.3 201.7 0v1.5C132 3.7 81.1 18.9 50.7 31.2 17.8 44.6 1.1 57.5.9 57.7z"
                fill="url(#prefix__Path_2866_1_)"
            />
            </g>
            <g id="prefix__Group_2173" transform="translate(37.559 186.009)">
            <linearGradient
                id="prefix__Path_2867_1_"
                gradientUnits="userSpaceOnUse"
                x1={-208.596}
                y1={315.31}
                x2={-207.579}
                y2={315.31}
                gradientTransform="matrix(186.1165 0 0 -50.1704 38830.379 15844.294)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2867"
                d="M.7 50.2c-.2 0-.4-.1-.6-.3-.2-.3-.1-.8.2-1h.1c.1 0 .2-.1.3-.2C14.9 40.4 90.4-.6 186.1 0v1.5h-2C89.7 1.5 15.4 41.7 1.4 49.9c0 0-.1.1-.2.1-.2.1-.4.2-.5.2z"
                fill="url(#prefix__Path_2867_1_)"
            />
            </g>
            <g id="prefix__Group_2174" transform="translate(35.927 191.802)">
            <linearGradient
                id="prefix__Path_2868_1_"
                gradientUnits="userSpaceOnUse"
                x1={-207.021}
                y1={309.659}
                x2={-206.01}
                y2={309.659}
                gradientTransform="matrix(196.3411 0 0 -51.5154 40652.61 15977.941)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2868"
                d="M.8 51.5L0 50.3c.2-.1 22.1-13.6 57.1-26.4C89.4 12.1 139.7-1.7 196.3.2v1.5C139.9-.2 89.8 13.5 57.6 25.2 22.8 38 1 51.4.8 51.5z"
                fill="url(#prefix__Path_2868_1_)"
            />
            </g>
            <g id="prefix__Group_2175" transform="translate(30.95 196.588)">
            <linearGradient
                id="prefix__Path_2869_1_"
                gradientUnits="userSpaceOnUse"
                x1={-202.068}
                y1={305.438}
                x2={-201.05}
                y2={305.438}
                gradientTransform="matrix(201.7002 0 0 -57.6721 40763.055 17644.113)"
            >
                <stop offset={0} stopColor="#9bd0e5" />
                <stop offset={1} stopColor="#97baea" />
            </linearGradient>
            <path
                id="prefix__Path_2869"
                d="M.9 57.7L0 56.5c.2-.1 17-13.2 50.1-26.6C80.6 17.5 131.7 2.3 201.7 0v1.5C132 3.7 81.1 18.9 50.7 31.2 17.8 44.6 1.1 57.5.9 57.7z"
                fill="url(#prefix__Path_2869_1_)"
            />
            </g>
        </g>
        </g>
        <path
        id="prefix__Path_2871"
        className="prefix__st74"
        d="M188.4.5c-5.1 1.3-16.6 11.6-10 18.5 4.8 4.9 7-5.5 16.6-7.8 14.5-3.6 3.7-13.5-6.6-10.7z"
        />
        <path
        id="prefix__Path_2872"
        className="prefix__st74"
        d="M196.9 20.8c0-2.1-1.7-3.8-3.8-3.8-2.1 0-3.8 1.7-3.8 3.8 0 2.1 1.7 3.8 3.8 3.8s3.8-1.7 3.8-3.8z"
        />
        <linearGradient
        id="prefix__Path_2873_1_"
        gradientUnits="userSpaceOnUse"
        x1={-114.648}
        y1={429.057}
        x2={-112.3}
        y2={432.02}
        gradientTransform="matrix(3.0403 0 0 -3.0403 467.962 1308.202)"
        >
        <stop offset={0} stopColor="#b0e4f7" />
        <stop offset={1} stopColor="#9dd7e9" />
        </linearGradient>
        <path
        id="prefix__Path_2873"
        d="M120.1 4.8c0-.8-.7-1.5-1.5-1.5S117 4 117 4.8s.7 1.5 1.5 1.5c.9 0 1.6-.6 1.6-1.5z"
        fill="url(#prefix__Path_2873_1_)"
        />
        <g id="prefix__Group_2178" transform="translate(190.951 12.099)">
        <path
            id="prefix__Path_2874"
            className="prefix__st74"
            d="M3.4 61.3c-1.9 0-3.4-1.5-3.4-3.4 0-.8.3-1.7.9-2.3L49.7 1.1C51-.3 53.1-.4 54.5.9s1.5 3.4.3 4.8L6 60.1c-.7.8-1.6 1.2-2.6 1.2z"
        />
        </g>
        <g id="prefix__Group_2179" transform="translate(235.181 12.14)">
        <path
            id="prefix__Path_2875"
            className="prefix__st74"
            d="M1.9 35.1c-1 0-1.9-.8-1.9-1.9 0-.5.2-.9.5-1.3L28.5.6c.7-.8 1.9-.8 2.7-.1s.8 1.9.1 2.7l-28 31.3c-.3.4-.9.6-1.4.6z"
        />
        </g>
        <g id="prefix__Group_2182" transform="translate(67.998 .011)">
        <g id="prefix__Group_2180" transform="translate(0 4.553)">
            <path
            id="prefix__Path_2876"
            className="prefix__st74"
            d="M1.1 4.8C.5 4.8 0 4.3 0 3.7c0-.5.4-1 .9-1.1L12.5 0c.6-.1 1.2.3 1.4.9.1.6-.3 1.2-.9 1.4L1.4 4.8h-.3z"
            />
        </g>
        <g id="prefix__Group_2181" transform="translate(4.552)">
            <path
            id="prefix__Path_2877"
            className="prefix__st74"
            d="M3.7 13.9c-.5 0-1-.4-1.1-.9L0 1.4C-.1.8.2.2.9 0c.6-.2 1.2.2 1.4.8v.1l2.5 11.6c.1.6-.3 1.2-.9 1.4h-.2z"
            />
        </g>
        </g>
        <g id="prefix__Group_2185" transform="translate(225.76 178.482)">
        <g id="prefix__Group_2183" transform="translate(.134)">
            <image
            width={40}
            height={42}
            id="prefix__Rectangle_388"
            xlinkHref="19909EBB612C3C1.png"
            transform="translate(.657 .757) scale(.1261)"
            overflow="visible"
            />
            <path
            id="prefix__Path_2878"
            className="prefix__st74"
            d="M.8 6.7C.3 6.7 0 6.4 0 6c0-.2.1-.4.2-.5L5.1.3c.3-.3.8-.4 1.1-.1.3.3.4.8.1 1.1l-.1.1-4.9 5.1c-.1.1-.3.2-.5.2z"
            />
        </g>
        <g id="prefix__Group_2184" transform="translate(0 .135)">
            <path
            id="prefix__Path_2879"
            className="prefix__st74"
            d="M6 6.5c-.2 0-.4-.1-.5-.2l-5.3-5C-.1 1-.1.6.1.2.4-.1.8-.2 1.2.1l.1.1 5.2 4.9c.3.3.3.8 0 1.1-.1.2-.3.3-.5.3z"
            />
        </g>
        </g>
        <linearGradient
        id="prefix__Path_2880_1_"
        gradientUnits="userSpaceOnUse"
        x1={-171.612}
        y1={517.044}
        x2={-170.618}
        y2={517.044}
        gradientTransform="matrix(207.6082 0 0 -187.5083 35560.7 97059.203)"
        >
        <stop offset={0} stopColor="#6eaac1" />
        <stop offset={1} stopColor="#9dd7e9" />
        </linearGradient>
        <path
        id="prefix__Path_2880"
        className="faze_one_animate"
        d="M226.9 100.4c10.4-22.3 4.7-56.4-23.3-68.5-38.1-16.4-52.7 12-93.7-8-47.5-23.1-75.7 4.9-84 36.9-6.2 24.1 2.4 60.5 38.7 77.6 63.8 30 53.8 63.1 91 64.4 55.6 2 46.1-48.3 71.3-102.4z"
        fill="url(#prefix__Path_2880_1_)"
        />
        <ellipse
        id="prefix__Ellipse_69"
        className="prefix__st74"
        cx={94.4}
        cy={236.7}
        rx={6.4}
        ry={6.4}
        />
        <path
        id="prefix__Path_2881"
        className="prefix__st74"
        d="M122 228.3c0 2.4-2 4.4-4.4 4.4-2.4 0-4.4-2-4.4-4.4 0-2.4 2-4.4 4.4-4.4 2.4 0 4.4 2 4.4 4.4z"
        />
    </svg>
)