import React from 'react';
import ContentPage from './';

export const Dictionary = () => {
    return (
        <ContentPage page={false} setPage={{ page:6, subpage: 1 }}>
            <div className="content">
                <h2 className="blue_overlay_heading">
                   RJEČNIK KLJUČNIH POJMOVA
                </h2>

                <p className="content_text"><b>Korisnici:</b></p>
                <p className="content_text">1. Pojedinci, grupe ili organizacije, ciljane ili ne, koje imaju direktne ili indirektne koristi od razvojne intervencije.</p>
                <p className="content_text">2. Pravna ili fizička osoba koja prima grant: u praksi, uspješni aplikant i ugovorna strana u ugovoru s ugovornim organom (odnosno, organizacijom koja dodjeljuje grant).</p>

                <p className="content_text">Napomena: ova dva značenja su vrlo različita pa čak i kontradiktorna. U praksi ih različite organizacije različito poimaju. Osim kada značenje očigledno proizilazi iz samog konteksta, u ovom priručniku ovaj pojam označava drugu grupu.</p>

                <p className="content_text"><b>Podzakonski akti:</b> Pravni akti koje donose vlade ili ministarstva u vladama, a koji reguliraju određena pitanja. Mogu imati različite oblike: odluke, pravilnici, smjernice itd.</p>
                <p className="content_text"><b>Ekonomičnost: </b>Odustvo gubitaka u odnosu na određeni izlazni rezultat. Napomena: Aktivnost je ekonomična onda kada su troškovi utrošenih oskudnih sredstava približni potrebnom minimumu za ostvarivanje planiranog cilja.</p>
                <p className="content_text"><b>Efektivnost: </b> Sposobnost ili mjera u kojoj je moguće ostvariti neki ishod (shvaćeno kao korisna promjena za one kojima je namijenjena).</p>
                <p className="content_text"><b>Efikasnost: </b> Mjerilo ekonomičnog pretvaranja resursa/inputa (sredstva, ekspertiza, vrijeme itd.) u ishode.</p>
                <p className="content_text"><b>Evaluacija: </b> Sistematična i objektivna procjena tekućeg ili okončanog projekta, programa ili politike, njezinog dizajna, provedbe i rezultata. Cilj joj je utvrditi relevantnost i stepen ostvarenja ciljeva, efikasnost, efektivnost, utjecaj i održivost razvoja. Evaluacija bi trebala dati vjerodostojne i korisne informacije, koje omogućavaju ugrađivanje stečenih iskustava u proces odlučivanja kako korisnika tako i donatora. Evaluacija, također, znači proces utvrđivanja vrijednosti ili značaja neke aktivnosti, politike ili programa, te što sistematičnija i objektivnija procjena mogućih, planiranih, tekućih ili okončanih razvojnih intervencija. Napomena: Evaluacija u nekim slučajevima podrazumijeva definiranje odgovarajućih standarda, provjeru učinka u odnosu na te standarde, procjenu ostvarenih i očekivanih rezultata i utvrđivanje relevantnih lekcija.</p>
                <p className="content_text"><b>Indikator:</b> Kvantitativni ili kvalitativni faktor ili varijabla koja osigurava jednostavno i pouzdano sredstvo za mjerenje postignuća, odražavanje promjena u vezi s interevencijom ili procjenu učinkovitosti nekog razvojnog aktera.</p>
                <p className="content_text"><b>Inputi:</b> Finansijski, ljudski i materijalni resursi korišteni za razvojnu intervenciju.</p>
                <p className="content_text"><b>Monitoring: </b>Kontinuirana funkcija koja koristi sistematično prikupljene podatke o određenim indikatorima kako bi upravljačkim strukturama i glavnim interesnim grupama koje imaju interes u razvojnoj interevneciji dala naznaku o stepenu napretka u ostvarenju ciljeva i korištenju doznačenih sredstava.</p>
                <p className="content_text"><b>Izlazni rezultat:</b> Direktni “proizvod” inputa/resursa (obično finansijskim i drugim resursima) i aktivnosti.</p>
                <p className="content_text"><b>Ishod (Rezultat):</b> Pozitivna “promjena” nastala kao rezultat jednog ili više izlaznih rezultata. Ova “promjena” treba biti relativno konkretna, mjerljiva i mora se moći pripisati nekoj grupi, području i sl. (nije apstraktna). To je “korist”.</p>

                <p className="content_text"><b>Interesne grupe:</b> Agencije, organizacije, grupe ili pojedinci koji imaju direktan ili indirektan interes u razvojnoj intervenciji ili njezinoj evaluaciji.</p>
                <p className="content_text"><b>Održivost:</b> Trajanje koristi od razvojne intervencije i nakon završetka veće razvojne pomoći. Vjerovatnoća nastavka trajanja dugoročnih koristi. Otpornost na rizik od prestanka neto ekonomske prednosti tokom vremena.</p>
            </div>
        </ContentPage>
    )
}
