import React from 'react';
import ReactDOM from 'react-dom';

class Toolpit extends React.PureComponent{
    toggleTooltip = (e) => {
        const node = ReactDOM.findDOMNode(this.refs.toolpit);
        const nodeStyle = node.style.display;
        const closeAll = document.querySelectorAll('.toolpit');

        for(let i = 0; i < closeAll.length; i++) {
            closeAll[i].style.display = 'none';
        }

        if(nodeStyle === 'block') {
            return node.style.display = 'none';
        }

        return node.style.display = 'block';
    }

    render() {
        return (
            <span className="toolpit_wrap">
                <img src={`${process.env.PUBLIC_URL}/images/fussnote.svg`} className="fussnote" onClick={this.toggleTooltip} alt="fussnote" />
                <span ref={'toolpit'} className="toolpit main-text black"><span className="main-text black">{this.props.text ? this.props.text : this.props.children}</span></span>
            </span>
        );
    }
}

export default Toolpit;