import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Slider from "react-slick";
import { setCurrentPage } from '../../../actions';
import MenuButtons from '../../common/MenuButtons';
import FazeLanding from '../FazeLanding';
import { FazeOneSvg, FazeTwoSvg, FazeThreeSvg } from '../../../include';
import { hideDropdowns } from '../../utiles';

class Landing extends PureComponent {
    constructor(props) {
      super(props);
      this.body = document.querySelector('body');
    }

    state = {
      index: 0,
      list: ['Šta morate znati prije poduzimanja bilo kakvih koraka', 'Trogodišnji budžetski ciklus'],
      mainHeading: 'NULTA FAZA',
      mainUnderheading: 'UVOD U GRANT ŠEME'
    }

    componentDidMount() {
      setTimeout(() => {
        this.body.classList.add('loaded');
      }, 500);
    }

    shift = () => {
      this.addActiveClass();
      const landing = document.getElementById('landing');
      landing.style.transform = 'translate3d(-100%,0,0)';
    }

    addActiveClass = () => {
      const topNavBar = document.getElementsByClassName('top_nav_bar');

      return topNavBar[0].classList.add('showNavBar');
    }

    shiftToFazeZero = () => {
      this.shift();

      this.setState({
        index: 0,
        list: ['Šta morate znati prije poduzimanja bilo kakvih koraka', 'Trogodišnji budžetski ciklus'],
        mainHeading: 'NULTA FAZA',
        mainUnderheading: 'UVOD U GRANT ŠEME'
      })
    }

    shiftToFazeOne = () => {
      this.shift();

      this.setState({
        index: 1,
        list: ['Prvi koraci', 'Formiranje tima za izradu grant šeme', 'Provjera relevantnosti politike', 'Uključivanje relevantnih aktera', 'Analiza problema, potreba, prilika i potražnje', 'Jasnoća pravca djelovanja', 'Ispitivanje predizvodljivosti', 'Strukturiranje koncepta šeme', 'Postavljanje parametara za kriterije odabira', 'Razrada podzakonskih akata'],
        mainHeading: 'PRVA FAZA',
        mainUnderheading: 'KONCEPTUALIZACIJA ŠEME'
      })
    }


    shiftToFazeTwo = () => {
      this.shift();

      this.setState({
        index: 2,
        list: ['Definiranje izlaznih rezultata šeme', 'Definiranje prihvatljivih aplikanata, aktivnosti i troškova', 'Omogućavanje optimalnog odgovora aplikanata na šemu', 'Kriteriji odabira', 'Scenarij za implementaciju', 'Opća procjena izvodljivosti šeme', 'Izrada plana za monitoring i izvještavanje'],
        mainHeading: 'DRUGA FAZA',
        mainUnderheading: 'IZRADA ŠEME'
      })
    }

    shiftToFazeThree = () => {
      this.shift();

      this.setState({
        index: 3,
        list: ['Razrada javnog poziva', 'Razrada obrasca prijave','Razrada smjernica za aplikante', 'Objava javnog poziva za dostavljanje projekata'],
        mainHeading: 'TREĆA FAZA',
        mainUnderheading: 'RAZRADA DOKUMENATA U OKVIRU ŠEME'
      })
    }


    setCurrentPageAndSubpage = (p,sp) => {
      this.props.setCurrentPage(p,sp);
    }

    render() {
      const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1080,
            settings: {
              slidesToShow: 3,
              infinite:true,
              slidesToScroll: 1,
              autoplay:true,
              speed:1000,
              autoplaySpeed: 8000
            }
          },
          {
            breakpoint:850,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint:500,
            settings: {
              slidesToShow: 1,
              infinite:false,
              dots:true
            }
          }
        ]
      };

        return (
            <div id="landing">
                <div className="heading-top d-flex d-flex-jcsb">
                    <div className="heading">
                      <h2>Izrada javnih grant šema:</h2>
                      <h1>Priručnik za praktičare iz javnog sektora</h1>
                    </div>
                    <MenuButtons className="menu_items_landing" />
                </div>
                <div className="landing_pic" onClick={hideDropdowns}>
                    <img src={`${process.env.PUBLIC_URL}/images/landing_pic.png`} alt="Landing Pic" />
                </div>
                <div className="fazes d-flex d-flex-jcsb d-flex-d-c" onClick={hideDropdowns}>
                    <div className=" d-flex d-flex-jcsb">
                        <Slider {...settings}>
                          <Link to={`${process.env.PUBLIC_URL}/page=0&subpage=0`} className="one_faze">
                            <div className="card">
                              <div className="front">
                                <div className="faze_picture">
                                    <img src={`${process.env.PUBLIC_URL}/images/faze_one_people.png`} alt="faze_one" />
                                    <FazeOneSvg />
                                </div>
                                <h2 className="main-heading">NULTA FAZA</h2>
                                <h3>UVOD U GRANT ŠEME</h3>
                              </div>
                              <div className="back yellow_color">
                                <h2 className="main-heading">NULTA FAZA</h2>
                                <p className="main-text">Šta morate znati prije poduzimanja bilo kakvih koraka?</p>
                                <p className="main-text white">Pročitaj više <img src={`${process.env.PUBLIC_URL}/images/arrow.png`} alt="arrow" /></p>
                              </div>
                            </div>
                        </Link>

                        <Link to={`${process.env.PUBLIC_URL}/page=1&subpage=0`} className="one_faze">
                          <div className="card">
                            <div className="front">
                              <div className="faze_picture">
                                  <img src={`${process.env.PUBLIC_URL}/images/faze_two_people.png`} alt="faze_one" />
                                  <FazeTwoSvg color={'#cd4800'} color2={'#c44500'}/>
                              </div>
                              <h2 className="main-heading">PRVA FAZA</h2>
                              <h3>KONCEPTUALIZACIJA ŠEME</h3>
                            </div>
                            <div className="back orange_color">
                              <h2 className="main-heading">PRVA FAZA</h2>
                              <p className="main-text">Kako pripremiti koncept javne šeme i vladinih/ ministarskih podzakonskih akata o kriterijima odabira i realizacije javnih sredstava?</p>
                              <p className="main-text white">Pročitaj više <img src={`${process.env.PUBLIC_URL}/images/arrow.png`} alt="arrow" /></p>
                            </div>
                          </div>
                        </Link>

                        <Link to={`${process.env.PUBLIC_URL}/page=2&subpage=0`} className="one_faze">
                          <div className="card">
                            <div className="front">
                              <div className="faze_picture">
                                  <img src={`${process.env.PUBLIC_URL}/images/faze_three_people.png`} alt="faze_one" />
                                  <FazeThreeSvg />
                              </div>
                              <h2 className="main-heading">DRUGA FAZA</h2>
                              <h3>IZRADA ŠEME</h3>
                            </div>
                            <div className="back lightblue_color">
                              <h2 className="main-heading">DRUGA FAZA</h2>
                              <p className="main-text">Kako provesti detaljno planiranje sadržaja javnog poziva i pratećih dokumenata?</p>
                              <p  className="main-text white">Pročitaj više <img src={`${process.env.PUBLIC_URL}/images/arrow.png`} alt="arrow" /></p>
                            </div>
                          </div>
                        </Link>

                        <Link to={`${process.env.PUBLIC_URL}/page=3&subpage=0`} className="one_faze">  
                          <div className="card">
                            <div className="front">
                              <div className="faze_picture blue_color">
                                  <img src={`${process.env.PUBLIC_URL}/images/faze_four_people.png`} alt="faze_one" />
                                  <FazeTwoSvg color={'#cd4800'} color2={'#c44500'}/>
                              </div>
                              <h2 className="main-heading">TREĆA FAZA</h2>
                              <h3>RAZRADA DOKUMENATA U OKVIRU ŠEME</h3>
                            </div>
                            <div className="back darkblue_color">
                              <h2 className="main-heading">TREĆA FAZA</h2>
                              <p className="main-text">Kako provesti izradu i objavljivanje javnog poziva i pratećih dokumenata šeme (smjernice za aplikante i anekse)?</p>
                              <p  className="main-text white">Pročitaj više <img src={`${process.env.PUBLIC_URL}/images/arrow.png`} alt="arrow" /></p>
                            </div>
                          </div>
                        </Link>
                        </Slider>
                    </div>
                </div>

                <div className="bottom_buttons">
                  <Link to={`${process.env.PUBLIC_URL}/additional-tools-intro`} className="one_button">
                    <img src={`${process.env.PUBLIC_URL}/images/settings.png`} alt="icon" />
                    <p>Alati i <br></br>obrasci</p>
                  </Link>
                  <Link to={`${process.env.PUBLIC_URL}/additional-tools-2-intro`} className="one_button">
                    <img src={`${process.env.PUBLIC_URL}/images/documents.png`} alt="icon" />
                    <p>Studije <br></br>slučaja</p>
                  </Link>
                  <Link to={`${process.env.PUBLIC_URL}/dictionary-intro`} className="one_button">
                    <img src={`${process.env.PUBLIC_URL}/images/flag.png`} alt="icon" />
                    <p>Rječnik<br></br> ključnih<br></br> pojmova</p>
                  </Link>
                  <div className="one_button pos-rel">
                      <div className="download_buttons">
                        <img src={`${process.env.PUBLIC_URL}/images/documents.png`} alt="icon" />
                        <p>Preuzimanje<br></br> dokumenata<br></br> priručnika</p>
                      </div>
                      <div className="download d-flex d-flex-jcc">
                        <a href={`${process.env.PUBLIC_URL}/pdf/Izrada-javnih-grant-shema-prirucnik-za-prakticare-iz-javnog-sektora.pdf`} download="Izrada-javnih-grant-shema-prirucnik-za-prakticare-iz-javnog-sektora.pdf" target="_blank" className="button">Preuzmi<br></br> dokument</a>
                        <a href={`${process.env.PUBLIC_URL}/pdf/Design of Public Grant Scheme for Public Sector Practitioners.pdf`} download="Design of Public Grant Scheme for Public Sector Practitioners.pdf" target="_blank" className="button ml-15">Download <br></br>document</a>
                      </div>
                  </div>
                </div>

                <div className="logos">
                  <img src={`${process.env.PUBLIC_URL}/images/swiss_agency.svg`} alt="swiss agency" /> 
                    <img src={`${process.env.PUBLIC_URL}/images/undp.svg`} className="undp" alt="swiss agency" />
                </div>

                <div className="copy">
                  <p><span>©</span>2019 Projekat integriranog lokalnog razvoja (ILDP)</p>
                </div>

                <div className="disclamer">
                  <p><i className="white">Ovaj web priručnik izrađen je u okviru "Projekta integriranog lokalnog razvoja (ILDP)" koji je zajednički projekat Vlade Švicarske i UNDP-a u Bosni i Hercegovini. Sadržaj ovog priručnika, kao i nalazi prikazani u njemu, ni na koji način ne odražava stavove Vlade Švicarske, niti Razvojnog programa Ujedinjenih nacija (UNDP). 2019.</i></p>
                </div>

                <FazeLanding>
                    <div className="d-flex d-flex-jcsb list_name_wrap">
                        <div className="d-flex-m-5_5 d-flex d-flex-aafe">
                          <ul className="menu_list">
                            {this.state.list.map((e,i) => {
                              return (
                                <li key={e} onClick={() => this.setCurrentPageAndSubpage(this.state.index, i + 1)} className="d-flex">{`${this.state.index}.${i + 1}`} <Link to={`${process.env.PUBLIC_URL}/page=${this.state.index}&subpage=${i + 1}`}>{e}</Link></li>
                              )
                            })}
                        </ul>
                      </div>
                      <div className="d-flex-m-5_5">
                        <h1 className="faze_landing_heading">{this.state.mainHeading}</h1>
                      </div>
                    </div>

                    <div className="faze_landing_underheading d-flex d-flex-jcfe">{this.state.mainUnderheading}</div>
                </FazeLanding>
            </div>
        )
    }
}

export default connect(null, { setCurrentPage })(Landing)