import React from 'react';
import ContentPage from '../ContentPage/';
const AboutUs = () => {
    return (
        <ContentPage page={false} setPage={{ page:0, subPage:0 }} hideButtons={true}>
            <div className="content">
                <h2 className="blue_overlay_heading">
                    O nama
                </h2>

                <p className="content_text">Projekat integriranog lokalnog razvoja (ILDP) pokrenut je 2008. godine kao zajednički projekat Vlade Švicarske i UNDP-a u Bosni i Hercegovini (BiH), s ciljem usklađivanja integriranog i inkluzivnog strateškog planiranja na lokalnom nivou. ILDP se implementira u partnerstvu sa Ministarstvom za ljudska prava i izbjeglice BiH, Federalnim Ministarstvom pravde, Ministarstvom uprave i lokalne samouprave Republike Srpske, savezima općina/opština i gradova u oba entiteta BiH.</p>

                <p className="content_text">U prethodnim godinama ILDP je pružio značajan doprinos u standardizaciji pristupa stateškom planiranju i implementaciji strategija razvoja u blizu 60 opština i gradova u BIH i 10 kantona u Federaciji Bosne i Hercegovine. Istovremeno, Projekat sarađuje sa vladama oba entiteta i Brčko distrikta BiH i pruža im podršku u uspostavi učinkovitih sistema strateškog planiranja i upravljanja razvojem, uključujući i finansijske mehanizme koji pružaju ciljanu podršku provedbi razvojnih prioriteta utvrđenih strategijama razvojem na lokalnom nivou. </p>

                <p className="content_text">Putem finansijskih mehanizama nastoji se osnažiti i vertikalna usklađenost razvojnih prioriteta različitih nivoa vlasti kroz transparentan način finansiranja usklađen sa praksama Evropske unije a kako bi se u konačnici doprinijelo poboljšanju kvaliteta života građana u BiH. Projekat je u sklopu podrške finansijskim mehanizmima za podršku lokalnom razvoju, pripremio i tematsku web stranicu “Izrada javnih grant šema: Priručnik za praktičare iz javnog sektora”, kao elektronski vodič za izradu javnih grant šema na konkurentskoj osnovi za budžetske korisnike na svim nivoima vlasti u BIH.</p>

                <div className="yellow_box bg-darkblue ta-c"><i className="white">Ovaj web priručnik izrađen je u okviru "Projekta integriranog lokalnog razvoja (ILDP)" koji je zajednički projekat Vlade Švicarske i UNDP-a u Bosni i Hercegovini. Sadržaj ovog priručnika, kao i nalazi prikazani u njemu, ni na koji način ne odražava stavove Vlade Švicarske, niti Razvojnog programa Ujedinjenih nacija (UNDP). <br />     2019.</i>

</div>
            </div>
        </ContentPage>
    )
}

export default AboutUs;
 