import React from 'react';
import ContentPage from './';
import Toolpit from "../../common/Toolpit";

import { HashLink as Link } from 'react-router-hash-link';

export const AdditionalTools_2 = () => {
    return (
        <ContentPage page={false} setPage={{ page: 5, subpage: 1 }}>
            <div className="content">
			<div className="yellow_overlay_text">Lokacija: <b>Prilog 2: Studije slučaja</b></div>
                <h2 className="content_heading blue mt-50 mb-25"><b>PRILOG 2 - STUDIJE SLUČAJA</b></h2>
				<ul>
                    <li className="black_list pos-rel content_text mb-10"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="arrow" className="mr-15" /> <Link to={`${process.env.PUBLIC_URL}/additional-tools-2`}>Studija slučaja 1</Link></li>

                    <li className="black_list pos-rel content_text mb-10"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="arrow" className="mr-15" /> <Link to={`${process.env.PUBLIC_URL}/additional-tools-2#studije-slucaja-2`}>Studija slučaja 2</Link></li>

                    <li className="black_list pos-rel content_text mb-10"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="arrow" className="mr-15" /> <Link to={`${process.env.PUBLIC_URL}/additional-tools-2#studije-slucaja-3`}>Studija slučaja 3</Link></li>

					<li className="black_list pos-rel content_text mb-10"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="arrow" className="mr-15" /> <Link to={`${process.env.PUBLIC_URL}/additional-tools-2#studije-slucaja-4`}>Studija slučaja 4</Link></li>

					<li className="black_list pos-rel content_text mb-10"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="arrow" className="mr-15" /> <Link to={`${process.env.PUBLIC_URL}/additional-tools-2#studije-slucaja-5`}>Studija slučaja 5</Link></li>

					<li className="black_list pos-rel content_text mb-10"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="arrow" className="mr-15" /> <Link to={`${process.env.PUBLIC_URL}/additional-tools-2#studije-slucaja-6`}>Studija slučaja 6</Link></li>

					<li className="black_list pos-rel content_text"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="arrow" className="mr-15" /> <Link to={`${process.env.PUBLIC_URL}/additional-tools-2#studije-slucaja-7`}>Studija slučaja 7</Link></li>
                </ul>


                <h2 className="blue_overlay_heading" id="studije-slucaja-8">STUDIJA SLUČAJA 1:</h2>

                <h2 className="content_heading blue mt-25"><b>KAKO SE TO MOŽE URADITI: </b></h2>
                <h2 className="content_text blue mb-25">PRIČA IZ SJEVEROISTOČNE BUGARSKE</h2>

                <p className="content_text">U nastavku predstavljamo priču koja se temelji na stvarnom događaju (sjeveroistočna
                Bugarska, 2006.) i koja pokazuje kako se može uraditi Prva faza i kreirati okvir utemeljen
                na dokazima, koji omogućava detaljnije skiciranje i razradu šeme. U ovom se slučaju
                radilo o šemi koja je <b>imala 3 glavne teme, koje su 12 mjeseci ranije postavljene u
                programskom dokumentu EU i Bugarske, s ukupnim budžetom od 6 miliona sredstava EU.</b></p>
                                
                <h3 className="content_heading black d-flex no-wrap d-flex-aac mb-20"> <img src={`${process.env.PUBLIC_URL}/images/lightbulb_yellow.svg`} alt="lightbulb"/><span> <b>Primjer: </b>Sjeveroistočna Bugarska – o ovom scenariju za grant šemu (četiri komponente)
                odlučeno je nakon postupka koji je sličan ranije definiranoj Fazi 1.</span></h3>

                <p className="content_text"><b>Važno: </b>Navedeni parametri u sklopu predstavljene grant šeme u studiji slučaja odraz su jasne
                potrebe i potražnje utvrđene nakon opsežnog terenskog rada i konsultacija u postupku sličnom
                onome opisanom u ovom priručniku u dijelu o Fazi 1. Premda to ovdje nije prikazano, indikativni
                ishodi i vrste aktivnosti su već uveliko bili definirani. Opći kriteriji odabira još uvijek nisu bili
                definirani (<b>što je razlika u odnosu na BiH</b>).</p>

                <p className="content_text">Priča o ovom iskustvu izložena je nakon prikaza šeme.</p>
				<br></br>
                <p className="content_text blue"><b>Opći ciljevi grant šeme:</b></p>
				<br></br>
                <p className="content_text">Poboljšati specifične faktore i uvjete koji podržavaju regionalni razvoj, naročito u smislu razvoja
                poslovanja i turizma, ili pripremiti put za tržište rada i mehanizme podrške za ciljane grupe,
                doprinoseći tako regionalnoj konkurentnosti i socijalnoj koheziji.</p>

				<div className="custom_tables">
				<table id="table073 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-203 " />
					<col className="_idGenTableRowColumn-204 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Komponenta</p>
						</td>
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Budžet (Mill €)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Poslovna infrastruktura (neturistička)</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">1,640 (30%)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Integrirana podrška razvoju turizma</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">2,760 (50%)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Razvoj ljudskih potencijala - Lokalne inicijative za zapošljavanje i zapošljivost</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">0,550 (10%)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Ulaganje u unapređenje uvjeta i vanjskog okruženja za proizvodni sektor</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">0,550 (10%)</p>
						</td>
					</tr>
				</tbody>
			</table>
				</div>

            <p className="content_text blue mt-50"><b>Opće karakteristike grant šeme</b></p>

			<div className="custom_tables">
			<table id="table074" className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-177 " />
					<col className="_idGenTableRowColumn-205 " />
					<col className="_idGenTableRowColumn-206 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-127 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Komponenta</p>
						</td>
						<td className="No-Table-Style CellOverride-127 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Cilj (svrha)</p>
						</td>
						<td className="No-Table-Style CellOverride-127 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Posebni ciljevi </p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-106 " rowspan="2 ">
							<p className="tekst-u-tabelama ">Poslovna infrastruktura (neturistička)</p>
						</td>
						<td className="No-Table-Style CellOverride-106 " rowspan="2 ">
							<p className="tekst-u-tabelama ">Povećati konkurentnost poslovanja Sjeveroistočne regije i poboljšati okruženje, podržavajući ulaganja u razvoj ključnih elemenata općinske poslovne infrastrukture.</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Poboljšati pristup poslovnim lokacijama / područjima i njihovu povezanost s glavnom cestovnom infrastrukturom.</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-179 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Poboljšati funkcioniranje poslovnih lokacija / područja razvijanjem komunalnih usluga (komunalna infrastruktura).</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-106 " rowspan="2 ">
							<p className="tekst-u-tabelama ">Integrirana podrška razvoju turizma</p>
						</td>
						<td className="No-Table-Style CellOverride-106 " rowspan="2 ">
							<p className="tekst-u-tabelama ">Unaprijediti turističku konkurentnost regije i poboljšati doprinos turizma regionalnom razvoju proširivanjem asortimana turističkih proizvoda i boljom geografskom i sezonskom turističkom distribucijom u regiji.</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Poboljšati uvjete za privlačenje i opsluživanje posjetitelja u područjima / općinama koje imaju potencijal za razvoj turizma.</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-123 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Ostvariti bolju koordinaciju, unaprijediti upravljanje turizmom i poboljšati marketing, fokusirajući se na relevantne proizvode i usluge od regionalne važnosti i opsega, uključujući i povezivanje obale s ostalim dijelovima sjeveroistočne regije.</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-106 " rowspan="4 ">
							<p className="tekst-u-tabelama ">Razvoj ljudskih potencijala - Lokalne inicijative za zapošljavanje i zapošljivost</p>
						</td>
						<td className="No-Table-Style CellOverride-106 " rowspan="4 ">
							<p className="tekst-u-tabelama ">Promovirati inicijative za zapošljavanje i zapošljivost s ciljem postizanja ravnoteže između ponude i potražnje za radnom snagom na regionalnom / lokalnom tržištu rada.</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Doprinijeti prilagođavanju obuke potrebama održivog razvoja industrijskih sektora u regiji (proizvodna industrija, turizam, građevinarstvo, transport).</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-147 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Poboljšati mogućnosti za zapošljavanje i omogućiti registriranim nezaposlenim licima i ugroženim grupama (mladi, dugotrajno nezaposlena i nekvalifikovana lica, žene itd.) da steknu vještine i kompetencije koje zadovoljavaju potrebe regionalnog/domaćeg tržišta rada.</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Poboljšati vještine zaposlenih.</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Promovirati stvaranje novih radnih mjesta u regiji.</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-207 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Ulaganje u unapređenje uvjeta i vanjskog okruženja za proizvodni sektor</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Stvoriti uvjete za konkurentna i atraktivna strana i domaća ulaganja u regionalnu privredu kroz pružanje poslovne savjetodavne podrške.</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Doprinijeti razvoju sektora malog i srednjeg poduzetništva pružanjem ciljanih usluga mladim preduzećima (start-ups).</p>
							<p className="tekst-u-tabelama ">Poboljšati konkurentnost postojećih malih i srednjih poduzeća razvojem njihovih izvoznih potencijala i trgovine s međunarodnim preduzećima kroz pružanje ciljane obuke za razvoj ljudskih potencijala i podržavanjem daljnjeg širenja preduzeća.</p>
							<p className="tekst-u-tabelama ">Promovirati saradnju između poslovnih udruženja, granskih organizacija, regionalnih agencija i univerziteta kao vid olakšavanja poslovnih aktivnosti. </p>
						</td>
					</tr>
				</tbody>
			</table>
			</div>

            <p className="content_text blue mt-40 mb-40"><b>Priča: Pristup i metodologija</b></p>

            <p className="content_text">Proces pripreme grant šeme pokazao se vrlo uspješnim, tj. uspješnijim nego što se očekivalo.
            Ovdje ćemo opisati druge faktore koji su doprinijeli uspjehu, koji je uveliko nadmašio i pristup
            i metodologiju, odnosno nastojanja tima odgovornog za izradu šeme. Ukazat ćemo, također, na
            određena ograničenja zbog kojih je, unatoč svim naporima, ova šema objavljena kasnije nego što je
            trebala ili mogla biti objavljena.</p>

            <p className="content_text">Uobičajeno je da se pristup osmišljavanja i izrade šeme temelji na iskustvu i uvjerenju da se grant
            šema, kao mjera u okviru strukturnih fondova ili bilo kojeg drugog mehanizma intervencije kroz
            koji se realizira javna pomoć u razvojne svrhe, mora temeljiti na: stručnosti, metodologiji i procesu.
            Ništa od ovoga, pojedinačno ili samo po sebi, nije do voljno.</p>

            <p className="content_text">Svaka šema je funkcija djelovanja niza parametara: programske smjernice (ili osnovni dokument
            politike), očekivanja provedbene agencije i drugih ključnih aktera, potrebe i očekivanja na terenu
            i potražnje, odnosno, opipljivih dokaza koji nam govore hoće li aplikanti i u kojoj mjeri uspješno
            pristupiti šemi.<b> Prema definiciji, mnogi od tih elemenata ne mogu se naći u dokumentima ili u
            stručnim knjigama već su nestrukturirane ideje i razmišljanja mnogih ljudi. </b>Upravo zbog toga,
            potreban je proces aktivnog sudjelovanja:<b> diskusije, slušanje, predlaganje, reagiranje, sumiranje
            i dogovaranje.</b> Ali, da bi se to efikasno i efektivno postiglo (i da bi se izbjeglo stalno vraćanje na
            iste probleme), bitno je imati metodologiju i strukturiran proces.</p>

            <p className="content_text mt-50">Projektni tim zadužen za izradu šeme odlučio je da primijeni metodologiju i postupak koji će
            pripremu grant šeme provesti kroz nekoliko koraka. Ukratko, to su:</p>

            <p className="content_text d-flex d-flex-aac mt-50"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Identifikacija i mapiranje </b> projekata i projektnih ideja u skladu s projektnim sažetkom (ne dovodeći
            u pitanje odluke o finansiranju pojedinačnih projekata): to je učinjeno uz puno sudjelovanje
            regionalnih koordinatora resornog ministarstva;</span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Korelacija i analiza podataka </b> predstavljenih tokom mapiranja (korelaciju su, uglavnom, radili
            regionalni koordinatori resornog ministarstva): tim za izradu projekta je uradio analizu;</span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Rana analiza i utvrđivanje ključnih pitanja </b> – u vezi s realizacijom na osnovu analize procesa i
            izrade scenarija koji bi se mogli testirati u pogledu razumijevanja i reakcije relevantih aktera; </span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>Predstavljanje ključnih dokumenata za nacrt sporazuma sa akterima </b>- odnosi se na nacrt opsega
            šeme i njezine realizacije; </span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Relevantni akteri se zadužuju (od Upravnog odbora ili Komisije za za upravljenje šemom) - </b>kako
            bi razradili dizajn na osnovu dogovorenih glavnih karakteristika; </span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Proces stalnih strukturiranih razgovora s relevantnim ministarstvima (budući da ova šemau ključuje najmanje četiri ministarstva) na osnovu konkretnih, zapisanih ideja za svaku
            komponentu šeme: </b> </span></p>

            <p className="content_text ml-25">a.  Razrada bilješki o dogovorenim idejama nakon svakog sastanka;</p>

            <p className="content_text ml-25">b.  Bilješke dostavljene relevantnim akterima;</p>

            <p className="content_text ml-25">c.  Tim za projektiranje obavlja dodatne tehničke zadatke;</p>

            <p className="content_text ml-25">d.   Dodatni sastanci i dorada bilješki s relevantnim akterima.</p>
            
            <p className="content_text ml-40"><em>(ovaj se proces odvijao u periodu od 2. do 24. februara: da je bilo više vremena, proces
            je mogao biti interaktivniji)</em></p>


            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Predstavljanje ključnih dokumenata za ugovore s akterima </b>koji se odnose na opseg i strukturu
            šeme, ciljeve, potencijalne vrste ishoda, izlaznih rezultata i projekata;  </span></p>  

            <p id="studije-slucaja-2" className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Zaduženje tima za provedbu (od Upravnog odbora) da započne aktivnosti s provedbenom
            agencijom na kompletiranju relevantne dokumentacije</b>, ne tražeći dodatnu pomoć od njih do
            kraja procesa izrade dokumentacije.   </span></p>  

            
            <h2 className="blue_overlay_heading mt-70" >STUDIJA SLUČAJA 2:</h2>

            <p className="content_text"><em>Ova studija slučaja će vam pomoći da razumijete ključne zadatke<u> u Fazi 2, korak 1 (a koji se odnose na definiranje
            indikatora u sklopu koraka u kojem se utvrđuju </u> izlazni rezultati šeme, odnosno, pojašnjenje i kvantificiranje
            rezultata/ ishoda šeme).</em></p>

            
            <h2 className="content_heading blue mt-25"><b>KAKO SE MOŽE URADITI: </b></h2>
            <h2 className="content_text blue">DEFINIRANJE INDIKATORA - PRIČA IZ IRSKE</h2>

            <h3 className="content_heading d-flex no-wrap d-flex-aac mb-20 blue"> <img src={`${process.env.PUBLIC_URL}/images/lightbulb_yellow.svg`} alt="lightbulb"/><span><b> Svrha Studije slučaja</b></span></h3>

            <p className="content_text">Ova studija slučaja nudi praktičan primjer kvantificiranja i izrade indikatora za mjerenje uspješnosti
            grantova za osnivanje novih preduzeća. Ona pokazuje da:</p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Utvrđivanje indikatora izlaznih rezultata i ishoda je težak zadatak;  </span></p>  

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Kvantificiranje indikatora naročito zavisi od prethodnog iskustva, a najbolji način da se ono provede
            je putem istraživanja različitih hipoteza;  </span></p>  

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Indikatori – ako se odaberu – trebaju biti mjerljivi za tijelo koje provodi šemu, a ta realnost će
            ograničiti vrste indikatora koje se mogu koristiti.  </span></p> 

             <p className="content_text"><b>Napomena: </b><em>Ovo je stvarna Studija slučaja zasnovana na stvarnoj šemi iz Zapadne Evrope ( format i
            dužina su prilagođeni i uređeni). Može se činiti da neki elementi znatno prevazilaze okvire sadašnje
            prakse u BiH. Ipak, ova studija slučaja predstavlja onu vrstu najbolje prakse kojoj BiH treba težiti.</em></p>

            <p className="content_text"><b>Osnovne informacije koje su potrebne za izradu seta indikatora (za izlazne rezultate i ishode) i
            njihovo kvantificiranje su:</b></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Razumijevanje <b> relevantne politike/strategije </b>i „teorije promjene“, čijoj realizaciji intervencija,
            odnosno, šema, treba doprinijeti;  </span></p> 

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>Raspoloživi budžet;</b>  </span></p> 

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Ciljevi;</b> </span></p> 

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>Vrste aktivnosti </b>  koje će se podržati šemom i približan <b>značaj svake aktivnosti </b>za ostvarivanje
            ciljeva; </span></p> 

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>Informacije o kontekstima u kojima su provedene slične inicijative </b>u prošlosti i/ili na drugim
            analognim teritorijama, a naročito o onome što se naučilo od aktera o kontekstu za intervenciju.  </span></p> 


            <h2 className="content_heading blue mt-35 mb-30"> <b>Kontekst </b> </h2>

            <p className="content_text">Cilj Politike o grantovima za pokretanje preduzeća, koja će se provoditi u periodu 2018-19, je
            promoviranje razvoja preduzeća, naročito u sektorima rasta. Stoga će se pomoć odobravati novim
            firmama, naročito onima sa jasnim potencijalom za rast. Osim toga, nastojat će se osigurati da
            nove firme na adekvatan način dobiju poslovne savjete i prostorije.</p>

            <p className="content_text">U okviru inicijative se predviđa pomoć u osnivanju novih firmi, povećavanju nivoa zapošljavanja i
            razvoju centra za izvrsnost, u kojem odabrane nove firme u sektorima rasta mogu pronaći namjenske
            inkubatorske jedinice i razmjenjivati ideje. Također će se nastojati promovirati poduzetništvo
            - među učenicima i studentima kao karijerna opcija i alternativa za pokretanje poduzetnički
            orijentiranog zaposlenja. Ujedno, svrha šeme je da se uspostave veze između različitih vrsta
            aktivnosti koje su podržane u okviru ove inicijative, kada je to adekvatno. Pored toga, kako bi se
            šemom obuhvatila šira ciljna grupa na one koje nemaju dovoljne kapacitete, prihvatanje poslovnih
            savjeta će biti posebno važan preduvjet za dobivanje granta.</p>

            <p className="content_text">Za ovu inicijativu je izdvojen budžet od 60 miliona eura, a sastoji se od četiri komponente, odnosno,
            lota. Budžet je podijeljen prema komponentama na sljedeći način:</p>

            <table id="table075 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-208 " />
					<col className="_idGenTableRowColumn-148 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Komponente (lotovi)</p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Budžet (M€)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-54 ">
							<p className="tekst-u-tabelama ">1. Nove firme</p>
						</td>
						<td className="No-Table-Style CellOverride-54 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">30</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-54 ">
							<p className="tekst-u-tabelama ">2. Centar izvrsnosti</p>
						</td>
						<td className="No-Table-Style CellOverride-54 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">15</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-54 ">
							<p className="tekst-u-tabelama ">3. Usluge poslovnog savjetovanja</p>
						</td>
						<td className="No-Table-Style CellOverride-54 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">20</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-54 ">
							<p className="tekst-u-tabelama ">4. Promoviranje poduzetništva </p>
						</td>
						<td className="No-Table-Style CellOverride-54 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">3</p>
						</td>
					</tr>
				</tbody>
			</table>

			<h2 className="content_heading blue mt-35 mb-30"> <b>Ciljevi </b> </h2>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Povećanje broja novih firmi, naročito u sektorima rasta;</span></p> 

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Doprinos razvoju kulture preduzeća; </span></p> 

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Osiguravanje fizičke infrastrukture za podršku preduzećima usmjerenim na rast;</span></p> 

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Pružanje usluga poslovne podrške.</span></p> 

            

			<h2 className="content_heading blue mt-35 mb-30"> <b>Aktivnosti </b> </h2>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Podrška novim firmama, naročito onima koje imaju potencijal za rast, putem grantova i stručnih
            savjeta i podrške; </span></p> 

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Promoviranje poduzetništva među učenicima i studentima kroz privremene radne angažmane i
            studijske posjete;  </span></p> 

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Popunjavanje praznina u trenutnoj podršci preduzećima u pogledu infrastrukture (npr. inkubatorske
            jedinice) i usluga poslovnog savjetovanja putem: (i) uspostave Centra za izvrsnost u kojem firme s
            potencijalom za rast mogu pronaći i razmjenjivati ideje i (ii) nabavke konsultantskih usluga za nove
            firme koje su dobile pomoć; </span></p> 

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Firme koje budu smještene u Centru neće plaćati rentu tokom prvih šest mjeseci; iznos rente će
            biti smanjen tokom narednih 18 mjeseci, a onda će plaćati puni iznos rente tokom perioda od 12
            mjeseci, nakon čega moraju napustiti Centar i prebaciti se u komercijalne prostorije.</span></p> 
            

            <p className="content_text mt-35">U nastavku je opisana izrada odgovarajućeg izlaznog rezultata, ishoda i indikatora učinka za svaku
            od ovih aktivnosti.</p>


			<h2 className="content_heading blue mt-35 mb-30"> <b>Komponente/Lotovi</b> </h2>

            <p className="content_text blue"><b>1. Komponenta: Nove firme (engl. Start-ups)</b> </p>

            <p className="content_text"><b>Polovina sredstava</b> u okviru ove inicijative izdvojit će se za pomoć u pokretanju novih firmi. S
            obzirom na značaj ove aktivnosti,<b> važno je da postoje relevantni indikatori izlaznih i drugih
            rezultata za nove firme.</b></p>

            <p className="content_text blue"><b>Indikatori izlaznih rezultata i ishoda: </b></p>

            <p className="content_text">Imajući u vidu relevatni cilj šeme kao i komponentu grant šeme koja se odnosi na start-up firme,
            očigledan izbor za indikator izlaznog rezultata je <b><em>„Broj novih start-up firmi kojima je pružena
            pomoć“.</em></b></p>

            <p className="content_text pos-rel">U pogledu indikatora ishoda usmjerenog na praćenje <b>nivoa zapošljavanja,<em> „Broj zaposlenih osoba u
            podržanim start-up firmama“</em></b> očigledno je dobro odabran indikator poslovnog učinka. Indikatori
            ishoda koji se odnosne na praćenje <b> nivoa prometa ili izvoza su, također, potencijalno relevantni,
            ali ih je nemoguće znati prije provedbe šeme i stoga nisu adekvatni <Toolpit text="Također, ova intervencija se fokusira na sasvim nove firme i obuhvata relativno kratak period – šest godina. Vjerovatno bi
            mnoge od ovih firmi bile u preranoj fazi razvoja da bi zabilježile značajan promet ili izvoz u periodu obuhvaćenom intervencijom. Osim
            toga, vrsta i kombinacija firmi su bez sumnje relevantne za nivo prometa i izvoza. Ipak, to se ne može znati tokom izrade indikatora.
            Dakle, bilo bi teško navesti brojke u indikatorima nivoa prometa ili izvoza prije početka Programa."></Toolpit></b> (osim toga, potencijalno su
            i povjerljivi u smislu tajnosti poslovnih informacija).</p>

            <p className="content_text blue"><b>Utvrđivanje ciljeva za indikatore: </b></p>

            <p className="content_text pos-rel">Kod utvrđivanja <b>kvantificiranih ciljanih vrijednosti</b> za ove <b>indikatore izlaznih rezultata i ishoda,
            ranija uspješnost </b> inicijativa u sklopu sličnih šema vjerovatno predstavljaju <b>adekvatnu polaznu
            osnovu <Toolpit text="Ex-post evaluacijski izvještaji o ranije provedenim sličnim šemama su osobito korisni i usmjeravajući izvori u ovom pogledu.
            Zbog ovih, ali i drugih razloga, uobičajeno je da se proces evaluacije provodi nakon završetka programskih intervencija u sklopu šeme."></Toolpit></b>.</p>

            <p className="content_text">Tako, na primjer, ako su nedavne slične inicijative uključivale grantove u iznosu od oko 25.000 eura
            po firmi i ako je prosječna firma koja je dobila grant zaposlila tri osobe, onda bi se sa 30 miliona
            raspoloživih sredstava mogla odobriti pomoć za 1.200 novih firmi, što bi dovelo do otvaranja 3.600
            radnih mjesta.</p>

            <p className="content_text">Međutim, trebaju se uzeti u obzir trenutno socijalno i ekonomsko okruženje, kao i nalazi istraživanja
            o stanju i potencijalu poduzetništva na datoj teritoriji. Osim toga, ako su ranije postojale slične
            inicijative, možda bi se moglo osnovano pretpostaviti da je administracija postala efikasnija u
            upravljanju takvim inicijativama, što bi moglo unaprijediti njihove rezultate. Također, treba uzeti
            u obzir da se ova inicijativa fokusira na firme usmjerene na rast, kojima bi vjerovatno trebali veći
            iznosi grantova, ali da one istovremeno nude i više mogućnosti za zapošlja vanje.</p>

            <p className="content_text">S obzirom na to da je fokus na firmama usmjerenim na rast, <b>moglo bi se pretpostaviti </b>da će se najmanje 5
            miliona eura dodijeliti firmama sa visokim potencijalom za rast, da će prosječan iznos granta biti 50.000
            eura i da će ove firme u prosjeku zaposliti 10 osoba. Na osnovu ovoga, možemo zaključiti da će pomoć
            dobiti 100 firmi usmjerenih na rast, koje će otvoriti oko 1.000 radnih mjesta. Preostalim sredstvima će se
            podržati još oko 1.000 klasičnih firmi, što će rezultirati brojem od oko 3.000 radnih mjesta. </p>

            <p className="content_text blue mt-50"><b>Indikatori izlaznih rezultata i ishoda i njihove ciljane vrijednosti:</b></p>

            <table id="table076 " className="No-Table-Style TableOverride-1">
				<colgroup>
					<col className="_idGenTableRowColumn-16 " />
					<col className="_idGenTableRowColumn-209 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-124 ">
							<p className="tekst-u-tabelama-link-podcrtan "><span className="CharOverride-9 ">Indikator izlaznog rezultata:</span><span className="CharOverride-1 "> Broj novih firmi koje su dobile pomoć         </span></p>
						</td>
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama-link-podcrtan "><span className="CharOverride-9 ">Cilj:</span><span className="CharOverride-103 "></span><span className="CharOverride-1 ">1.100 firmi dobilo pomoć (od čega 100 u sektorima rasta)</span></p>
						</td>
					</tr>
				</tbody>
			</table>
            <br></br>
			<table id="table077 " className="No-Table-Style TableOverride-1">
				<colgroup>
					<col className="_idGenTableRowColumn-16 " />
					<col className="_idGenTableRowColumn-209 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-22 ">
						<td className="No-Table-Style CellOverride-107 ">
							<p className="tekst-u-tabelama-link-podcrtan "><span className="CharOverride-9 ">Indikator ishoda:</span> <span className="CharOverride-1 ">Nivoi zapošljavanja </span></p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama-link-podcrtan pos-rel"><span className="CharOverride-9 ">Cilj</span>: <span className="CharOverride-1 ">4.000 radnih mjesta (od čega 1.000 u firmama usmjerenim na rast)</span><span className="CharOverride-104 "><Toolpit text="Prilikom razmatranja ishoda šeme ne uzimamo u obzir ono što se podrazumijeva kao dugoročni „utjecaj“ (eng. „impact“)
                                realizacije šeme, koji nastaje kao „konačni efekat“ nakon realizacije programskih intervencija u sklopu šeme. (Objašnjenje: Utvrđivanje
                                neposrednog indikatora na nivou utjecaja podrazumijevalo bi utvrđivanje ciljanog broja radnih mjesta koja bi mogla opstati, te
                                vjerovatnog broja dodatnih radnih mjesta. Ranije iskustvo iz sličnih šema bi moglo pokazati da oko 70% radnih mjesta u start-up
                                (novim) firmama koje su dobile grantove čine dodatna i održiva radna mjesta. Na tom osnovu, konačni „utjecaj“ realizirane šeme, „kao
                                ishod na višem nivou“, mogao bi se procijeniti na oko i 2.800 neto radnih mjesta (700 u firmama usmjerenim na rast, 2.100 u ostalim
                                firmama). Međutim, kako se radi o utjecaju koji nastaje nakon završetka šeme, procjena „utjecaja“ se ne uzima u obzir u procesu
                                izrade šeme tokom kvantifikacije izlaznih rezultata i ishoda šeme. S druge strane, procjena utjecaja je važna za vrednovanje ostvarenih
                                efekata šeme i kao takva obično se provodi u sklopu ex-post evaluacije programskih intervencija i vjerovatno ne ranije od 12 mjeseci
                                po završetku intervencije (provodi se kroz široku anketu, koja obuhvata sve kompanije koje su dobile pomoć)."></Toolpit></span></p>
						</td>
					</tr>
				</tbody>
			</table>

            <p className="content_text blue mt-50"><b>2. Komponenta: Centar za izvrsnost</b></p>

            <p className="content_text">Dodatnih 25% ukupnih sredstava - oko 15 miliona eura – izdvojilo bi se za uspostavljanje fizičke
            infrastrukture za podršku otvaranju firmi. Veći dio tog iznosa – oko 10 miliona eura – usmjerit će
            se na uspostavu inkubatorskih jedinica za nove firme. U pregledu inicijative je jasno naznačeno da
            će oni biti smješteni u Centru za izvrsnost i da će taj centar ciljati na 100 firmi usmjerenih na rast.
            Također, u pregledu je jasno naznačeno da će postojati veze između različitih aktivnosti kad god je
            to prikladno. Kao što je ranije naznačeno, za potrebe ovog primjera, Centar će imati inkubatorske
            jedinice za 10 firmi. Firme koje su smještene u Centru neće plaćati rentu za prvih šest mjeseci;
            potom će plaćati sniženu rentu tokom narednih 18 mjeseci i punu rentu 12 mjeseci nakon toga, a
            onda će morati napustiti Centar i preći u komercijalne prostorije.</p>

            <p className="content_text blue"><b>Indikatori izlaznih rezultata i ishoda: </b></p>
            
            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>Inicijalni indikator izlaznih rezultata</b> mogao bi biti referentni cilj: <b>uspostavljanje samog Centra
            za izvrsnost.</b></span></p>


            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Jednako važan referentni indikator izlaznih rezultata je  <b> razvoj sistema za odabir firmi koje će biti
            smještene u inkubatorskim jedinicama.</b></span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b> Postizanje visokog i konstantnog nivoa popunjenosti prostorija u Centru za izvrsnost </b>bi, također,
            moglo biti indikator ishoda.</span></p>

            <p className="content_text pos-rel">Realizacija ovih referentnih tačaka bi mogla potrajati. Same jedinice bi možda bile spremne tek
            nakon dvije godine <Toolpit text="Ovaj primjer pokazuje i potrebu za produžavanjem perioda implementacije grant šeme znatno više od godinu dana."></Toolpit> implementacije programa, a isto bi moglo važiti i za proces odabira. To bi
            upućivalo na sljedeće indikatore:</p>

            <p className="content_text blue"><b>Indikatori izlaznih rezultata i ishoda i njihove ciljane vrijednosti:</b></p>
            

            <table id="table078 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-210 " />
					<col className="_idGenTableRowColumn-211 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-112 ">
							<p className="tekst-u-tabelama-link-podcrtan "><span className="CharOverride-9 ">Indikator izlaznog rezultata: </span><span className="CharOverride-1 "> Završetak osnivanja Centra za izvrsnost </span></p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama-link-podcrtan "><span className="CharOverride-9 ">Cilj:</span><span className="CharOverride-1 "> Osnivanje završeno do druge godine provedbe politike</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-112 ">
							<p className="tekst-u-tabelama-link-podcrtan "><span className="CharOverride-9 ">Indikator izlaznog rezultata: </span><span className="CharOverride-1 "> Razvoj sistema za odabir firmi koje će biti smještene u inkubatorskim jedinicama</span></p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama-link-podcrtan "><span className="CharOverride-9 ">Cilj:</span><span className="CharOverride-1 "> Identificiranje deset firmi za smještaj do treće godine provedbe politike</span></p>
						</td>
					</tr>
				</tbody>
			</table>


            <p className="content_text mt-25">Prvi od navedenih indikatora izlaznih rezultata je sam po sebi razumljiv; drugi implicira da je
            uspostavljena funkcionalna administracija i da se odgovarajuće firme mogu identificirati.</p>

            <table id="table079 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-212 " />
					<col className="_idGenTableRowColumn-211 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-112 ">
							<p className="tekst-u-tabelama-link-podcrtan "><span className="CharOverride-9 ">Indikator ishoda: </span><span className="CharOverride-1 ">Visok nivo popunjenosti prostorija Centra</span></p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama-link-podcrtan "><span className="CharOverride-9 ">Cilj: </span><span className="CharOverride-1 ">Centar je sasvim popunjen do kraja treće godine</span>.</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-128 ">
							<p className="tekst-u-tabelama-link-podcrtan "><span className="CharOverride-9 ">Indikator ishoda:</span><span className="CharOverride-1 "> Konstantna popunjenost prostorija Centra </span></p>
						</td>
						<td className="No-Table-Style CellOverride-129 ">
							<p className="tekst-u-tabelama-link-podcrtan pos-rel"><span className="CharOverride-9 ">Cilj: </span><span className="CharOverride-1 ">Najmanje četiri firme su iskoristile čitav period smještaja u Centru do kraja šeste godine 6</span><span className="CharOverride-104 "><Toolpit text="Ovaj ishod na visokom nivou zahtijevao bi dugoročni monitoring nakon intervencije, pa čak i evaluaciju."></Toolpit></span><span className="CharOverride-1 ">.</span></p>
						</td>
					</tr>
				</tbody>
			</table>

            <p className="content_text mt-35"><em>U ovim indikatorima je naglasak stavljen na uspostavljanje Centra, prvo kao fizičke lokacije za
            nove firme, a onda kao funkcionalne administrativne jedinice koja može identificirati potencijalno
            uspješne firme sa potencijalom za rast i razvoj, koje bi mogle pokriti rastuće troškove rente i na
            kraju otići i nastaviti s poslovanjem.</em></p>

            <p className="content_text blue"><b>3. Komponenta: Usluge poslovnog savjetovanja</b></p>

            <p className="content_text">Za usluge poslovnog savjetovanja će se izdvojiti dodatnih 20 miliona eura iz budžeta šeme. Za
            potrebe ovog primjera, pretpostavit će se da će se najveći dio tog iznosa (15 mil. eura) iskoristiti
            za plaćanje vanjskih eksperata (konsultanti, akademici, vlasnici firmi), koji će davati savjete svim
            novim firmama koje dobiju grant. Ako prosječni troškovi vanjskog konsultanta iznose 750 eura
            dnevno, onda se sa 15 miliona eur a može finansirati 6.000 konsultantskih dana.</p>

            <p className="content_text">Jedan od ciljeva Politike je promoviranje <b>veza između različitih aktivnosti koje ona obuhvata.</b>
            Dakle, budžet za savjetodavne usluge mogao bi se koristiti za pružanje <b>savjeta i podrške za svih
            1.100 novih firmi koje su dobile grant.</b> Za svaku bi se moglo izdvojiti oko 20 konsultantskih dana.
            Međutim, inicijativa se fokusira i na firme usmjerene na rast, a one najperspektivnije će dobiti
            mjesto u Centru za izvrsnost. Imajući to u vidu, <b>firme usmjerene na rast bi mogle dobiti veću
            konsultantsku podršku, a one smještene u Centru za izvrsnost još i veću.</b> Deset firmi smještenih
            u Centru mogle bi dobiti 50 dana, ostale firme usmjerene na rast oko 40 dana, dok bi preostale
            firme dobile između 15-20 dana. Sve navedeno bi upućivalo na<b> sljedeće indikatore:</b></p>

            <p className="content_text blue"><b>Indikatori izlaznih rezultata i ishoda:</b></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b><u>Indikator izlaznog rezultata: </u></b>Broj ostvarenih dana konsultantske podrške (mogući cilj: 20.000, od
            čega 4.000 za firme u sektorima rasta). </span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b><u>Indikator ishoda: </u></b> Procenat firmi koje su prijavile da su zadovoljne pruženim savjetima. </span></p>

            <p className="content_text d-flex d-flex-aac pos-rel"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b><u>Indikator ishoda: </u></b> Firme koje su dobile pomoć ostvaruju veću stopu „preživljavanja“ i visok
            produkcioni učinak (u odnosu na promet) od sličnih firmi koje nisu dobile pomoć. <Toolpit text="Kao i u prethodnom slučaju, ovaj ishod na višem nivou zahtijevao bi dugoročni monitoring nakon intervencije, pa čak i
            evaluaciju."></Toolpit> </span></p>

            <p className="content_text"><em>Ovdje indikator izlaznog rezultata ne predstavlja očigledan problem. Međutim, indikatori ishoda će
            zahtijevati provođenje ankete, što bi moglo povećati troškove i pokrenuti pitanja kvaliteta podataka.</em></p>

            <p className="content_text blue"><b>4. Komponenta: Promoviranje poduzetništva </b></p>

            <p className="content_text">Na koncu, 3 miliona eura će se iskoristiti za promoviranje poduzetništva kao karijerne opcije među
            učenicima i studentima. To će podrazumijevati organiziranje studijskih posjeta i privremenih
            radnih angažmana, a naročito posjeta Centru za izvrsnost i privremenih radnih angažmana u
            firmama usmjerenim na rast. Odgovarajući indikatori bi mogli biti:</p>

            <p className="content_text blue"><b>Indikatori izlaznih rezultata i ishoda: </b></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b><u>Indikator izlaznog rezultata: 
            </u></b> broj osoba koje su obuhvaćene studijskom posjetom (mogući cilj: 5.000).  </span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b><u>Indikator izlaznog rezultata: 
             </u></b>broj osoba uključenih u privremeni radni angažman u firmama (mogući cilj: 250). </span></p>

             <p className="content_text d-flex d-flex-aac pos-rel"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b><u>Indikator ishoda: 
             </u></b>Povećanje broja učenika i studenata koji su izrazili interes za karijeru u
            poduzetništvu ili radu u novoosnovanim firmama <Toolpit text="Kao i u prethodnom slučaju, ovaj ishod na višem nivou zahtijevao bi dugoročni monitoring nakon intervencije, pa čak i
            evaluaciju."></Toolpit>. </span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b><u>Indikator ishoda: 
             </u></b>Učesnici u studijskim posjetama, firme i učenici i studenti koji učestvuju u
            programima privremenog radnog angažmana izražavaju zadovoljstvo i navode da su ostvarili koristi. </span></p>


            <p className="content_text"><em>Ovdje indikator izlaznog rezultata ne predstavlja očigledan problem. Međutim, indikatori ishoda će
            zahtijevati provođenje ankete, što bi moglo povećati troškove i pokrenuti pitanja kvaliteta podataka.
            Također, indikator na nivou ishoda na višem nivou će možda biti teško procijeniti. Stavovi učesnika
            bi se mogli procijeniti kroz ankete ili intervjue ili promatranjem njihovog ponašanja u vezi sa
            karijerama koje odaberu.</em></p>

            <h2 class="content_heading_big blue mt-50 mb-35"><b>Neka zapažanja na osnovu izvršene analize studije slučaja</b></h2>

            <p className="content_text"><b>Očigledno, vrlo su male šanse da ovakva šema može ostvariti značajne ishode i izlazne rezultate,
            osim ako u procesu izrade šeme koji se odnosi na analize potražnje ne prepozna i finansira
            određene vrste projekata.</b> Takvi projekti, ako slijede logiku „usmjerenu na rezultate“, mogu
            ostvariti određene izlazne rezultate i ishode, koji bi u konačnici omogućili da šema ostvari svoje
            ciljeve. To konkretno znači da:</p>

			<div className="yellow_box mt-50">
						<ul>
							<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <span className="blue">Komponenta 1: </span>Start-up firme trebaju biti u mogućnosti da vrše ulaganja (npr. oprema,
							poslovni procesi itd.) koja u kratkom roku omogućavaju brzi rast i otvaranje radnih mjesta.</span></li>

							<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <span className="blue">Komponenta 2: </span>Općina ili grupa općina ili neka druga grupa treba osnovati Centar za
							izvrsnost. Za to su potrebni veliki kapaciteti. Također, Centar treba biti u stanju da vodi
							sistem poslovnih inkubatora na ekonomičan način. Za to su, također, potrebni značajni
							kapaciteti.</span></li>

							<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <span className="blue">Komponenta 3: </span>Zahtijeva dovoljan nivo povjerenja među kompanijama da bi koristile usluge
							poslovnog savjetovanja, te dovoljan broj dobrih konsultanata koji mogu efektivno postizati
							rezultate.
							</span></li>

							<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <span className="blue">Komponenta 4: </span> Zahtijeva saradnju između škola, obrazovnih institucija i kompanija. Ako ne
							sarađuju, neće biti ni projekta, a samim tim ni izlaznih ili drugih rezultata.</span></li>
							
						</ul>

            </div>
            
            <p className="content_heading blue mt-30 mb-30"><b>Zaključci</b></p>
            <p className="content_text">Utvrđivanje i kvantificiranje izlaznih rezultata i ishoda za šemu je proces pun pretpostavki i
            procjena o:</p>

            <p className="content_text"><span className="blue mr-10">i. </span><b>Uvjetima i kapacitetima za ostvarivanje ishoda i izlaznih rezultata na nivou projekta;</b></p>
            <p className="content_text"><span className="blue mr-10">ii. </span>Stvarnom nivou <b>razumijevanja „uvjeta na terenu“</b> i sličnih iskustava i situacija;</p>
            <p className="content_text"><span className="blue mr-10">iii. </span>Određenom nivou <b>učenja iz ranijih iskustava </b> jer ćete, u suprotnom, svake godine biti prisiljeni
            na nagađanja koja nemaju veze sa realnošću – a neka od n jih će biti veoma pogrešna;</p>
            <p className="content_text"><span className="blue mr-10">iv. </span>snažnom <b>procesu lokalnog razvoja, koji neprestano stvara uvjete i kapacitete za omogućavanje
            dobrih projekata.</b></p>

            <p id="studije-slucaja-3" className="content_text">Zaključno, bez dobrih projekata, ovakva šema može (nepromišljeno) potrošiti svoj novac, ali neće
            ostvariti dobre (očekivane) izlazne rezultate, a naročito ishode.</p>


            <h2 className="blue_overlay_heading mt-70 mb-40" ><b>STUDIJA SLUČAJA 3:</b></h2>
            
            <h3 className="content_text blue"><b>PRIMJERI TABELE ZA OCJENJIVANJE KRITERIJA ODABIRA NA OSNOVU KVALITETA</b></h3>

            <p className="content_text"><b><u>U primjeru 1 </u></b>u nastavku teksta data je tabela kriterija odabira na osnovu kvaliteta, koja je korištena
            za dodjelu grantova u projektu “Lokalna partnerstva za zapošljavanje”, koji je finansirao EU. Projekt
            je realiziran u Hrvatskoj u periodu 2005-2007. godina.</p>   

            <br></br>

            <table id="table080 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-213 " />
					<col className="_idGenTableRowColumn-214 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Odjeljak</p>
						</td>
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Maksimalni broj bodova</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-107 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-107 ">1. Finansijska i operativna sposobnost</span></p>
						</td>
						<td className="No-Table-Style CellOverride-107 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-107 ">20</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">1.1 Imaju li aplikant i partneri dovoljno iskustva u vođenju projekata? </p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">1.2 Imaju li aplikant i partneri odgovarajuću tehničku stručnost? (naročito u problematici koja se nastoji riješiti.)</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">1.3 Imaju li aplikant i partneri dostatne upravljačke kapacitete? (uključujući osoblje, opremu i sposobnosti upravljanja budžetom projekta)?</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">1.4 Ima li aplikant stabilne i dostatne izvore finansiranja?</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-107 _idGenCellOverride-5 " colspan="2 ">
							<p className="tekst-u-tabelama ParaOverride-59 ">Lokalno partnerstvo za zapošljavanje, Hrvatska</p>
						</td>
					</tr>
				</tbody>
			</table>

            <br></br>
			<br></br>

            <table id="table081 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-215 " />
					<col className="_idGenTableRowColumn-62 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Odjeljak</p>
						</td>
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Maksimalni broj bodova</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-107 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-107 ">2. Relevantnost</span></p>
						</td>
						<td className="No-Table-Style CellOverride-130 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-107 ">25</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-131 ">
							<p className="tekst-u-tabelama ">2.1 Koliko je prijedlog relevantan u odnosu na ciljeve i jedan ili više prioriteta ovog javnog konkursa? Napomena: Ocjena 5 (vrlo dobro) dodjeljuje se samo ako se projektni prijedlog konkretno bavi makar jednim prioritetom.</p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-131 ">
							<p className="tekst-u-tabelama ">2.2 Je li prijedlog u skladu s operativnim programom – osnovna infrastruktura, nacionalni plan razvoja i jedinstveni programski dokument, cilj 2 NUTS-a i programom ekonomskog i socijalnog razvoja regije?</p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-131 ">
							<p className="tekst-u-tabelama ">2.3 Koliko dobro su definirani i strateški odabrani sudionici na projektu (posrednici, krajnji korisnici, ciljne grupe)?</p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-21 ">
						<td className="No-Table-Style CellOverride-131 ">
							<p className="tekst-u-tabelama ">2.4 Jesu li potrebe ciljnih grupa i krajnjih korisnika jasno definirane i bavi li se prijedlog projekta tim potrebama na adekvatan način?</p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-132 ">
							<p className="tekst-u-tabelama ">2.5 Sadrži li prijedlog projekta neke posebne elemente koji predstavljaju dodanu vrijednost, kao što su inovativni pristupi, modeli dobre prakse, snažan i jasan lokalni/regionalni učinak, jesu li prijedlozi spremni za realizaciju?</p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-133 " colspan="2 ">
							<p className="tekst-u-tabelama ParaOverride-59 ">Lokalno partnerstvo za zapošljavanje, Hrvatska</p>
						</td>
					</tr>
				</tbody>
			</table>

            <br></br>
			<br></br>

            <table id="table082 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-216 " />
					<col className="_idGenTableRowColumn-217 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Odjeljak</p>
						</td>
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Maksimalni broj bodova</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-107 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-107 ">3. Metodologija</span></p>
						</td>
						<td className="No-Table-Style CellOverride-107 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-107 ">30</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama "><span lang="en-US ">3.1 Jesu li predložene aktivnosti prikladne, praktične i konzistentne s ciljevima i očekivanim rezultatima? </span></p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">3.2 Koliko je dobro logički povezan cjelokupni dizajn projekta? (Odražava li analizu problema? Uzima li u obzir vanjske faktore i predviđa li evaluaciju?)</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">3.3 Je li stepen uključenosti partnera u realizaciju projekta zadovoljavajući? Napomena: Ako nema partnera, dodijelit će se samo jedan (1) bod. </p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">3.4 Je li stepen uključenosti ciljanih grupa i krajnjih korisnika u realizaciju projekta zadovoljavajući?</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">3.5 Je li akcioni plan jasan i izvodljiv?</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">3.6 Sadrži li prijedlog projekta indikatore ishoda projekta koji se mogu objektivno provjeriti? </p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-107 " colspan="2 ">
							<p className="tekst-u-tabelama ParaOverride-59 ">Lokalno partnerstvo za zapošljavanje, Hrvatska</p>
						</td>
					</tr>
				</tbody>
			</table>

            <br></br>
            <br></br>

            <table id="table083 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-46 " />
					<col className="_idGenTableRowColumn-47 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Odjeljak</p>
						</td>
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Maksimalni broj bodova</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-107 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-107 ">4. Održivost</span></p>
						</td>
						<td className="No-Table-Style CellOverride-107 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-107 ">15</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">4.1 Je li izvjesno da će projekt imati konkretan učinak na ciljne grupe?</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">4.2 Je li izvjesno da će projektni prijedlog imati multiplikativne učinke? (npr. opseg replikacije i proširenje ishoda projekta i širenje informacija)</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-218 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">4.3 Jesu li očekivani rezultati prijedloga projekta održivi:</p>
							<p className="tekst-u-tabelama ">- finansijski (kako će se aktivnosti finansirati nakon što se okonča finansiranje sredstvima Evropske zajednice?)</p>
							<p className="tekst-u-tabelama ">- institucionalno (hoće li strukture omogućiti nastavak aktivnosti nakon okončanja projekta? Hoće li lokalne vlasti preuzeti odgovornost nad rezultatima projekta?)</p>
							<p className="tekst-u-tabelama ">- na nivou politike (gdje je primjenjivo) (kakav će biti strukturni učinak projekta - npr. hoće li dovesti do poboljšanja zakona, kodeksa ponašanja, metoda itd.?)</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-124 " colspan="2 ">
							<p className="tekst-u-tabelama ParaOverride-59 ">Lokalno partnerstvo za zapošljavanje, Hrvatska</p>
						</td>
					</tr>
				</tbody>
			</table>

            <br></br>
			<br></br>

            <table id="table084 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-46 " />
					<col className="_idGenTableRowColumn-47 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Odjeljak</p>
						</td>
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Maksimalni broj bodova</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-107 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-107 ">5. Budžet i isplativost</span></p>
						</td>
						<td className="No-Table-Style CellOverride-107 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-107 ">10</span></p>
						</td>
					</tr>
					<tr className="No-Table-Sbntyle _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">5.1 Je li omjer između procijenjenih troškova i očekivanih rezultata zadovoljavajući?</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">5.2 Jesu li predloženi izdaci neophodni za realizaciju projekta?</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-124 " colspan="2 ">
							<p className="tekst-u-tabelama ParaOverride-59 ">Lokalno partnerstvo za zapošljavanje, Hrvatska</p>
						</td>
					</tr>
				</tbody>
			</table>

            <p className="content_text mt-50 mb-25"><b><u>U primjeru 2 </u></b>u nastavku data je tabela za evaluaciju i odabir projekata koju je koristila malezijska
            fondacija Yayasan Hasanah</p>

			<h2 className="content_heading_big mt-50 mb-30 blue"><b>SMJERNICE ZA PRIJAVU ZA GRANT</b> (2. CIKLUS) </h2>
			
			<p className="content_text">Prilozi uz obrazac prijave bit će dostavljeni organu koji će vršiti evaluaciju projektnih
			prijedloga. Dakle, izuzetno je važno da u tim dokumentima dostavite sve relevantne informacije o
			projektnom prijedlogu. Ne trebate dostavljati nikakve dodatne priloge, osim onih koji su naznačeni.
			U tom smislu, preporučujemo da koristite kontrolnu listu priloga uz obrazac prijave.</p>

			<p className="content_text">Nakon što se izvrši pregled opisa projekata, uspješni aplikanti će dobiti informaciju o
			krajnjem roku do kojeg mogu dostaviti kompletirane prijave (druga faza) putem interneta.</p>

			<p className="content_text">Da bi osigurala ravnopravan tretman aplikanata, fondacija Hasanah ne može unaprijed davati
			mišljenje o prihvatljivosti aplikanata ili prijedloga. </p>

			<h2 className="content_text blue mt-40"><b>1.5 EVALUACIJA I ODABIR APLIKANATA</b></h2>

			<p className="content_text">Prijave će pregledati i ocjenjivati Odbor fondacije Hasanah za dodjelu grantova. Svi će se
			prijedlozi procjenjivati prema sljedećim koracima i kriterijima.</p>

			<p className="content_text">Ako se prilikom evaluacije prijave ustanovi da predloženi projekt ne zadovoljava kriterije
			prihvatljivosti navedene u stavku 1.3, to će biti osnov za odbijanje prijave.</p>

			<h2 className="content_text"><b><u>Prvi korak: Administrativna provjera evaluacija koncepta projekta</u></b></h2>

			<p className="content_text">Procjenjuje se sljedeće:</p>

			<p className="content_text"> - <span className="ml-15">Je li prijava dostavljena u roku? Ako nije, prijava će automatski biti odbijena.</span> </p>
			<p className="content_text"> - <span className="ml-15">Ako bilo koja od traženih informacija nedostaje ili je netačna, prijava će zbog toga biti
			odbijena i neće se dalje razmatrati.</span> </p>

			
			<p className="content_text">Opisi koncepta projekta koji prođu prvu administrativnu provjeru, razmatrat će se u pogledu
			relevantnosti i dizajna predloženih inicijativa.</p>

			<p className="content_text">Koncept projekta može dobiti maksimalno 50 bodova, na način kako je razloženo u tabeli za
			evaluaciju u nastavku teksta. Evaluacijski kriteriji podijeljeni su na rubrike i podrubrike. Svaka
			rubrika se ocjenjuje bodovima od 1 do 5 i od 1 do 10 , kako je navedeno u nastavku. Za više detalja pogledajte tabelu za ocjenjivanje koncepta projekta u nastavku teksta.</p>


			<p className="content_text"><b>U fazi predodabira, u obzir se uzimaju samo koncepti s najmanje 30 osvojenih bodova. Sistem
			bodovanja 1 - 5: <br></br> <br></br> </b>1 – slabo razrađen / ispod očekivanja, 2 – zahtijeva pojašnjenje / doradu, 3 – ispunjava
			osnovna očekivanja, 4 – dobro razrađen / objašnjen, 5 – izuzetno kvalitetni odgovori /nadilazi
			očekivanja</p>

			<p className="content_text"><b>Sistem bodovanja 1 - 10:<br></br><br></br></b>1-2 - slabo razrađen / ispod očekivanja, 3-4 – zahtijeva pojašnjenje / doradu, 5-6 – ispunjava
			osnovna očekivanja, 7-8 – dobro razrađen / objašnjen, 9-10 – izuzetno kvalitetni odgovori /
			nadilazi očekivanja</p>

			<br></br>
			<p className="content_text">Fondacija Hasanah će u roku od 14 radnih dana od datuma podnošenja poslati pismo svim
			aplikantima, u kojem će ih obavijestiti o rezultatima evaluacije koncepta. Aplikanti koji su prošli
			predselekciju dobit će poziv da podnesu kompletiranu prijavu.</p>
			
			<p className="content_heading blue mt-50 mb-30">TABELA ZA EVALUACIJU KONCEPTA (2. CIKLUS)</p>

			<div className="custom_tables"><table id="table085 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-219 " />
					<col className="_idGenTableRowColumn-220 " />
					<col className="_idGenTableRowColumn-221 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-134 " colspan="2 ">
							<p className="nalovi-u-tabelama ">1.&#9;Relevantnost projekta</p>
						</td>
						<td className="No-Table-Style CellOverride-135 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-222 ">
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ">1.1 Ocijenite prijedlog na temelju sljedećeg:  </p>
							<p className="tekst-u-tabelama ParaOverride-72 ">i)<span className="CharOverride-109 ">       </span>Koliko dobro su definirani i strateški odabrani sudionici na projektu(krajnji korisnici, interesne grupe)?</p>
							<p className="tekst-u-tabelama ParaOverride-72 ">ii)<span className="CharOverride-109 ">&#9;</span>Jesu li njihove potrebe jasno opisane?  </p>
							<p className="tekst-u-tabelama ParaOverride-72 ">iii)<span className="CharOverride-109 ">&#9;</span>Kako će oni direktno imati koristi od očekivanih izlaznih rezultata ovog projekta?    </p>
							<p className="tekst-u-tabelama ">(Pozovite se na tačku 2.3 prijave koncepta).  </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">10 </p>
						</td>
						<td className="No-Table-Style CellOverride-113 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ">1.2  Kako projekt doprinosi postizanju pozitivne promjene bilo kojeg relevantnog programa / plana na lokalnom, regionalnom i / ili nacionalnom nivou? (Pozovite se na tačku 2.4 prijave koncepta).  </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5 </p>
						</td>
						<td className="No-Table-Style CellOverride-113 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-113 " />
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Rezultat rubrike</p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">/15 </p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-134 " colspan="2 ">
							<p className="nalovi-u-tabelama ">2.&#9;Dizajn projekta</p>
						</td>
						<td className="No-Table-Style CellOverride-135 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-223 ">
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ">2.1 Ocijenite sljedeće aspekte dizajna projekta:  </p>
							<p className="tekst-u-tabelama ">      i)      Je li aplikant dobro upoznat s problemom koji se nastoji riješiti? </p>
							<p className="tekst-u-tabelama ">      ii)     Bavi li se projekt utvrđenim problemima na odgovarajući način? </p>
							<p className="tekst-u-tabelama ParaOverride-73 ">iii)    Jesu li predložene aktivnosti direktno povezane s očekivanim izlaznim rezultatima i ishodima projekta?  </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">10 </p>
						</td>
						<td className="No-Table-Style CellOverride-113 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-142 ">
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ">2.2 Je li projekt izvodljiv i u skladu s ciljevima i očekivanim rezultatima?  </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">10 </p>
						</td>
						<td className="No-Table-Style CellOverride-113 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-113 " />
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Rezultat rubrike  </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">/20 </p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-134 " colspan="2 ">
							<p className="nalovi-u-tabelama ">3.&#9;Prednosti organizacije</p>
						</td>
						<td className="No-Table-Style CellOverride-135 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-6 ">
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ">3.1. Ocijenite prijedlog projekta na temelju sljedećeg:</p>
							<p className="tekst-u-tabelama ParaOverride-34 ">i)      Ima li organizacija potreban broj uposlenika na neodređeno/određeno i aktivnih volontera za uspješno provođenje projekta?</p>
							<p className="tekst-u-tabelama ParaOverride-34 ">ii)      Ima li organizacija dobre rezultate u provedbi ranijih projekata?  </p>
							<p className="tekst-u-tabelama ">(Pozovite se na tačku 1.2 prijave koncepta).  </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">10 </p>
						</td>
						<td className="No-Table-Style CellOverride-113 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ">3.2. Ima li organizacija dovoljan godišnji budžet i dovoljan broj donatora koji su je podržavali u protekle dvije godine? </p>
							<p className="tekst-u-tabelama ">(Pozovite se na tačku 1.2 prijave koncepta).  </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-113 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-113 " />
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Rezultat rubrike  </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">/15 </p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-136 " colspan="2 ">
							<p className="tekst-u-tabelama ParaOverride-59 "><span className="CharOverride-110 ">UKUPNI REZULTAT</span></p>
						</td>
						<td className="No-Table-Style CellOverride-136 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-60 ">/50 </span></p>
						</td>
					</tr>
				</tbody>
			</table></div>

			<h2 className="content_text mt-40"><b><u>Drugi korak 2: Evaluacija kompletne prijave</u></b></h2>

			<p className="content_text">Procjenjuje se sljedeće</p>
			<p className="content_text">Je li prijava dostavljena u roku? Ako nije, prijava će automatski biti odbijena.</p>
			<p className="content_text">Ispunjava li aplikacija sve kriterije naznačene u kontrolnoj listi priloga uz obrazac prijave. Ako bilo
			koja od traženih informacija nedostaje ili je netačna, prijava će zbog toga biti odbijena i neće se
			dalje razmatrati.</p>
			<p className="content_text">Kvalitet prijava, uključujući predloženi budžet, plan realizacije i kapacitet aplikanata, ocjenjuju se
			prema kriterijima za evaluaciju naznačenim u tabeli za evaluaciju u nastavku.</p>
			<p className="content_text"><b>U fazi odobravanja, razmatrat će se samo potpuni prijedlozi koji su dobili najmanje 80 bodova.
			Prije donošenja konačne odluke, fondacija Hasanah će posjetiti aplikante radi njihove temeljite
			identifikacije.</b></p>
			<p className="content_text">Evaluacijski kriteriji podijeljeni su na rubrike i podrubrike. Svaka podrubrika se ocjenjuje bodovima
			od 1 do 5 i od 1 do 10 , kako je navedeno u nastavku.</p>

			<p className="content_text"><b>Sistem bodovanja 1 - 5:</b><br></br>1 – slabo razrađen / ispod očekivanja, 2 – zahtijeva pojašnjenje / doradu, 3 – ispunjava osnovna
			očekivanja, 4 – dobro razrađen / objašnjen, 5 – izuzetno kvalitetni odgovori /nadilazi očekivanja</p>

			<p className="content_text"><b>Sistem bodovanja 1 - 10:</b><br></br>1-2 - slabo razrađen / ispod očekivanja, 3-4 – zahtijeva pojašnjenje / doradu, 5-6 – ispunjava
			osnovna očekivanja, 7-8 – dobro razrađen / objašnjen, 9-10 – izuzetno kvalitetni odgovori /
			nadilazi očekivanja</p>

			<p className="content_heading blue mt-70 mb-30">TABELA ZA EVALUACIJU CJELOKUPNE PRIJAVE (2. CIKLUS)</p>
			
			<table id="table086 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-224 " />
					<col className="_idGenTableRowColumn-191 " />
					<col className="_idGenTableRowColumn-37 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-149 ">
						<td className="No-Table-Style CellOverride-136 ">
							<p className="nalovi-u-tabelama ParaOverride-4 "> 1.<span className="CharOverride-109 ">&#9;</span>Operativna i finansijska sposobnost* </p>
							<p className="tekst-u-tabelama ParaOverride-4 "><span className="CharOverride-60 ">*Napomena: Ako je ukupni rezultat za ovaj dio manji od 12 bodova, prijava će biti odbijena. Ako je rezultat u makar jednoj rubrici ovog dijela (odnosno 1.1 - 1.3) „1“, prijava će, također, biti odbijena.U tom slučaju, nema potrebe za nastavkom evaluacije.</span></p>
						</td>
						<td className="No-Table-Style CellOverride-136 " />
						<td className="No-Table-Style CellOverride-136 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-89 ">
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-4 ">1.1 Ima li aplikant dovoljne upravljačke kapacitete, tj. relevantno osoblje (uključujući više rukovodstvo / osoblje zaposleno na neodređeno/određeno, volontere)? </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5 </p>
						</td>
						<td className="No-Table-Style CellOverride-113 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-225 ">
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-4 ">1.2 Ocijenite prijedlog projekta na temelju sljedećeg:  </p>
							<p className="tekst-u-tabelama ParaOverride-4 ">Je li aplikant adekvatno predstavio učešće relevantnih aktera u realizaciji projektnih aktivnosti?  </p>
							<p className="tekst-u-tabelama ParaOverride-4 ">Ako mjesto projekta nije mjesto u kojem je sjedište organizacije, ocijenite sposobnost aplikanta da realizira ovaj projekt (npr. saradnja s lokalnim povezanim licima/volonterima  na mjestu projekta/podružnice u mjestu projekta). (Informaciju o sjedištu organizacije možete naći u sažetoj tabeli na prvoj stranici ove tabele za evaluaciju).  </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">10 </p>
						</td>
						<td className="No-Table-Style CellOverride-113 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-94 ">
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-4 ">1.3 Ima li aplikant dovoljno iskustva u upravljanju grantovskim sredstvima ili sličnim iznosima, u skladu sa zahtjevima?</p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5 </p>
						</td>
						<td className="No-Table-Style CellOverride-113 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-113 " />
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Rezultat rubrike  </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">/20 </p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-136 ">
							<p className="nalovi-u-tabelama ">2.<span className="CharOverride-109 ">&#9;</span>Relevantnost projekta</p>
						</td>
						<td className="No-Table-Style CellOverride-136 " />
						<td className="No-Table-Style CellOverride-136 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-94 ">
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ">Rezultat prenesen iz evaluacije koncepta</p>
							<p className="tekst-u-tabelama "><span className="CharOverride-31 ">(Rezultat iz programa + rezultat iz stuba /2x0.6)  </span></p>
						</td>
						<td className="No-Table-Style CellOverride-113 " />
						<td className="No-Table-Style CellOverride-113 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-113 " />
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Rezultat rubrike  </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">/30 </p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-136 ">
							<p className="nalovi-u-tabelama "><span className="CharOverride-9 ">3.&#9;Efektivnost i izvodljivost projekta</span></p>
						</td>
						<td className="No-Table-Style CellOverride-136 " />
						<td className="No-Table-Style CellOverride-136 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ">3.1 Jesu li predložene aktivnosti dobro opisane, prate li logički slijed, jesu li prikladne i u skladu s očekivanim izlaznim rezultatima? Pozovite se na tačku 2.3 prijave. </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">10 </p>
						</td>
						<td className="No-Table-Style CellOverride-113 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ">3.2 Odražava li plan realizacije predložene aktivnosti? Je li izvodljiv? Pozovite se na Prilog C. </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5 </p>
						</td>
						<td className="No-Table-Style CellOverride-113 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-69 ">
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ">3.3 <span className="CharOverride-31 ">Sadrži li prijedlog dobro definirane aktivnosti praćenja i evaluacije i relevantne indikatore?Pozovite se na tačku 2.4 prijave. </span></p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5 </p>
						</td>
						<td className="No-Table-Style CellOverride-113 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-113 " />
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Rezultat rubrike  </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">/20 </p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-136 ">
							<p className="nalovi-u-tabelama ">4.<span className="CharOverride-109 ">&#9;</span>Održivost projekta</p>
						</td>
						<td className="No-Table-Style CellOverride-136 " />
						<td className="No-Table-Style CellOverride-136 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-69 ">
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ">4.1 <span className="CharOverride-31 ">Je li izvjesno da će projekt imati konkretan pozitivan učinak na ciljane grupe/krajnje korisnike?</span></p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5 </p>
						</td>
						<td className="No-Table-Style CellOverride-113 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-39 ">
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-31">4.2 Je li izvjesno da će projektni prijedlog imati multiplikativne učinke? (npr. uključujući mogućnosti (i opseg) replikacije projekta, eventualno proširenje projekta, kao i širenje saznanja i informacija stečenih kroz realizaciju projekta</span><span className="CharOverride-31 "></span><span className="CharOverride-31 ">)</span></p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5 </p>
						</td>
						<td className="No-Table-Style CellOverride-113 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ">4.3 Je li predviđen nastavak projekta nakon što se završi finansiranje fondacije Hasanah?  </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5 </p>
						</td>
						<td className="No-Table-Style CellOverride-113 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-113 " />
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Rezultat rubrike  </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">/15 </p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-226 ">
						<td className="No-Table-Style CellOverride-136 ">
							<p className="nalovi-u-tabelama "><span className="CharOverride-9 ">5.&#9;Budžet i troškovna učinkovitost (isplativost) projekta</span></p>
							<p className="tekst-u-tabelama "><span className="CharOverride-60 ">*Napomena: Varijacije razloženog budžeta po stavkama, koji je dostavljen u prijavi, ne smiju biti veće od 15% u odnosu na ukupni budžet dostavljen u konceptu projekta. </span></p>
						</td>
						<td className="No-Table-Style CellOverride-136 " />
						<td className="No-Table-Style CellOverride-136 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ">5.1 Jesu li procijenjeni troškovi projektnih aktivnosti realistični (previsoki/preniski)? Je li dostavljen detaljan prikaz troškova, razložen po stavkama?  </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5 </p>
						</td>
						<td className="No-Table-Style CellOverride-113 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ">5.2 Je li omjer između procijenjenih troškova i očekivanih rezultata zadovoljavajući? (Navedite predloženu reviziju budžeta ukoliko odnos procijenjenih troškova i procijenjenih rezultata nije zadovoljavajući).  </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">10 </p>
						</td>
						<td className="No-Table-Style CellOverride-113 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-113 " />
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Rezultat rubrike  </p>
						</td>
						<td className="No-Table-Style CellOverride-113 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">/15 </p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-136 " />
						<td className="No-Table-Style CellOverride-136 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-60 ">Ukupni rezultat</span></p>
						</td>
						<td className="No-Table-Style CellOverride-136 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-110 ">/100 </span></p>
						</td>
					</tr>
				</tbody>
			</table>
				
			<p className="content_text mt-40"><b><u>U primjeru 3 </u></b>u nastavku data je tabela kvalitativnih evaluacijskih kriterija za odabir projekata koje
			je finansiralo Federalno ministarstvo razvoja, poduzetništva i obrta u okviru grant šeme “Jačanje
			konkurentnosti malih i srednjih preduzeća”, čiju je izradu potpomogao UNDP (2017. godine). Očito
			je da su “osnovni” kriteriji u ovom primjeru detaljnije razrađeni nego u ranijim primjerima, tj. više
			su fokusirani na realnost finansiranja malih i srednjih preduzeća (MSP).</p>

			<p className="content_text">Jasno je da se kod ove šeme nije koristio “generički” pristup, koji se često koristi za grant šeme EU
			izvan EU i može se smatrati, u mnogim aspektima, da je prilagođeniji onoj vrsti projekata koji su
			obuhvaćeni ovom šemom, u ovom slučaju tehnološkim projektima.</p>

			<p className="content_text">Treba, međutim, istaći da mnogi finansijeri, uključujući i EU, nastoje postaviti opći i minimalni
			standard kada je riječ o kriterijima odabira koji se odnose na kvalitet. Evropska unija priznaje da
			su njezini kriteriji po prirodi općeniti, ali: (a) ničim ne sprečava izradu preciznijih podkriterija
			za svaki kriterij kvaliteta i (b) obično osigurava obuku za one koji će raditi na evaluaciji kada su
			u pitanju tumačenja generičkih kriterija za projekte koji potpadaju pod ovu šemu (vidi primjer u
			nastavku). U zemljama članicama EU, šeme obično karakteriziraju sektorski/tematski kriteriji, koji
			su prilagođeni vrstama projekata koji će se vjerovatno finansirati u okviru šeme.</p>

			<p className="content_text">Bez obzira na odabir rješenja, prilikom izrade kriterija jasno je da se generički kriteriji kvaliteta,
			koji se nekad koriste kao polazna osnova, moraju nešto jasnije precizirati i prilagoditi temama grant šeme. Primjer u nastavku predstavlja dobar kompromis različitih pristupa. Naravno, ovako
			osmišljeni kriteriji kvaliteta bi mogli biti i precizniji: to bi, međutim, nužno zahtijevalo da svaka
			šema ili svaka komponenta šeme bude monotematska i da njezin opseg bude vrlo striktno definiran.
			To nije lako postići.</p>
			
			<p className="content_text">Ovdje navodimo jedan takav primjer: pogledajmo dva tipična kriterija “relevantnosti”.</p>

			<p className="content_text mt-60"><u>Šema za unapređenje turističkih usluga:</u></p>

			<table id="table087 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-227 " />
					<col className="_idGenTableRowColumn-228 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Relevantnost - Relativno generički kriteriji</p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Odgovarajući specifični kriteriji <br />(turističke usluge)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-207 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-9 ">Jesu li projektni ciljevi, izlazni rezultati, odnosno ishodi projekta, relevantni za ciljeve, izlazne rezultate i ishode šeme, odnosno, politike?</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-4 ">Jesu li ciljevi projekta u skladu s ciljem šeme/mjere [kojom se nastoji povećati kvalitet i kvantitet turističkih usluga i proizvoda vezanih za kulturni turizam i turizam prirodnih ljepota]?</p>
							<p className="tekst-u-tabelama ParaOverride-4 ">Jesu li izlazni rezultati projekta u skladu s izlaznim rezultatima šeme/mjera [povećanje broja kulturnih i prirodnih turističkih proizvoda u regiji za 40% nakon 2 godine]?</p>
							<p className="tekst-u-tabelama ParaOverride-4 ">Jesu li ishodi projekta u skladu sa ishodima šeme/mjera [povećanje broja turista od 30% u segmentu kulturnog i prirodnog turizma nakon 2 godine i povećanje osnovnih prihoda od kulturnog i prirodnog turizma od 20% nakon 2 godine]?</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-229 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-9 ">Zadovoljava li projekt jasno utvrđene potrebe i potražnju?</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-4 "><span className="CharOverride-9 ">Koja su potencijalna tržišta za proizvode/usluge predložene projektom?</span></p>
							<p className="tekst-u-tabelama ParaOverride-4 "> </p>
							<p className="tekst-u-tabelama ParaOverride-4 ">Koji turisti su ciljana populacija (profil, dnevni posjetitelji, posjetitelji koji ostaju na jednom noćenju)? Odakle se mogu privući dodatni (novi) posjetitelji (koja su potencijalna tržišta, ciljane grupe, njihove lokacije, veličina, druge karakteristike).</p>
							<p className="tekst-u-tabelama ParaOverride-4 ">  </p>
							<p className="tekst-u-tabelama ParaOverride-4 ">Šta će se konkretno ponuditi različitim tržištima? Temelji li se vaš pristup na konkretnom istraživanju tržišta (ako da, navedite pojedinosti)?</p>
							<p className="tekst-u-tabelama ParaOverride-4 ">Ko će biti uključen u prodaju usluga, kao i organizaciju pristupa turističkim atrakcijama i objektima, uslugama, posebno onima koje su kreirane/unaprijeđene kroz projekt? </p>
							<p className="tekst-u-tabelama ParaOverride-4 ">Postoje li u području turističke agencije/operateri?</p>
							<p className="tekst-u-tabelama ParaOverride-4 "> </p>
							<p className="tekst-u-tabelama ParaOverride-4 ">Jesu li već izrađeni marketinški planovi/strategije?</p>
							<p className="tekst-u-tabelama ParaOverride-4 "> </p>
							<p className="tekst-u-tabelama ParaOverride-4 ">Kakvo je ranije iskustvo s marketingom?</p>
						</td>
					</tr>
				</tbody>
			</table>

			<p className="content_text mt-40">Kao što se može vidjeti iz gornje tabele, specifični kriteriji (turističke usluge) mogu se formulirati
			kao pitanja ili izjave. Odgovor na ta pitanja nije jednostavno „da“ ili „ne“, već treba odgovoriti u
			kojoj je „mjeri“ neki uvjet ispunjen. Oni koji razmatraju prijave trebaju dokaze (u ovom slučaju,
			i kvantitativne i kvalitativne) kako bi procijenili opseg i dodijelili odgovarajući broj bodova. Oni moraju biti uvjereni da su ti dokazi pouzdani. Sve to zahtijeva da se u aplikaciji i/ili popratnoj
			dokumentaciji dostavi što više dokaza.</p>

			<p className="content_text mt-40"><b> <u>Pogledajmo sada primjer 3, tabela kriterija za odabir projekata iz BiH: </u></b></p>

			<p className="content_text">Evaluacijski kriteriji su podijeljeni na kategorije i podkategorije, gdje se svaka podkategorija boduje
			na skali od 1 do 5, pri čemu je: 0 = vrlo loše; 1 = loše; 2 = zadovoljavajuće; 3 = dobro; 4 = vrlo dobro;
			5 = odlično</p>

			<p className="content_text mt-70"><b>Skala za evaluaciju </b></p>
			<br></br>
			<div className="custom_tables">
			<table id="table088 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-230 " />
					<col className="_idGenTableRowColumn-231 " />
					<col className="_idGenTableRowColumn-55 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-105 _idGenCellOverride-1 " rowspan="2 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Osnovni kriteriji: <br />relevantnost projedloga</p>
						</td>
						<td className="No-Table-Style CellOverride-137 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Maksimalni broj bodova</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-138 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">40</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Hoće li predviđeno unapređenje tehnološke opremljenosti omogućiti kvantitativno poboljšanje proizvodnog procesa i pristup novim tržištima? (Prioritetna oblast 1)</p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">DA&#160;-&#160;5&#160;;&#160;NE&#160;-&#160;0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Hoće li planirano ulaganje u nove tehnologije ostvariti povećanje prihoda od najmanje 3% godišnje u 12 mjeseci od nabavke? (Prioritetna oblast 1)</p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">DA&#160;-&#160;5&#160;;&#160;NE&#160;-&#160;0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Hoće li planirano ulaganje u unapređenje tehnološke opremljenosti rezultirati smanjenem broja zaposlenih? (Prioritetna oblast 1)</p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">DA&#160;-&#160;5&#160;;&#160;NE&#160;-&#160;0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Hoće li predviđene obuke za potrebnu radnu snagu osigurati zapošljavanje minimalno 5 osoba koje su prošle posebnu obuku sufinansiranu kroz grant šemu? (Prioritetna oblast 2)</p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 "> DA&#160;-&#160;2x5&#160;;&#160;NE - 0         </p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Hoće li MSP kroz projekt otvoriti nova tržišta ili proširiti prisustvo na postojećim tržištima? (Prioritetna oblast 3) </p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">DA&#160;-&#160;5&#160;;&#160;NE&#160;-&#160;0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Hoće li MSP povećati trenutni nivo izvoza u poređenju s 2016. finansijskom godinom? (Prioritetna oblast 3)</p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">DA&#160;-&#160;5&#160;;&#160;NE&#160;-&#160;0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-140 ">
							<p className="tekst-u-tabelama ">Hoće li MSP stvoriti nove ili unaprijediti postojeće kanale komunikacije i alate za pristup tržištu i promociju na inostranim tržištima? (Prioritetna oblast 3)</p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">DA&#160;-&#160;5&#160;;&#160;NE&#160;-&#160;0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-141 _idGenCellOverride-1 " rowspan="2 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Osnovni kriteriji: </p>
							<p className="nalovi-u-tabelama ParaOverride-2 ">kvalitet i logika prijedloga</p>
						</td>
						<td className="No-Table-Style CellOverride-142 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-143 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Maksimalni broj bodova</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-142 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-144 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">25</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-115 ">
							<p className="tekst-u-tabelama ">Da li je prijedlog projekta jasan, logičan i relevantan u odnosu na očekivane rezultate? </p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">0 - 5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Da li je plan aktivnosti izvodljiv i logičan? </p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">0 - 5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Da li su očekivani rezultati izvodljivi, dobro opisani (mjerljivi i kvantificirani) te logički povezani?  </p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">0 - 5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Da li su indikatori praćenja dobro definirani i logički povezani s očekivanim rezultatima i ishodima? </p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">0 - 5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Predviđa li projektni prijedlog logičku vidljivost i promotivne aktivnosti? </p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">0 - 5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-105 " rowspan="2 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Osnovni kriterij: održivost prijedloga</p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Maksimalni broj bodova</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-139 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Je li prijedlog projekta održiv u smislu nastavka funkcioniranja/finansiranja relevantnih aktivnosti i nakon završetka implementacije? (Dati jasne naznake održivosti, tj. jasno obrazloženje).</p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">0 - 5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-105 " rowspan="2 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Osnovni kriterij: </p>
							<p className="nalovi-u-tabelama ParaOverride-2 ">budžet prijedloga</p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Maksimalni broj bodova</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-139 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">1&#160;0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-222 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Je li predloženi budžet realan, jasan i povezan s očekivanim rezultatima? </p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">Budžet je jasan, realan, u skladu s ponudom, predračunom i tehničkom specifikacijom i u skladu s izjavom o minimalnom sufinansiranju;</li>
								<li className="tekst-u-tabelama--bullet ">Budžet nije u potpunosti jasan, realan, u skladu s ponudom i tehničkom specifikacijom i u skladu s izjavom o minimalnom sufinansiranju;</li>
								<li className="tekst-u-tabelama--bullet ">Budžet je nedefiniran, nejasan i nepovezan s očekivanim rezultatima.</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">3</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-147 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Sadrži li budžet narativna pojašnjenja stavki?</p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">Budžet sadrži detaljna narativna pojašnjenja stavki;</li>
								<li className="tekst-u-tabelama--bullet ">Budžet sadrži djelomična narativna pojašnjenja stavki;</li>
								<li className="tekst-u-tabelama--bullet ">Budžet ne sadrži narativna pojašnjenja stavki ili su ona nerazumljiva.</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">3</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-105 " rowspan="2 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Dodatni kriteriji </p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Maksimalni broj bodova</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-139 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">70</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">MSP izvozi 50% i više prometa:</p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">80% i više;</li>
								<li className="tekst-u-tabelama--bullet ">50% - 80%;</li>
								<li className="tekst-u-tabelama--bullet ">Ispod 50%.</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">3</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-147 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Predviđeno učešće MSP-a u ukupnoj vrijednosti projekta iznosi više od minimalnog zahtjeva od 50%:</p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">80% i više;</li>
								<li className="tekst-u-tabelama--bullet ">60% - 80%;</li>
								<li className="tekst-u-tabelama--bullet ">51% - 60%.</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">3</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">1</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-147 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">MSP ima 6 i više uposlenika:</p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">6 – 9 uposlenika;</li>
								<li className="tekst-u-tabelama--bullet ">10 - 30 uposlenika;</li>
								<li className="tekst-u-tabelama--bullet ">31 – 80 uposlenika;</li>
								<li className="tekst-u-tabelama--bullet ">81 – 249 uposlenika.</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama "><span lang="hu-HU ">&#160;</span></p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span lang="hu-HU ">1</span></p>
							<p className="tekst-u-tabelama ParaOverride-2 "><span lang="hu-HU ">4</span></p>
							<p className="tekst-u-tabelama ParaOverride-2 "><span lang="hu-HU ">5</span></p>
							<p className="tekst-u-tabelama ParaOverride-2 "><span lang="hu-HU ">3</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama "><span lang="hu-HU ">MSP je u većinskom vlasništvu osoba mlađih od 35 godina ili u vlasništvu žena.</span></p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama "><span lang="hu-HU ">&#160;</span></p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span lang="hu-HU ">DA - 5;&#160;NE - 0</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama "><span lang="hu-HU ">MSP posluje u jedinicama lokalne samouprave koje su izrazito nerazvijene ili nedovoljno razvijene:</span></p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">izrazito nerazvijene;</li>
								<li className="tekst-u-tabelama--bullet ">nedovoljno razvijene.</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama "><span lang="hu-HU ">&#160;</span></p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span lang="hu-HU ">&#160;</span></p>
							<p className="tekst-u-tabelama ParaOverride-2 "><span lang="hu-HU ">&#160;</span></p>
							<p className="tekst-u-tabelama ParaOverride-2 "><span lang="hu-HU ">5</span></p>
							<p className="tekst-u-tabelama ParaOverride-2 "><span lang="hu-HU ">3</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama "><span lang="hu-HU ">MSP do sada nije bio korisnik poticajnih grant sredstava FMRPO-a.</span></p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama "><span lang="fr-CA ">&#160;</span></p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span lang="fr-CA ">DA - 5;&#160;NE - 0</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-147 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama "><span lang="fr-CA ">MSP će kroz projekt unapređenja tehnološke opremljenosti doprinijeti jačanju sljedećih lanaca vrijednosti: proizvodnja mašina, dijelova i materijala za autoindustriju; proizvodnja namještaja od masivnog drveta; proizvodnja lične zaštitne odjeće; programiranje i IT aplikacije: proizvodnja finalnih prehrambenih proizvoda.</span></p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama "><span lang="fr-CA ">&#160;</span></p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span lang="fr-CA ">DA - 5;&#160;NE - 0</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-147 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama "><span lang="fr-CA ">MSP obavlja pretežnu djelatnost proizvodnje proizvoda sa visokim stepenom finalizacije (finalni funkcionalni priozvod):</span></p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">Primarna prerada;</li>
								<li className="tekst-u-tabelama--bullet ">Proizvodnja poluproizvoda (elemenata ili dijelova funkcionalnih proizvoda);</li>
								<li className="tekst-u-tabelama--bullet ">Proizvodnja finalnih funkcionalnih proizvoda.</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama "><span lang="fr-CA ">&#160;</span></p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span lang="fr-CA ">0</span></p>
							<p className="tekst-u-tabelama ParaOverride-2 "><span lang="fr-CA ">3</span></p>
							<p className="tekst-u-tabelama ParaOverride-2 "><span lang="fr-CA ">5</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-35 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Predviđeno unapređenje tehnološke opremljenosti će rezultati novim zapošljavanjem:</p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">5 i više novouposlenih;</li>
								<li className="tekst-u-tabelama--bullet ">4 novouposlena;</li>
								<li className="tekst-u-tabelama--bullet ">3 novouposlena;</li>
								<li className="tekst-u-tabelama--bullet ">2 novouposlena;</li>
								<li className="tekst-u-tabelama--bullet ">1 novouposleni.</li>
							</ul>
							<p className="tekst-u-tabelama ">Ovaj se kriterij zbog važnosti boduje duplim bodovima.</p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">10 (2x5)&#160;</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">8 (2x4)&#160;</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">6 (2x3)&#160;</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">4 (2x2)&#160;</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">2 (2x1)&#160;</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama "><span lang="fr-CA ">Predviđeno unapređenje tehnološke opremljenosti doprinosi integraciji inovacija u poslovanje MSP-a.</span></p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama "><span lang="fr-CA ">&#160;</span></p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span lang="fr-CA ">DA - 5;&#160;NE - 0</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama "><span lang="fr-CA ">Predviđena obuka za potrebnu radnu snagu rezultirat će zapošljavanjem više od 5 osoba.</span></p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama "><span lang="fr-CA ">&#160;</span></p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span lang="fr-CA ">DA - 5;&#160;NE - 0</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama "><span lang="fr-CA ">Predložena obuka za potrebnu radnu snagu predviđa zapošljavanje žena, mladih, povratnika i raseljenih osoba, osoba s posebnim potrebama te ostalih socijalno ugroženih kategorija stanovništva (preko 70% od ukupnog broja osoba koje će biti uposlene).</span></p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama "><span lang="fr-CA ">&#160;</span></p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span lang="fr-CA ">DA - 5;&#160;NE - 0</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama "><span lang="fr-CA ">Obuhvata li projekt aktivnosti iz više od jedne prioritetne oblasti grant šeme?</span></p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="tekst-u-tabelama "><span lang="fr-CA ">&#160;</span></p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span lang="en-US ">DA - 5;&#160;NE - 0</span></p>
						</td>
					</tr>
					<tr id="studije-slucaja-4" className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Ukupan broj bodova:</p>
						</td>
						<td className="No-Table-Style CellOverride-139 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">150</p>
						</td>
					</tr>
				</tbody>
			</table>
			</div>


			<h2 className="blue_overlay_heading mt-90">STUDIJA SLUČAJA 4:</h2>

			<h2 className="content_heading blue mt-40 mb-25"><b>ANALIZA PRIHVATLJIVOSTI, KRITERIJI ODABIRA I TABELE ZA EVALUACIJU KOJE SE KORISTE U STRUKTURNIM I INVESTICIJSKIM FONDOVIMA EU </b></h2>

			<p className="content_text pos-rel"><b>Općenito gledano, ustanovili smo da se u mnogim šemama koje smo razmatrali u sklopu
			pripreme za izradu ovog priručnika ponavljaju određeni elementi koje ćemo navesti u sljedećim
			primjerima, te, stoga, te elemente možemo posmatrati kao relativno tipične za šeme koje se
			finansiraju iz strukturnih fondova,</b>ali oni nisu identični. Kriteriji “prihvatljivosti” obično su vrlo
			formalni, mnogo formalniji od kriterija “odabira“ (ili “kvaliteta”), ali naša procjena je, općenito
			gledano, da je veći fokus stavljen na usklađenost nego na kvalitet. To djelomično odražava
			činjenicu da je Evropski socijalni fond posebno postavio “minimalne kriterije (koji su naročito teški
			u pogledu prihvatljivosti, čak i za tijela odgovorna za upravljanje) za tekuće programsko razdoblje
			(2014-2020)”, koji su naročito zastupljeni u primjerima iz Velike Britanije u nastavku teksta <Toolpit>(Za više detalja i izvorni dokument pogledajte dokument “Kriteriji odabira 2014-2020 - Evropski fond za regionalni razvoj i
			Evropski socijalni fond”, mart, 2015. Dostupno na: dokument 30 na <a href="http://bit.ly/izvornidokumenti" target="_blank">http://bit.ly/izvornidokumenti</a></Toolpit>.</p>


			<p className="content_text">U narednoj tabeli predstavljeni su primjeri kriterija primijenjenih u sklopu šema koje se finansiraju u EU,
			kod kojih se mogu uočiti određeni tipični elementi koji su važni iz perspektive analize prihvatljivosti
			i primjene kriterija odabira tokom postupka evaluacije. Među njima se posebno izdvajaju: </p>

			<p className="content_text"><span className="blue mr-15">I.</span><b>Kapacitet za realizaciju </b>– sposobnost realiziranja projekta u kratkom vremenskom periodu;</p>
			<p className="content_text"><span className="blue mr-15">II.</span><b>Horizontalna/multidisciplinarna pitanja (tri glavna kriterija: rodna ravnopravnost,
			nediskriminacija i održivost okoliša);</b></p>
			<p className="content_text"><span className="blue mr-15">III.</span><b>Održivost</b> projekta;</p>
			<p className="content_text"><span className="blue mr-15">IV.</span><b>Usklađenost s pravilima EU o objavljivanju.</b></p>

			<p className="content_text">Napomene prije čitanja tabele:</p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15"/><b>Irski primjer u tabeli u nastavku teksta snažno se fokusira na ključno ekonomsko obrazloženje
			“neefikasnog tržišta” (radi se, naravno, u šemi podrške MSP-u i smatramo da je takav fokus
			primjeren). Ključni opći kriteriji su:</b> kvalitet, komercijalna održivost projektnog prijedloga i
			njegova relevantnost za jedinicu lokalne samouprave, ukupni troškovi projekta i njegova isplativost;
			rizik za alokativnu neefikasnost; potencijal za povećanje konkurentnosti preduzeća i dodanu
			vrijednost razvoju lokalnih preduzeća; zastupljenost multidisciplinarnih tema. U ovom primjeru
			“multidisciplinarni kriteriji” javljaju se gotovo kao “posljednja misao”.</span></p>


			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15"/><b>Primjer s Malte pokazuje dobar balans između relativno formalnih kriterija </b> (djelomično
			potaknutim usklađivanjem) i širih ekonomskih kriterija.</span></p>
			
			<div className="custom_tables">			<table id="table089 " className="No-Table-Style TableOverride-2 custom_width_table">
				<colgroup>
					<col className="_idGenTableRowColumn-185 " />
					<col className="_idGenTableRowColumn-232 " />
					<col className="_idGenTableRowColumn-204 " />
					<col className="_idGenTableRowColumn-73 " />
					<col className="_idGenTableRowColumn-233 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">N</p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Fond</p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Država</p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Tema</p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Kriteriji odabira</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-207 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">1</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Socijalni fond</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 pos-rel">Italija – Ministarstvo rada i socijalnog razvoja<span className="CharOverride-11 "><Toolpit>Dostupno na: dokument 31 na <a href="http://bit.ly/izvornidokumenti" target="_blank">http://bit.ly/izvornidokumenti</a></Toolpit></span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Zapošljavanje; <br />Ekonomski rast</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ">Kvalitet projekta </p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">stepen inovacije;</li>
								<li className="tekst-u-tabelama--bullet ">sposobnost da bude primjer dobre prakse za druge projekte;</li>
								<li className="tekst-u-tabelama--bullet ">kapaciteti partnera.</li>
							</ul>
							<p className="tekst-u-tabelama ">Relevantnost i učinkovitost </p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">sadržaj;</li>
								<li className="tekst-u-tabelama--bullet ">alati;</li>
								<li className="tekst-u-tabelama--bullet ">vrste korisnika;</li>
								<li className="tekst-u-tabelama--bullet ">pouzdanost;</li>
								<li className="tekst-u-tabelama--bullet ">dodana vrijednost analize/obrazloženje koje podržava plan;</li>
								<li className="tekst-u-tabelama--bullet ">usklađenost s horizonatalnim prioritetima rodne ravnopravnosti i održivog razvoja.</li>
							</ul>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-236 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">2</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Fond za regionalni razvoj; Kohezijski fond</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 pos-rel">Malta – Ured premijera<span className="CharOverride-11 "><Toolpit>Dostupno na: dokument 32 na <a href="http://bit.ly/izvornidokumenti">http://bit.ly/izvornidokumenti</a></Toolpit></span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Konkurentnost; <br />Održivi razvoj</p>
						</td>
						<td className="No-Table-Style CellOverride-49 ">
							<table id="table090 " className="Basic-Table TableOverride-2 ">
								<colgroup>
									<col className="_idGenTableRowColumn-234 " />
									<col className="_idGenTableRowColumn-90 " />
									<col className="_idGenTableRowColumn-235 " />
									<col className="_idGenTableRowColumn-52 " />
								</colgroup>
								<tbody>
									<tr className="Basic-Table _idGenTableRowColumn-4 ">
										<td className="Basic-Table CellOverride-145 ">
											<p className="nalovi-u-tabelama ParaOverride-2 pos-rel">Kriteriji prihvatljivosti<span className="CharOverride-11"><Toolpit>Dostupno na: dokument 33 na <a href="http://bit.ly/izvornidokumenti">http://bit.ly/izvornidokumenti</a></Toolpit></span></p>
										</td>
										<td className="Basic-Table CellOverride-145 ">
											<p className="nalovi-u-tabelama ParaOverride-2 ">Procjena</p>
										</td>
										<td className="Basic-Table CellOverride-145 ">
											<p className="nalovi-u-tabelama ParaOverride-2 ">Kriteriji odabira</p>
										</td>
										<td className="Basic-Table CellOverride-145 ">
											<p className="nalovi-u-tabelama ParaOverride-2 ">Bodovi</p>
										</td>
									</tr>
									<tr className="Basic-Table _idGenTableRowColumn-7 ">
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">Potpuna prijava</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">DA/NE</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">Doprinos koji je veći od minimalnog zahtijevanog učešća</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">10</p>
										</td>
									</tr>
									<tr className="Basic-Table _idGenTableRowColumn-10 ">
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">Zadatak projekta je u mandatu korisnika</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">DA/NE</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">Spremnost</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">15</p>
										</td>
									</tr>
									<tr className="Basic-Table _idGenTableRowColumn-10 ">
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">Voditelj projekta iz organizacije-korisnika</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">DA/NE</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">Održivost projekta</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">10</p>
										</td>
									</tr>
									<tr className="Basic-Table _idGenTableRowColumn-10 ">
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">Projekt realiziran u okviru teritorija koje zadovoljavaju uvjete</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">DA/NE</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">Kapacitet organizacije</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">10</p>
										</td>
									</tr>
									<tr className="Basic-Table _idGenTableRowColumn-10 ">
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">Dokaz o sufinansiranju, gdje je to relevantno</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">DA/NE</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">Doprinos razvojnim potrebama</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">10</p>
										</td>
									</tr>
									<tr className="Basic-Table _idGenTableRowColumn-7 ">
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">Doprinos u smislu prioriteta za ulaganja</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">DA/NE</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">Potencijal projekta da doprinese ostvarenju očekivanih rezultata</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">15</p>
										</td>
									</tr>
									<tr className="Basic-Table _idGenTableRowColumn-14 ">
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">Prijedlog je u skladu s relevantnim specifičnim ciljem i očekivanim rezultatima prioriteta za ulaganja</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">DA/NE</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">Horizontalni prioriteti; Održivost okoliša i jednake prilike</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">10</p>
										</td>
									</tr>
									<tr className="Basic-Table _idGenTableRowColumn-14 ">
										<td className="Basic-Table CellOverride-147 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">Doprinos jednom indikatoru</p>
										</td>
										<td className="Basic-Table CellOverride-147 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">DA/NE</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">Drugi horizontalni prioriteti (jednakost, nediskriminacija) i ublažavanje i adaptacija klimatskim promjenama</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">10</p>
										</td>
									</tr>
									<tr className="Basic-Table _idGenTableRowColumn-5 ">
										<td className="Basic-Table CellOverride-148 _idGenCellOverride-5 " colspan="2 " />
										<td className="Basic-Table CellOverride-149 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">Kvalitet prijave</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">10</p>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">3</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Socijalni fond</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Engleska – Kriteriji koji se primjenjuju na sve finansirane programe (od maja 2017.)</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Multitematski </p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-78 ">Vidi listu na dnu studije slučaja </span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-35 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">4</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Fond za regionalni razvoj</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 pos-rel">Irska – Južna <br />regionalna skupštna<span className="CharOverride-11 "><Toolpit>Dostupno na: dokument 34 na <a href="http://bit.ly/izvornidokumenti" target="_blank">http://bit.ly/izvornidokumenti</a></Toolpit></span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Razvoj MSP-ova</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-106 ">Ključni kriteriji odabira<br /></span></p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">kvalitet, komercijalna održivost projekta i njegova relevantnost za jedinicu lokalne samouprave;</li>
								<li className="tekst-u-tabelama--bullet ">ukupni troškovi projekta i njegova isplativost;</li>
								<li className="tekst-u-tabelama--bullet ">doprinos prijedloga razvoju preduzeća u pogledu internih kapaciteta;</li>
								<li className="tekst-u-tabelama--bullet ">potencijal za alokativnu neefikasnost ili premiještanje;</li>
								<li className="tekst-u-tabelama--bullet ">potencijal za povećanje konkurentnosti preduzeća i dodanu vrijednost razvoju lokalnih preduzeća i</li>
								<li className="tekst-u-tabelama--bullet ">zastupljenost multidisciplinarnih tema.</li>
							</ul>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-241 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">5</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Socijalni fond </p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 pos-rel">Škotska – Vijeće sjevernog Lanarkshirea (NLC)<span className="CharOverride-11 "><Toolpit>Dostupno na: dokument 35 na <a href="http://bit.ly/izvornidokumenti" target="_blank">http://bit.ly/izvornidokumenti</a></Toolpit></span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Zapošljivost - (ugrožene grupe)</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<table id="table091 " className="Basic-Table TableOverride-1 ">
								<colgroup>
									<col className="_idGenTableRowColumn-237 " />
									<col className="_idGenTableRowColumn-238 " />
									<col className="_idGenTableRowColumn-239 " />
								</colgroup>
								<tbody>
									<tr className="Basic-Table _idGenTableRowColumn-8 ">
										<td className="Basic-Table CellOverride-145 ">
											<p className="nalovi-u-tabelama ParaOverride-2 ">Ključni kvalitativni kriteriji </p>
										</td>
										<td className="Basic-Table CellOverride-145 ">
											<p className="nalovi-u-tabelama ParaOverride-2 ">Detaljni kvalitativni kriteriji </p>
										</td>
										<td className="Basic-Table CellOverride-145 ">
											<p className="nalovi-u-tabelama ParaOverride-2 ">Ponderiranje (%)</p>
										</td>
									</tr>
									<tr className="Basic-Table _idGenTableRowColumn-240 ">
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">Sposobnost i posvećenost iznalaženju rješenja</p>
										</td>
										<td className="Basic-Table CellOverride-150 ">
											<p className="tekst-u-tabelama ">- Prethodno iskustvo i rezultati u radu sa datom grupom korisnika</p>
											<p className="tekst-u-tabelama ">- Ekspertiza / posebna stručnost u rješavanju prepreka u zapošljavanju i provođenju obuka</p>
											<p className="tekst-u-tabelama ">- Veza s portfolijom zapošljivosti „Nacionalna plaća dostatna za život“</p>
											<p className="tekst-u-tabelama ">- Angažiranje /proces zapošljavanja</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-106 ">40%</span></p>
										</td>
									</tr>
									<tr className="Basic-Table _idGenTableRowColumn-240 ">
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 ">Funkcionalnost </p>
										</td>
										<td className="Basic-Table CellOverride-150 ">
											<p className="tekst-u-tabelama ">- Stepen podudarnosti sa zahtjevima NLC-a i predloženim rješenjem</p>
											<p className="tekst-u-tabelama ">- Mehanizmi izvještavanja</p>
											<p className="tekst-u-tabelama ">- Izvodljivost predložene intervencije – vremenski raspored</p>
											<p className="tekst-u-tabelama ">- Upravljanje projektom /sistemi za praćenje</p>
											<p className="tekst-u-tabelama ">- Budžetska alokacija</p>
										</td>
										<td className="Basic-Table CellOverride-146 ">
											<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-106 ">60%</span></p>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-242 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">6</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Fond za regionalni razvoj</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Škotska, Škotska prirodna baština<span className="CharOverride-11 pos-rel"><Toolpit>Dostupno na: dokument 36 na <a href="http://bit.ly/izvornidokumenti" target="_blank">http://bit.ly/izvornidokumenti</a></Toolpit></span></p>
							<p className="tekst-u-tabelama ParaOverride-2 ">&#9;</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Zelena infrastruktura – Angažman u zajednici </p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-9 ">1) Kapacitet za realizaciju </span></p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">Kompetencije aplikanta – upravljanje projektom</li>
								<li className="tekst-u-tabelama--bullet ">Troškovi projekta</li>
								<li className="tekst-u-tabelama--bullet ">Učešće u finansiranju</li>
								<li className="tekst-u-tabelama--bullet ">Prethodni rezultati</li>
							</ul>
							<p className="tekst-u-tabelama "><span className="CharOverride-9 ">2) Utjecaj</span></p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">Dokazi o potrebi</li>
								<li className="tekst-u-tabelama--bullet ">Potreba za grantom / nedostatak</li>
								<li className="tekst-u-tabelama--bullet ">Dodana vrijednost, sinergija i komplementarnost s drugim programima i intervencijama</li>
								<li className="tekst-u-tabelama--bullet ">Stepen inovacije</li>
								<li className="tekst-u-tabelama--bullet ">Javnost, komunikacija, vidljivost i anagažman interesnih grupa</li>
								<li className="tekst-u-tabelama--bullet ">Monitoring i okvir za evaluaciju</li>
							</ul>
							<p className="tekst-u-tabelama "><span className="CharOverride-9 ">3)</span> <span className="CharOverride-9 ">Ishodi</span></p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">Priroda, biodiverzitet i ekosistemi</li>
								<li className="tekst-u-tabelama--bullet ">Regulacija okoliša, poplave i klimatske promjene</li>
								<li className="tekst-u-tabelama--bullet ">Angažman u zajednici i učešće</li>
								<li className="tekst-u-tabelama--bullet ">Povećanje stepena atraktivnosti i konkurentnosti mjesta </li>
								<li className="tekst-u-tabelama--bullet ">Unapređenje zdravlja i dobrostanja</li>
								<li className="tekst-u-tabelama--bullet ">Ukupni utjecaj</li>
							</ul>
							<p className="tekst-u-tabelama "><span className="CharOverride-9 ">4) Horizontalne teme</span></p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">Održivost okoliša </li>
								<li className="tekst-u-tabelama--bullet ">Socijalna inkluzija</li>
								<li className="tekst-u-tabelama--bullet ">Jednake prilike</li>
							</ul>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-243 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">7</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Fond za regionalni razvoj</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Gibraltar<span className="CharOverride-11 pos-rel"><Toolpit>Dostupno na: dokument 37 na <a href="http://bit.ly/izvornidokumenti" target="_blank">http://bit.ly/izvornidokumenti</a></Toolpit></span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Zapošljavanje - Rast</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-106 ">Osnovni kriteriji odabira:</span></p>
							<ul>
								<li className="tekst-u-tabelama ParaOverride-76 "><span className="CharOverride-9 ">Strateška primjerenost;</span></li>
							</ul>
							<ol>
								<li className="tekst-u-tabelama ParaOverride-61 ">Predloženi projekt doprinosi rješavanju potreba/iskorištavanju prilika utvrđenih u programu rada Gibraltara (Evropski fond za regionalni razvoj - ERDF). </li>
								<li className="tekst-u-tabelama ParaOverride-61 ">Predloženi projekt predstavlja odgovarajuće sredstvo za postizanje specifičnih ciljeva, izlaznih rezultata i ishoda tematskog cilja, koji je utvrđen programom rada. </li>
								<li className="tekst-u-tabelama ParaOverride-61 ">Predloženi projekt je usklađen s lokalnim prioritetima za rast. </li>
								<li className="tekst-u-tabelama ParaOverride-61 ">Predloženi projekt mora donijeti dodanu vrijednost, a ne duplirati postojeće nacionalne odredbe i ne smije biti u suprotnosti s nacionalnom politikom. </li>
								<li className="tekst-u-tabelama ParaOverride-61 ">Predloženi projekt doprinosi kreiranju održivog zaposlenja.</li>
							</ol>
							<ul>
								<li className="tekst-u-tabelama ParaOverride-76 "><span className="CharOverride-9 ">Vrijednost za novac; </span></li>
							</ul>
							<ol>
								<li className="tekst-u-tabelama ParaOverride-61 ">Projektni prijedlog mora pokazati da će ulaganje rezultirati relevantnim aktivnostima, izlaznim rezultatima i ishodima koji se, u suprotnom, ne bi desili. </li>
								<li className="tekst-u-tabelama ParaOverride-61 ">Projekt mora odražavati vrijednost za novac. Prilikom procjene isplativosti, tijelo odgovorno za upravljanje vodit će računa o efikasnosti, efektivnosti i ekonomičnosti.</li>
							</ol>
							<ul>
								<li className="tekst-u-tabelama ParaOverride-76 "><span className="CharOverride-9 ">Upravljanje i kontrola; </span></li>
							</ul>
							<p className="tekst-u-tabelama ParaOverride-57 ">Aplikant mora pokazati:</p>
							<ol>
								<li className="tekst-u-tabelama ParaOverride-61 "> Odgovarajuću ekspertizu, kapacitet i sposobnost da uspješno realizira projekt. </li>
								<li className="tekst-u-tabelama ParaOverride-61 ">Da ima neophodne upravljačke kapacitete, sisteme i postupke da ispuni zahtjeve Fonda. </li>
								<li className="tekst-u-tabelama ParaOverride-61 ">Da ima kapacitet da ispuni finansijske zahtjeve i obaveze koje proističu iz primljene podrške Evropskog fonda za regionalni razvoj. </li>
							</ol>
							<ul>
								<li className="tekst-u-tabelama ParaOverride-76 "><span className="CharOverride-9 ">Kapacitet za realizaciju; </span></li>
							</ul>
							<ol>
								<li className="tekst-u-tabelama ParaOverride-61 ">Projekt se može realizirati u okviru zahtjeva Operativnog programa, vodeći računa o rizicima, ograničenjima i drugim faktorima koji zavise od njega.</li>
								<li className="tekst-u-tabelama ParaOverride-61 ">Projekt ima odgovarajuće aranžmane kojima može osigurati potrebni nivo učešća u sufinansiranju.</li>
								<li className="tekst-u-tabelama ParaOverride-61 ">Dokazi ukazuju da je ova vrsta projekta učinkovita ili, kad se radi o novim ili inovativnim projektima, da su valjano razmotreni rizici i da su predviđene mjere njihovog ublažavanja.</li>
							</ol>
							<ul>
								<li className="tekst-u-tabelama ParaOverride-76 "><span className="CharOverride-9 ">Usklađenost sa zahtjevima nabavke; </span></li>
							</ul>
							<ol>
								<li className="tekst-u-tabelama ParaOverride-61 ">Aplikant mora biti potpuno upoznat sa zahtjevima koji se odnose na nabavke, državnu pomoć, pravila EU o javnosti i pravilima prihvatljivosti koji su specifični za Fond. </li>
							</ol>
							<ul>
								<li className="tekst-u-tabelama ParaOverride-76 "><span className="CharOverride-9 ">Državna pomoć, zahtjevi u pogledu javnosti i pravila o prihvatljivosti;</span></li>
							</ul>
							<ol>
								<li className="tekst-u-tabelama ParaOverride-61 ">Svaka nabavka koja je već provedena u sklopu projekta u skladu je s evropskim zahtjevima o nabavkama iz strukturnih i investicijskih fondova. </li>
								<li className="tekst-u-tabelama ParaOverride-61 ">Svi okviri koje namjeravaju koristiti moraju biti identificirani i usklađeni.</li>
								<li className="tekst-u-tabelama ParaOverride-61 ">Da aplikant ima pravo da primi grant u traženom iznosu u okviru propisa o državnoj pomoći, ako je primjenjivo.</li>
								<li className="tekst-u-tabelama ParaOverride-61 ">Dopuštena je dodjela granta trećim stranama, uz obavezu upravljanja grantom u skladu sa propisima o državnoj pomoći.</li>
								<li className="tekst-u-tabelama ParaOverride-61 ">Promotivne aktivnosti, provedene kao dio projekta, u skladu su sa zahtjevima o javnosti koji važe za evropske strukturne i investicijske fondove. </li>
							</ol>
							<ul>
								<li className="tekst-u-tabelama ParaOverride-76 "><span className="CharOverride-9 ">Multidisciplinarne teme; </span></li>
							</ul>
							<ol>
								<li className="tekst-u-tabelama ParaOverride-61 ">Projekt uzima u obzir i doprinosi multidisciplinarnim temama (horizontalnim principima) i ispunjava zakonske obaveze svake od njih: i. ravnopravnost spolova i nediskriminacija i ii. održivi razvoj.</li>
							</ol>
							<p className="tekst-u-tabelama "><span className="CharOverride-106 ">Projekti će se birati na temelju sljedećih investicijskih principa: </span></p>
							<ul>
								<li className="tekst-u-tabelama ParaOverride-76 ">Fokus će biti na projektima koji pokazuju da će za proizvodnju energije biti korišteni obnovljivi izvori. </li>
								<li className="tekst-u-tabelama ParaOverride-76 ">Diversifikacija i uvođenje novih proizvoda/usluga u privredu. </li>
								<li className="tekst-u-tabelama ParaOverride-76 ">Realistične strategije za proširenje poslovanja.</li>
								<li className="tekst-u-tabelama ParaOverride-76 ">Realistične i jasne izlazne strategije za postizanje efekta koji će trajati. </li>
								<li className="tekst-u-tabelama ParaOverride-76 ">Javna pomoć neće premašiti iznos “De-Minimis” podrške koja je bila primjenjiva u vrijeme dodjele granta. </li>
								<li className="tekst-u-tabelama ParaOverride-76 ">Posebna pažnja će se posvetiti osjetljivoj prirodi mjera pomoći usmjerenih na promoviranje izvoza i internacionalizaciju, naročito zato što su izvozne subvencije “zabranjene subvencije” prema pravilima STO-a i kao takve su isključene iz opsega propisa o de minimis pomoći. </li>
							</ul>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-244 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">8</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Fond za regionalni razvoj</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Engleska<span className="CharOverride-11 pos-rel "><Toolpit>Dostupno na: dokument 38 na <a href="http://bit.ly/izvornidokumenti" target="_blank">http://bit.ly/izvornidokumenti</a></Toolpit></span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Zelena energija / Zaštita okoliša </p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-106 ">Osnovni kriteriji:</span></p>
							<ul>
								<li className="tekst-u-tabelama ParaOverride-76 ">Usklađenost s lokalnom strategijom</li>
								<li className="tekst-u-tabelama ParaOverride-76 ">Usklađenost s nacionalnom strategijom</li>
								<li className="tekst-u-tabelama ParaOverride-76 ">Vrijednost za novac</li>
								<li className="tekst-u-tabelama ParaOverride-76 ">Upravljanje i kontrola</li>
								<li className="tekst-u-tabelama ParaOverride-76 ">Kapacitet za realizaciju</li>
								<li className="tekst-u-tabelama ParaOverride-76 ">Usklađenost<ol><li className="tekst-u-tabelama ParaOverride-77 ">Nabavke</li><li className="tekst-u-tabelama ParaOverride-77 ">Državna pomoć</li><li className="tekst-u-tabelama ParaOverride-77 ">Zahtjevi u pogledu javnosti </li></ol></li>
								<li className="tekst-u-tabelama ParaOverride-76 ">Multidisciplinarne teme<ol><li className="tekst-u-tabelama ParaOverride-77 ">Održivost okoliša </li><li className="tekst-u-tabelama ParaOverride-77 ">Jednake prilike</li></ol></li>
								<li className="tekst-u-tabelama ParaOverride-76 ">Vrijednost za novac</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table></div>
		
			<p className="content_text blue mt-70"><b>Primjer iz Engleske – Kriteriji koji se primjenjuju na sve finansirane programe
			(od maja 2017.) - (Evropski socijalni fond) – Vidi primjer br. 3 u gornjoj tabeli.</b></p>

			<p className="content_text"><b><u>Minimalni broj bodova:</u></b> Da bi prijave do kraja postupka razmatranja potpune prijave bile uspješne,
			potrebno je osvojiti najmanje 3 boda za svaki kriterij. Svi se kriteriji smatraju ključnim i ukupno je
			potrebno osvojiti najmanje 57 bodova (42.8% od ukupnog broja bodova). Ukoliko se ovaj broj bodova
			ne osvoji, prijava će biti odbijena. Ako je prijava uspješna, pravi se ugovor o finansiranju, koji će biti
			uvjetan ukoliko ukupni broj bodova po kriteriju ostane 3.</p>

			<p className="content_text blue"><b>Okvir za bodovanje koncepta projektnog prijedloga za finansiranje iz Evropskog
			socijalnog fonda za period 2014-2020.</b></p>

			<div className="custom_tables"><table id="table092" className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-189 " />
					<col className="_idGenTableRowColumn-245 " />
					<col className="_idGenTableRowColumn-190 " />
					<col className="_idGenTableRowColumn-167 " />
					<col className="_idGenTableRowColumn-164 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-151 _idGenCellOverride-6 " colspan="4 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">1. Usklađenost sa strategijom</p>
						</td>
						<td className="No-Table-Style CellOverride-152 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-153 " rowspan="3 " colspan="2 ">
							<p className="tekst-u-tabelama ">1.1 Doprinosi li predložena intervencija potrebama/prilikama identificiranim u konkursu na koji se javlja?</p>
						</td>
						<td className="No-Table-Style CellOverride-154 " colspan="2 ">
							<p className="tekst-u-tabelama ">U potpunosti (5)       </p>
						</td>
						<td className="No-Table-Style CellOverride-155 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">Djelomično (3)          </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/171.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-5 ">
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">Uopće ne (0)             </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-7 ">
						<td className="No-Table-Style CellOverride-158 " rowspan="3 " colspan="2 ">
							<p className="tekst-u-tabelama ">1.2 Predstavlja li predložena intervencija odgovarajuće sredstvo kojim se mogu ostvariti relevantni, specifični ciljevi, izlazni rezultati i ishodi određenog prioriteta zadatog u Operativnom Programu?</p>
						</td>
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">U potpunosti (5)       </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-5 ">
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">Djelomično (3) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">Uopće ne (0) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-158 " rowspan="3 " colspan="2 ">
							<p className="tekst-u-tabelama ">1.3 Povezuje li projektni prijedlog aktivnosti, troškove, izlazne rezultate/ishode s realizacijom prioriteta iz Operativnog programa?</p>
						</td>
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">U potpunosti (5) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">Djelomično (3) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-7 ">
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">Uopće ne (0) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-158 " rowspan="3 " colspan="2 ">
							<p className="tekst-u-tabelama ">1.4 (Ako je primjereno), pokazuje li prijava da će projekt ispuniti zahtjeve zadate u operativnom programu za ovu vrstu aktivnosti?</p>
						</td>
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">U potpunosti (5) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">Djelomično (3) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">Uopće ne (0) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-158 " rowspan="3 " colspan="2 ">
							<p className="tekst-u-tabelama ">1.5 Je li predložena intervencija u skladu s lokalnim potrebama za rast određenim u lokalnim ESIF strategijama?</p>
						</td>
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">U potpunosti (5) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">Djelomično (3) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">Uopće ne (0) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-158 " rowspan="3 " colspan="2 ">
							<p className="tekst-u-tabelama ">1.6 Duplira li predložena intervencija postojeću nacionalnu politiku i je li u suprotnosti s njom?</p>
						</td>
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">U potpunosti (5) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">Djelomično (3) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-159 " colspan="2 ">
							<p className="tekst-u-tabelama ">Uopće ne (0)</p>
						</td>
						<td className="No-Table-Style CellOverride-160 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-161 _idGenCellOverride-6 " colspan="4 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">2. Kapacitet za realizaciju</p>
						</td>
						<td className="No-Table-Style CellOverride-162 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-153 " rowspan="3 " colspan="2 ">
							<p className="tekst-u-tabelama ">2.1 Je li intervencija izvodljiva u okvirima zahtjeva definiranim operativnim programom?</p>
						</td>
						<td className="No-Table-Style CellOverride-154 " colspan="2 ">
							<p className="tekst-u-tabelama ">U potpunosti (5) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">Djelomično (3) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">Uopće ne (0) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-158 " rowspan="3 " colspan="2 ">
							<p className="tekst-u-tabelama ">2.2 Je li osigurano učešće za sufinansiranje?</p>
						</td>
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">U potpunosti (5) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">Djelomično (3) </p>
						</td>
						<td className="No-Table-Style CellOverride-160 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-5 ">
						<td className="No-Table-Style CellOverride-156 " colspan="2 ">
							<p className="tekst-u-tabelama ">Uopće ne (0) </p>
						</td>
						<td className="No-Table-Style CellOverride-155 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-11 ">
						<td className="No-Table-Style CellOverride-163 " colspan="4 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">3. Vrijednost za novac</p>
						</td>
						<td className="No-Table-Style CellOverride-164 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-5 ">
						<td className="No-Table-Style CellOverride-165 " rowspan="3 " colspan="2 ">
							<p className="tekst-u-tabelama ">3.1 Osim toga, potvrđuje li prijava da ulaganje ESIF-a dodaje vrijednost u smislu aktivnosti i utjecaja?</p>
						</td>
						<td className="No-Table-Style CellOverride-166 " colspan="2 ">
							<p className="tekst-u-tabelama ">U potpunosti (5) </p>
						</td>
						<td className="No-Table-Style CellOverride-167 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-5 ">
						<td className="No-Table-Style CellOverride-166 " colspan="2 ">
							<p className="tekst-u-tabelama ">Djelomično (3) </p>
						</td>
						<td className="No-Table-Style CellOverride-167 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-5 ">
						<td className="No-Table-Style CellOverride-166 " colspan="2 ">
							<p className="tekst-u-tabelama ">Uopće ne (0) </p>
						</td>
						<td className="No-Table-Style CellOverride-167 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-11 ">
						<td className="No-Table-Style CellOverride-163 " colspan="4 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">4. Upravljanje i kontrola</p>
						</td>
						<td className="No-Table-Style CellOverride-164 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-246 ">
						<td className="No-Table-Style CellOverride-165 " colspan="2 ">
							<p className="tekst-u-tabelama ">4.1 Je li aplikant potvrdio da je pročitao i razumio dokument: zahtjevi u pogledu upravljanja i kontrole za projekte koji se finansiraju iz ESIF-a ?</p>
						</td>
						<td className="No-Table-Style CellOverride-165 ">
							<p className="tekst-u-tabelama ">DA</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (5)</p>
						</td>
						<td className="No-Table-Style CellOverride-165 " colspan="2 ">
							<p className="tekst-u-tabelama ParaOverride-4 ">NE</p>
							<p className="tekst-u-tabelama ParaOverride-4 "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (0)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-246 ">
						<td className="No-Table-Style CellOverride-165 " colspan="2 ">
							<p className="tekst-u-tabelama ">4.2 Organizacija aplikant već ima sisteme koji su u skladu s tim zahtjevima; ili ima sposobnost da uspostavi takve sisteme kako bi ispunila ove zahtjeve?</p>
						</td>
						<td className="No-Table-Style CellOverride-165 ">
							<p className="tekst-u-tabelama ">DA</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (5)</p>
						</td>
						<td className="No-Table-Style CellOverride-165 " colspan="2 ">
							<p className="tekst-u-tabelama ParaOverride-4 ">NE</p>
							<p className="tekst-u-tabelama ParaOverride-4 "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (0)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-246 ">
						<td className="No-Table-Style CellOverride-165 " colspan="2 ">
							<p className="tekst-u-tabelama ">4.3 Ukazuju li dosadašnji rezultati aplikanta da aplikant nema ili da neće imati potrebne upravljačke kapacitete, sisteme i procese kako bi ispunio zahtjeve Fonda?</p>
						</td>
						<td className="No-Table-Style CellOverride-165 ">
							<p className="tekst-u-tabelama ">DA</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (0)</p>
						</td>
						<td className="No-Table-Style CellOverride-165 " colspan="2 ">
							<p className="tekst-u-tabelama ParaOverride-4 ">NE</p>
							<p className="tekst-u-tabelama ParaOverride-4 "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (0)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-246 ">
						<td className="No-Table-Style CellOverride-165 ">
							<p className="tekst-u-tabelama ">4.4 Ima li prijava odgovarajući pristup za raspodjelu troškova između partnerskih (LEP) područja?</p>
						</td>
						<td className="No-Table-Style CellOverride-165 ">
							<p className="tekst-u-tabelama ">Nije primjenjivo</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (5)</p>
						</td>
						<td className="No-Table-Style CellOverride-165 ">
							<p className="tekst-u-tabelama ">DA</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (5)</p>
						</td>
						<td className="No-Table-Style CellOverride-165 " colspan="2 ">
							<p className="tekst-u-tabelama ParaOverride-4 ">NE</p>
							<p className="tekst-u-tabelama ParaOverride-4 "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (0)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-246 ">
						<td className="No-Table-Style CellOverride-165 ">
							<p className="tekst-u-tabelama ">4.5 Ima li prijava odgovarajući pristup za raspodjelu troškova između COR-a?</p>
						</td>
						<td className="No-Table-Style CellOverride-165 ">
							<p className="tekst-u-tabelama ">Nije primjenjivo</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (5)</p>
						</td>
						<td className="No-Table-Style CellOverride-165 ">
							<p className="tekst-u-tabelama ">DA</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (5)</p>
						</td>
						<td className="No-Table-Style CellOverride-165 " colspan="2 ">
							<p className="tekst-u-tabelama ParaOverride-4 ">NE</p>
							<p className="tekst-u-tabelama ParaOverride-4 "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (0)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-246 ">
						<td className="No-Table-Style CellOverride-165 ">
							<p className="tekst-u-tabelama ">4.6 Ima li prijava odgovarajući pristup za raspodjelu troškova između prioriteta za ulaganje?</p>
						</td>
						<td className="No-Table-Style CellOverride-165 ">
							<p className="tekst-u-tabelama ">Nije primjenjivo</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (5)</p>
						</td>
						<td className="No-Table-Style CellOverride-165 ">
							<p className="tekst-u-tabelama ">DA</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-41 " src="./image/210.png " alt=" " /> (5)</p>
						</td>
						<td className="No-Table-Style CellOverride-165 " colspan="2 ">
							<p className="tekst-u-tabelama ParaOverride-4 ">NE</p>
							<p className="tekst-u-tabelama ParaOverride-4 "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (0)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-246 ">
						<td className="No-Table-Style CellOverride-165 " colspan="2 ">
							<p className="tekst-u-tabelama ">4.7 Ima li projekt namjeru praviti bilo kakve izdatke prije potpisivanja sporazuma o finansiranju?</p>
						</td>
						<td className="No-Table-Style CellOverride-165 ">
							<p className="tekst-u-tabelama ">DA</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (3)</p>
						</td>
						<td className="No-Table-Style CellOverride-165 " colspan="2 ">
							<p className="tekst-u-tabelama ParaOverride-4 ">NE</p>
							<p className="tekst-u-tabelama ParaOverride-4 "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (0)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-246 ">
						<td className="No-Table-Style CellOverride-168 " colspan="2 ">
							<p className="tekst-u-tabelama ">4.8 Due Diligence: Je li potrebno dubinsko ispitivanje?</p>
						</td>
						<td className="No-Table-Style CellOverride-168 ">
							<p className="tekst-u-tabelama ">DA</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (3)</p>
						</td>
						<td className="No-Table-Style CellOverride-168 " colspan="2 ">
							<p className="tekst-u-tabelama ParaOverride-4 ">NE</p>
							<p className="tekst-u-tabelama ParaOverride-4 "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (0)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-11 ">
						<td className="No-Table-Style CellOverride-169 _idGenCellOverride-6 " colspan="4 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">5. Usklađenost</p>
						</td>
						<td className="No-Table-Style CellOverride-170 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-5 ">
						<td className="No-Table-Style CellOverride-171 _idGenCellOverride-6 " colspan="4 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-14 ">5.1 Nabavke</span></p>
						</td>
						<td className="No-Table-Style CellOverride-172 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-165 " colspan="2 ">
							<p className="tekst-u-tabelama ">5.1.1 Obuhvata li projekt bilo kakve nabavke?</p>
						</td>
						<td className="No-Table-Style CellOverride-165 ">
							<p className="tekst-u-tabelama ">DA <img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (3)</p>
						</td>
						<td className="No-Table-Style CellOverride-165 " colspan="2 ">
							<p className="tekst-u-tabelama ParaOverride-4 ">NE <img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (5)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-5 ">
						<td className="No-Table-Style CellOverride-173 " rowspan="3 ">
							<p className="tekst-u-tabelama ">5.1.2 Definira li prijava pristup nabavkama koji je u skladu sa odredbama Zakona o nabavkama i smjernicama o ESIF projektima?</p>
						</td>
						<td className="No-Table-Style CellOverride-173 " rowspan="3 ">
							<p className="tekst-u-tabelama ">Nije primjenjivo</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (5)</p>
						</td>
						<td className="No-Table-Style CellOverride-174 " colspan="2 ">
							<p className="tekst-u-tabelama ">U potpunosti (5)</p>
						</td>
						<td className="No-Table-Style CellOverride-175 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-5 ">
						<td className="No-Table-Style CellOverride-174 " colspan="2 ">
							<p className="tekst-u-tabelama ">Djelomično (3) </p>
						</td>
						<td className="No-Table-Style CellOverride-175 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-5 ">
						<td className="No-Table-Style CellOverride-174 " colspan="2 ">
							<p className="tekst-u-tabelama ">Uopće ne (0) </p>
						</td>
						<td className="No-Table-Style CellOverride-175 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-5 ">
						<td className="No-Table-Style CellOverride-173 " rowspan="3 ">
							<p className="tekst-u-tabelama ">5.1.3 Ukazuju li dosadašnji rezultati aplikanta da aplikant nema ili da neće imati potrebne upravljačke kapacitete, sisteme i procese kako bi ispunio zahtjeve ESIF-a u pogledu nabavki?</p>
						</td>
						<td className="No-Table-Style CellOverride-173 " rowspan="3 ">
							<p className="tekst-u-tabelama ">Nije primjenjivo</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (5)</p>
						</td>
						<td className="No-Table-Style CellOverride-174 " colspan="2 ">
							<p className="tekst-u-tabelama ">U potpunosti (0) </p>
						</td>
						<td className="No-Table-Style CellOverride-175 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-174 " colspan="2 ">
							<p className="tekst-u-tabelama ">Djelomično (3) </p>
						</td>
						<td className="No-Table-Style CellOverride-175 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-5 ">
						<td className="No-Table-Style CellOverride-174 " colspan="2 ">
							<p className="tekst-u-tabelama ">Uopće ne (5) </p>
						</td>
						<td className="No-Table-Style CellOverride-175 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-5 ">
						<td className="No-Table-Style CellOverride-173 _idGenCellOverride-6 " colspan="4 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-14 ">5.2 Državna pomoć</span></p>
						</td>
						<td className="No-Table-Style CellOverride-175 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-173 " colspan="2 ">
							<p className="tekst-u-tabelama ">5.2.1 Hoće li se projektom davati državna pomoć trećim stranama?</p>
						</td>
						<td className="No-Table-Style CellOverride-165 ">
							<p className="tekst-u-tabelama ">DA <img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (3)</p>
						</td>
						<td className="No-Table-Style CellOverride-165 " colspan="2 ">
							<p className="tekst-u-tabelama ParaOverride-4 ">NE <img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (5)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-173 " rowspan="3 ">
							<p className="tekst-u-tabelama ">5.2.2 Imaju li aplikant i njegovi partneri pravo na traženi grant prema propisima o državnoj pomoći?</p>
						</td>
						<td className="No-Table-Style CellOverride-173 " rowspan="3 ">
							<p className="tekst-u-tabelama ">Nije primjenjivo</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (5)</p>
						</td>
						<td className="No-Table-Style CellOverride-174 " colspan="2 ">
							<p className="tekst-u-tabelama ">U potpunosti (5) </p>
						</td>
						<td className="No-Table-Style CellOverride-175 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-5 ">
						<td className="No-Table-Style CellOverride-174 " colspan="2 ">
							<p className="tekst-u-tabelama ">Djelomično (3) </p>
						</td>
						<td className="No-Table-Style CellOverride-175 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-5 ">
						<td className="No-Table-Style CellOverride-174 " colspan="2 ">
							<p className="tekst-u-tabelama ">Uopće ne (0) </p>
						</td>
						<td className="No-Table-Style CellOverride-175 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
				</tbody>
			</table></div>
			<br></br>
		<div className="custom_tables">	<table id="table093" className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-247 " />
					<col className="_idGenTableRowColumn-248 " />
					<col className="_idGenTableRowColumn-249 " />
					<col className="_idGenTableRowColumn-250 " />
					<col className="_idGenTableRowColumn-163 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-176 " rowspan="3 ">
							<p className="tekst-u-tabelama ">5.2.3 Ako imaju, je li državna pomoć razmatrana? Ako jeste, je li zakonito i imaju li aplikant i bilo koja treća strana pravo da dobiju grant u traženom iznosu prema propisima o državnoj pomoći?</p>
						</td>
						<td className="No-Table-Style CellOverride-176 " rowspan="3 " colspan="2 ">
							<p className="tekst-u-tabelama ">Nije primjenjivo</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (5)</p>
						</td>
						<td className="No-Table-Style CellOverride-177 ">
							<p className="tekst-u-tabelama ">U potpunosti (5) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-177 ">
							<p className="tekst-u-tabelama ">Djelomično (3) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-177 ">
							<p className="tekst-u-tabelama ">Uopće ne (0) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-176 " rowspan="3 ">
							<p className="tekst-u-tabelama ">5.2.4 Ukazuju li dosadašnji rezultati aplikanta da aplikant nema ili da neće imati potrebne upravljačke kapacitete, sisteme i procese kako bi ispunio zahtjeve za državnu pomoć?</p>
						</td>
						<td className="No-Table-Style CellOverride-176 " rowspan="3 " colspan="2 ">
							<p className="tekst-u-tabelama ">Nije primjenjivo</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (5)</p>
						</td>
						<td className="No-Table-Style CellOverride-177 ">
							<p className="tekst-u-tabelama ">U potpunosti (0)</p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-177 ">
							<p className="tekst-u-tabelama ">Djelomično (3)</p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-177 ">
							<p className="tekst-u-tabelama ">Uopće ne (5) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-176 " colspan="5 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-14 ">5.3 Javnost</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-176 " colspan="5 ">
							<p className="tekst-u-tabelama ">Je li aplikant potvrdio:</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-176 " colspan="2 ">
							<p className="tekst-u-tabelama ">5.3.1 Da je pročitao i razumio zahtjeve u pogledu brendiranja i promocije?</p>
						</td>
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">DA</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (5)</p>
						</td>
						<td className="No-Table-Style CellOverride-177 ">
							<p className="tekst-u-tabelama ">NE</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (0)</p>
						</td>
						<td className="No-Table-Style CellOverride-178 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-176 " colspan="2 ">
							<p className="tekst-u-tabelama ">5.3.2 Organizacija aplikant ima sisteme koji su u skladu s ovim zahtjevima; ili ima kapacitet da uspostavi takve sisteme kako bi ispunila zahtjeve?</p>
						</td>
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">DA</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (5)</p>
						</td>
						<td className="No-Table-Style CellOverride-177 ">
							<p className="tekst-u-tabelama ">NE</p>
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /> (0)</p>
						</td>
						<td className="No-Table-Style CellOverride-178 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-179 " colspan="5 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">6. Multidisciplinarne teme</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-176 " rowspan="3 " colspan="3 ">
							<p className="tekst-u-tabelama ">6.1 Pokazuje li prijava da će aplikant poduzeti pozitivne mjere kako bi podržao temu održivog razvoja?</p>
						</td>
						<td className="No-Table-Style CellOverride-177 ">
							<p className="tekst-u-tabelama ">U potpunosti (5) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-177 ">
							<p className="tekst-u-tabelama ">Djelomično (3) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-177 ">
							<p className="tekst-u-tabelama ">Uopće ne (0) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-176 " rowspan="3 " colspan="3 ">
							<p className="tekst-u-tabelama ">6.2 Pokazuje li prijava da će aplikant poduzeti pozitivne mjere kako bi doprinio multidisciplinarnim temama ravnopravnosti spolova i nediskriminacije?</p>
						</td>
						<td className="No-Table-Style CellOverride-177 ">
							<p className="tekst-u-tabelama ">U potpunosti (5) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-177 ">
							<p className="tekst-u-tabelama ">Djelomično (3) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
					<tr id="studije-slucaja-5" className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-177 ">
							<p className="tekst-u-tabelama ">Uopće ne (0) </p>
						</td>
						<td className="No-Table-Style CellOverride-157 ">
							<p className="tekst-u-tabelama "><img className="_idGenObjectAttribute-40 " src="./image/170.jpg " alt=" " /></p>
						</td>
					</tr>
				</tbody>
			</table></div>

			<h2 className="blue_overlay_heading mt-90" >STUDIJA SLUČAJA 5:</h2>

			<h2 className="content_heading blue mt-35"><b>PRIMJERI JAVNIH POZIVA ZA PODNOŠENJE PRIJAVA  </b>(FAZA 3)</h2>

			<p className="content_text pos-rel mt-50"><b><u>Primjer 1: Fond civilnog društva 2018 - javni konkurs - Irish Aid3</u></b> <Toolpit text="Imajte na umu da je poziv skraćen na način da su izdvojeni samo elementi koji su relevantni za kontekst BiH."></Toolpit></p>

			<table id="table094 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-251 " />
					<col className="_idGenTableRowColumn-252 " />
					<col className="_idGenTableRowColumn-253 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Država</p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Organizacija</p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Sektor</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Irska</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Irish Aid (Irska pomoć) - Ministarstvo <br />vanjskih poslova i trgovine, Vlada Irske </p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Civilno društvo </p>
						</td>
					</tr>
				</tbody>
			</table>



			<h2 className="content_text blue mt-40"><b>1. KONTEKST</b></h2>

			<p className="content_text">Irish Aid ima dugu historiju pružanja podrške irskim organizacijama civilnog društva koje rade u
			zemljama u razvoju. Njihova podrška civilnom društvu povezana je s ciljevima održivog razvoja,
			okvirom za djelovanje koji je blisko povezan s irskom politikom za međunarodni razvoj, jedan
			svijet, jednu budućnost i politikom podrške civilnom društvu organizacije Irish Aid.</p>

			<p className="content_text">Fond za civilno društvo (CSF) glavna je šema organizacije Irish Aid za finansiranje projekata na
			konkurentnoj osnovi. Fond na godišnjoj osnovi zaprima prijave irskih i pozvanih međunarodnih
			organizacija civilnog društva za realizaciju razvojnih projekata relevantnih za prioritete politike
			organizacije Irish Aid. Projekti moraju trajati između jedne i tri godine, a prioritet se daje projektima
			u Subsaharskoj Africi. Irish Aid snažan fokus stavlja na rezultate, tj. održive društveno-ekonomske
			promjene ili koristi za siromašne ljude. Organizacije koje se prijavljuju za grantove CSF-a moraju
			pokazati jasnu logiku intervencije između: (i) identificiranog problema; (ii) načina na koji će se
			intervencija pozabaviti tim problemom i (iii) onoga što se intervencijom očekuje postići. Aplikanti
			trebaju pokazati jasan postupak analize i određivanja prioriteta u pogledu rezultata ka kojima
			organizacija teži. U skladu s tim, indikatore treba pažljivo odabrati kako bi učinkovito pratili
			napredak i promjene.</p>

			<h2 className="content_text blue mt-40"><b>2. VREMENSKI OKVIR</b></h2>

			<p className="content_text">U nastavku je dat vremenski raspored za ciklus u 2018. godini:</p>
			<p className="content_text">Obrazac prihvatljivosti i računi: 1. decembar 2017.</p>
			<p className="content_text">Obrazac prijave: 19. januar 2018.</p>
			<p className="content_text">Procjena: januar – april 2018.</p>
			<p className="content_text">Obavijest o rezultatima javnog poziva: april/maj 2018.</p>

			<h2 className="content_text blue mt-40"><b>3.  BUDŽET PROJEKTA I OGRANIČENJA OBIMA GRANTOVA</b></h2>
			<p className="content_text">Budžet za cijeli projekt mora biti dostavljen zajedno uz prijavu, uključujući i detaljno razložen
			iznos granta koji se traži od Irish Aida.</p>


			<p className="content_text blue mt-40">3.1 60% TRAŽENI IZNOS</p>
			<p className="content_text">Sredstva dobivena od Irish Aida, direktno ili indirektno, ne smiju preći 60% ukupnih primitaka
			organizacije aplikanta. To se izračunava na osnovu prosjeka računa iz prethodne dvije godine, a
			traženi iznos granta ne bi trebao premašiti taj iznos</p>

			<p className="content_text blue mt-40">3.2 MAKSIMALNO DOSTUPNI GRANT</p>

			<p className="content_text">Pod uvjetom da je zavisnost od sredstava Irish Aida ograničena na 60%, maksimalni iznos granta
			po godini iznosi:</p>
			<p className="content_text">€400,000 godišnje za irske organizacije;</p>
			<p className="content_text">€200,000 godišnje za međunarodne organizacije.</p>
			<p className="content_text">Imajte na umu da se u ciklusu za 2018. godinu očekuje veliki broj prijava. U tom smislu, vrlo je
			vjerovatno da će samo mali broj organizacija (ako uopće dobije) dobiti ukupni traženi iznos granta.</p>

			<p className="content_text blue mt-40">3.3 MAKSIMALNI DOPRINOS IRISH AIDA</p>

			<p className="content_text">U svakom slučaju, Irish Aid će finansirati do najviše 70% ukupnih tr oškova projekta svake godine.</p>
			<p className="content_text">U prvoj godini, u kojoj se od Irish Aida traži 70% ukupnih troškova, preostalih 30% ukupnog
			budžeta projekta mora biti već dostupno ili osigurano, za šta organizacija mora predočiti dokaze.
			Tih 30% sredstava ne smije biti iz sredstava koje je Irish Aid alocirao, bilo direktno ili indirektno.</p>
			<p className="content_text">Za drugu i treću godinu (ako je relevantno), organizacija mora garantirati svoje učešće od 30%.</p>
			<p className="content_text">U tom smislu, razmatrat će se rezultati prethodnih projekata u pogledu izvora finansiranja, kao
			što je prikazano u dvije finansijske godine prije podnošenja prijave.</p>



			<p className="content_text blue mt-40">3.4 INDIREKTNI TROŠKOVI</p>
			<p className="content_text">Irish Aid će podržati indirektne troškove do najviše 10% ukupnog granta. Indirektni troškovi su
			troškovi koji podržavaju ciljeve projekta, ali koji nisu nužno nastali u kontekstu projekta te se ne
			mogu tako lako pripisati projektu, npr. troškovi administracije, finansijskih usluga, putovanja i
			dnevnica. Ukoliko indirektni troškovi premašuju 10% granta dodijeljenog od Irish Aida, to će se
			smatrati ozbiljnim kršenjem zahtjeva granta.</p>

			<h2 className="content_text blue mt-40"><b>4. NEPRIHVATLJIVE AKTIVNOSTI </b></h2>
			<p className="content_text"><span className="mr-15">-</span>Razvoj organizacije</p>
			<p className="content_text"><span className="mr-15">-</span>Intervencije koje prevenstveno predstavljaju socijalnu pomoć</p>

			<p className="content_text"><span className="mr-15">-</span>Sponzoriranje pojedinaca ili porodica</p>
			<p className="content_text"><span className="mr-15">-</span>Stipendije za studiranje ili istraživanje (što ne obuhvata kratkoročne obuke za osoblje,
			partnere i korisnike u regiji)</p>

			<p className="content_text"><span className="mr-15">-</span>Velike infrastrukturne šeme</p>
			<p className="content_text"><span className="mr-15">-</span>Evangelizacija ili preobraćanje</p>
			<p className="content_text"><span className="mr-15">-</span>Pomoć u vanrednim situacijama/humanitarna pomoć</p>
			<p className="content_text"><span className="mr-15">-</span>Aktivnosti razvoja obrazovanja u Irskoj</p>
			<p className="content_text"><span className="mr-15">-</span>Podizanje svijesti javnosti u Irskoj</p>
			<p className="content_text"><span className="mr-15">-</span>Retroaktivni troškovi (odnosno troškovi koji su nastali prije dogovorenog datuma početka
			projekta/potpisivanja ugovora s Irish Aidom)</p>


			<h2 className="content_text blue mt-40"><b>5. POSTUPAK UTVRĐIVANJA PRIHVATLJIVOSTI </b></h2>
			<p className="content_text">Obrazac o ispunjavanju uvjeta prihvatljivosti bit će dostupan na internetskoj stranici organizacije
			Irish Aid. Ovaj obrazac mora biti popunjen i vraćen Irish Aidu najkasnije do petka, 1. decembra,
			2017. do 17 sati. Imajte na umu da se obrasci pristigli nakon tog roka neće razmatrati. Također,
			prijave organizacija koje ne ispunjavaju sljedeće uvjete prihvatljivosti neće biti razmatrane:</p>

			<p className="content_text"><span className="mr-15">-</span>Država registracije: Organizacija koja se javlja na konkurs mora imati sjedište u Irskoj ili mora
			biti organizacija koja nema sjedište u Irskoj, ali koja je dobila poziv da se javi na konkurs za dodjelu
			grantova.</p>

			<p className="content_text"><span className="mr-15">-</span>Status organizacije: Aplikant mora biti nevladina, neprofitna organizacija civilnog društva koja
			ima svojstvo pravnog lica. Mora posjedovati registracijski broj dobrotvorne organizacije najmanje
			dvije godine prije datuma podnošenja prijave. Za organizacije koje nemaju sjedište u Irskoj, tražit
			će se ekvivalent registracijskoj broja.</p>

			<p className="content_text"><span className="mr-15">-</span>Upravljanje: Aplikanti moraju imati formalnu strukturu za odlučivanje (npr. upravni odbor/odbor
			povjerenika), koja može preuzeti pravnu odgovornost za upravljanje i korištenje sredstava Irish
			Aida (svi irski aplikanti moraju imati takvu strukturu koja je priznata u Irskoj).</p>

			<p className="content_text"><span className="mr-15">-</span>Završni račun: Aplikanti moraju podnijeti godišnji završni račun koji sadrži minimalno bilans
			stanja, bilans uspjeha te izvještaj o novčanom toku za dvije finansijske godine prije datuma
			podnošenja prijave. Ako aplikant ima godišnji prihod veći do 100.000 eura, ti računi moraju biti
			pregledani od nezavisnog vanjskog revizora. Godišnji računi (ako je potrebno, revidirani) moraju
			biti dostupni na internetskoj stranici organizacije do prihvatljivog roka.</p>

			<p className="content_text"><span className="mr-15">-</span>Status finansiranja: Prijave organizacija kojima je 2018. godine odobren grant u okviru fonda
			civilnog društva ili nekog programskog fonda neće biti prihvaćene.</p>

			<p className="content_text"><span className="mr-15">-</span>Fokus rada: Područja intervencije organizacije moraju biti u skladu s definicijom zvanične razvojne pomoći OECD DAC-a i moraju se dešavati u zemlji koja je na listi prihvatljivih zemalja za
			pomoć.</p>


			<p className="content_text"><span className="mr-15">-</span>Potvrda o usklađenosti: Aplikanti koji su ranije primili sredstva Irish Aida moraju imati potvrdu o
			usklađenosti s uvjetima koji se odnose na upravljanje i korištenje tih sredstava. Prijave aplikanata
			koji nemaju ovu potvrdu o usklađenosti s uvjetima ugovora Irish Aida neće se razmatrati za
			finansiranje.</p>

			<p className="content_text"><span className="mr-15">-</span>Zaštita djece: Organizacije koje rade s djecom i mlađim odraslim osobama moraju imati politike
			o zaštiti djece koje su u skladu s Prvim zakonom o djeci iz 2015. Takve politike moraju imati i sve
			predložene partnerske organizacije.</p>

			
			<h2 className="content_text blue mt-40"><b>6. POSTUPAK PRIJAVE </b></h2>
			<p className="content_text">Irish Aid će obrazac prijave za 2018. dostaviti organizacijama koje ispunjavaju uvjete. Ako ne
			dobijete nikakvu obavijest, kontaktirajte nas putem internetske stranice, opcija «Contact Us»,
			kako biste potvrdili status svog zahtjeva za utvrđivanje prihvatljivosti.</p>

			<p className="content_text">Obrazac prijave podijeljen je na šest g lavnih poglavlja i četiri priloga. Glavna poglavlja su:</p>

			<p className="content_text ml-15">1. Sažetak projekta</p>
			<p className="content_text ml-15">2. Opis organizacije i njezinih kapaciteta za rad u partnerstvu s organizacijom Irish Aid</p>
			<p className="content_text ml-15">3. Dokaz o izmjenama</p>
			<p className="content_text ml-15">4. Pojedinosti o projektu</p>
			<p className="content_text ml-15">5. Partneri projekta</p>
			<p className="content_text ml-15">6. Dodatne informacije i pojašnjenja (opcionalno)</p>

			<p className="content_text">Prilozi su podjednako važni i treba ih dostaviti u Excel formatu, a naročito:</p>

			<p className="content_text ml-15">1. Okvir rezultata</p>
			<p className="content_text ml-15">2. Budžet</p>
			<p className="content_text ml-15">3. Procjena tokova sredstava</p>

			<p className="content_text">Jedna organizacija može podnijeti samo jedan zahtjev Fondu za civilno društvo. Tamo gdje se
			organizacije žele prijaviti kao konzorcij, prijavu treba popuniti na način da se vodeća organizacija
			nominira kao aplikant, dok druge predstavljaju njezine partnere. Prijave se trebaju dostaviti
			elektronskom poštom na adresu civilsocietyfunding@dfat.ie najdalje do petka, 19. januara, 2018.
			do 17 sati. Prijave pristigle nakon ovog roka neće se razmatrati.</p>

			<p className="content_text">Napominjemo da organizacije trebaju voditi računa da sadržaj obrasca prijave bude jasan i potpun jer
			Irish Aid neće tražiti daljnje informacije tokom postupka ocjenjivanja. Pažljivo pročitajte smjernice
			za popunjavanje obrasca prijave. Dostavlja se samo obrazac prijave i u procesu ocjenjivanja neće
			se uzimati u obzir nikakvi dokumenti koji nisu izričito traženi.</p>

			<p className="content_text">Pitanja za “Fond civilnog društva“ dostavljaju se putem internetske stranice organizacije Irish Aid,
			sekcija «Contact Us». Po potrebi, na interentskoj stranici Irish Aida bit će objavljen dokument
			«najčešća pitanja», koji će se redovno ažurirati.</p>

			<p className="content_text">Imajte na umu da će svaki pokušaj utjecanja na ishod procesa donošenja odluka u vezi s prijavama
			za dodjelu granta rezultirati diskvalifikacijom. U tom smislu, vodite računa da niko od vaših
			predstavnika ne traži individualne sastanke s predstavnikom Ministarstva vanjskih poslova i
			trgovine u vezi s prijavom nakon objavljivanja konkursa.</p>



			<h2 className="content_text blue mt-40"><b>7. PROCJENA I POSTUPAK ODOBRAVANJA</b></h2>

			<p className="content_text">Prijave koje ispunjavaju kriterije prihvatljivosti ocjenjivat će vanjski konsultanti, koji će koristiti
			kriterije ocjenjivanja navedene na poleđini.</p>

			<p className="content_text">Irish Aid će nastaviti insistirati da sve finansirane intervencije doprinose održivoj i pozitivnoj
			promjeni za siromašne i marginalizirane ljude. Irish Aid će u svakom trenutku promovirati
			transparentnost i odgovornost u korištenju javnih sredstava.</p>

			<p className="content_text">Da bi se predložile za finansiranje, organizacije će morati postići minimalne rezultate u svakom
			od sljedećih šest područja:</p>
			<p className="content_text ml-15">1. Dokaz da organizacija djeluje na dobrim strateškim, političkim i finansijskim osonovama</p>
			<p className="content_text ml-15">2. Nadzor nad upravljanjem i finansijama</p>
			<p className="content_text ml-15">3. Dokaz da je ranijim projektima ostvarena promjena (koje je finansirao Irish Aid, ako je
			primjenjivo)</p>
			<p className="content_text ml-15">4. Dokaz o jasnoj log ici intervencije</p>
			<p className="content_text ml-15">5. Dokaz o sposobnosti praćenja rezultata</p>
			<p className="content_text ml-15">6. Usklađenost s politikama Irish Aida i, naročito, s politikom davanja doprinosa jednom od
			rezultata iz Okvira djelovanja na visokoj razini i jednom od ciljeva održivog razvoja.Organizacije
			će dobiti pismeno obavještenje o ishodu postupka ocjenjivanja, a na zahtjev će dobiti i povratne
			informacije. Prije potpisivanja ugovora, uspješne organizacije će morati zadovoljiti određene
			uvjete, koji će obuhvatati, između ostalog, dostavljanje svih politika i strategija navedenih u
			obrascu prijave, revidiranog budžeta, revidiranog prognoziranog priliva sredstava, revidiranog
			okvira rezultata. Ugovori mogu sadržavati i usaglašene pokazatelje koje organizacija mora postići
			tokom trajanja projekta.</p>


			<h2 className="content_heading blue mt-40"><b>8. MONITORING, EVALUACIJA I IZVJEŠTAVANJE</b></h2>
			<h2 className="content_text blue mt-20">8.1 MONITORING</h2>

			<p className="content_text">Od organizacija se očekuje da primjenjuju najviše standarde odgovornosti, dobrog upravljanja i
			finansijskog nadzora u upravljanju projektima. Grantovi se moraju trošiti isključivo u dogovorene
			svrhe i u odgovarajućim rokovima.</p>


			<p className="content_text">Organizacije trebaju imati na umu zahtjeve iz instrukcije Ministarstva za javnu potrošnju i reforme( 13/2014), koja se odnosi na upravljanje grantovima i odgovornost. Na organizacije koje prime
			grant od bilo kog vladinog tijela primjenjuju se četiri principa: jasnoća, upravljanje, vrijednost za
			novac i pravičnost. </p>

			<p className="content_text">Kada irska agencija za međunarodni razvoj Irish Aid usmjeri sredstva u formi grantova
			organizacijama koje su njezini implementacijski partneri, na organizaciji koja je potpisala ugovor
			sa Irish Aidom je da dokaže kako je pogodna za implementacijskog partnera i da može propisno
			provesti projekt i upravljati sredstvima.</p>
			<p className="content_text">Predstavnici Irish Aida mogu ići u terenske posjete ovim organizacijama u svrhe monitoringa.
			Takve posjete u okviru monitoringa obuhvaćene su projektnim zadatkom, a fokus je na praćenju
			ostvarivanja rezultata.</p>

			<h2 className="content_text blue">8.2 EVALUACIJA</h2>
			<p className="content_text">Irish Aid ohrabruje organizacije koje dobiju grant da razvijaju i produbljuju kulturu „učenja lekcija“,
			koja doprinosi boljem budućem učinku i pruža dokaze o efektivnosti. Stoga, Irish Aid ohrabruje
			organizacije da provedu evaluacije kako bi što vjerodostojnije dokumentirale rezultate projektnog
			ulaganja.</p>

			<h2 className="content_text blue">8.3 IZVJEŠTAVANJE</h2>

			<p className="content_text">Svaka organizacija će na godišnjoj osnovi izvještavati o postignutom napretku u ostvarivanju
			očekivanih rezultata, u formatu koji propiše Irish Aid. Ti izvještaji će obuhvatati mnoge oblasti,
			uključujući napredak projekta, analizu rezultata, naučene lekcije, najnovije informacije o upravljanju
			i organizaciji te raspolaganju sredstvima. Uz izvještaje se moraju priložiti najnoviji godišnji računi
			i uvjerenje kojim se potvrđuje da su sredstva granta Irish Aida propisno obračunata.</p>


			<p className="content_text pos-rel mt-60"><b><u>Primjer 2: Šema klaster grantova Istočno partnerstvo (IP) Plus4</u></b> <Toolpit text="Imajte na umu da je poziv skraćen na način da su izdvojeni samo elementi koji su relevantni za kontekst BiH."></Toolpit></p>

			<table id="table095 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-129 " />
					<col className="_idGenTableRowColumn-254 " />
					<col className="_idGenTableRowColumn-255 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Zemlja</p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Organizacija</p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Sektor</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Armenija, Azerbejdžan, Bjelorusija, Gruzija, Moldavija, Ukrajina</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Evropska unija</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Istraživanja i inovacije</p>
						</td>
					</tr>
				</tbody>
			</table>

			<p className="content_text blue mt-40"><b>PREDSTAVLJANJE PROJEKTA IP PLUS I PREGLED AKTIVNOSTI U OKVIRU GRANT ŠEME</b></p>
			
			<p className="content_text">Cilj projekta IP PLUS je da potakne saradnju u oblasti istraživanja i inovacija između Evropske
			unije (EU) i zemalja Istočnog partnerstva (IP) te da se djelotvorno pozabavi postojećim prilikama i
			preostalim preprekama za takvu saradnju.</p>

			<p className="content_text">Grant šema IP PLUS (2017) osmišljena je kako bi osnažila partnerstva između EU i IP-a u oblasti
			istraživanja i inovacija kroz saradnju klastera i sličnih organizacija. Cilj ove aktivnosti je jačanje
			klastera u zemljama IP-a, prijenos dobrih praksi EU – i zemalja IP-a, kada je to relevantno, i
			omogućavanje održive saradnje između postojećih i novoosnovanih klastera u EU i zemljama IP-a.</p>

			<p className="content_text">IP PLUS klasterima u IP-u ili sličnim pravnim licima ili organizacijama nudi šest grantova u iznosu
			do 10.000 eura. Grant obuhvata troškove aktivnosti u okviru saradnje između klastera iz IP-a i EU.
			Grantovi će se dodjeljivati na konkurentnoj osnovi, nakon evaluacije od predstavnika IP projekta.</p>


			<p className="content_text blue mt-40"><b>INFORMACIJE O PRIJAVLJIVANJU ZA GRANT ŠEMU</b></p>
			<p className="content_text blue">VRIJEME OBJAVLJIVANJA KONKURSA</p>

			<p className="content_text">Konkurs se otvara 15. juna 2017, a zatvara 30. septembra 2017. godine.</p>
			<p className="content_text">U roku od 8 sedmica po isteku roka, predstavnici IP projekta će provjeriti prihvatljivost prijava i
			izvršiti njihovo ocjenjivanje.</p>

			<p className="content_text">Najraniji datum započinjanja svih pozitivno ocijenjenih projekata je 4 sedmice nakon obavještavanja
			o rezultatima evaluacije (novembar 2017.). Aktivnosti bi se trebale završiti najkasnije deset mjeseci
			nakon datuma započinjanja aktivnosti, a najkasnije do de cembra 2018. godine.</p>


			<p className="content_text blue">PRIHVATLJIVI UČESNICI</p>

			<p className="content_text">Konkurs je namijenjen klasterima i sličnim inicijativama, isključivo u zemljama IP-a i državama
			članicama EU. Klasteri ili slične organizacije koje se prijave trebaju što vjerodostojnije zadovoljiti
			evropsku definiciju klastera. Ovo je važan kriterij za odabir korisnika grant šeme.</p>

			<p className="content_text">Prihvatljivi aplikanti su: klasteri; klasteri u nastanku i slične inicijative; poslovne mreže fokusirane
			na istraživanja i inovacije (R&I); industrijska udruženja i regionalne organizacije fokusirane na
			istraživanja i inovacije; privredne komore.</p>

			<p className="content_text">Zajednička prijava treba uključivati najmanje jedan klaster iz IP-a i jedan klaster iz EU. Zajedničku prijavu podnosi klaster iz IP-a u ime obje organizacije.</p>

			<p className="content_text">Kandidatima iz IP-a se preporučuje da se registriraju na ECCP platformu za kandidatske klastere
			iz IP-a i EU (Evropska platforma za saradnju klastera), a registracija je obavezna za kandidate iz
			EU. Da biste se prijavili, slijedite link naveden na ECCP platformi.</p>

			<p className="content_text">Klasteri i slične organizacije koje se prijave moraju imati svojstvo pravnog lica. Finansijska sredstva
			se ne odobravaju fizičkim licima.</p>

			<p className="content_text blue">EVALUACIJSKI KRITERIJI KOMISIJA ZA ODABIR</p>

			<p className="content_text">Evaluatori aktivnosti u okviru grant šeme IP PLUS su partneri projekta IP PLUS. Vođa radne grupe
			(iz Inno TSD koji je pružatelj usluga) i koordinator projekta CeRISS-a zaduženi su za nadgledanje
			cjelokupne aktivnosti. Za dodatne informacije o partnerima, posjetite internetsku stranicu IP
			PLUS2.</p>

			<p className="content_text">Komisija za odabir vrši evaluaciju na osnovu tri glavna kriterija:</p>

			<p className="content_text ml-15"><span>- </span>Mjera u kojoj klasteri ili slične novoosnovane organizacije iz IP-a i EU zadovoljavaju
			evropsku definiciju klastera. Aplikant iz IP-a mora skoro u potpunosti zadovoljavati definiciju
			evropskog klastera, ili bar imati dobro dokumentiran plan/program za prerastanje u klastersku
			organizaciju u evropskom smislu. Klaster iz EU koji se prijavljuje mora imati registriran profil na
			ECCP-u. </p>

			<p className="content_text ml-15"><span>- </span>Stabilnost aktivnosti klastera i sličnih organizacija iz EU i zemalja IP-a u oblasti istraživanja
			i inovacija. Organizacije moraju dokazati da provode aktivnosti u okviru dimenzije za istraživanja
			i inovacije. Primjer: poljoprivredni klaster koji je aktivan u proizvodnji sjemena i čiji poljoprivredni
			članovi sarađuju sa laboratorijom u proizvodnji novih sjemenskih sorti. </p>

			<p className="content_text ml-15"><span>- </span>Potencijal klastera ili slične organizacije iz zemalja IP-a za uspostavljanje održive dugoročne
			saradnje sa evropskim klasterom i očekivani utjecaj aktivnosti u okviru grant sheme na održavanje
			i/ili razvijanje te saradnje. Klasteri moraju jasno definirati svoj interes za uspostavljanje saradnje
			i dodatnu vrijednost koja će rezultirati iz nje. Aplikanti bi možda željeli opisati i ranije aktivnosti
			saradnje između dva klastera. </p>


			<p className="content_text blue">PRIHVATLJIVE AKTIVNOSTI ZA FINANSIRANJE</p>


			<p className="content_text">Prihvatljive aktivnosti za finansiranje su, između ostalog: misije rukovodnih timova iz države
			članice EU u zemlju IP-a i obratno, sa jasno definiranim ciljevima; razmjena osoblja kako bi učili
			o strategiji i praksama klastera i sličnih organizacija iz EU i zemalja IP-a u oblasti istraživanja
			i inovacija; priprema Memoranduma o razumijevanju između dvije klasterske organizacije;
			organizacija zajedničkog događaja; komunikacijske aktivnosti sa ciljem informiranja članova
			klastera o mogućnostima za saradnju u oblasti istraživanja i inovacija itd.</p>

			<p className="content_text">Odabrani aplikanti će potpisati sporazum sa partnerom iz IP PLUS-a odgovornim za izdvajanje
			sredstava u okviru grant šeme za klastera korisnika iz zemlje IP-a. Partner iz IP-a, Inno i CeRISS
			će definirati aktivnosti koje će se finansirati, maksimalne troškove koji se mogu nadoknaditi, te proces nadoknade troškova. Avansna uplata u iznosu od 40% se prebacuje klasteru korisniku iz
			IP-a nakon potpisivanja sporazuma. Preostalih 60% se prebacuje nakon implementacije granta i
			prihvatanja izvještaja o grant šemi.</p>

			<p className="content_text">Nadoknada troškova aktivnosti zasniva se na dokumentiranim stvarnim troškovima (npr.
			putovanje, viza, dnevnica, hrana, troškovi organizacije događaja itd, bez troškova radne snage i
			ostalih naknada), odnosno, do 6.000 eura prije početka aktivnosti i do 4.000 eura po završetku
			aktivnosti.</p>

			<p className="content_text">Izvještaj treba uključivati:</p>	

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Izvještaj o aktivnosti i njezinim ishodima (5-10 strana), uključujući:
            </span></p>
			
			<p className="content_text ml-15"><span> - </span> Opis provedenih aktivnosti u okviru granta, uključujući kvantitativne podatke kada su dostupni</p>

			<p className="content_text ml-15"><span> - </span> Sažetak glavnih ishoda</p>

			<p className="content_text ml-15"><span> - </span> Naslov i sadržaj prijedloga ili projekta koji je proizašao iz aktivnosti i/ili eventualnih budućih
			planiranih aktivnosti</p>

			<p className="content_text ml-15"><span> - </span> Jedna ili više slika, ako je moguće (uz saglasnost za fotografiranje)</p>

			<p className="content_text ml-15"><span> - </span> Konačno, povratne informacije nakon provedene aktivnosti, preporuke, drugi utjecaji granta itd.</p>

			<p className="content_text ml-15"><span> - </span> Detaljan pregled troškova </p>

			<p className="content_text">Finansirane aktivnosti u svakom slučaju trebaju biti usmjerene na saradnju između industrije i
			akademske zajednice u oblasti istraživanja, razvoja i inovacija ili saradnju između klastera, ne
			uključujući trgovačke djelatnosti</p>

			
			<p className="content_text blue">KAKO SE PRIJAVITI</p>

			<p className="content_text">Klaster ili slična organizacija iz zemlje IP-a dostavlja jednu prijavu u ime svoje organizacije
			i partnerske organizacije iz EU. U okviru grant šeme, sredstva se dodjeljuju samo prijavama
			„uparenih“ organizacija (klaster iz IP-a + klaster iz EU).</p>

			<p className="content_text">Prijavu čini: </p>
			<p className="content_text ml-15"><span> - </span> Jedan obrazac prijave  </p>
			<p className="content_text ml-15"><span> - </span> Jedno potpisano i datirano pismo saglasnosti od Evropske organizacije klastera,</p>
			<p className="content_text ml-15"><span> - </span> Kratka prezentacija (PDF, PPT) o klasterima iz EU i IP -a. </p>
			<p className="content_text ml-15"><span> - </span> Poveznica/snimak ekrana sa ECCO profilima klastera, koji je obavezan za aplikanta iz EU, a
			posebno se preporučuje aplikantima iz zemalja IP-a.</p>

			<p className="content_text">Prijave se moraju dostaviti elektronskim putem, na engleskom jeziku.</p>

			<p id="studije-slucaja-6" className="content_text">Aplikanti će biti obaviješteni o ishodu svoje prijave putem e-maila (adresa elektronske pošte koju
			su koristili za slanje prijave).</p>

			<h2 className="blue_overlay_heading" >STUDIJA SLUČAJA 6:</h2>
			<p className="content_heading blue"> (FAZA 3. SMJERNICE ZA APLIKANTE) </p>

			<h2 className="blue_overlay_heading mt-30">KAKO SE MOŽE URADITI: PRIČA IZ SJEVEROISTOČNE BUGARSKE
			</h2>

			<p className="content_text ta-c mb-50 mt-40">Ministarstvo...</p>

			<p className="content_text ta-c mt-100"><b>Pilot integrirana šema ulaganja u projekte regionalnog razvoja</b></p>
			<p className="content_text ta-c"><b>(Planiranje SI regije)</b></p>

			<p className="content_text ta-c mt-70">(Izvodi iz)</p>

			<p className="content_text ta-c"><b>Smjernica za aplikante</b></p>
			<p className="content_text ta-c"><b>Prijava na javni konkurs</b></p>

			<p className="content_text ta-c mt-70">Budžetska linija: ...</p>


			<div className="yellow_box">
                    <p className="main-text d-flex d-flex-aac mb-20"><img src={`${process.env.PUBLIC_URL}/images/lightbulb.svg`} alt="lightbulb"></img> <b>UVOD U OBRAĐENE DIJELOVE ŠEME</b></p>
					
					<p className="content_text">Ovaj prilog sadrži mnoge elemente <b>stvarne grant šeme</b> koja je dizajnirana i
					realizirana u Bugarskoj u okviru <b>vanjske pomoći EU</b> .Njegova struktura dosljedno
					prati oblik koji i dalje predlaže Komisija Evropske unije za grant šeme u okviru
					različitih instrumenata vanjske pomoći.</p>
                    
					<p className="content_text">Grant šema je osmišljena uz pomoć tima konsultanata. U to vrijeme, Komisija
					Evropske unije, koja je morala odobriti program, smatrala je da je dizajnirana na
					vrlo visokom nivou, naročito u sljedećim aspektima:</p>

					<p className="content_text">i.   <span className="ml-20">Šema je vrlo <b>usko usmjerena na konkretne akcije i aplikante</b>: nije široko
					otvorena za svaku vrstu intervencija. Dizajneri su se trudili, u skladu s koracima
					Faze 1 i Faze 2, opisanim u ovom priručniku, <b>da osiguraju da šema odgovara
					ciljevima politike i programa, stvarnim potrebama, zahtjevima i kapacitetima
					potencijalnih aplikanata;</b></span></p>

					<p className="content_text">ii. <span className="ml-20"> Smjernice za aplikante, koje aplikantima predstavljaju šemu, razvijane su, <b>dio po
					dio, tokom perioda od 12 sedmica.</b> Zapravo, <b>glavna struktura šeme </b>i njezinih
					komponenti je već razvijena u formi radnog dokumenta već nakon 7 sedmica.
					Pregled strukture u formi matrice za planiranje šeme izrađen je nakon 5 sedmica;</span> </p>

					<p className="content_text">iii.  <span className="ml-20"><b>Dokument je jasan:</b> obraća se direktno aplikantima. Od samog početka ih
					usmjerava na najbitnije dijelove dokumenta, a naročito na komponente (kojih
					ima četiri) koje su za njih najbitnije.</span></p>


					<p className="content_text blue mt-20"><b>Ograničenja u pogledu izrade ove šeme</b></p>

					<p className="content_text">i.   <span className="ml-20">S izradom šeme se krenulo u drugoj polovini januara. <b>Budžet </b>je već bio
					programiran (dvije godine ranije) i, zbog kašnjenja, <b>morao se potrošiti mnogo
					brže nego što se željelo. Projekti su se morali ugovoriti najdalje do novembra
					i realizirati za maksimalno 10-12 mjeseci. Ova su ograničenja značila da je ova
					šema zavisila ne samo od dobrih projekata već i od stepena zrelosti projekata,</b> 
					odnosno, projekata koji su se realativno brzo mogli pripremiti za realizaciju.</span></p>



					<p className="content_text">ii.   <span className="ml-20">U početnim programskim dokumentima bilo je određenih problema koji su
					ograničili praktični opseg šeme i zahtijevali neke teške odluke. Naime, <b>šema
					je izvorno podrazumijevala mogućnost pružanja subvencija za ulaganja
					preduzećima na nivou od 10% ukupne subvencije. To očigledno nije bilo
					dovoljno da bi se utjecalo na ponašanje preduzeća.</b> Nije bilo pravnog načina da
					se taj nivo poveća bez izmjene izvornih programskih dokumenata. U to vrijeme
					to nije bilo moguće. Zbog toga je <b>ovaj element morao biti izbačen iz šeme u
					fazi konceptualizacije.</b></span></p>
					
					
					<p className="content_text blue mt-20"><b>Stečena iskustva iz postupka izrade </b></p>

					<p className="content_text">i.   <span className="ml-20">Ova je šema izrađena prema postupku i metodama koje su dosljedno pratile glavne
					elemente pristupa koji su predloženi u ovom priručniku. Konkretno, cilj je bio
					osigurati da šema može apsorbirati predviđeni budžet, uprkos kasnom početku
					i smanjenom vremenskom okviru za realizaciju. Zbog toga je šema osmišljena na
					način da privuče dobre projekte. Shodno tome, <b>osnovni dizajn šeme zasnivao se
					na ispitivanju potražnje za projektima koji se bave općim temama naznačenim
							u programskim dokumentima:</b> tj. poslovna infrastruktura, produktivna ulaganja
					i razvoj ljudskih resursa. </span></p>


					<p className="content_text">ii.   <span className="ml-20">Na osnovu ispitivanja potražnje među potencijalnim aplikantima, došlo se do oko
					100 mogućih projekata koji su bili u kategoriji pomenutih tema. No, procijenjeno
					je da bi manje od 60% tih projekata moglo biti realizirano u relevantnom roku,
					odnosno, da bi mogli blagovremeno podnijeti prijavu. <b>Šema je stoga dizajnirana
					na način da pokuša uvezati one projekte koji su imali najbolje ideje i koji su
					istovremeno bili izvodljivi sa ciljevima politike šeme. Zbog toga su opseg i
					kriteriji tako usko definirani: autori šeme su predvidjeli da neće biti više od 50
							prijava te je interni cilj bio finansirati oko 40% tih projekata.</b> </span></p>


				<p className="content_text">iii.   <span className="ml-20">Međutim, zaprimljeno je <b>67 prijava</b>, što je mnogo manje nego što je bilo
					uobičajeno za grant šeme. <b>Kvalitet je bio prilično visok i projekti su bili dobro
					prilagođeni opsegu i parametrima šeme. Kao rezultat toga, grant je ponuđen
						za 45 i ugovoren za nešto više od 40 projektnih prijedloga.</b> Šema je apsorbirala
					ukupni budžet, a ciljani izlazni rezultati i ishodi su bili u v elikoj mjeri ispunjeni. </span></p>


					<p className="content_text">iv.   <span className="ml-20">Konačno, radno opterećenje ocjenjivača, komisije i provedbenog tima, iako
					veliko, smanjilo se samom činjenicom da priliv prijava nije bio ogroman. <b>U praksi
							se obično odbije više od 80% prijava.</b> </span></p>
                </div>
					
				<div className="yellow_border">
					<p className="content_text mb-15"><b><u>Napomena za čitatelje:</u></b></p>
					<p className="content_text">Pregled sadržaja, koji je dat u nastavku teksta, predstavlja izvorni sadržaj, odnosno
					cjelokupnu strukturu izvornog dokumenta šeme. Odjeljci sadržaja označeni crnom bojom
					nisu obuhvaćeni, odnosno više od polovine izvornog dokumenta nije obuhvaćeno. Imajte
					u vidu da u daljnjem tekstu tri tačke u zagradi <b>(...) označavaju mjesta na kojima je samo
					dio teksta izvađen iz izvornog dokumenta.</b></p>
				</div>



			<h2 className="blue_overlay_heading mt-50 mb-35">SADRŽAJ</h2>

			<p className="content_heading blue mb-25"><a className="black_list blue" href="#sad1"><b>1. PILOT INTEGRIRANA ŠEMA ULAGANJA U REGIONALNI RAZVOJ</b></a></p>
			<ul className="ml-30">
				<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#kontekst`} className="black_list blue" href=""> 1.1 Kontekst </Link></li>
				<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#cgs-1`} className="black_list blue">1.2 Ciljevi grant šema i prioritetna pitanja</Link></li>
				<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#fs-1`} className="black_list blue">1.3 Finansijska sredstva koja je doznačilo ministarstvo</Link></li>
			</ul>

			<p className="content_heading blue mb-25"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#pjk`} className="black_list blue"><b>2. PRAVILA OVOG JAVNOG KONKURSA</b></Link></p>
			<ul className="ml-30">
				<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#kriterijprih`} className="black_list blue">2.1 KRITERIJI PRIHVATLJIVOSTI</Link></li>
					<ul className="ml-60">

						<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#paksmp`} className="black_list blue">2.1.1 <span className="ml-15">Prihvatljivost aplikanata: ko može podnijeti prijavu </span></Link></li>
						<li className="content_text">2.1.2 <span className="ml-15">Partnerstvo i prihvatljivost partnera</span></li>
						<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#pppzksmpp`}className="black_list blue">2.1.3 <span className="ml-15">Prihvatljivi projekti: projekti za koje se može podnijeti prijava</span></Link></li>
						<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#pt`} className="black_list blue">2.1.4 <span className="ml-15">Prihvatljivost troškova</span></Link></li>
						<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#uzppbb`} className="black_list blue">2.1.5 <span className="ml-15">Upute za predstavljanje priloga B. – budžet </span></Link></li>
						<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#okgsippksonk`} className="black_list blue">2.1.6 <span className="ml-15">Opis komponenti grant šeme i posebnih pravila koja se odnose na komponente </span></Link></li>

							<ul className="ml-90">
								<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#pi`} className="black_list blue">2.1.6.1 Poslovna infrastruktura</Link></li>

								<ul className="ml-120">
									<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#k2`}className="black_list blue">2.1.6.1.1 Kontekst</Link></li>
									<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#c2`} className="black_list blue">2.1.6.1.2 Ciljevi</Link></li>
									<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#vpp2`} className="black_list blue">2.1.6.1.3 Vrsta prihvatljivih projekata</Link></li>
									<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#vg2`} className="black_list blue">2.1.6.1.4 Veličina granta</Link></li>
									<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#pa2`} className="black_list blue">2.1.6.1.5 Prihvatljivost aplikanata</Link></li>
									<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#p2`} className="black_list blue">2.1.6.1.6 Partnerstvo</Link></li>
									<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#t2`} className="black_list blue">2.1.6.1.7 Trajanje</Link></li>
									<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#pt2`} className="black_list blue">2.1.6.1.8 Prihvatljivi troškovi</Link></li>
									<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#nt2`} className="black_list blue">2.1.6.1.9 Neprihvatljivi troškovi</Link></li>
								</ul>
				
								<li className="content_text">2.1.6.2 Razvoj ljudskih potencijala - Lokalne inicijative za zapošljavanje i zapošljivost</li>

								<ul className="ml-120">
									<li className="content_text">2.1.6.2.1 Kontekst</li>
									<li className="content_text">2.1.6.2.2 Ciljevi</li>
									<li className="content_text">2.1.6.2.3 Vrsta prihvatljivih projekata</li>
									<li className="content_text">2.1.6.2.4 Veličina grantova</li>
									<li className="content_text">2.1.6.2.5 Prihvatljivost aplikanata</li>
									<li className="content_text">2.1.6.2.6 Partnerstvo</li>
									<li className="content_text">2.1.6.2.7 Trajanje</li>
									<li className="content_text">2.1.6.2.8 Prihvatljivi troškovi (specifični za svaku od komponenata)</li>
									<li className="content_text">2.1.6.2.9 Neprihvatljivi troškovi</li>
								</ul>

								<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#iprlt`} className="black_list blue">2.1.6.3 Integrirana podrška regionalnom/lokalnom turizmu</Link></li>

								<ul className="ml-120">
									<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#k3`} className="black_list blue">2.1.6.3.1 Kontekst</Link></li>
									<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#c3`} className="black_list blue">2.1.6.3.2 Ciljevi</Link></li>
									<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#vpp3`} className="black_list blue">2.1.6.3.3 Vrsta prihvatljivih projekata</Link></li>
									<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#vg3`} className="black_list blue">2.1.6.3.4 Veličina grantova</Link></li>
									<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#pa3`} className="black_list blue">2.1.6.3.5 Prihvatljivi aplikanti</Link></li>
									<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#p3`} className="black_list blue">2.1.6.3.6 Partnerstvo</Link></li>
									<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#t3`} className="black_list blue">2.1.6.3.7 Trajanje</Link></li>
									<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#pt3`} className="black_list blue">2.1.6.3.8 Prihvatljivi troškovi</Link></li>
								</ul>

								<li className="content_text">2.1.6.4 Ulaganje u unapređenje uvjeta i vanjskog okruženja proizvodnog sektora</li>

								<ul className="ml-120">
									<li className="content_text">2.1.6.4.1 Kontekst</li>
									<li className="content_text">2.1.6.4.2 Ciljevi</li>
									<li className="content_text">2.1.6.4.3 Vrste prihvatljivih projekata</li>
									<li className="content_text">2.1.6.4.4 Veličina grantova</li>
									<li className="content_text">2.1.6.4.5 Prihvatljivi aplikanti</li>
									<li className="content_text">2.1.6.4.6 Partnerstvo 2.1.6.1</li>
									<li className="content_text">2.1.6.4.7 Trajanje</li>
									<li className="content_text">2.1.6.4.8 Prihvatljivi troškovi</li>
								</ul>
							</ul>
						
						<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#dtzvuojk`} className="black_list blue">2.1.7 Drugi tehnički zahtjevi vezani uz ovaj javni konkurs</Link></li>
			
					</ul>
					<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#kppipkts`} className="black_list blue">2.2 KAKO PODNIJETI PRIJAVU I POSTUPCI KOJE TREBA SLIJEDITI</Link></li>
					
						<ul className="ml-60">
							<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#opipd`} className="black_list blue">2.2.1 Obrazac prijave i prateća dokumentacija </Link></li>
							<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#gikpp`} className="black_list blue">2.2.2 Gdje i kako poslati prijavu </Link></li>
							<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#rzpp`} className="black_list blue">2.2.3 Rok za podnošenje prijava </Link></li>
							<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#di`} className="black_list blue">2.2.4 Dodatne informacije </Link></li>
							<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#pp5`} className="black_list blue">2.2.5 Potvrda prijema </Link></li>
						</ul>
					
					<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#eiop`} className="black_list blue">2.3 EVALUACIJA I ODABIR PRIJAVA</Link></li>

					<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#ooouo`} className="black_list blue">2.4 OBAVIJEST O ODLUCI UGOVORNOG ORGANA</Link></li>

					<li className="content_text blue"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#uksonr`} className="black_list blue">2.5 UVJETI KOJI SE ODNOSE NA REALIZACIJU</Link></li>

				</ul>
				<p className="content_heading blue mb-25"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2#p10`} className="black_list blue"><b>3. PRILOZI</b></Link></p>
		

				<div id="sad1"></div>
				<h2 className="blue_overlay_heading mt-75 mb-50 d-block" ><b>OBJAŠNJENJA I NAPOMENE STUDIJE SLUČAJA 6. </b> - PILOT INTEGRIRANA ŠEMA ULAGANJA U PROJEKTE REGIONALNOG RAZVOJA (PLANIRANJE ZA SJEVEROISTOČNU REGIJU)</h2>

				<p className="content_text">Tekst u nastavku predstavlja skraćenu verziju originalnih smjernica za aplikante, odnosno,
				organizacije koje su zainteresirane za izradu i podnošenje projektnih prijedloga u okviru pilot
				integrirane šeme ulaganja u projekte regionalnog razvoja (planiranje sjeveroistočne regije)</p>
			

				<div className="yellow_box mt-50">
						<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/lupa.svg`} alt="lightbulb"></img> <b>NAPOMENA 1</b></p>
						
						<p className="content_text"><b>U prvom odjeljku aplikantima su date ključne informacije o kontekstu kako bi
						razumjeli: </b></p>

						<ul>
							<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>Kako najbolje čitati ovaj dokument (i kako pisati na način koji će pomoći ljudima da razumiju);</b></span></li>

							<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>Zašto ova šema; </b></span></li>


							<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>Ko finansira ovu šemu.</b></span></li>
						</ul>

						<p className="content_text"><b>U sistemu izrade šema koji se koristi u BiH, većina ovih informacija proizilazi iz
						odluke (vidi Faza 1, Korak 10, ovog priručnika).</b></p>

						<p className="content_text"><b>Šema je obuhvatala četiri komponente, </b>od kojih je svaka imala svoju zasebnu
						finansijsku alokaciju, ciljeve, aktivnosti, izlazne rezultate, ishode i korisnike. Budući
						da se radi o složenoj šemi, dokument je morao biti vrlo jasan. </p>

						<p className="content_text">U izvornom dokumentu korišteni su ikonice i specifične upute i objašnjenja u okviru
						(box) kako bi čitatelja lakše vodile kroz dokument. U to vrijeme, ovakav se pristup
						smatrao inovativnim.</p>

						<p className="content_text"><b><u>Napomena: u skraćenom izvadku teksta iz originalne verzije navedeni su detalji za
						samo dvije od ukupno četiri komponente izvorne šeme:</u></b></p>

						<ul>

							<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>Poslovna infrastruktura </b></span></li>

							<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b> Integrirana podrška razvoju turizma </b></span></li>

						</ul>

			
                </div>




				<div className="yellow_box mt-50">
						<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/warrning.svg`} alt="lightbulb"></img> <b>VAŽNA NAPOMENA</b></p>
						
						<p className="content_text"><b><u>Svim</u> potencijalnim aplikantima savjetujemo da pažljivo pročitaju: </b></p>

						<ul>
							<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Poglavlje 1 – koje daje <b><u>OSNOVNI</u></b> pregled cijelog dokumenta i korisne informacije
							o cijeloj grant šemi;</span></li>

							<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Odjeljke 2.1.1 do 2.1.4 u Poglavlju 2 – koji utvrđuju opće kriterije i pravila koja se
							primjenjuju na sve aplikante</span></li>


							<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Odjeljak 2.1.7 na kraju Poglavlja 2 (odjeljci 2.2 do 2.5) i sve priloge – koji daju <b><u>BITNE</u></b> informacije za <b><u>sve</u></b> aplikante.</span></li>
						</ul>

						<p className="content_text"><b><u>Ukoliko ste zainteresirani za izradu i podnošenje projektnog prijedloga, morate
						proučiti i pridržavati se svih navedenih savjeta:</u></b></p>

						<ul>
							
						<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Za komponentu <b>Poslovna infrastruktura (neturistička)</b> pogledajte Odjeljak <b>2.1.6.1.</b></span></li>

						<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Za komponentu <b>Razvoj ljudskih potencijala - Lokalne inicijative za zapošljavanje
						i zapošljivost</b> pogledajte Odjeljak <b>2.1.6.2</b></span></li>


						<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Za komponentu <b>Integrirana podrška regionalnom/lokalnom turizmu </b>pogledajte
						Odjeljak <b>2.1.6.3</b></span></li>

						
						<li id="kontekst" className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Za komponentu <b>Ulaganje u unapređenje uvjeta i vanjskog okruženja proizvodnog
						sektora</b> pogledajte Odjeljak <b>2.1.6.4</b></span></li>

					</ul>
                </div>

			<p className="content_text blue" ><b>KONTEKST</b></p>

			<p className="content_text">Evropska unija je kroz svoju politiku “ekonomske i socijalne kohezije“ posvećena “smanjenju razlika
			u nivou razvoja različitih regija i zaostalosti najzapostavljenijih regija” (…)</p>

			<p id="cgs-1" className="content_text">Konkretno, ova šema se temelji na značajnim naporima koji su učinjeni u Bugarskoj i njezinoj
			sjeveroistočnoj regiji (SI) u proteklim godinama. To je obuhvatalo pažljivu pripremu različitih
			strateških dokumenata i utvrđivanje mogućih područja za intervencije. U sklopu pripreme ove
			šeme angažiran je tim konsultanata, čiji je zadatak bio da na osnovu sveobuhvatnog mapiranja
			postojećih projekata i razvojnih potreba SI regije izrade odgovarajući dizajn grant šeme. Na ovaj
			se način namjeravalo osigurati da se šemom podrže kvalitetni projekti u ključnim oblastima koji
			doprinose ekonomskom i društvenom razvoju SI regije i koji se mogu učinkovito realizirati u
			predviđenom periodu dodjele sredstava iz šeme.</p>


			<p className="content_text blue" ><b>CILJEVI GRANT ŠEME</b></p>
			<p className="content_text"><b><u>Opći ciljevi grant šeme su: (...)</u></b></p>

			<p  id="fs-1" className="content_text">1. <span className="ml-15">Poboljšati posebne faktore i uvjete koji podržavaju regionalni razvoj, naročito razvoj poslovanja i turizma, odnosno, koji ciljanim skupinama ukazuju na put do tržišta rada i dostupne
			mehanizme podrške, doprinoseći time regionalnoj konkurentnosti i socijalnoj koheziji (…)</span></p>

			<p className="content_text blue"><b>FINANSIJSKA SREDSTVA KOJA JE IZDVOJILO MINISTARSTVO</b></p>

			<p className="content_text pos-rel">Ukupni indikativni iznos dostupan u sklopu ovog javnog konkursa iznosi € 5,77 miliona (4,35
			miliona su sredstva iz programe Phare <Toolpit text="Program Phare jedan je od tri instrumenta pretpristupne pomoći koji finansira Evropska unija kako bi pomogla zemljama
			aplikantima iz centralne i istočne Evrope da se pripreme za pridruživanje Evropskoj uniji."></Toolpit> , a 1,42 miliona su sredstva iz državnog budžeta). Ugovorno
			tijelo zadržava pravo da ne dodijeli sva raspoloživa sredstva.</p>

			<p className="content_text">Ukupni indikativni iznos će se rasporediti kroz četiri komponente na sljedeći način: </p>
			

			<table id="table096 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-143 " />
					<col className="_idGenTableRowColumn-119 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Naziv komponente</p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Indikativni iznos  (u milionima eura)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Poslovna infrastruktura </p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">1,735</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Integrirana podrška razvoju turizma</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">2,885</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Razvoj ljudskih potencijala</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">Lokalne inicijative za zapošljavanje i zapošljivost</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">0,575</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Ulaganje u unapređenje uvjeta i vanjskog okruženja proizvodnog sektora</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">0,575</p>
						</td>
					</tr>
				</tbody>
			</table>

			<p className="content_text mt-25">U slučaju da u okviru određene komponente ne bude dovoljno dobrih projekata koji bi apsorbirali
			sredstva dodijeljena za tu komponentu, dio tih sredstava se može preusmjeriti za podršku dobrim
			projektima u okviru drugih komponenti.</p>

			<div className="yellow_box mt-50">
						<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/lupa.svg`} alt="lightbulb"></img> <b>NAPOMENA 2</b></p>
						

						<ul>
							<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Na prvih nekoliko stranica sljedećeg odjeljka date su osnovne polazne informacije koje aplikanti trebaju ODMAH znati, a to su informacije o <b>finansijskoj veličini granta.</b></span></li>

							<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />U ovom slučaju <b>dat je (finansijski) raspon, koji je prilično širok, </b>što ostavlja dovoljno prostora za naknadne odluke uprave, ali koji potencijalnim aplikantima
								omogućava da procijene (premda ugrubo) broj projekata koji će se potencijalno
								finansirati i do kojeg iznosa. </span></li>
						</ul>
                </div>

				<p className="content_text blue"><b>VELIČINA GRANTOVA </b></p>
				<p className="content_text">Svaki grant odobren u okviru ovog programa mora biti između sljedećeg minimalnog i maksimalnog
				iznosa:</p>

				<table id="table097 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-256 " />
					<col className="_idGenTableRowColumn-33 " />
					<col className="_idGenTableRowColumn-137 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-180 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Naziv komponente</p>
						</td>
						<td className="No-Table-Style CellOverride-180 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Minimalni iznos: EUR</p>
						</td>
						<td className="No-Table-Style CellOverride-180 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Maksimalni iznos: EUR</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-57 ">
							<p className="body ParaOverride-2 ">Poslovna infrastruktura </p>
						</td>
						<td className="No-Table-Style CellOverride-57 ">
							<p className="body ParaOverride-2 ">50.000</p>
						</td>
						<td className="No-Table-Style CellOverride-57 ">
							<p className="body ParaOverride-2 ">300.000</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-57 ">
							<p className="body ParaOverride-2 ">Integrirana podrška razvoju turizma</p>
						</td>
						<td className="No-Table-Style CellOverride-57 ">
							<p className="body ParaOverride-2 ">50.000</p>
						</td>
						<td className="No-Table-Style CellOverride-57 ">
							<p className="body ParaOverride-2 ">300.000</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-257 ">
						<td className="No-Table-Style CellOverride-57 ">
							<p className="body ParaOverride-2 ">Razvoj ljudskih potencijala</p>
							<p className="body ParaOverride-2 ">Lokalne inicijative za zapošljavanje i zapošljivost</p>
						</td>
						<td className="No-Table-Style CellOverride-57 ">
							<p className="body ParaOverride-2 ">20.000</p>
						</td>
						<td className="No-Table-Style CellOverride-57 ">
							<p className="body ParaOverride-2 ">50.000</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-57 ">
							<p className="body ParaOverride-2 ">Ulaganje u unapređenje uvjeta i vanjskog okruženja proizvodnog sektora</p>
						</td>
						<td className="No-Table-Style CellOverride-57 ">
							<p className="body ParaOverride-2 ">20.000</p>
						</td>
						<td className="No-Table-Style CellOverride-57 ">
							<p className="body ParaOverride-2 ">100.000</p>
						</td>
					</tr>
				</tbody>
			</table>

			<p className="content_text mt-25">Štaviše, ni jedan grant ne može biti veći od 90% ukupnih prihvatljivih troškova projekta (vidi,
			također, Odjeljak 2.1.4). Razlika se finansira iz vlastitih sredstava aplikanta ili sredstava iz drugih
			izvora, koji nisu budžet Evropske zajednice.</p>

			<p id="pjk" className="content_text">Aplikant mora predočiti jasno razložen budžet po stavkama, kojim opravdava traženi iznos granta. (…)</p>

			<h2 className="blue_overlay_heading mt-50" >PRAVILA JAVNOG KONKURSA </h2>

			<div className="yellow_box mt-50">

					<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/lupa.svg`} alt="lightbulb"></img> <b>NAPOMENA 3</b></p>
						
					<p className="content_text">Odjeljci u nastavku aplikantima daju ključne informacije na osnovu kojih će moći <b>gotovo odmah znati jesu li ili nisu prihvatljivi za ovu šemu. Ako je jasno da nisu prihvatljivi, </b> poželjno je da to znaju da ne moraju čitati dalje! (Vidi Faza 1, Korak 1.3 i posebno Faza 2, Korak 2.3 ovog priručnika.)</p>

					<p className="content_text"><u>Obratite pažnju na jasnoću savjeta koji se odnosi na profitne aktivnosti. </u> Ovo je dio
					koji često stvara konfuziju: u okviru ove konkretne šeme, profitne aktivnosti se
					ne mogu i neće podržati. (Sjetite se da u vrijeme izrade ove šeme opća pravila o
					državnoj pomoći još uvijek nisu bila u potpunosti donesena ili nisu bila adekvatno
					shvaćena, pa su takve aktivnosti u potpunosti isključene iz ove šeme).</p>

            </div>

			<p id="kriterijprih" className="content_text mt-40 pos-rel">Ove smjernice propisuju pravila za podnošenje, odabir i realizaciju projekata koji se finansiraju iz ovog programa, u skladu s odredbama praktičnog priručnika <Toolpit>Praktični vodič za postupke sklapanja ugovora koji se finansiraju iz zajedničkog budžeta evropskih zajednica u kontekstu vanjske pomoći”, dostupno na internetskoj stranici: dokument 42 na <a href="http://bit.ly/izvornidokumenti" target="_blank">http://bit.ly/izvornidokumenti</a></Toolpit> .</p>

			<p className="content_text blue" ><b>KRITERIJI PRIHVATLJIVOSTI </b></p>

			<p className="content_text">Prva četiri pododjeljka ovog odjeljka odnose se na opće kriterije prihvatljivosti koji se primjenjuju
			na projekte koji se odnose na sve četiri komponente pilot integrirane šeme ulaganja. Pored općih
			kriterija prihvatljivosti, u narednom odjeljku navedeni su posebni kriteriji za aplikante, partnere,
			aktivnosti i troškove za svaku pojedinačnu komponentu (vidi Odjeljak 2.1.6 u nastavku teksta).
			Aplikanti trebaju proučiti oba odjeljka i tamo navedene odgovarajuće vrste kriterija.</p>

			<p className="content_text">Pri tome treba voditi računa da se kriteriji prihvatljivosti odnose na: </p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Organizacije koje mogu tražiti grant;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Projekte za koje grant može biti dodijeljen;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Vrste troškova koji se mogu uzeti u obzir kod određivanja iznosa granta.</span></p>


			<div className="yellow_box mt-50">

				<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/warrning.svg`} alt="warrning"></img> <b>VAŽNO</b></p>
					
				<p id="paksmp" className="content_text"><b><em>U okviru pilot integrirane šeme ulaganja neće se dodjeljivati grantovi za bilo koji
				vid profitnih aktivnosti. Korisnici (vodeće organizacije i partneri) nemaju pravo
				ostvarivati nikakvu dobit iz projektnih aktivnosti. Ovi se uvjeti odnose na sve projekte
				koji se finansiraju u okviru četiri komponente pilot integrirane šeme ulaganja (…)</em></b></p>

			</div>


			<p className="content_text blue"><b>2.1.1 PRIHVATLJIVOST APLIKANATA: KO SE MOŽE PRIJAVITI</b></p>
			
			<p className="content_text"><b>(1) Da bi bio prihvatljiv za dodjelu granta, aplikant mora: </b></p>

			<p className="content_text">Biti neprofitno pravno lice; </p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Biti općina ili savez općina, organizacija koju je osnovala ili vodi općina, nevladina organizacija (npr.
			strukovna komora, podružnica, akreditirana javna organizacija za obuke) itd;</span></p>

			<p className="content_text d-flex d-flex-aac pos-rel"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Biti organizacija koja se nalazi u SI regiji ili organizacija koja ima registriranu podružnicu u SI
			regiji ili mora jasno pokazati odgovarajuće veze s SI regijom <Toolpit text="Aplikant ili partner(i) ili oboje moraju biti registrirani u SI regiji."></Toolpit> , odnosno, mora imati sredstva za
			realizaciju projekta u regiji sa održivim rezultatima. (…)</span></p>

			
			<div className="yellow_box mt-50">

				<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/warrning.svg`} alt="warrning"></img> <b>VAŽNO</b></p>
					
				<p className="content_text"><b>Ministarstva, državne agencije i njihove regionalne ispostave i subregionalne
				distrikti) nisu prihvatljivi kao aplikanti niti kao partneri.</b></p>

			</div>

			<p className="content_text"><b>(2) Potencijalni aplikanti ne mogu učestvovati u javnom konkursu niti im može biti dodijeljen
			grant:</b></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />ako su u stečaju ili likvidaciji, ako njima upravlja povjerenik kojeg je odredio sud, ako su sklopili
			sporazum s vjerovnicima, ako im je obustavljeno poslovanje, ako su predmet postupka u vezi s tim
			pitanjima, odnosno, ako su u bilo kojoj sličnoj situaciji koja proizilazi iz sličnog postupka propisanog
			domaćim zakonom ili propisima; (…)</span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />ako su u procesu ocjenjivanja na ovom ili ranijim javnim konkursima pokušali pribaviti povjerljive
			informacije ili utjecati na komisiju za ocjenjivanje ili ugovorni organ.
			(…)</span></p>




			<p className="content_text">Detaljna specifikacija aplikanata za svaku komponentu data je u <em>Odjeljku 2.1.6.</em></p>

			<p className="content_text pos-rel">Prema tome, oni moraju zadovoljiti iste kriterije prihvatljivosti kao aplikanti <Toolpit text="Jedini izuzetak od gore navedenih pravila prihvatljivosti odnosi se na mala i srednja preduzeća kao partnere u situacijama kada
			oni pružaju obuku na radnom mjestu (više pojedinosti u komponenti Razvoj ljudskih potencijala - lokalne inicijative za zapošljavanje i
			zapošljivost)."></Toolpit> <em>(vidi Odjeljak 2.1.1 ranije u tekstu, kao i Odjeljak 2.1. 6 u nastavku).</em></p>

			<p id="pppzksmpp" className="content_text">U projekt mogu biti uključene i druge organizacije kao saradnici. Saradnici imaju stvarnu ulogu u
			realizaciji projekta, ali ne mogu dobiti sredstva iz granta. Saradnici ne moraju ispunjavati kriterije
			prihvatljivosti iz <em>Odjeljka 2.1.1.</em></p>

			<p  className="content_text">(…)</p>



			<p className="content_text blue"><b>2.1.3 PRIHVATLJIVI PROJEKTI: PROJEKTI ZA KOJE SE MOŽE PODNIJETI PRIJAVA</b></p>
			<p className="content_text"><b><u>Trajanje</u></b></p>

			<p className="content_text">Projekt ne može trajati duže od 18 mjeseci.</p>

			<p className="content_text">Detaljna specifikacija trajanja projekta po pojedinačnim komponentama data je u Odjeljku 2.1.6.</p>

			<p className="content_text"><b><u>Sektori ili teme</u></b></p>

			<p className="content_text">Pilot integriranom šemom ulaganja potpomoći će se projekti u okviru sljedećih komponenti:</p>


			<p className="content_text">Komponenta 1: <span class="ml-20">Poslovna infrastruktura.</span></p>
			<p className="content_text">Komponenta 2: <span class="ml-20">Integrirana podrška razvoju turizma. </span></p>
			<p className="content_text">Komponenta 3: <span class="ml-20">Razvoj ljudskih potencijala - lokalne inicijative za zapošljavanje i zapošljivost.</span></p>
			<p className="content_text">Komponenta 4: <span class="ml-20">Ulaganje u unapređenje uvjeta i vanjskog okruženja za proizvodni sektor.</span></p>

			

			<p className="content_text"><b><u>Lokacija</u></b></p>

			<p className="content_text">Projekti se moraju realizirati u Bugarskoj, u SI regiji, na teritoriji sljedećih administrativnih jedinica: (…)</p>

			<p className="content_text"><b><u>Za različite komponente precizirane su različite aktivnosti:</u></b></p>
			

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Komponentom poslovne infrastrukture podržat će se ulaganja u fizičku infrastrukturu koja služi
			poslovnim lokacijama/zonama, čime će se unaprijediti okruženje za rad postojećih i privući nova
			preduzeća.</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Komponenta „Razvoj ljudskih potencijala - lokalne inicijative za zapošljavanje i zapošljivost“ fokusira
			se na lokalne, relativno male aktivnosti, kojima se odgovara na potrebu i koje su komplementarne
			s državnim/sektorskim intervencijama, a koje provode regionalni ili lokalni akteri - općine i
			neprofitne nevladine organizacije.</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Komponentom „Integrirane podrške razvoju regionalnog/lokalnog turizma“ podržat će se ulaganja
			u fizičku infrastrukturu (osnovni turizam), unapređenje uvjeta i vanjskog okruženja turističkog
			sektora, kao i u aktivnosti razvoja ljudskih resursa potrebnih za realizaciju projektnih aktivnosti i
			održivost njihovih rezultata.</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Komponentom „Ulaganja u unapređenje uvjeta i vanjskog okruženja za proizvodni sektor“ podržat
			će se projekti koji nude usluge podrške malim i srednjim preduzećima u sektoru proizvodnje ili
			trgovine uslugama, naročito novoosnovanim ili postojećim preduzećima.</span></p>

			<p className="content_text">Detaljna specifikacija prihvatljivih aktivnosti po pojedinačnim komponentama data je u Odjeljku 2.1.6.</p>
			
			<p className="content_text"><b>Sljedeće </b><u>vrste aktivnosti nisu prihvatljive:</u></p>


			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Pojedinačna sponzorstva za učešće u radionicma, seminarima, konferencijama, kongresima;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Pojedinačne stipendije za studij ili programe obuke;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Bilo kakve aktivnosti komercijalne prirode koje generiraju profit u korist aplikanta i/ili njegovih
			partnera;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Radnje koje potpadaju pod opće aktivnosti nadležnih državnih institucija ili službi državne uprave,
			uključujući i lokalnu upravu;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Radnje koje obuhvataju finansiranje rutinskih aktivnosti organa lokalnih vlasti, naročito u smislu
			stalnih troškova;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Radnje koje su započete prije potpisivanja ugovora o grantu;</span></p>
			
			<p className="content_text">Detaljna specifikacija neprihvatljivih aktivnosti po pojedinačnim komponentama data je u Odjeljku
			2.1.6.</p>

			<p className="content_text"><b><u>Broj prijedloga i grantova po aplikantu</u></b></p>


			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />aplikant može podnijeti samo jedan prijedlog po komponenti pilot integrirane šeme ulaganja i
			može biti partner u najviše jednom projektnom prijedlogu po komponenti;</span></p>

			<p id="pt" className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />aplikantu se može dodijeliti jedan grant po prijedlogu za svaku komponentu u okviru ovog javnog
			konkursa;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />jedan prijedlog može dobiti samo jedan grant koji se finansira iz sredstava EU.</span></p>

			<p className="content_text blue">2.1.4 PRIHVATLJIVOST TROŠKOVA</p>
			<p className="content_text">Za određivanje granta uzimaju se u obzir samo “prihvatljivi troškovi”. Oni su detaljno navedeni
			u nastavku teksta. Budžet, dakle, predstavlja procjenu troškova, ali i gornji prag „prihvatljivih
			troškova“. (…)</p>

			<p className="content_text"><b><u>Prihvatljivi direktni troškovi</u></b></p>
			<p className="content_text">Da bi troškovi u okviru ovog javnog konkursa bili prihvatljivi: </p>
			
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />oni moraju biti neophodni za provođenje projekta, predviđeni ugovorom koji je priložen uz ove
			smjernice i u skladu s načelima dobrog finansijskog upravljanja, naročito u pogledu isplativosti i
			troškovne učinkovitosti;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />oni moraju stvarno nastati kod korisnika ili njihovih partnera tokom perioda realizacije projekta,
			kako je definirano u Članu 2. posebnih uvjeta, bez obzira na vrijeme kada ih je korisnik ili partner
			platio - to ne utječe na prihvatljivost troškova završne revizije;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />oni moraju biti uknjiženi u računovodstvenim ili poreznim knjigama korisnika ili partnera, moraju
			se moći utvrditi i provjeriti, te moraju biti potkrijepljeni originalnom popratnom dokumentacijom.</span></p>

			<p className="content_text">Shodno ovim uvjetima i poštujući pravila postupka dodjele ugovora, prihvatljivi direktni troškovi
			obuhvataju: (…)</p>

			<p className="content_text">Detaljnija specifikacija prihvatljivih troškova po pojedinačnim komponentama data je u <u>Odjeljku 2.1.6.</u></p>

			<p className="content_text"><b><u>Neprihvatljivi troškovi</u></b></p>
			<p className="content_text">Sljedeći troškovi nisu prihvatljivi:</p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Dugovanja i rezerve za gubitke;</span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Troškovi kamata;</span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Stavke koje se već finansiraju iz drugih sredstava.</span></p>

			<p id="uzppbb" className="content_text">(…)</p>

			<p className="content_text">Kupovina zemljišta ili objekata, izuzev kad je to neophodno za direktnu realizaciju projekta, u kom
			slučaju se vlasništvo mora prenijeti na krajnje korisnike do kraja projekta. (…)</p>

			<p className="content_text blue"><b>2.1.5 UPUTA ZA PREDSTAVLJANJE PRILOGA B - BUDŽET</b></p>

			<div className="yellow_box mt-50">

				<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/warrning.svg`} alt="warrning"></img> <b>VAŽNO</b></p>
					
				<p className="content_text"><b>Koristite (u Excelu) pripremljenu tablicu budžeta. </b></p>
				<p className="content_text">Budžet se mora predstaviti u skladu s prilogom B obrasca za prijavu.</p>
				<p className="content_text">Ukoliko želite dodatno razložiti budžet, te informacije radi jasnoće treba staviti u
				zasebno umetnute redove, kao podstavke.</p>

			</div>

			<p className="content_text"><b>A. <u>Ljudski resursi (...)</u></b></p>
			<p className="content_text"><b>B. <u>Putni troškovi (…) itd.</u></b></p>
			
			<div className="yellow_box mt-50">
						<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/lupa.svg`} alt="lupa"></img> <b>NAPOMENA 4</b></p>
						
						<ul>
							<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Nakon što su pojasnile nacrt cijele šeme, <b> smjernice se sada fokusiraju na
							pojedinačne komponente;</b></span></li>

							<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <span className="blue">Napominjemo da smo za potrebe studije slučaja </span>naveli samo dvije komponente.
							U nastavku teksta predstavićemo prvo komponentu “Poslovne infrastrukture”, a
							potom ćemo obraditi komponentu “Integrirane podrške razvoju turizma;</span>
							</li>

							<li className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <span className="blue"> Treba imati u vidu da su </span> u pripremi ove konkretne šeme </span> <b>dizajneri napravili opsežnu analizu potražnje i na taj način procijenili koje su vrste projekata bile moguće u relevantnom periodu.</b>
							</li>
						</ul>

						<p id="okgsippksonk" className="content_text">Iz tog razloga je fokus svake pojedinačne komponente usko definiran: <b>dizajneri
						znaju </b> kakve projekte žele podržati i koje projekte mogu dobiti u okviru zadatog
						roka. Planirali su, također, da će finansirati najmanje 40% svih projektnih prijedloga.
						Da bi to osigurali, morali su <b>izbjeći prekomjeran broj loših projekata. </b> Na koncu
						je finansirano više od 50% projekata, a broj dostavljenih projektnih prijedloga je
						bio znatno manji nego u ranijim javnim konkursima. To je kao posljedicu imalo <b>smanjeno nepotrebno radno opterećenje među članovima tima odgovornim za
						realizaciju.</b></p>
                </div>

			<p id="pi" className="content_text blue mt-80"><b>2.1.6 OPIS KOMPONENTI GRANT ŠEME I POSEBNIH PRAVILA KOJA SE ODNOSE NA KOMPONENTE </b></p>
			<p id="k2" className="content_text blue">2.1.6.1 POSLOVNA INFRASTRUKTURA </p>
			<p className="content_text">2.1.6.1.1 KONTEKST</p>

			<div className="yellow_box mt-50">
						<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/lupa.svg`} alt="lupa"></img> <b>NAPOMENA 5</b></p>
						
						<p className="content_text"><b>Kontekst ovog odjeljka je kratak, </b> svega četiri pasusa, ali u njemu aplikanti mogu
						naći dovoljno jasno <b>obrazloženje </b>za ovu komponentu šeme. To je, također, odraz
						politike, koja je u potpunosti usklađena sa širom državnom strategijom regionalnog
						razvoja. </p>
            </div>


			<p className="content_text">Uprkos značajnom doprinosu SI regije nacionalnoj ekonomiji i njezinoj relativno raznovrsnoj
			industrijskoj strukturi, ekonomski razvoj SI regije je problematičan zbog BDP-a po glavi stanovnika,
			koji je 15% niži od nacionalnog prosjeka, prekomjerno zastupljenog poljoprivrednog sektora i
			najveće stope nezaposlenosti u zemlji. Skoro svi razvojni indikatori ukazuju na značajne razlike
			između razvijenijeg okruga Varna i ostalih dijelova regije. Jedan od razloga ove nepovoljne situacije
			su razlike u infrastrukturi, koja je u službi privrednih subjekata u pojedinim dijelovima SI regije.</p>


			<p className="content_text">Infrastruktura je jedna od temeljnih komponenti ekonomske aktivnosti, a njezina dostupnost
			i kvalitet značajno utječu na konkurentnost pojedinačnih preduzeća i odluke o ulaganjima.
			Povećana javna ulaganja u infrastrukturu rezultiraju povećanjem stope povrata na privatni kapital,
			veću produktivnost, veću stopu privatnog ulaganja - a sve to povećava stopu stvaranja bogatstva
			i dinamiku regionalnog razvoja. (…)</p>


			<p className="content_text">Relativno razvijene infrastrukturne mreže (ceste, telekomunikacije, električna energija itd.) u
			regiji suočavaju se s problemom lošeg održavanja. U nekim slučajevima, nedovršena infrastruktura
			na lokalnom nivou stvara nepovoljno okruženje za razvoj (težak pristup, komunalni nedostaci,
			neučinkovit rad i povećani troškovi poslovanja itd.) i, u konačnici, smanjuje konkurentnost i
			atraktivnost za ulaganja. Iako su u SI regiji nastanjene mnoge poslovne zone (industrijske zone i
			druge koncentracije preduzeća), njihova je infrastruktura često zastarjela i loše održavana.</p>


			<div className="yellow_box mt-50">
						<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/lupa.svg`} alt="lupa"></img> <b>NAPOMENA 6</b></p>
						
						<p className="content_text">Prethodni pasus jasno ukazuje da fokus ove komponente NIJE nova transportna
						infrastruktura već mali, ali značajni radovi, koji poboljšavaju pristup ključnim poslovnim
						lokacijama. U drugom dijelu se navodi da poslovnu infrastrukturu treba vrlo usko
						tumačiti: ulaganja moraju biti usko povezana s poslovnim potrebama (općine su često
						predlagale projekte koji su imali malo veze s neposrednim poslovnim potrebama). </p>
            </div>	

			<p className="content_text">(…)</p>
			<p id="c2" className="content_text">Ova se grant šema fokusira na podršku integriranom poboljšanju ključnih elemenata poslovne
			infrastrukture na ograničenom broju poslovnih (industrijskih) lokacija, gdje bi otklanjanje
			nedostataka u lokalnoj poslovnoj infrastrukturi moglo imati značajan utjecaj na lokalni i regionalni
			privredni razvoj.</p>



			<p className="content_text"><b>2.1.6.1.2 CILJEVI</b></p>

			<p className="content_text"><b>Cilj </b>ove komponente je povećati konkurentnost poslovanja SI regije i poboljšati njezino okruženje
			kroz podršku ulaganjima u razvoj ključnih elemenata lokalne poslovne infrastrukture.</p>

			<p className="content_text">Specifični <b>ciljevi </b>komponente “Poslovne infrastrukture” su:</p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Poboljšati pristup poslovnim lokacijama / zonama i njihovu povezanost s glavnom cestovnom
			infrastrukturom;</span></p>

			<p id="vpp2" className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Poboljšati funkcioniranje poslovnih lokacija / zona kroz razvoj njihovih komunalnih usluga
			(komunalne infrastrukture).</span></p>

			
			<p className="content_text"><b>2.1.6.1.3 VRSTA PRIHVATLJIVIH PROJEKATA </b></p>
			<p className="content_text">Ovom komponentom grant šeme podržat će se ulaganja u fizičku infrastrukturu koja služi
			poslovnim lokacijama/zonama, čime će se unaprijediti okruženje za rad postojećih i privući nova
			preduzeća.</p>

			<p className="content_text"><b>Da bi bili prihvatljivi, projekti: </b></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Trebaju biti direktno <b>usmjereni na poslovne lokacije/zone </b>i dobro opravdane potrebama
			poslovnih subjekata;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Trebaju obuhvatati radove koji se vrše <b>samo na javnoj (općinskoj ili državnoj) imovini.</b></span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />su usmjereni <b>na postojeće industrijske lokacije ili zone gdje je prisutna veća koncentracija
			preduzeća</b></span></p>


			<p className="content_text pos-rel"><span className="mark">[Napomena: Prijave koje se odnose na projekte preko 100,000 eura ovo opravdavaju ispunjavanjem
			obrasca za procjenu potražnje i izvodljivosti - Prilog G];</span> <Toolpit text="Veći projekti trebaju
			dati detaljnije opravdanje u odnosu na manje projekte. To je logično."></Toolpit></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> su jasno pokazali utjecaj na poslovne lokacije;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>su integrirani,</b> odnosno obuhvataju više vrsta infrastrukture,
			čime se postiže sinergija;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> donose korist za više od jedne općine;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> pokazuju dosljednost s posebnim ciljem, prioritetom ili mjerom
			u važećem regionalnom ili lokalnom planu / strategiji.</span></p>
			
			<p className="content_text"><b>Aktivnosti koje se mogu finansirati u okviru ove komponente mogu obuhvatati sljedeće: </b></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Izgradnju, rekonstrukciju i rehabilitaciju ceste i povezane cestovne infrastrukture, čime se
			poboljšava pristup poslovnim lokacijama; </span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Rehabilitaciju, rekonstrukciju i proširenje postojećeg sistema vodovoda i kanalizacije poveznog sa
			ili unutar poslovnih zona;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Ugradnju malih uređaja za pročišćavanje otpadnih voda i lokalnih postrojenja za tretman otpadnih
			voda (prvi stepen) vezano za rad industrijskih zona;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Ugradnju telekomunikacijske infrastrukture potrebne za poboljšanje/daljnji razvoj poslovnih zona
			i lokacija;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Popratnu infrastrukturu unutar poslovnih zona (npr. osvjetljenje, pločnici, zelene površine itd.);</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><span className="mark">Usluge nadzora (u vezi s građevinskim radovima). </span></span></p>

			<p className="content_text d-flex d-flex-aac pos-rel"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Uvrštavanje aktivnosti s gore navedene liste je dopušteno i poželjno, ali njihovo kombiniranje treba
			biti logično i predstavljati odgovarajuće sinergije. <span className="mark">Sve bi aktivnosti trebale doprinijeti poboljšanju
			poslovnog okruženja. Podržat će se logički povezani projekti (projekti moraju biti usmjereni,
			s jasno definiranim ciljevima i aktivnostima koje su neophodne za njihovo ostvarivanje), a <b>ne
			grupa raznih, uzajamno nepovezanih aktivnosti. </b> +</span> <Toolpit text="Ključno zapažanje:
			previše je projekata
			koji su sastavljeni
			od tzv. “prihvatljivih
			aktivnosti”. Projekt
			mora biti koherentan."></Toolpit></span></p>

			<p className="content_text">Projekti moraju ispunjavati sve zakonske, građevinske i ekološke zahtjeve (<em>vidi, također, Odjeljak 2.1.7</em>)</p>
			<p className="content_text">Sljedeće aktivnosti su <b>naročito neprihvatljive </b>u okviru ove komponente </p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Aktivnosti usmjerene na privatno poslovanje i objekte; </span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Aktivnosti usmjerene na poslovanje i objekte koji generiraju profit;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Aktivnosti usmjerene na turistička mjesta (budući da su takve aktivnosti prihvatljive u okviru zasebne komponente ove grant šeme);</span></p>

			<p id="vg2" className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Pripremne studije, projektiranje ili druge pripremne aktivnosti za radove koji se realiziraju unutar
			projekta.</span></p>

			<p className="content_text"><b>2.1.6.1.4 VELIČINA GRANTA</b></p>
			<p id="pa2" className="content_text">Svaki grant dodijeljen u okviru ove komponente mora biti između sljedećeg minimalnog i
			maksimalnog iznosa:</p>
			<p className="content_text">Minimalni iznos: 50.000 € - Maksimalni iznos: 300.000 €.</p>


			<p  id="p2" className="content_text"><b>2.1.6.1.5 PRIHVATLJIVOST APLIKANATA</b></p>
			<p className="content_text">Da bi bili prihvatljivi za podršku iz ove grant šeme, aplikanti moraju biti općine iz SI regije.</p>


			<p className="content_text"><b>2.1.6.1.6 PARTNERSTVO</b></p>
			<p className="content_text">Aplikanti mogu nastupiti samostalno ili u konzorciju s partnerskim organizacijama. Prednost se,
			međutim, daje partnerstvima.</p>

			<p className="content_text"><b>Prihvatljivi partneri</b></p>
			<p className="content_text">Općine ili konzorcij općina.</p>

			<p className="content_text"><b>Saradnici</b></p>
			<p id="t2" className="content_text">Saradnici mogu biti, između ostalog, regionalni savezi općina, javna komunalna preduzeća,
			regionalni ogranci Agencije za ceste, mala i srednja preduzeća nastanjena u pomenutim poslovnim
			zonama, privredne komore, granska udruženja.</p>

			<p className="content_text"><b>2.1.6.1.7 TRAJANJE</b></p>


			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Projekti u okviru ove komponente ne mogu trajati duže od 18 mjeseci.</span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />U toku trajanja projekta, sama izgradnja ne može trajati duže od 14 mjeseci.</span></p>
			<p id="pt2" className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Aplikant treba voditi računa o trajanju svih neophodnih procedura za realizaciju njegovog projekta:
			projektiranje, odobravanje projekta, izdavanje odobrenja, nabavka radova, odobrenja nakon
			završetka građevinskih radova itd.</span></p>

			<p className="content_text"><b>2.1.6.1.8 PRIHVATLJIVI TROŠKOVI</b></p>
			<p className="content_text"><b>Prihvatljivi troškovi </b> specifični za komponentu „poslovne infrastrukture“ <span className="mark"> obuhvataju:</span></p>



			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Troškovi građevinskih radova na rekonstrukciji / rehabilitaciji ili
			izgradnji cesta;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Troškovi rekonstrukcije / rehabilitacije vodovodnog i kanalizacionog sistema i ugradnje malih
			postrojenja za tretman otpadnih voda;</span></p>

			<p id="nt2" className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Troškovi ostalih poboljšanja infrastrukture u skladu s tehničkim i detaljnim projektom, koji su dati
			u prilogu uz obrazac prijave;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Troškovi nadzora nad radovima.</span></p>


			<p className="content_text"><b>2.1.6.1.9 NEPRIHVATLJIVI TROŠKOVI</b></p>
			<p className="content_text">U posebnom kontekstu komponente „poslovne infrastrukture“ sljedeći troškovi nisu prihvatljivi: </p>



			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Nabavka usluga (izuzev nadzora); </span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Nabavka zaliha;</span></p>

			<p id="iprlt" className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Troškovi pripremnih studija, projektiranja ili drugih pripremnih aktivnosti za radove koji se provode
			unutar projekta.</span></p>

			<p className="content_text">(…)</p>

			<p className="content_text blue mt-50">2.1.6.3 INTEGRIRANA PODRŠKA REGIONALNOM/LOKALNOM TURIZMU</p>

		
			<div className="yellow_box mt-50">
						<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/lightbulb.svg`} alt="lightbulb"></img> <b>VAŽNO</b></p>
						
						<p id="k3" className="content_text">U ovom se odjeljku daju važne informacije u vezi sa komponentom grant šeme koja
						se tiče integrirane podrške regionalnom/lokalnom turizmu. Ovaj odjeljak treba
						čitati zajedno s općim pravilima i zahtjevima navedenim u odjeljcima 2.1.1 do 2.1.4. </p>
            </div>


			<p className="content_text"><b>2.1.6.3.1 KONTEKST</b></p>

			<p className="content_text pos-rel">Sjeveroistočna regija ima najrazvijeniji i najdinamičniji turistički sektor među svim NUTS 2 <Toolpit>NUTS klasifikacija (Nomenklatura prostornih jedinica za statistiku) hijerarhijski je sistem za identifikaciju i klasifikaciju
			ekonomske teritorije unutar EU. Prema njoj se regije EU dijele na tri nivoa u svrhu statističkih i analitičkih obrada i oblikovanja
			regionalnih politika. NUTS 2 regije su osnovne regije na koje se primjenjuju regionalne politike. Za više detalja, vidi: <a href="http://ec.europa.
			eu/eurostat/web/nuts">http://ec.europa.
			eu/eurostat/web/nuts</a> </Toolpit>  regijama u Bugarskoj, a turizam se smatra sredstvom za poticanje razvoja regije u cjelini.</p>

			<p className="content_text">Međutim,
			opći nacionalni problemi u sektoru turizma još su izraženiji u SI regiji: ekstremna koncentracija
			na crnomorskoj obali i zavisnost od morsko-rekreativne ponude, što je povezano s problemima
			prekomjernog razvoja, pritisak na infrastrukturu i rizik nosivosti, velike sezonske oscilacije,
			ograničena ponuda turističkih aktivnosti, izrazito velika koncentracija na koristi od razvoja
			turizma i nedovoljno iskorištavanje postojećih potencijala u drugim dijelovima regije. Razlozi ove, relativno problematične, slike su različiti i obuhvataju, između ostalog, nerazvijenost osnovne i
			turističke infrastrukture, strukturu samog turističkog sektora (kojim dominiraju mala i srednja
			preduzeća koja imaju ograničen utjecaj na razvoj infrastrukture i oskudna sredstva za vršenje
			nezavisnih marketinških aktivnosti), marketinške aktivnosti usmjerene uglavnom na morska
			odmarališta, ograničena ponuda regionalnih proizvoda, slabe veze između mora i unutrašnjosti,
			nedostatak regionalnih strategija i struktura za razvoj turizma (iako je turizam u većini općinskih
			i okružnih planova i strategija prepoznat kao prioritet). S druge strane, unutrašnjost regije nudi
			postojeće i potencijalne atrakcije. Postojeća turistička potražnja za sekundarnim izletima na obalu
			jedinstvena je prilika u odnosu na druge regije.</p>

			<p className="content_text pos-rel">Da bi se turizam optimalno iskoristio za privredni rast regije (...) potreban je integriraniji i regionalni
			pristup. To podrazumijeva širu distribuciju turističkog razvoja i njegove koristi na područja koja „nisu
			uz obalu“, čime bi se doprinijelo diversifikaciji ponude onim turistima koji borave u morskim
			odmaralištima. Time će se smanjiti sezonske oscilacije i učinkovitije ciljati nacionalna i međunarodna
			tržišta. Ovaj je pristup podudaran s politikom turizma i regionalnog razvoja, koji je artikuliran u
			postojećim političkim, strateškim i programskim dokumentima <span className="mark">uključujući i regionalni operativni
			program za 2004-2006.). </span> <Toolpit text="Napomena:
			komponenta je
			posebno relevantna
			za ključnu politiku."></Toolpit></p>

			<p id="c3" className="content_text">Ovom se komponentom nastoji pomoći posebno onim turističkim
			subjektima koji predviđaju sveobuhvatno rješenje za svoje potrebe,
			a cilj joj je omogućiti ovaj pristup čak i na nivou projekta. (…) U tom
			smislu, poželjni su projekti kojima su obuhvaćena: a) ulaganja u osnovnu
			i turističku infrastrukturu, popraćena informacijama, marketingom
			i promocijom ili b) projekti koji uglavnom predviđaju „meke“ (eng.
			„soft“) aktivnosti (informacije, marketing, promocije, istraživanja itd.), uz potrebno ulaganje u
			infrastrukturu i opremu. (…)</p>

			<p className="content_text"><b>2.1.6.3.2 CILJEVI</b></p>

			<p className="content_text">Cilj ove komponente je povećati turističku konkurentnost regije i doprinos turizma regionalnom
			razvoju kroz proširivanje asortimana turističkih proizvoda i geografsku i sezonsku distribuciju
			turizma.</p>

			<p className="content_text">Specifični ciljevi komponente „Integrirana podrška regionalnom / lokalnom turizmu“ su: </p>


			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Poboljšati uvjete za privlačenje i usluživanje posjetitelja u područjima / općinama s potencijalom
			za razvoj turizma kroz lokalno usmjerene projekte koji podupiru razvoj osnovne i turističke
			infrastrukture, atrakcije i promociju.</span></p>

			<p id="vpp3" className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Unaprijediti koordinaciju, upravljanje i marketing, fokusirajući se na relevantne proizvode i usluge
			od regionalne važnosti i opsega, uključujući povezivanje obale i unutrašnjosti regije SI kroz
			regionalno usmjerene projekte koji podupiru regionalne proizvode i usluge, od kojih će korist imati
			više općina i okruga (marketing i promocija, istraživanja, informacijski / rezervacijski sistemi i
			komplementarna infrastruktura).</span></p>

			<p className="content_text"><b>2.1.6.3.3 VRSTA PRIHVATLJIVIH PROJEKATA</b></p>

			<p className="content_text d-flex d-flex-aac pos-rel">Komponentom grant šeme koja se bavi integriranom podrškom regionalnom/lokalnom turizmu podržat će se ulaganja u fizičku infrastrukturu (osnovna, turistička), kojom će se poboljšati uvjeti
			i vanjsko okruženje turističkog sektora kao i ulaganja u aktivnosti usmjerene na razvoj ljudskih
			potencijala koje su neophodne za realizaciju projektnih aktivnosti i osiguranje održivosti rezultata.
			Grantom će se podržati samo nekomercijalne (neprofitne) aktivnosti, između ostalog, razvoj/
			poboljšanje objekata i/ili atrakcija. Ovom se komponentom neće podržavati privatna preduzeća,
			ali će se stvoriti okruženje koje će omogućiti privatna ulaganja.<Toolpit text="Da bi se izbjegla svaka
			moguća zabuna,
			potrebno je dati detaljan
			opis i objašnjenje
			prihvatljivih aktivnosti."></Toolpit> </p>

			<p className="content_text d-flex d-flex-aac"><b>Da bi bili prihvatljivi, projekti trebaju: </b></p>

			<p className="content_text d-flex d-flex-aac pos-rel"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> biti direktno usmjereni na<span className="mark"> <b>turizam</b>, artikulirani kao posjete
			nerezidenata (vanjski posjetitelji za općinu, kao minimalni zahtjev) s
			ekonomskim učinkom (odnosno, kao ekonomski sektor koji općini/
			okrugu donosi prihode);</span><Toolpit text="Obratite pažnju na precizno opisane k oristi koje se
			očekuju od projekata. Vrsta turista je već definirana:
			oni koji donose nove prihode lokalnom području/regiji."></Toolpit></span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />obuhvatati radove koji se vrše na <b>javnoj (općinskoj ili državnoj)
			imovini.</b></span></p>

			<p className="content_text"><b><u>Prednost će se dati: </u></b> projektima koji:</p>

			
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />su usmjereni na <b>mjesta i područja s potencijalom za razvoj turizma, </b> odnosno, koja imaju dokazanu
			ili potencijalnu sposobnost da kreiraju zaokružen (sveobuhvatni) turistički proizvod (ili da budu
			dijelom takvog proizvoda) i privuku posjetitelje te razviju ekonomski održiv turizam;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />primjenjuju <b>integrirani i regionalni pristup</b> koji se temelji na <b>zdravoj razvojnoj logici </b> i koji pruža <b>regionalne, a ne lokalne rezultate</b> (odnosno, donosi korist za više općina i okruga - bilo kroz
			kreiranje /poboljšanje lokalnih proizvoda koji se prodaju na regionalnom nivou bilo kroz razvoj
			regionalnih proizvoda i usluga koji po potrebi zahtijevaju poboljšanje lokalne infrastrukture; </span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>dopunjuju </b> postojeće atrakcije, sadržaje i turističke aktivnosti na tom području i koji koriste
			potencijal za stvaranje <b>grupa uzajamno komplementarnih turističkih atrakcija i sadržaja</b> („klasteri“); </span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />su <b>usmjereni na područja u unutrašnjosti </b> tj. na općine i okruge, a ne na dobro razvijena primorska
			odmarališta i komplekse,</span> ili <b>koji omogućavaju uvezivanje </b> odmarališta sa turističkim
			područjima u unutrašnjosti;</p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />su <b>nastavak </b> drugih (okončanih) projekata;</span></p>

			<p className="content_text d-flex d-flex-aac pos-rel"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />su jasno pokazali <span className="mark">usklađenost s nekim specifičnim ciljem, prioritetom ili mjerom nedavno
			usvojenog regionalnog ili lokalnog plana/strategije.</span><Toolpit text="Projekti moraju biti usklađeni s
			lokalnim razvojnim planovima."></Toolpit></span></p>
			

			<p className="content_text pos-rel"><b>Projekti koji se mogu finansirati u okviru ove komponente mogu obuhvatati </b><span className="mark">(specifične aktivnosti
			pobrojane pod svakom vrstom projekta ne treba smatrati kao konačnu i iscrpnu listu, već samo
			kao primjere):</span><Toolpit text="Ova lista nije iscrpna."></Toolpit></p>

			<p className="content_text"><span className="blue">c. </span> <span className="ml-15">rekonstrukciju, rehabilitaciju ili proširenje pristupnih cesta i povezane
			cestovne infrastrukture sa mjestima od turističkog interesa/turističkim atrakcijama;</span></p>

			<p className="content_text"><span className="blue">d. </span> <span className="ml-15">rekonstrukciju, rehabilitaciju ili proširenje komunalne infrastrukture neophodne za korištenje
			atrakcija i razvoj turističkih mjesta/ područja / odmarališta (npr. vodovod, kanalizacija,
			električna energija, telekomunikacijska oprema, uključujući pristup internetu), izgradnju,
			rekonstrukciju ili obnovu infrastrukture kojom se poboljšava okoliš i funkcioniranje atrakcija
			(npr. parkirališta, pločnici, uređenje okoliša, zelene površine, parkovi, mokri čvorovi, rasvjeta,
			kontejneri za otpad itd.);</span></p>

			<p className="content_text"><span className="blue">e. </span> <span className="ml-15">izgradnju, rekonstrukciju, obnovu/nadgradnju i proširenje pješačkih, planinskih, jahačkih i
			biciklističkih staza, mjesta za piknike, objekata za izlaganje, neprofitnih kulturnih i prirodnih
			atrakcija kao dijela lokalnog turističkog proizvoda (npr. muzeji, kulturni centri), neprofitnih
			sadržaja za djecu, zabavnih parkova i sportskih sadržaja, uključujući infrastrukturu za vodene
			sportove i aktivnosti na rijekama i akumulacijama u unutrašnjosti (izvan morskih odmarališta)
			itd.</span></p>

			<p className="content_text"><span className="blue">f. </span> <span className="ml-15">rekonstrukciju, obnovu i/ili opremanje turističkih informativnih centara (uključujući hardver
			i softver);</span></p>

			<p className="content_text"><span className="blue">g. </span> <span className="ml-15">označavanje cesta, pješačkih, biciklističkih, planinskih staza/puteva, područja oko atrakcija,
			plakati, navigacijski sistemi, informativni panoi itd;</span></p>

			<p className="content_text"><span className="blue">h. </span> <span className="ml-15">izradu, štampu i distribuciju karata i vodiča za rute i područja, priručnika s pojedinostima o
			objektima i uslugama koje se nude na tom području;</span></p>

			<p className="content_text"><span className="blue">i. </span> <span className="ml-15">organizaciju događaja (kao turističkih atrakcija) (npr. festivali, događaji na otvorenom, sportskih
			takmičenja, folklornih događaja, predstavljanje lokalnih/regionalnih tradicija, kulinarskih
			specijaliteta itd.) od regionalnog i nacionalnog značaja, predstavljanje zanata, izložbe, kao i
			razvoj programa izlaganja i tumačenja;</span></p>

			<p className="content_text"><span className="blue">j. </span> <span className="ml-15">promociju aktivnosti, uključujući pripremu promotivnih materijala kao što su brošure, plakati,
			mape, razglednice itd. i njihovu distribuciju turističkim agencijama, turističkim operaterima,
			hotelima u regiji, potrošačima itd; razvoj audiovizuelnih materijala i multimedijskih proizvoda;
			izradu internetske stranice; organizaciju i sudjelovanje na regionalnim i nacionalnim
			turističkim sajmovima i izložbama, pokusna putovanja, pozivanje i ugošćavanje putničkih
			agencija, turoperatora, putopisaca i fotografa, novinara, agencija za e-marketing itd;</span></p>

			<p className="content_text"><span className="blue">k. </span> <span className="ml-15">izradu regionalnog proizvoda, marketing i planiranje aktivnosti za grupu općina i/ili okruga
			kao što su istraživanja tržišta, popis atrakcija, aktivnosti i usluga, izradu baza podataka, izradu
			informacijskih i rezervacijskih sistema, e-marketing, distribuciju relevantnih informacija
			lokalnom/regionalnim turističkim subjektima, koncepte za regionalni turistički proizvod,
			brendiranje i uvođenje jedinstvenih sistema označavanja, strategije razvoja turizma itd.</span></p>


			<p className="content_text pos-rel">Prijavljeni projekti <span className="mark">trebaju sadržavati kombinaciju nekih od pomenutih aktivnosti koje su
			komplementarne i koje se temelje na zdravoj razvojnoj logici.</span> <Toolpit text="Obratite pažnju na ovaj bitni uvjet.
			Sredstva se ne mogu tražiti za
			projekte koji podrazumijevaju samo
			jednu aktivnost (u ovoj oblasti to je
			najčešće infrastruktura). Da bi se
			osiguralo da se prijave samo dobri
			projekti, ubačena je ova ključna
			rečenica, jer će samo ti projekti imati
			potencijal da ostvare potrebne ishode."></Toolpit> Podržat će se logički
			povezani projekti (projekti moraju biti usmjereni, s
			jasno definiranim ciljevima i aktivnostima koje su
			neophodne za njihovo ostvarivanje), a <b>ne grupa
			raznih, uzajamno nepovezanih aktivnosti.</b> </p>

			<p className="content_text d-flex d-flex-aac pos-rel"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Svi projektni prijedlozi <span className="mark"><b>trebaju obuhvatiti
			neinfrastrukturne aktivnosti</b> (vrste koje su naznačene
			na listi prihvatljivih projekata, od vrste f) do vrste j) i
			najmanje 50% prihvatljivih troškova treba se odnositi
			na radove (gore naznačene vrste aktivnosti <Toolpit text="Obratite pažnju na izuzetno preciziran
			uvjet. Ovo je moguće isključivo zbog
			prethodnog ispitivanja potražnje."></Toolpit>,  od vrste a
			do vrste e).</span><Toolpit text="Projekti koji se odnose samo na infrastrukturu imaju
			mali razvojni učinak. Stoga ih treba optimizirati
			kroz prateće, neinfrastrukturne aktivnosti."></Toolpit></span></p>
			



			<p className="content_text">Projekti moraju ispuniti sve zakonske, građevinske i okolinske zahtjeve;</p> 
			<p className="content_text">Sljedeće aktivnosti se smatraju <b>naročito neprihvatljivim </b> u okviru ove komponente:</p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Aktivnosti usmjerene na atrakcije i objekte u privatnom vlasništvu;</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Aktivnosti usmjerene na profitne atrakcije i objekte;</span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Pripremne studije, projektiranje i druge pripremne aktivnosti za radove koji se provode unutar
			projekta;</span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Smještajni i ugostiteljski objekti;</span></p>
			<p className="content_text d-flex d-flex-aac pos-rel"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><span className="mark">Objekti koji se uglavnom koriste za općinske svrhe i koje
			koriste mještani lokalne zajednice (npr. škole u blizini
			historijskih objekata);</span><Toolpit text="Isključeni su ne zato
			što nisu korisni već
			zato što nemaju
			turističku funkciju i
			ne mogu generirati
			nove prihode te stoga
			nisu relevantni u
			kontekstu politike ili
			programa."></Toolpit></span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Učešće na međunarodnim turističkim sajmovima i izložbama
			(inostranstvo);</span></p>
			<p id="vg3" className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Komercijalne reklamne aktivnosti za pojedinačna preduzeća;</span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Sveobuhvatni turistički vodiči i opće informativne publikacije.</span></p>

			<p className="content_text"><b>2.1.6.3.4 VELIČINA GRANTOVA</b></p>

			<p id="pa3" className="content_text">Svaki grant odobren u okviru ove komponente mora biti između sljedećeg minimalnog i
			maksimalnog iznosa:</p>

			<p className="content_text">Minimalni iznos: 50.000 € - Maksimalni iznos: 300.000 €.</p>


			<p className="content_text"><b>2.1.6.3.5 PRIHVATLJIVI APLIKANTI</b></p>

			<p className="content_text">Da bi imali pravo na grant, aplikanti moraju biti:</p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Općine; </span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Savezi općina; </span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Regionalne, lokalne turističke organizacije ili podružnice turističkih organizacija (u skladu sa
			Zakonom o turizmu);</span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Privredne komore, regionalne razvojne agencije; </span></p>
			<p id="p3" className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Ostale nevladine organizacije koje se bave turizmom; turizam bi trebao biti jedna od aktivnosti
			navedenih u statutu organizacije.</span></p>


			<p className="content_text"><b>2.1.6.3.6 PARTNERSTVO</b></p>

			<p className="content_text">Aplikanti mogu nastupiti samostalno ili u konzorciju s partnerskim organizacijama. Prednost
			se, međutim, daje partnerstvima. Za regionalne projekte naročito su poželjni konzorciji općina,
			predvođeni jednom od tih općina.</p>

			<p className="content_text">Partneri moraju ispuniti iste opće kriterije prihvatljivosti kao i aplikanti.</p>

			<p className="content_text"><b>Prihvatljivi partneri: </b></p>


			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Općine;</span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Savezi općina;</span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Regionalne, lokalne turističke organizacije ili podružnice turističkih organizacija (u skladu sa
			Zakonom o turizmu);</span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Privredne komore, regionalne razvojne agencije;</span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Ostale nevladine organizacije koje se bave turizmom; turizam bi trebao biti jedna od aktivnosti
			navedenih u statutu organizacije.</span></p>


			<p className="content_text"><b>Saradnici</b></p>

			<p id="t3" className="content_text pos-rel">Saradnici mogu biti, između ostalog, okružni organi vlasti, državne agencije (regionalne
			podružnice), druge nevladine organizacije koje ne ispunjavaju kriterije za aplikante/partnere (npr.
			ekološke i kulturne organizacije), univerziteti, muzeji, privatna privredna društva <Toolpit text="Posebno u slučaju javno-privatnih marketinških inicijativa (iako privatna preduzeća nisu prihvatljivi aplikanti, ona pružaju
			usluge i proizvode koji su sastavni dio promotivnih aktivnosti organizacija iz javnog sektora i mogu podržati, ali i imati koristi od ove
			aktivnosti, odnosno, mogu indirektno imati koristi ili, shodno tome, podržati ovu aktivnost."></Toolpit>.</p>


			<p className="content_text"><b>2.1.6.3.7 TRAJANJE</b></p>

			<p id="pt3" className="content_text">Projekti u okviru ove komponente ne mogu trajati duže od 18 mjeseci. Tokom trajanja projekta,
			sama izgradnja ne može trajati duže od 14 mjeseci. Aplikant treba voditi računa o trajanju svih neophodnih procedura za realizaciju njegovog projekta: projektiranje, odobravanje projekta,
			izdavanje odobrenja, nabavka usluga, robe i radova i, ako je primjenjivo, odobrenja nakon završetka
			građevinskih radova itd.</p>


			<p className="content_text"><b>2.1.6.3.8 PRIHVATLJIVI TROŠKOVI</b></p>

			<p className="content_text"><b>Prihvatljivi troškovi </b> u okviru komponente integrirane podrške lokalnom i regionalnom turizmu
			obuhvataju sljedeće:</p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Troškovi gradnje / rekonstrukcije;</span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Troškovi nadzora nad radovima;</span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Troškovi informatičke opreme, informacijskih sistema, atrakcija (npr. muzeji);</span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Troškovi sudjelovanja na turističkim sajmovima, izložbama (kotizacija);</span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Troškovi registracije djelatnosti organiziranja putovanja ili turističkih agencija u sklopu javnih
			organizacija koje upravljaju turističko-informativnim centrima i sistemima rezervacija;</span></p>
			
			<p className="content_text"><b>U kontekstu komponente integrirane podrške lokalnom i regionalnom turizmu, </b>sljedeći su
			troškovi naročito <b>neprihvatljivi</b></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Troškovi pripremnih studija, projektiranja ili drugih pripremnih aktivnosti za radove koji se provode
			unutar projekta.</span></p>


			<div className="yellow_box mt-50">
						<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/lupa.svg`} alt="lupa"></img> <b>NAPOMENA 7: </b></p>
						
						<p id="dtzvuojk" className="content_text"> Odjeljak u nastavku iznimno obuhvata <b>zahtjeve koji su osmišljeni tako da predloženi
						infrastrukturni projekti</b> (za čije je projektiranje potrebno dosta vremena) <b>budu
						većim dijelom pripremljeni i da se mogu brzo realizirati. S obzirom na ograničeno
						vrijeme za realizaciju projekta i apsorbciju sredstava, to je eksplicitno navedeno
						u ovoj šemi.</b></p>
            </div>


			<p className="content_text blue"><b>2.1.7 DRUGI TEHNIČKI ZAHTJEVI VEZANI UZ OVAJ JAVNI KONKURS</b></p>
			<p className="content_text"><b>Spremnost projekta</b></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Ovaj je odjeljak relevantan za komponente koje obuhvataju radove, odnosno, za komponentu
			poslovne infrastrukture i integrirane podrške lokalnom / regionalnom turizmu.</span></p>


			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Što se tiče aktivnosti usmjerenih na izgradnju/rekonstrukciju (radovi), aplikant je dužan utvrditi je
			li za predviđene aktivnosti potreban detaljan projekt.</span></p>


			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Kad je riječ o aktivnostima koje po relevantnim bugarskim propisima zahtijevaju takav projekt, uz
			prijavu je potrebno priložiti idejno rješenje (crteže, predmjer radova) kao prateću dokumentaciju.
			Iznos traženih sredstava za ove aktivnosti treba opravdati na osnovu idejnog rješenja i predmjera
			radova.</span></p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Tamo gdje relevantni bugarski propisi ne zahtijevaju dostavljanje projekta, aplikant uz svoju prijavu
			treba priložiti samo obrazložen predmjer radova.</span></p>


			<p className="content_text">(...)</p>


			<div className="yellow_box mt-50">
						<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/lupa.svg`} alt="lupa"></img> <b>NAPOMENA 8: </b></p>
						
						<p id="kppipkts" className="content_text">Šema je sad predstavljena. <b>Ovaj dokument potencijalne aplikante jasno
						upućuje šta moraju uraditi kako bi podnijeli valjanu prijavu.</b> Poštujući <b>načelo
						transparentnosti, ukazuje šta će se desiti nakon što se prijava podnese. </b> U grant
						šemama EU ovi pasusi o transparentnosti posebno su detaljni jer Evropska komisija
						na njima više insistira nego neke države članice.</p>
            </div>

			<h2 id="opipd" className="blue_overlay_heading">2.2 KAKO PODNIJETI PRIJAVU I POSTUPCI KOJE TREBA SLIJEDITI:</h2>

			<p className="content_text blue"><b>2.2.1 OBRAZAC PRIJAVE PROJEKTNOG PRIJEDLOGA I PRATEĆA DOKUMENTACIJA</b></p>

			<p className="content_text">Prijave se moraju podnijeti na prijavnom obrascu koji je dat u prilogu ovih smjernica (Prilog A).
			Kompletan set potrebnih dokumenata na engleskom jeziku dostupan je na internetskoj stranici.</p>

			<p className="content_text">Aplikanti se trebaju strogo pridržavati formata prijave i pravilno popuniti stranice.</p>

			<p className="content_text">Aplikanti prijavu podnose i na engleskom i na bugarskom jeziku. Prateću dokumentaciju mogu
			podnijeti samo na bugarskom jeziku.</p>

			<p className="content_text">Pažljivo i što je moguće jasnije popunite prijavni obrazac kako bismo ga mogli pravilno procijeniti.
			Budite precizni i navedite dovoljno detalja kako bi prijava bila što jasnija, naročito u dijelu gdje
			opisujete kako ćete postići ciljeve projekta, koristi koje će iz toga proizaći i kako je to relevantno
			za ciljeve programa.</p>

			<p className="content_text">Prijave popunjene rukom neće biti uzeti u razmatranje.</p>

			<div className="yellow_box mt-50">
						<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/lupa.svg`} alt="lupa"></img> <b>NAPOMENA 9: </b></p>
						
						<p className="content_text">Naredni odjeljak nastoji što jasnije objasniti <b>potrebnu prateću dokumentaciju.
						Aplikanti često prave greške u ovom dijelu, što za posljedicu ima njihovu
						diskvalifikaciju.</b> Bitno je ograničiti stepen <b>“eliminacije zbog administrativnih razloga”,</b>jer to predstavlja gubljenje vremena i napora i za provedbeno tijelo i za
						aplikanta.</p>
            </div>

			<p className="content_text">Prateća dokumentacija</p>
			<p className="content_text">Prateća dokumentacija mora biti priložena uz sve projektne prijedloge. Ta je dokumentacija
			pobrojana na listama priloženim uz obrazac prijave za grant šemu. Postoje dvije vrste listi:</p>

			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Lista obavezne prateće dokumentacije prema vrsti pravnog oblika (npr. općina, NVO itd.)</span></p>
			<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Lista dodatne obavezne prateće dokumentacije, zavisno od vrste aktivnosti koju predlažete u
			okviru svog projekta (npr. radovi).</span></p>


			<div className="yellow_box mt-50">
						<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/warrning.svg`} alt="warrning"></img> <b>VAŽNO </b></p>
						
						<p className="content_text">Morate pažljivo pročitati obje liste i dostaviti traženu prateću dokumentaciju.
						Imajte na umu da, ukoliko ne dostavite tražene dokumente uz prijavu, vaša prijava
						neće biti uzeta u razmatranje.</p>
            </div>

			<p className="content_text">Tražena prateća dokumentacija mora se priložiti u dva primjerka.</p>



			<div className="yellow_box mt-50">
						<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/warrning.svg`} alt="warrning"></img> <b>VAŽNO</b>(POLJE 1)</p>
						
						<p className="content_text"><b><u>Za SVE aplikante:</u></b></p>

						<p className="content_text">Prilog 1 - Biografije (i.e. CV) voditelja projekta i drugog ključnog osoblja.</p>

						<p className="content_text">Prilog 2 - Potpisana izjava da aplikant ne traži niti je primio druga sredstva u formi
						granta za iste projektne aktivnosti (u slobodnom formatu, uz notarsku ovjeru).</p>

						<p className="content_text">Prilog 3 - Ovjerena potvrda revizora (notarski ovjeren primjerak službene potvrde).</p>

            </div>

			<div className="yellow_box mt-50">
						<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/warrning.svg`} alt="warrning"></img> <b>VAŽNO</b>(POLJE 2)</p>
						
						<p className="content_text"><b><u>Za općine (koje se prijavljuju kao APLIKANTI ili PARTNERI):</u></b></p>

						<p className="content_text">Prilog 4 - Primjerak porezne registracije (ovjeren od aplikanta/partnera);</p>
						<p className="content_text">Prilog 5 - Primjerak državne registracije (ovjeren od aplikanta/partnera);</p>
						<p className="content_text">Prilog 6 - Bilans stanja i bilans uspjeha iz završnog računa za godinu (...) ovjeren od aplikanta/partnera);</p>
						<p className="content_text">Prilog 7 - Izjave Nacionalnog zavoda za socijalno osiguranje i Porezne uprave o
						izmirenim obavezama (ne starije od 6 mjeseci prije roka za podnošenje prijava) ili
						planu reprogramiranja dugova, u slučaju neizmirenih poreznih obaveza - (original
						ili kopija ovjerena od aplikanta/partnera);</p>
						<p className="content_text">Prilog 8 - Nalog o delegiranju ovlaštenja koji izdaje načelnik u slučajevima kada
						obrazac prijave/izjavu o partnerstvu potpisuje druga osoba (original ili notarski
						ovjeren primjerak).</p>


            </div>



			<div className="yellow_box mt-50">
						<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/warrning.svg`} alt="warrning"></img> <b>VAŽNO</b>(POLJE 3)</p>
						
						<p className="content_text"><b><u>Za NVO-e (koji se prijavljuju kao APLIKANTI ili PARTNERI):</u></b></p>

						<p className="content_text">Prilog 9 - Statut (notarski ovjeren primjerak);</p>
						<p className="content_text">Prilog 10 - Sudsko rješenje o registraciji (notarski ovjeren primjerak);</p>
						<p className="content_text">Prilog 11 - Potvrda o ovlaštenjima (original ili notarski ovjeren primjerak koji je
						izdao sud, ne stariji od 6 m jeseci prije roka za podnošenje prijave);</p>
						<p className="content_text">Prilog 12 - Uvjerenje o poreznoj registraciji i registraciji PDV broja (ako postoji)
						(ovjeren od aplikanta/partnera);</p>
						<p className="content_text">Prilog 13 - Rješenje o registraciji u bugarskom registru poslovnih subjekata (ovjeren
						od aplikanta/partnera);</p>
						<p className="content_text">Prilog 14 - Bilans stanja i bilans uspjeha iz završnog računa za 2004. godinu (ovjeren
						od aplikanta/partnera);</p>
						<p id="gikpp" className="content_text">Prilog 15 - Izjave Nacionalnog zavoda za socijalno osiguranje i Porezne uprave o
						izmirenim obavezama (ne starije od 6 mjeseci prije roka za podnošenje prijava) ili planu
						reprogramiranja dugova, u slučaju neizmirenih poreznih obaveza - (original ili kopija
						ovjerena od aplikanta/partnera (original ili kopija ovjerena od aplikanta/partnera).</p>
            </div>
			<p className="content_text pos-rel">(…) <Toolpit text="Naredna četiri polja napomena preuzeta su iz izvornog dokumenta, koji nije dat u ovom prilogu."></Toolpit></p>


			<p className="content_text blue"><b>2.2.2 GDJE I KAKO POSLATI PRIJAVU</b></p>

			<p className="content_text">Prijave se moraju dostaviti u zapečaćenoj koverti, preporučenom poštom, kurirskom službom ili
			lično (dostavljač će dobiti potpisanu i datiranu potvrdu primitka) na niže navedenu adresu:</p>


			<p className="content_text"><u>Poštanska adresa</u></p>
			<p className="content_text">(…)</p>

			<p className="content_text"><u>Adresa za ličnu dostavu</u></p>
			<p className="content_text">(…)</p>

			<p className="content_text"><u>Dostava putem privatne kurirske službe</u></p>
			<p className="content_text">(…)</p>

			<p className="content_text">Prijave poslane na bilo koji drugi način (npr. faksom ili elektronskom poštom) ili dostavljene na
			druge adrese bit će odbijene.</p>

			<p id="rzpp" className="content_text pos-rel">Obrazac prijave (Prilog A uz ove smjernice), zajedno s prilozima (Prilog B – budžet, Prilog C – logički okvir,
			Prilog F – Opis projekta za ugovor o uslugama nadzora nad radovima, Prilog G – procjena potražnje i
			izvodljivosti projekta - za komponentu poslovne infrastrukture, Prilog H - procjena potražnje i izvodljivosti
			projekta - za komponentu integrirane podrške lokalnom/regionalnom turizmu), dostavlja se u jednom
			originalnom primjerku na <span className="mark">engleskom jeziku</span> <Toolpit text="Tipični zahtjev grant
			šema EU, a često i ozbiljan
			problem za male aplikante."></Toolpit> i jednom originalnom primjerku i dvije kopije na bugarskom
						jeziku. Prateća dokumentacija, priložena uz originale i kopije, dostavlja se samo na bugarskom jeziku. (…)</p>

           
			
			<p id="di" className="content_text blue"><b>2.2.3 </b>ROK ZA PODNOŠENJE PRIJAVA</p>

			<p className="content_text"> Rok za dostavu prijava je &lt; datum  &gt; u 16 sati. (…) </p>


			<p className="content_text blue"><b>2.2.4 </b>DODATNE INFORMACIJE</p>

			<p className="content_text">Pitanja se mogu poslati elektronskom poštom ili faksom najkasnije 21 dan prije krajnjeg roka za
			dostavu prijedloga na dolje navedene adrese, s jasnom naznakom poziva za dostavu projektnih
			prijedloga: </p>

			<p className="content_text">Email adresa: &lt; <span className="blue">xx@xx.xx</span> &gt; Faks: &lt;.........&gt; </p>
			
			<p className="content_text">Odgovor ćete dobiti najkasnije 11 dana prije roka za dostavu prijedloga. Pitanja koja mogu biti
			relevantna za druge aplikante objavit će se, zajedno s odgovorima, na internetskoj stranici (…)</p>


			<div className="yellow_box mt-50">
						<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/lupa.svg`} alt="lupa"></img> <b>NAPOMENA 10:</b></p>

						<p className="content_text">U <b>sljedećim je odjeljcima </b> detaljno pojašnjen postupak, od prijema prijave do
						ugovaranja. Naročito je pojašnjen <b>cijeli proces evaluacije, koji vodi do odabira projekata.</b> Imajte na umu da je <b>skala za evaluaciju u ovoj fazi javno dostupna i da
						je cijeli sistem bodovanja i rangiranja, koji je tipičan na grant šeme EU, pojašnjen.</b></p>
						
						<p className="content_text">Kod grant šema EU u kontekstu vanjske pomoći koristi se <b>standardna skala za evaluaciju</b> i obično se angažiraju procjenitelji, čiji je zadatak da ocijene projekte u
						odnosu na skalu. Nove države članice EU su uglavnom preuzele ovu praksu, ali ovaj <b>stepen transparentnosti nije uvijek uobičajen u zemljama zapadne Evrope, </b>gdje mnoge javne uprave kod građana uživaju veći stepen povjerenja.</p>

						<p id="pp5" className="content_text"><b>Komisija EU nastoji insistirati na većem stepenu transparentnosti </b>jer smatra: (i)
						da transparentnost odražava stepen dobrog upravljanja, koji je bitan za usklađivanje
						s evropskim standardima, (ii) zato što ima posebnu odgovornost prema građanima
						EU koji su pristali da se sredstva EU troše van Unije, odnosno, (iii) zato što se
						kultura transparentnosti i odgovornog korištenja javnih sredstava u određenim
						zemljama još uvijek nije dobro ukorijenila.</p>
					
            </div>
		
		<p className="content_text blue"><b>2.2.5 </b>POTVRDA PRIJEMA</p>
		<p id="eiop" className="content_text">Nakon sastanka za otvaranje projektnih prijedloga, ugovorni organ će poslati potvrdu o prijemu svim aplikantima, u kojem će naznačiti je li njihova prijava zaprimljena
		prije isteka roka za dostavu i obavijestiti ih o referentnom broju koji im je dodijeljen.</p>

		<p className="content_text blue">Evaluacija i odabir prijava</p>

		<p className="content_text">Ugovorni organ će uz pomoć vanjskih procjenitelja ispitati i ocijeniti prijave. Svi projektni prijedlozi
		aplikanata procjenjivat će se prema sljedećim kriterijima:</p>


		<p className="content_text"><b>1. <span className="ml-15">Ispunjavanje administrativnih zahtjeva</span></b></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Potvrda da je prijava ispunjena u skladu s kontrolnom listom priloženom uz obrazac prijave.</span></p>


		<p className="content_text"><b>2. <span className="ml-15"></span>Prihvatljivost aplikanata, partnera i projekata</b></p>


		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Potvrda da su aplikanti, partneri (i saradnici, gdje je to primjenjivo) i projekti prihvatljivi prema
		kriterijima navedenim u Odjeljku 2.1.1 i posebnim odjeljcima unutar svake komponente.</span></p>


		<p className="content_text"><b>3. <span className="ml-15"></span>Ocjena kvaliteta prijedloga i finansijska evaluacija</b></p>

		<p className="content_text pos-rel">Ocjena kvaliteta prijedloga, uključujući i predloženi budžet, provodit će se u skladu s evaluacijskim
		kriterijima, navedenim u evaluacijskoj tabeli prikazanoj na sljedećoj stranici. Postoje dvije vrste
		evaluacijskih kriterija: <span className="mark">kriteriji odabira i kriteriji dodjele. </span> <Toolpit text="Terminologija u ovom primjeru
		malo je drugačija od one koja se koristi u Priručniku. Mi smo koristili opće (generičke) pojmove - kriterij “prihvatljivost” i kriterij “kvaliteta”."></Toolpit></p>


		<p className="content_text">Svrha <b>kriterija odabira je </b> da pomognu kod procjene aplikantove finansijske i operativne sposobnosti kako bi
		se osiguralo da:</p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />imaju stabilne i dostatne izvore finansiranja kojima
		mogu održati svoju djelatnost tokom perioda realizacije projekta i, gdje je prikladno, sudjelovati u njegovom sufinansiranju;</span></p>


		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />imaju stručne sposobnosti i kvalifikacije potrebne za uspješnu realizaciju predloženog projekta.
		Isto vrijedi i za sve aplikantove partnere.</span></p>


		<p className="content_text"><b>Kriteriji dodjele </b> omogućavaju procjenu kvaliteta dostavljenih prijedloga u odnosu na ciljeve
		i postavljene prioritete te dodjelu grantova za one projekte koji uvećavaju učinkovitost javnog
		konkursa. Oni obuhvataju aspekte kao što su relevantnost projekta, njegova usklađenost s ciljevima
		javnog konkursa, kvalitet, očekivani efekti, održivost i isplativost.</p>


		<p className="content_text"><b><u>Imajte na umu sljedeće važne informacije:</u></b></p>

		<p className="content_text"><em>Bodovanje:</em></p>

		<p className="content_text">Evaluacijski kriteriji podijeljeni su na kategorije i podkategorije, gdje se svaka podkategorija boduje
		na skali od 1 do 5, pri čemu je: 1 = vrlo loše; 2 = loše; 3 = dobro; 4 = vrlo dobro; 5 = odlično.</p>


		<p className="content_text">Prijave s najvećim brojem bodova imat će prioritet kod dodjele grantova.</p>

		<p className="content_text"><em>Napomena o Odjeljku 1 - finansijska i oper ativna sposobnost:</em></p>

		<p className="content_text">Ako je ukupni rezultat za Odjeljak 1 ispod „zadovoljavajućeg“ (12 bodova), prijedlog se neće dalje razmatrati.</p>

		<p className="content_text"><em>Napomena o Odjeljku 2 - relevantnost:</em></p>

		<p className="content_text">Ako je ukupni rezultat za Odjeljak 2 ispod „dobrog“ (20 bodova), prijedlog se neće dalje razmatrati.</p>
		
		<div className="yellow_box mt-50">
						<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/lupa.svg`} alt="lupa"></img> <b>NAPOMENA 11:</b></p>

						<p className="content_text"><b>Tabela za evaluaciju slijedi model predložen u priručniku (predstavljen u Faza 2,
						Korak 4).</b> Napominjemo da tabela u <b>desnoj koloni ukazuje na mjesta na kojima se
						nalaze dokazi koji će se koristiti da se utvrdi ispunjava li prijedlog podkriterij
						ili ne. Ove se oznake odnose na pitanja u obrascu prijave.</b> Ocjenjivači će, također,
						gdje bude potrebno, razmatrati i određenu prateću dokumentaciju, između
						ostalog, studije ili sažetke studija. <b>Odjeljak naslovljen “metodologija” u suštini
						obuhvata pitanja koja se odnose na efikasnost i efektivnost, kao što je navedeno
						u Priručniku.</b> Ne zaboravite da praksa procjenjivanja projekata u mnogim zemljama
						nije dobro poznata i da ova tabela, koja je vrlo detaljna, u tom smislu daje važne
						smjernice onima koji su odgovorni za evaluaciju projekata.</p>
							
        </div>


		<p className="content_text"><b>Tabela za evaluaciju </b></p>


		<div className="custom_tables"><table id="table099 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-259 " />
					<col className="_idGenTableRowColumn-37 " />
					<col className="_idGenTableRowColumn-260 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-130 ">
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Odjeljak</p>
						</td>
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Maksimalni broj bodova</p>
						</td>
						<td className="pos-rel No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Obrazac prijave</p>
							<span className="CharOverride-11 "> <Toolpit text="U ovoj koloni možete naći odjeljak obrasca prijave na koji se trebate pozvati kod bodovanja svakog pojedinačnog kriterija."></Toolpit></span>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-107 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">1. Finansijska i operativna sposobnost</span></p>
						</td>
						<td className="No-Table-Style CellOverride-107 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">20</span></p>
						</td>
						<td className="No-Table-Style CellOverride-107 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">1.1 Imaju li aplikant i partneri dovoljno iskustva u vođenju projekata? </p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">II.4.1 i III.1</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">1.2 Imaju li aplikant i partneri odgovarajuću tehničku stručnost? (naročito u problematici koja se nastoji riješiti.)</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">II.4.1 i III.1</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">1.3 Imaju li aplikant i partneri dostatne upravljačke kapacitete? <br />(uključujući osoblje, opremu i sposobnosti upravljanja budžetom projekta)?</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">II.4.2 i III.1</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">1.4 Ima li aplikant stabilne i dostatne izvore finansiranja?</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">II.4.2</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-107 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">2. Relevantnost</span></p>
						</td>
						<td className="No-Table-Style CellOverride-107 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">25</span></p>
						</td>
						<td className="No-Table-Style CellOverride-107 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-147 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">2.1 Koliko je prijedlog relevantan u odnosu na ciljeve i jedan ili više prioriteta ovog javnog konkursa? <br />Napomena: Ocjena 5 (odlično) dodjeljuje se samo ako se projektni prijedlog konkretno bavi makar jednim prioritetom. </p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">I.1.6 (a) (b)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">2.2 Koliko je projektni prijedlog relevantan u odnosu na specifične potrebe i ograničenja ciljane zemlje(zemalja) ili regije (regija) (npr. izbjegavanje dupliranja i sinergija s drugim inicijativama EZ)?</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">I.1.6 (c)</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">Prilog G*, 1A, 2A, </p>
							<p className="tekst-u-tabelama ParaOverride-2 ">Prilog H*, 1, 2, 3</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">2.3 Koliko dobro su definirani i strateški odabrani sudionici na projektu (posrednici, krajnji korisnici, ciljane grupe)?</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">I.1.6 (e)</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">Prilog G 1B, 1C, 2A, 2C, </p>
							<p className="tekst-u-tabelama ParaOverride-2 ">Prilog H 5 </p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">2.4 Jesu li potrebe ciljnih grupa i krajnjih korisnika jasno definirane i bavi li se prijedlog projekta tim potrebama na adekvatan način? </p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">I.1.6 (c) (f)</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">Prilog G 1B, 1C, 2A, 2C </p>
							<p className="tekst-u-tabelama ParaOverride-2 ">Prilog H 5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">2.5 Sadrži li prijedlog projekta neke posebne elemente koji predstavljaju dodanu vrijednost kao što su inovativni pristupi, modeli dobre prakse, promoviranje rodne ravnopravnosti i jednakih prilika, zaštita okoliša? </p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Passim</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">(t.j. razne reference iz jednog ili više srodnih ref. izvora)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-107 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">3. Metodologija</span></p>
						</td>
						<td className="No-Table-Style CellOverride-107 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">30</span></p>
						</td>
						<td className="No-Table-Style CellOverride-107 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">3.1 Jesu li predložene aktivnosti prikladne, praktične i konzistentne s ciljevima i očekivanim rezultatima? </p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">I.1.7</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">3.2 Koliko je dobro logički povezan cjelokupni dizajn projekta? <br />(Odražava li analizu problema? Uzima li u obzir vanjske faktore i predviđa li evaluaciju?)</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5 </p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">I.1.8</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">3.3 Je li stepen uključenosti partnera u realizaciju projekta zadovoljavajući? <br />Napomena: Ako nema partnera, dodijelit će se samo jedan (1) bod. </p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">I.1.8 (e)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">3.4 Je li stepen uključenosti  ciljanih grupa i krajnjih korisnika u realizaciju projekta zadovoljavajući?</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">I.1.8 (e)</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">3.5 Je li akcioni plan jasan i izvodljiv?</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">I.1.9</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">3.6 Sadrži li prijedlog projekta indikatore ishoda projekta koji se mogu objektivno provjeriti? </p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Logički okvir</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">Prilog G 1C, 1D, 2C, 2D</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">Prilog H 5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-107 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">4. Održivost</span></p>
						</td>
						<td className="No-Table-Style CellOverride-107 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">15</span></p>
						</td>
						<td className="No-Table-Style CellOverride-107 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">4.1 Je li izvjesno da će projekt imati konkretan učinak na ciljane grupe?</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">I.2.1</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">Prilog G 1E, 2E, </p>
							<p className="tekst-u-tabelama ParaOverride-2 ">Prilog H 5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">4.2 Je li izvjesno da će projektni prijedlog imati multiplikativne učinke (npr. opseg replikacije i proširenje ishoda projekta i širenje informacija)?</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">I.2.2 &amp; I.2.3</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">Prilog H 5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-114 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">4.3 Jesu li očekivani rezultati prijedloga projekta održivi:</p>
							<p className="tekst-u-tabelama ">- finansijski (kako će se aktivnosti finansirati nakon što se okonča finansiranje sredstvima Evropske zajednice?)</p>
							<p className="tekst-u-tabelama ">- institucionalno (hoće li strukture omogućiti nastavak aktivnosti nakon okončanja projekta? Hoće li lokalne vlasti preuzeti odgovornost nad rezultatima projekta?)</p>
							<p className="tekst-u-tabelama ">- na nivou politike (gdje je primjenjivo) (kakav će biti strukturni učinak projekta - npr. hoće li dovesti do poboljšanja zakona, kodeksa ponašanja, metoda itd.?)</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">I.2.4</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">Prilog G 1E, 2E, </p>
							<p className="tekst-u-tabelama ParaOverride-2 ">Prilog H 5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-107 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">5. Budžet i troškovna učinkovitost (isplativost) </span></p>
						</td>
						<td className="No-Table-Style CellOverride-107 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">10</span></p>
						</td>
						<td className="No-Table-Style CellOverride-107 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">5.1 Je li omjer između procijenjenih troškova i očekivanih rezultata zadovoljavajući?</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">I.3</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">Prilog G 1C, 2C, </p>
							<p className="tekst-u-tabelama ParaOverride-2 ">Prilog H 5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">5.2 Jesu li predloženi izdaci neophodni za realizaciju projekta?</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">I.3</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ">Maksimalni broj bodova</p>
						</td>
						<td className="No-Table-Style CellOverride-106 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">100</p>
						</td>
						<td className="No-Table-Style CellOverride-106 " />
					</tr>
				</tbody>
			</table>
</div>

		<p id="ooouo" className="content_text mt-20">* Prilozi G i H se odnose samo na projekte preko 100,000 €, koji su dostavljeni u okviru komponenti
		poslovna infrastruktura i podrška integriranom regionalnom/lokalnom turizmu.</p>

		<p className="content_text blue"><b>2.4 Obavijest o odluci ugovornog organa</b></p>
		<p id="uksonr" className="content_text">Aplikanti će o odluci ugovornog organa u pogledu njihovih prijava biti obaviješteni pismeno.
		Odluka o odbijanju zahtjeva ili nedodjeljivanju granta temeljit će se na sljedećim osnovama: (…)</p>


		<p className="content_text blue"><b>2.5 Uvjeti koji se odnose na realizaciju projekta nakon što ugovorni organ donese odluku
		o dodjeli granta</b></p>

		<p className="content_text">Nakon donošenja odluke o dodjeli granta, korisniku će se ponuditi da potpiše ugovor, koji se
		temelji na standardnom ugovoru ugovornog organa koji se nalazi u prilogu ovih smjernica (Prilog
		E).</p>

		<p className="content_text pos-rel">Korisnik je dužan početi s realizacijom projektnih aktivnosti najkasnije 30 dana nakon potpisivanja
		ugovora. Planirano je da se, nakon što strane potpišu ugovor, krene s realizacijom projekta u
		mjesecu novembru 2005. - ovo je indikativni rok. <Toolpit text="Ova je odredba ubačena u šemu zbog izuzetno tijesnog vremenskog okvira."></Toolpit></p>
		
		<p className="content_text">(…)</p>

		<p className="content_text">Projektne aktivnosti trebaju biti okončane do 31. jula 2007, uključujući i
		podnošenje završnih izvještaja, bez obzira na datum početka realizacije.</p>

		<p className="content_text">Ugovorom će se posebno utvrditi sljedeća prava i obaveze: (…)</p>

		<p className="content_text blue"><b>Izvještaji </b></p>

		<p className="content_text">Standardni izvještaji moraju biti pripremljeni na engleskom jeziku.</p>

		<p className="content_text">(…)</p>

		<p className="content_text blue"><b>Plaćanje</b></p>

		<p className="content_text pos-rel">Korisniku će sredstva biti uplaćena <span className="mark">avansno.</span> <Toolpit text="Ovoliki procenat “avansa” je
		neuobičajen unutar EU. Desio se
		u ovom slučaju i često se dešava
		u programima vanjske pomoći
		zbog ozbiljnih odgađanja koja se
		dešavaju prilikom definiranja
		grant šema. To znači da postoji
		stvarni rizik da budžetirana
		sredstva neće biti absorbirana u
		predviđenom periodu."></Toolpit> Ukoliko
		cjelokupno trajanje projekta ne prelazi 12 mjeseci ili ako grant
		ne prelazi € 100.000, avans će iznositi 80% iznosa granta.</p>

		<p className="content_text">(…)</p>

		<p className="content_text">Uplata će se izvršiti na posebni bankovni račun ili podračun projekta, na kojem se jasno vide sredstva koja
		je uplatio ugovorni organ.</p>


		<p className="content_text blue"><b>Računi projekta</b></p>
		<p className="content_text">(…)</p>

		<p className="content_text blue"><b>Revizija </b></p>

		<p className="content_text">Uz svaki zahtjev za isplatu preostalog iznosa mora se priložiti izvještaj o reviziji računa projekta,
		sačinjen od ovlaštenog vanjskog revizora. U tu svrhu, korisnik mora angažirati revizora koji će provesti
		vanjsku reviziju i priložiti potvrdu o njegovom angažiranju kao prateću dokumentaciju uz prijavu.</p>
		<p className="content_text">(…)</p>


		<div className="yellow_box mt-50">
						<p className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/lupa.svg`} alt="lupa"></img> <b>NAPOMENA 12:</b></p>
						
						<p className="content_text"><b>Opća je praksa da se insistira na angažiranju vanjske revizije za sve programe
						koje finansira EU,</b> a u većini zemalja i za grantove koji se daju organizacijama izvan
						sistema javne uprave.</p>
					
         </div>

		 <p className="content_text blue"><b>Vidljivost </b></p>


		<p className="content_text">Grantovi koje daje EZ moraju biti popraćeni odgovarajućim elementima koji osiguravaju vidljivost
		i priznanje, na primjer, u izvještajima i publikacijama koje proizilaze iz projekta ili tokom javnih
		događaja povezanih s projektom itd. (vidi Član 6. Općih uvjeta).</p>
		<p className="content_text">(…)</p>

		<h2 className="blue_overlay_heading">LISTA PRILOGA</h2>


		<p className="content_text"><b>Prilog A: Obrazac prijave za grant (Word format) koji treba popuniti.</b></p>
		<p className="content_text"><b>Prilog B: Budžet (Excel format) koji treba popuniti.</b></p>
		<p className="content_text"><b>Prilog C: Logički okvir (Excel format) koji treba popuniti.</b></p>
		<p className="content_text"><b>(…)</b></p>
		<p className="content_text"><b>Prilog G: Procjena potražnje i izvodljivosti projekta - za komponentu Poslovne infrastrukture.</b></p>
		<p className="content_text"><b>Prilog H: Procjena potražnje i izvodljivosti projekta – za komponentu integrirane podrške
		razvoju regionalnog/lokalnog turizma.</b></p>


		<div className="yellow_box mt-50">
						<p id="p10" className="content_heading_big black d-flex d-flex-aac mb-35"><img src={`${process.env.PUBLIC_URL}/images/lupa.svg`} alt="lupa"></img> <b>NAPOMENA 13:</b></p>
						<p className="content_text"><b>Prilozi G i H: </b></p>

						<p className="content_text">Česta je praksa da se traže tzv. “studije izvodljivosti”, naročito kad je riječ o
						infrastrukturnim projektima. To se dešava u programima vanjske pomoći EU. Međutim, <b>mnogo je poteškoća s ovom praksom:</b></p>

						<p className="content_text">(i) različiti ljudi različito poimaju studiju izvodljivosti - konkretno, u mnogim
						postkomunističkim zemljama studija izvodljivosti <b>često ima izrazito tehničko
						značenje;</b></p>

						<p className="content_text">(ii) studije izvodljivosti često <b>rade sami aplikanti ili neko koga je angažirao aplikant</b> (dakle, malo je vjerovatno da će autor zaključiti da projekt nije izvodljiv);</p>

						<p className="content_text">(iii) <b>dobra izvodljivost ima cijenu koja je često veća od one koju aplikant može </b> ili je spreman platiti.</p>
					
						<p className="content_text">Prema tome, <b>u ovom je slučaju dogovoreno da se traže ciljane dodatne informacije kroz tzv. “test izvodljivosti”.</b>Pitanja i format za ovaj test <b>napravljeni su unaprijed i
						fokusirani na bitne informacije i dokaze koji opravdavaju projekt</b>Ovi su “testovi”
						potrebni samo za veće projekte.</p>
						
						<p className="content_text">Različite zemlje imaju različite prakse kada je riječ o <b>studijama izvodljivosti.</b></p>

						<p id="studije-slucaja-7" className="content_text">Općenito, <b>bitno je da zahtjevi za studijama izvodljivosti budu proporcionalni
						veličini i prirodi projekta</b>stoga, studije izvodljivosti uopće <b>nisu potrebne ili su
						vrlo jednostavne za male projekte. Ako se koriste kao pomoć pri ocjenjivanju
						projekata,</b> onda je poželjno da ih ugovori i plaća provedbeno tijelo (primatelja
						granta) U suprotnom, malo je vjerovatno da će dati nezavisne informacije koje su
						korisne u procesu evaluacije.</p>
						

					
         </div>

		 <h2  className="blue_overlay_heading"><b>STUDIJA SLUČAJA 7</b> (FAZA 3 -SMJERNICE ZA APLIKANTE)</h2>

		<h2 className="blue_overlay_heading">KAKO SE MOŽE URADITI: PRIČA IZ BOSNE I HERCEGOVINE</h2>

		<p className="content_text ta-c mt-50 mb-50"><b>JAVNI KONKURS</b></p>
		<p className="content_text ta-c mt-75"><b>za odabir grant šeme</b></p>
		<p className="content_text ta-c"><b>„Jačanje konkurentnosti malih i srednjih preduzeća (MSP)“ za 2017. godinu</b></p>
		<p className="content_text ta-c mt-80"><b><u>Smjernice za podnositelje prijava</u></b></p>
		<p className="content_text ta-c mt-90">April, 2017</p>



		<h2 className="blue_overlay_heading">1. INFORMACIJE O JAVNOM KONKURSU</h2>
		<h2 className="content_heading blue mt-50 mb-25"><b>1.1 UVODNE INFORMACIJE O GRANT ŠEMI „JAČANJE KONKURENTNOSTI MALIH I SREDNJIH PREDUZEĆA“</b></h2>
		

		<p className="content_text">Prepoznavajući značaj ulaganja u razvoj poduzetništva u Federaciji Bosne i Hercegovine (FBiH),
		Federalno ministarstvo razvoja, poduzetništva i obrta (FMRPO) posebnu pažnju usmjerava ka
		jačanju konkurentnosti malih i srednjih preduzeća (MSP) kroz plasman finansijskih sredstava u
		vidu grantova.</p>

		<p className="content_text pos-rel">U skladu sa Akcionim planom realizacije projekta „Razvoj malog i srednjeg poduzetništva u FBiH“
		za period 2016 – 2018. godina (Akcioni plan FMRPO), te Odlukom Vlade FBiH o usvajanju Programa
		utroška sredstava s kriterijima raspodjele sredstava tekućih transfera utvrđenim budžetom
		Federacije Bosne i Hercegovine za 2017. godinu, FMRPO poziva mala i srednja preduzeća (oblik
		organiziranja: d.o.o. i d.d.), shodno Zakonu o poticanju razvoja male privrede u FBiH <Toolpit>Zakon o sistemu državne pomoći BiH, dokument 49 na <a href="http://bit.ly/izvornidokumenti">http://bit.ly/izvornidokumenti</a></Toolpit>, da podnesu
		prijave za finansijsku podršku u sklopu grant šeme <b>„Jačanje konkurentnosti malih i srednjih
		preduzeća“ </b>za 2017. godinu</p>

		<p className="content_text">Akcioni plan FMPRO predstavlja glavni strateški okvir za razvoj poduzetništva i MSP-a u FBiH. Kao
		takav, Akcioni plan daje osvrt na prioritetne strateške ciljeve, konkretne aktivnosti i finansijski
		okvir za period 2016-2018. godina, te je usklađen sa prioritetima Vlade FBiH, Reformskom agendom
		za Bosnu i Hercegovinu za period 2015-2018. godina kao i Akcionim planom Vlade Federacije
		BiH 2015-2018. godina za provođenje Reformske agende. S tim u vezi, ova grant šema direktno
		doprinosi sljedećim strateškim prioritetima iz Akcionog plana:</p>


		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Tehnološki razvoj, </span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Stručno usavršavanje svih relevantnih osoba za malo i srednje poduzetništvo.</span></p>

		<p className="content_text">Kroz grant šemu pruža se podrška MSP-ovima koji djeluju u prioritetnim oblastima (obrada
		drveta, obrada metala, obrada tekstila, obuće i kože, obrada plastike, prehrambena industrija,
		informacione tehnologije) kako bi realizirali:<b>i) investicije usmjerene ka poboljšanju tehnološke
		opremljenosti, ii) programe obuke radne snage i iii) unaprijedili pristup tržištu, odnosno,
		promovirali proizvode na tržištima,</b> a s ciljem podizanja konkurentnosti MSP-a.</p>

		<p className="content_text">Podrška MSP-u kroz združivanje pojedinačnih mjera će dovesti do učinkovitijeg i kompletnijeg
		odgovora na njihove poslovne potrebe. Ovakav pristup će simultano pružiti podršku unapređenju
		kvaliteta i promocije proizvoda, razvoju potrebnih znanja i vještina uposlenih i lakšem pristupu
		tržištu, te će tako direktno povećati konkurentnost MSP-a, povećati produktivnost i omogućiti
		brži ekonomski rast. Ovo podrazumijeva udruživanje raspoloživih sredstava koja su planirana za
		realizaciju pojedinačnih mjera u okviru Akcionog plana Ministarstva u jedan finansijski paket, koji
		će moći odgovoriti na različite potrebe MSP-a.</p>


		<p className="content_text pos-rel">Pored navedenog, FMRPO je za stvaranje ove grant šeme koristio preporuke i nalaze analize lanaca vrijednosti, koje utvrđuju prioritetne industrijske sektore kao i specifične lance vrijednosti <Toolpit text="Analizu lanaca vrijednosti je u 2016. godini proveo UNDP na teritoriji 91 lokalne zajednice u Bosni i Hercegovini u okviru
		Projekta Lokalni integrirani razvoj (LIR)."></Toolpit> sa
		najvećim potencijalom za rast i stvaranje novih radnih mjesta.</p>


		<p className="content_text">Sredstva za realizaciju grant šeme osigurale su Vlada FBiH, kao i Evropska unija, putem Projekta
		Lokalnog integriranog razvoja (LIR), koji provodi UNDP u Bosni i Hercegovini.</p>


		<p className="content_text blue"><b>1.2 CILJEVI I PRIORITETNE OBLASTI GRANT ŠEME</b></p>
		<p className="content_text">Grant šema će podržati MSP u svrhu povećanja konkurentnosti kroz podršku sljedećih ciljeva:</p>


		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Jačanje MSP-a kroz tehnološko unapređenje poslovnih procesa i proizvodnje, te podršku
		uvođenju standarda i sistema kvaliteta, sa fokusom na sektore sa najvećim potencijalom za
		ekonomski rast.</b></span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Unapređenje znanja i vještine radnika radi unapređenja produktivnosti i kvaliteta finalnih
		proizvoda kao i sveobuhvatno jačanje ponude na tržištu rada u FBiH.</b></span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Povećanje konkurentnost MSP-ova kroz jačanje njihove sposobnosti da posluju na međunarodnim
		tržištima, jačanje tržišne pozicije i promocija izvoznih potencijala te umrežavanje sa inostranim
		tržištima i poslovnim partnerima.</span></p>

		<p className="content_text">U skladu s gore navedenim, ova grant šema se odnosi na sljedeće <b>3 prioritetne oblasti:</b></p>
		
		<div className="yellow_box">

			<p className="content_text"><b><u>Prioritetna oblast 1: </u>Tehnološko unapređenje MSP-a, unapređenje kvaliteta proizvoda
			i standardizacija poslovanja i proizvodnih procesa.</b></p>

			<p className="content_text"><b><u>Prioritetna oblast 2: </u> Prekvalifikacija i dokvalifikacija radnika radi postizanja višeg
			stepena produktivnosti i kreiranje radnih mjesta.</b></p>

			<p className="content_text"><b><u>Prioritetna oblast 3: </u> Pristup tržištu i promocija proizvoda MSP-a.</b></p>

		</div>

		<p className="content_text"><b>Jedan podnositelj prijave može podnijeti maksimalno 1 projektni prijedlog</b>, koji uključuje jednu
		ili više prioritetnih oblasti. Na primjer, u jednom projektnom prijedlogu MSP može podnijeti
		prijavu za podršku pod samo jednom prioritetnom oblašću ili u kombinaciji više oblasti, a u skladu
		sa specifičnim potrebama MSP-a. Ukoliko podnositelj prijave dostavi više od jednog projektnog
		prijedloga, nijedan projektni prijedlog se neće uzeti u razmatranje.</p>


		<p className="content_text blue"><b>1.3 OČEKIVANI MJERLJIVI REZULTATI ZA GRANT ŠEMU</b></p>
		<p className="content_text">Očekivani mjerljivi rezultati grant šeme su: </p>


		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />U roku od 12 mjeseci od realizacije grant šeme, minimalno 50 MSP-ova je ostvarilo povećanje
		prihoda (po mogućnosti od izvoznih poslova) u iznosu od minimalno 3% u odnosu na ostvareni
		prihod iz 2016. godine.</span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Kreirano najmanje 150 novih radnih mjesta kao rezultat unaprijeđene tehnološke opremljenosti,
		obučene radne snage i promocije podržanih MSP-ova.</span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Unaprijeđena znanja i kvalifikacija najmanje 200 osoba za bolji pristup tržištu rada.</span></p>


		<p className="content_text blue"><b>1.4 PRIHVATLJIVOST PODNOSITELJA PRIJAVE: KO MOŽE UČESTVOVATI U GRANT ŠEMI</b></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Kako bi se ispunili uvjeti prihvatljivosti, podnositelji prijave moraju biti: </span></p>

		<p className="content_text blue"><b>1.4.1 Prihvatljivi podnositelji prijave</b></p>

		<p className="content_text ml-10">-<span className="ml-10"><b>Pravna lica/privredna društva </b>(d.o.o. i d.d.), shodno Zakonu o poticanju razvoja male
		privrede FBiH, koji:</span></p>

		<p className="content_text ml-10">-<span className="ml-10">imaju sjedište na području FBiH;</span></p>

		<p className="content_text ml-10">-<span className="ml-10">nalaze se u većinskom privatnom vlasništvu državljana Bosne i Hercegovine;</span></p>

		<p className="content_text ml-10">-<span className="ml-10">posluju najmanje jednu godinu od dana objave ove grant šeme u Službenim novinama
		Federacije Bosne i Hercegovine;</span></p>

		<p className="content_text ml-10">-<span className="ml-10">imaju pozitivno poslovanje u 2016. godini te</span></p>

		<p className="content_text ml-10 pos-rel">-<span className="ml-10 pos-rel">imaju najmanje 5 (pet) zaposlenih na neodređeno vrijeme na dan 31.03.2017. godine unutar
		sljedećih područja Klasifikacije djelatnosti KD BiH 2010. <Toolpit text="Službeni glasnik Bosne i Hercegovine, broj 47/10."></Toolpit></span></p>


		<table id="table100 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-261 " />
					<col className="_idGenTableRowColumn-262 " />
				</colgroup>
				<thead>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">ŠIFRA DJELATNOSTI</p>
						</td>
						<td className="No-Table-Style CellOverride-105 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">OPIS DJELATNOSTI</p>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">C10</p>
						</td>
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">Proizvodnja prehrambenih proizvoda</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">C11.07</p>
						</td>
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">Proizvodnja osvježavajućih pića; proizvodnja mineralne vode i drugih flaširanih voda</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">C13</p>
						</td>
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">Proizvodnja tekstila</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">C14</p>
						</td>
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">Proizvodnja odjeće</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">C15</p>
						</td>
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">Proizvodnja kože i srodnih proizvoda</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">C16</p>
						</td>
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">Prerada drva i proizvoda od drva i pluta, osim namještaja; proizvodnja predmeta od slame i pletarskih materijala</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">C22.2</p>
						</td>
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">Proizvodnja proizvoda od plastičnih masa</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">C24</p>
						</td>
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">Proizvodnja baznih metala</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">C25</p>
						</td>
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">Proizvodnja gotovih  metalnih  proizvoda,  osim  mašina  i opreme, izuzev grane 25.4. Proizvodnja municije i oružja</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">C28</p>
						</td>
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">Proizvodnja mašina i uređaja, d. n.</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">C29</p>
						</td>
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">Proizvodnja motornih vozila, prikolica i poluprikolica</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">C31</p>
						</td>
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">Proizvodnja namještaja</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">J62</p>
						</td>
						<td className="No-Table-Style CellOverride-118 ">
							<p className="tekst-u-tabelama ">Računarsko programiranje, savjetovanje i djelatnosti u vezi s njima</p>
						</td>
					</tr>
				</tbody>
			</table>

		<p className="content_text mt-30 pos-rel"><b>Napomena: </b>Prijave podnositelja koji ne budu uskladili šifre djelatnosti sa Klasifikacijom djelatnosti
		KD BiH 2010 <Toolpit>Klasifikacija djelatnosti BiH, KD 2010. <a href="http://dissemination.bhas.ba/classifications/kdbih.aspx?l=b">http://dissemination.bhas.ba/classifications/kdbih.aspx?l=b.</a></Toolpit> biće odbačene i neće biti predmet daljnjeg razmatranja. Izbor korisnika vršit će
		se isključivo na osnovu šifre djelatnosti iz Obavijesti o razvrstavanju poslovnog subjekta prema
		djelatnosti, koju izdaje Zavod za statistiku FBiH.</p>


		<p className="content_text">Korisnici poticajnih grant sredstava od FMRPO-a iz 2016. godine ne mogu koristiti sredstva ove
		grant šeme u 2017. godini, kao ni oni MSP-ovi koji nisu opravdali prethodno dobivena poticajna
		sredstva FMRPO-a, kao i izvršili drug e preuzete obaveze u skladu sa odobrenim sredstvima.</p>

		<p className="content_text">Korisnici koji koriste grant sredstva FMRPO-a po programima sufinansiranja kredita sa bankama,
		nemaju pravo učešća u javnom konkursu do izvršenja ugovora koji su zaključili sa bankama.</p>


		<p className="content_text">MSP-i mogu podnijeti projektne prijedloge za <b>projekte definirane grant šemom isključivo
		samostalno.</b> Prijave više aplikanata (privrednih društava - d.o.o i d.d.) kroz jedan prijedlog neće
		biti uzete u obzir.</p>

		<p className="content_text"><b>MSP-i su odgovorni za realizaciju projekta, uključujući i finansijske obaveze.</b></p>

		<p className="content_text">MSP-i podnositelji prijedloga projekata trebaju ispunjavati sve uvjete definirane ovim smjernicama,
		odnosno, prijedlog projekta treba da bude pripremljen u skladu sa zahtjevima i karakteristikama
		datim u aplikacionom paketu i smjernicama, uključujući i obrazac projektnog prijedloga i sve
		odgovarajuće priloge.</p>


		<p className="content_text blue"><b>1.4.2 Neprihvatljivi podnositelji prijave </b></p>
		<p className="content_text">FMRPO neće odobriti podršku podnositeljima prijava koji:</p>



		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />su u postupku predstečajne nagodbe ili likvidacije;</span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />nemaju pozitivno poslovanje u 2016. godini;</span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />ne mogu pružiti na uvid bilans stanja i bilans uspjeha za 2016. godinu;</span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />nisu registrirani u Federaciji Bosne i Hercegovine;</span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />nisu ispunili obaveze vezane uz plaćanja dospjelih poreznih obaveza i obaveza za penziono i
		zdravstveno osiguranje u skladu sa zakonskim odredbama. Podnositelji prijave koji imaju sklopljen ugovor sa poreskim organima u Bosni i Hercegovini o servisiranju duga neće biti uzeti u razmatranje;</span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />nemaju podmirene sve obaveze prema svojim zaposlenicima po bilo kojoj osnovi;</span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />su osuđeni za kazneno djelo vezano za svoje poslovno ponašanje na temelju pravosnažne presude;</span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />su bili predmet pravosnažne presude za prevaru.</span></p>



		<p className="content_text blue"><b>1.5 DODJELA FINANSIJSKIH SREDSTAVA KROZ GRANT ŠEMU, IZNOS BESPOVRATNIH SREDSTAVA I UDIO SUFINASIRANJA</b></p>

		<p className="content_text">Ukupan iznos sredstava za finansiranje grant šeme iznosi do 2.000.000 KM, od čega FMRPO
		finansira do 1.550.000 KM, dok Evropska unija finansira do 450.000 KM.</p>

		<p className="content_text"><b>Indikativna raspodjela sredstava za svaku od prioritetnih oblasti je sljedeća: </b></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Prioritetna oblast 1:</b> Tehnološko unapređenje MSP-a, unapređenje kvaliteta proizvoda i
		standardizacija poslovanja i proizvodnih procesa: 1.200.000 KM, gdje FMRPO finansira do 950.000
		KM, a Evropska unija do 250.000 KM. </span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Prioritetna oblast 2:</b> Prekvalifikacija i dokvalifikacija radnika radi postizanja višeg stepena
		produktivnosti i kreiranje radnih mjesta: 500.000 KM, gdje FMRPO finansira do 300.000 KM, a
		Evropska unija do 200.000 KM. </span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Prioritetna oblast 3:</b> Pristup tržištu i promocija proizvoda MSP-a: 300.000 KM, gdje FMRPO
		finansira do 300.000 KM.</span></p>


		<p className="content_text"><b> Maksimalni iznos sredstava za jednu od prioritetnih oblasti je sljedeći: </b></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>Prioritetna oblast 1: Maksimalan iznos do 38.000 KM za jednu ili sve navedene stavke:</b>
		</span></p>


		<p className="content_text ml-10">- <span className="ml-10">ulaganja u materijalnu i nematerijalnu imovinu: do 30.000 KM i/ili</span></p>
		<p className="content_text ml-10">- <span className="ml-10">uvođenje/recertificiranje ISO, HACCP ili nekih drugih proizvodnih standarda: do 7.000 KM ili</span></p>
		<p className="content_text ml-10">- <span className="ml-10">uvođenje CE znaka: do 8.000 KM.</span></p>



		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>Prioritetna oblast 2: Maksimalan iznos do 17.000 KM za: </b>
		</span></p>
		<p className="content_text ml-10">- <span className="ml-10">obuku potrebne radne snage koja će rezultirati stvaranjem minimalno 5 novih radnih mjesta
		u MSP-u/podnositelju prijave, gdje učešće grant šeme po radniku može iznositi do 3.000 KM.</span></p>


		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>Prioritetna oblast 3: Maksimalan iznos do 10.000 KM za:</b>
		</span></p>

		<p className="content_text ml-10">- <span className="ml-10">unapređenje pristupa tržištu i promocije proizvoda na tržištu, za učešće grant šeme za
		sudjelovanje MSP-a na međunarodnim sajmovima van Bosne i Hercegovine.</span></p>

		<p className="content_text"><b>Napomena: </b> U skladu sa propisanim uvjetima iz tačke 1.2, <b>jedan podnositelj prijave može podnijeti
		maksimalno 1 projektni prijedlog u iznosu do 65.000 KM, </b> koji uključuje jednu ili više prioritetnih
		oblasti. Na primjer, u jednom projektnom prijedlogu, MSP može podnijeti prijavu za uvođenje ISO
		standarda u iznosu do 7.000 KM, obuku 5 radnika u iznosu od 15.000 KM i promociju proizvoda
		na međunarodnom sajmu u iznosu do 10.000 KM, ili ukupno 32.000 KM. Ukoliko podnositelj prijave dostavi više od jednog projektnog prijedloga, nijedan projektni prijedlog se neće uzeti
		u razmatranje. Također, ukoliko predloženi projekt prelazi navedene iznose koji su predmet
		finansiranja iz grant šeme, on neće biti uzet u razmatranje.</p>

		<p className="content_text">Za svaki projekt podnositelj prijave/MSP mora osigurati vlastito sufinansiranje u iznosu od
		minimalno 50% ukupnog iznosa za realizaciju predloženog projekta <b>(50% sufinansiranje MSP-a i
		50% sufinansiranje grant šeme).</b> Na primjer, ukoliko podnositelj prijave podnosi projekt ukupne
		vrijednosti od 130.000 KM, vlastito sufinansiranje mora iznositi minimalno 65,000 KM, dok
		finansiranje iz grant šeme može iznositi do 65.000 KM. Prednost će imati prijedlozi projekata u
		okviru kojih je vlastito učešće MSP-a iznad minimalno potrebnog učešća od 50%. Sufinansiranje
		MSP-a može biti samo u novcu, te se sufinansiranje u stvarima neće uzimati u obzir.</p>
		
		<p className="content_text">MSP će osigurati podatke o izdvojenim finansijskim sredstvima kroz prijavni obrazac i budžet,
		zajedno sa pismom namjere o minimalnom iznosu sufinansiranja, kojim se izražava spremnost da
		se alociraju MSP sredstva za ovu specifičnu namjenu.</p>


		<p className="content_text pos-rel">FMRPO će raspoloživa sredstva za ovu grant šemu uskladiti sa <b>pravilima državne potpore male
		vrijednosti</b> (de minimis potpora). Državna potpora male vrijednosti predstavlja potporu jednom
		privrednom subjektu za bilo koju svrhu koja se dodjeljuje jednom ili više puta i koja ne prelazi iznos
		od 200.000 EUR u tri uzastopne fiskalne godine, shodno Članu 2. stav (1) tačka c) Zakona o sistemu
		državne pomoći u Bosni i Hercegovini <Toolpit>Zakon o poticanju razvoja male privrede u FBiH, dokument 49 na <a href="http://bit.ly/izvornidokumenti">http://bit.ly/izvornidokumenti</a></Toolpit>(“Službeni glasnik BiH”, broj 10/12).</p>

		<p className="content_text">FMRPO zadržava pravo da ne raspodijeli sva raspoloživa sredstva u slučaju da kvalitet prijedloga
		projekata ne ispuni očekivanja i definirane kriterije.</p>

		<p className="content_text">U slučaju kada korisnik <b>ne ostvari planiranu razinu pokazatelja </b> navedenih u podnesenoj prijavi, FMRPO ima pravo <b>zatražiti od korisnika da izvrši povrat udjela uplaćenih sredstava</b> razmjerno
		neostvarenom udjelu pokazatelja.</p>

		<h2 className="blue_overlay_heading">2. PRAVILA JAVNOG KONKURSA</h2>

		<p className="content_text">Pravila Javnog konkursa definiraju opće kriterije i zahtjeve koje prijedlozi projekata moraju
		zadovoljiti kako bi bili uzeti u obzir za sufinansiranje.</p>

		<p className="content_text blue"><b>2.1 KRITERIJI ZA EVALUACIJU PROJEKTNIH PRIJEDLOGA</b></p>

		<p className="content_text">Grant šema „Jačanje konkurentnosti MSP-a“ će evaluirati prijave na osnovu sljedećih <b><u>osnovnih i
		dodatnih kriterija za odabir:</u></b></p>

		<p className="content_text blue"><b>Prioritetna oblast 1: Tehnološko unapređenje MSP-a, unapređenje kvaliteta proizvoda i
		standardizacija poslovanja i proizvodnih procesa</b></p>


		<p className="content_text"><b><u>Osnovni kriteriji:</u></b></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Predviđeno unapređenje tehnološke opremljenosti </b>će omogućiti kvantitativno poboljšanje
		proizvodnog procesa i pristup novim tržištima; </span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Planirana <b>investicija u nove tehnologije</b> će ostvariti povećanje prihoda u iznosu od minimalno 3%
		godišnje u 12 mjeseci od nabavke. Podnositelji prijave moraju u prijavi jasno predstaviti način na
		koji će doći do povećanja prihoda;</span></p>
		
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Planirana investicija u unapređenje tehnološke opremljenosti <b>neće rezultirati smanjenjem</b> broja
		zaposlenih;</span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />MSP je <b>osigurao finansijska sredstva </b>za sufinansiranje projekta u minimalnom iznosu zahtijevanom
		kroz ovaj javni poziv (minimalno 50% ukupnog iznosa za realizaciju predloženih projekata);</span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Predloženi projekti će biti <b>završeni najkasnije 8 mjeseci od dana potpisivanja Ugovora.</b></span></p>


		<p className="content_text"><b><u>Dodatni kriteriji:</u></b></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />MSP izvozi <b>50% i više prometa;</b></span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Predviđeno vlastito učešće MSP-a u ukupnoj vrijednosti projekta iznosi više od <b>minimalno
		zahtijevanih 50%;</b></span></p>


		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />MSP ima <b>6 i više zaposlenih;</b></span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />MSP je u većinskom vlasništvu <b>osoba mlađih od 35 godina ili u vlasništvu žena;</b></span></p>


		<p className="content_text d-flex d-flex-aac pos-rel"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15 pos-rel" />MSP posluje u jedinicama lokalne samouprave (JLS) koje su <b>izrazito nerazvijene ili nedovoljno
		razvijene</b><span className="ml-20"> <Toolpit>Zavod za programiranje razvoja FBiH, Socio-ekonomski pokazatelji po općinama u FBiH u 2015. godini, dokument 54 na
		<a href="http://bit.ly/izvornidokumenti">http://bit.ly/izvornidokumenti</a></Toolpit> <Toolpit text="Izrazito nerazvijeni JLS-i su: 1. Kupres (49,4), 2. Teočak (48,7), 3. Zavidovići (48,2), 4. Drvar (47,0), 5. Odžak (46,0), 6. Jajce
		(41,6), 7. Donji Vakuf (37,1), 8. B.Petrovac (34,4), 9. Ključ (32,1), 10. Vareš (26,2), 11.Domaljevac/Šamac (16,0), 12. Glamoč (15,4), 13. Pale
		(14,7), 14. Foča (-6,7), 15. B.Grahovo (-21,4), 16.Dobretići (-51,6)."></Toolpit> <Toolpit text="Nedovoljno razvijeni JLS-i su: 1. Velika Kladuša (72,6), 2. Gornji Vakuf/Uskoplje (70,9), 3. Stolac (70,9), 4. Fojnica (70,0), 5.
		Konjic (67,0), 6. Bugojno (66,7), 7. Olovo (63,2), 8. Trnovo (60,7), 9. Prozor (59,3), 10. Sapna (59,0), 11. Bosanska Krupa (57,7), 12. Sanski
		Most (57,0), 13. Maglaj (55,6), 14. Ravno (52,5)."></Toolpit></span> </span></p>


		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />MSP do sada <b>nije bio korisnik </b>poticajnih grant sredstava FMRPO-a;</span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> MSP će kroz projekt unapređenje tehnološke opremljenosti doprinijeti jačanju <b>sljedećih lanaca
		vrijednosti: </b> proizvodnja mašina, dijelova i materijala za autoindustriju; proizvodnja namještaja
		od masivnog drveta; proizvodnja lične zaštitne odjeće; programiranje i IT aplikacije; proizvodnja
		finalnih prehrambenih proizvoda;</span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />MSP obavlja pretežnu djelatnost proizvodnje <b>proizvoda sa visokim stepenom finalizacije;</b></span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Predviđeno unapređenje tehnološke opremljenosti će za rezultat imati <b>novo zapošljavanje;</b></span></p>

		<p className="content_text d-flex d-flex-aac pos-rel"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Predviđeno unapređenje tehnološke opremljenosti doprinosi <b>integraciji inovacija <Toolpit text="FMRPO koristi OECD definiciju za inovacije: “uvođenje novog ili značajno poboljšanog proizvoda (ili usluga), procesa,
		marketinških metoda, organizacionih metoda u poslovnim praksama, organizaciji radnog prostora ili odnosima sa vanjskim partnerima”
		(Frascati OECD, Paris (2002)."></Toolpit> u poslovanje MSP-a. </b></span></p>

		
		<p className="content_text blue"><b>Prioritetna oblast 2: Prekvalifikacija i dokvalifikacija radnika radi postizanja višeg stepena
		produktivnosti i kreiranja radnih mjesta</b></p>

		<p className="content_text"><b><u>Osnovni kriteriji:</u></b></p>


		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Predviđene obuke za potrebnu radnu snagu će osigurati <b>zapošljavanje minimalno 5 osoba</b> koje su
		prošle specifičnu obuku sufinansiranu kroz grant šemu;</span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />MSP je <b>osigurao finansijska sredstva </b>za sufinansiranje projekta u minimalnom iznosu zahtijevanom
		kroz ovaj javni poziv (minimalno 50% ukupnog iznosa za realizaciju predloženih projekata);</span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Predloženi projekti će biti <b>završeni najkasnije 8 mjeseci od dana potpisivanja Ugovora.</b></span></p>


		<p className="content_text"><b><u>Dodatni kriteriji: </u></b></p>
	
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> MSP izvozi <b>50% i više prometa;</b></span></p>


		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Predviđeno vlastito učešće MSP-a u ukupnoj vrijednosti projekta iznosi <b>od minimalno
		zahtijevanih 50%;</b></span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> MSP ima <b>6 i više zaposlenih;</b></span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> MSP je u većinskom vlasništvu <b>osoba mlađih od 35 godina ili u vlasništvu žena;</b></span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> MSP posluje u JLS-ima koji su <b>izrazito nerazvijeni ili nedovoljno razvijeni</b></span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> MSP do sada <b>nije bio korisnik</b> poticajnih grant sredstava FMRPO-a;</span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> MSP obavlja pretežnu djelatnost proizvodnje <b>proizvoda sa visokim stepenom finalizacije;</b></span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Predviđena obuka za potrebnu radnu snagu će rezultirati zapošljavanjem  <b>više od 5 osoba;</b></span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Predložena obuka za potrebnu radnu snagu predviđa zapošljavanje žena, mladih, povratnika
		i raseljenih osoba, osoba sa invaliditetom ili sa posebnim potrebama te ostalih socijalno
		ugroženih kategorija stanovništva (preko 70% od ukupnog broja osoba koje će biti zaposlene).</span></p>

		<p className="content_text blue"><b>Prioritetna oblast 3: Pristup tržištu i promocija proizvoda MSP-a</b></p>

		<p className="content_text"><b><u>Osnovni kriteriji: </u></b></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />MSP će kroz projekt otvoriti  <b>nova tržišta ili će proširiti prisustvo na postojećim tržištima; </b></span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />MSP će povećati <b>nivo izvoza za minimalno 5%</b> u poređenju sa 2016. finansijskom godinom; </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />MSP će stvoriti nove ili unaprijediti postojeće kanale komunikacije i alate  <b>za pristup tržištu i
		promociju na inostranim tržištima;</b></span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />MSP je  <b>osigurao finansijska sredstva</b> za sufinansiranje projekta u minimalnom iznosu zahtijevanom
		kroz ovaj javni poziv (minimalno 50% ukupnog iznosa za realizaciju predloženih projekata);</span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Predloženi projekti će biti <b>završeni najkasnije 8 mjeseci od dana potpisivanja Ugovora.</b></span></p>


		<p className="content_text"><b><u>Dodatni kriteriji: </u></b></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />MSP izvozi <b> 50% i više prometa; </b></span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Predviđeno vlastito učešće MSP-a u ukupnoj vrijednosti projekta iznosi   <b>više od minimalno
		zahtijevanih 50%; </b></span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />MSP ima  <b>6 i više zaposlenih;</b></span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />MSP je u većinskom vlasništvu <b>osoba mlađih od 35 godina ili u vlasništvu žena; </b></span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />MSP će kroz projekte pristup tržištu i promocija proizvoda doprinijeti  <b>jačanju sljedećih lanaca vrijednosti: </b> proizvodnja mašina, dijelova i materijala za autoindustriju; proizvodnja namještaja
		od masivnog drveta; proizvodnja lične zaštitne odjeće; programiranje i IT aplikacije; proizvodnja
		finalnih prehrambenih proizvoda;</span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />MSP posluje u JLS-ima koji su  <b>izrazito nerazvijeni ili nedovoljno razvijeni; </b></span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />MSP do sada <b>nije bio korisnik</b> poticajnih grant sredstava FMRPO-a; </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />MSP obavlja pretežnu djelatnost proizvodnje <b>proizvoda sa visokim stepenom finalizacije.</b> </span></p>

		<p className="content_text">Način evaluacije i bodovanja prijava prema administrativnim i tehničkim kriterijima detaljno je
		opisan u dijelu 4.</p>

		<h2 className="blue_overlay_heading">2.2 KORIŠTENJE SREDSTAVA GRANT ŠEME</h2>

		<h3 className="content_text blue"><b>Prihvatljive aktivnosti:</b></h3>

		<p className="content_text">Sredstva iz grant šeme će se usmjeriti na sljedeće aktivnosti za svaku prioritetnu oblast:</p>
		
		<p className="content_text blue mt-50"><b>Prioritetna oblast 1: Tehnološko unapređenje MSP-a, unapređenje kvaliteta proizvoda i
		standardizacija poslovanja i proizvodnih procesa</b></p>


		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> ulaganja u materijalnu i nematerijalnu imovinu vezanu uz <b>kupovinu novih tehnologija i opreme</b>npr. proizvodnih linija, mašina, uređaja, opreme itd.), uvođenje novih postupaka ili prerađivačkih
		metoda koje dovode do poboljšanja proizvodnje i/ili prerade i proizvoda većeg kvaliteta, transfer
		znanja radi poboljšanja proizvodnog ciklusa; </span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> ulaganje u materijalnu i nematerijalnu imovinu vezanu <b>za adaptaciju proizvodnog prostora </b>radi
		stvaranja adekvatnih radnih uvjeta za instaliranje i puštanje u rad nabavljene opreme i mašina kroz
		ovu grant šemu (na primjer: sitne rekonstrukcije za dostizanja potrebnog fizičkog i funkcionalnog standarda radnog prostora);</span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>diversifikacija proizvodnje</b> MSP-a u nove proizvode (novi proizvodi, nove proizvodne linije,
		povećanje palete ponude, usavršavanje proizvoda prema zahtjevu tržišta itd.);</span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>uvođenje sistema upravljanja kvalitetom i međunarodnih standarda;</b></span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> vršenje usluga vezanih uz projekt od <b>vanjskih konsultanata </b> koje nije vezano uz stalne ili periodične aktivnosti i koje se ne odnosi na uobičajene operativne troškove preduzeća, kao što su rutinske
		usluge poreznog savjetovanja, redovne pravne usluge ili oglašavanje, već koje su vezane uz projekt
		ulaganja, proizvod ili tehnologiju razvijenu na temelju ovog Javnog konkursa.</span></p>


		<p className="content_text blue mt-50"><b>Prioritetna oblast 2: Prekvalifikacija i dokvalifikacija radnika radi postizanja višeg stepena
		produktivnosti i kreiranja radnih mjesta</b></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b> usavršavanje postojeće radne snage unutar MSP-a</b>vezano uz ciljeve projekta i doprinosa njegovoj
		održivosti, kao što su obuka za korištenje novorazvijene ili integrirane tehnologije ili rada s novom
		opremom. Obuka koja je predmet finansiranja treba da osigura stvaranje novih kvalitetnih radnih
		mjesta, koja osiguravaju dovoljan izvor prihoda, sigurnost radnog mjesta te kvalitetno radno
		okruženje; </span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />usavršavanje <b>nove radne snage</b>  za potrebe MSP-a vezano uz ciljeve projekta i doprinosa njegovoj
		održivosti; </span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Mentorstvo, obuka i usavršavanje </b>za postojeću radnu snagu kroz praktični trening na radnom
		mjestu. Ova obuka može biti organizirana u drugoj kompaniji u Bosni i Hercegovini ili mentori iz
		inostranstva mogu gostovati u kompaniji u Bosni i Hercegovini.  </span></p>
		

		<p className="content_text blue mt-50"><b>Prioritetna oblast 3: Pristup tržištu i promocija proizvoda MSP-a</b></p>


		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>sudjelovanje na međunarodnim sajmovima </b> van Bosne i Hercegovine s ciljem promocije novih ili
		poboljšanih proizvoda proizvedenih kao rezultat ulaganja uz pomoć podrške FMRPO-a; </span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>izrada i distribucija promotivnih materijala </b>(video materijali, brošure, katalozi itd), sa posebnim
		fokusom na digitalni marketing i korištenje novih tehnologija u promociji proizvoda;  </span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>izrada strategija MSP-a za izvoz i promociju </b> kao i istraživanja potencijala ciljnih tržišta; </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>sudjelovanje na Business to Business skupovima</b>, uključujući i stručne skupove za razmjenu
		znanja i vještina, koji direktno doprinose poboljšanju izvoznog potencijala MSP-a.  </span></p>

		<p className="content_text blue"><b>2.2.2 Neprihvatljive aktivnosti:</b></p>
		<p className="content_text">Sredstva iz grant šeme se ne mogu koristiti za sljedeće aktivnosti:</p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> projekte kojima je za istu investiciju i iste prihvatljive troškove (ukupno ili djelomično) dodijeljena
		druga finansijska podrška od donatora ili finansirana kroz javne finansijske resurse FBiH;  </span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> kupovina zgrada ili zemljišta;  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> izgradnja objekata ili obnova i rekonstrukcija (osim navedenog pod tačkom 2.2.1. Prioritetna oblast
		1, stavka 2.);  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> adaptacija objekata za osobnu upotrebu;  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> kupovina, obnova, rekonstrukcija, modernizacija objekata za najam ili prodaju.  </span></p>


		<p className="content_text blue"><b>2.3 KATEGORIZACIJA PRIHVATLJIVIH I NEPRIHVATLJIVIH TROŠKOVA</b></p>

		<p className="content_text">Prilikom pripreme budžeta predloženih projekata (uključujući i sufinansiranje), podnositelji
		prijedloga trebaju znati da se u budžet mogu uključiti samo prihvatljivi troškovi, koji se odnose
		na predloženi projekt i nisu nastali prije datuma potpisivanja Ugovora, u skladu sa sljedećom
		kategorizacijom.</p>

		<p className="content_text">Da bi troškovi bili prihvatljivi, potrebno je da budu: i) neophodni za implementaciju predloženog
		projekta; ii) realni i predviđeni budžetom; iii) opravdani i mjerljivi i iv) nastali tokom implementacije
		projekta od MSP-a. Ova kategorizacija se odnosi kako na sredstva grant šeme, tako i na sredstva
		osigurana iz budžeta MSP-a, odnosno od projektnih partnera.</p>

		<br></br>
		<br></br>

		<table id="table101 " className="No-Table-Style TableOverride-1 custom_width_table mb-50">
				<colgroup>
					<col className="_idGenTableRowColumn-261 " />
					<col className="_idGenTableRowColumn-263 " />
					<col className="_idGenTableRowColumn-264 " />
					<col className="_idGenTableRowColumn-235 " />
				</colgroup>
				<thead>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Prioritetna oblast 1</p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Aktivnost</p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-78 ">Prihvatljivi troškovi</span></p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-78 ">Neprihvatljivi troškovi</span></p>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-114 ">
						<td className="No-Table-Style CellOverride-47 _idGenCellOverride-4 " rowspan="4 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">Tehnološko unapređenje MSP-a, unapređenje <br />kvaliteta proizvoda i standardizacija poslovanja <br />i proizvodnih procesa</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-9 ">Ulaganja u materijalnu i nematerijalnu imovinu vezanu uz kupovinu novih tehnologija i opreme</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Kupovina opreme i mašina za tehnološko unapređenje proizvodnje;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Obuka za nove tehnologije u sklopu nabavljene opreme i mašina; </li>
								<li className="tekst-u-tabelama--bullet list-bullet">Kupovina mjernih uređaja;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Unajmljivanje kratkoročnih eksperata za uvođenje novih postupaka ili prerađivačkih metoda;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi informatičko-komunikacijskih i audio/video rješenja (hardver) povezani sa projektom;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi informatičko-komunikacijskih i audio/video rješenja (softver);</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Nabavka patenata, autorskih prava, znanja ili drugih vrsta intelektualnog vlasništva;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Kupovina profesionalnih vozila za obavljanje osnovne djelatnosti (kamioni, dostavna vozila, viljuškari itd.);</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Posredni troškovi vezani za nabavku opreme i mašina (transportni troškovi, troškovi špedicije, carinjenja itd.).</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Kupovina polovne opreme i mašina;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Kupovina ličnih vozila;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Međunarodna putovanja;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Sirovine i poluproizvodi; </li>
								<li className="tekst-u-tabelama--bullet list-bullet">Kupovina vozila B kategorije.</li>
							</ul>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-114 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-9 ">Ulaganje u materijalnu i nematerijalnu imovinu vezanu za adaptaciju proizvodnog prostora radi stvaranje adekvatnih radnih uvjeta za instaliranje i puštanje u rad nabavljene opreme i mašina kroz ovu grant šemu</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Kupovina opreme u sklopu proširenja MSP-a;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Unajmljivanje kratkoročnih eksperata za proširenje MSP-a (nadzor, tehnički prijem, idejno rješenje i sl.);</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi informatičko-komunikacijskih i audio/video rješenja (hardver) povezani sa projektom;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi informatičko-komunikacijskih i audio/video rješenja (softver);</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Trošak adaptacije proizvodnog prostora radi stvaranja adekvatnih radnih uvjeta za instaliranje i puštanje u rad nabavljene opreme i mašina kroz ovu grant šemu (na primjer: sitne rekonstrukcije za dostizanja potrebnog fizičkog i funkcionalnog standarda radnog prostora);</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Nabavka patenata, autorskih prava, znanja ili drugih vrsta intelektualnog vlasništva;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Posredni troškovi vezani za nabavku opreme i mašina (transportni troškovi, troškovi špedicije, carinjenja itd.).</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Kupovina nekretnina;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Kupovina zemljišta;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Izgradnja objekata ili obnova i rekonstrukcija (osim navedenog pod tačkom 2.2.1. Prioritetna oblast 1, stavka 2.);</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Amortizacija dugotrajne imovine; </li>
								<li className="tekst-u-tabelama--bullet list-bullet">Kupovina vozila B kategorije.</li>
							</ul>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-6 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-9 ">Diversifikacija proizvodnje MSP-a u nove proizvode</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Kupovina i transport opreme i mašina;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi informatičko-komunikacijskih i audio/video rješenja (hardver i softver) koji su direktno povezani s provedbom projektnih aktivnosti;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Kupovina profesionalnih vozila za obavljanje osnovne djelatnosti (kamioni, dostavna vozila, viljuškari itd.);</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi vanjskih konsultanata;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Specijalizirana obuka za nove tehnologije/proizvodnju.</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Kupovina polovne opreme i mašina;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Kupovina vozila B kategorije;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Međunarodna putovanja;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Sirovine i poluproizvodi.</li>
							</ul>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-9 ">Uvođenje sistema upravljanja kvalitetom i međunarodnih standarda</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Finansiranje uvođenja standarda kvaliteta i CE znaka (uključujući i angažman konsultanata, obuku uposlenih, nabavku opreme itd.);</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi certifikacije;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi uvođenja i/ili nabavke softver rješenja.</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi dokumentacije koja nije neophodna za projekt.</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>

			<br></br>
			<br></br>

			<table id="table102 " className="No-Table-Style TableOverride-1 custom_width_table mb-50">
				<colgroup>
					<col className="_idGenTableRowColumn-173 " />
					<col className="_idGenTableRowColumn-265 " />
					<col className="_idGenTableRowColumn-266 " />
					<col className="_idGenTableRowColumn-43 " />
				</colgroup>
				<thead>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Prioritetna oblast 2</p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Aktivnost</p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-78 ">Prihvatljivi troškovi</span></p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-78 ">Neprihvatljivi troškovi</span></p>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-267 ">
						<td className="No-Table-Style CellOverride-47 _idGenCellOverride-4 " rowspan="3 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">Prekvalifikacija i dokvalifikacija <br />radnika radi postizanja višeg <br />stepena produktivnosti i <br />kreiranja radnih mjesta</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-9 ">Usavršavanje postojeće radne snage unutar MSP-a</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Trening i specijalizirane obuke;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi anganžmana eksperta i predavača;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi radnih materijala za obuke;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi iznajmljivanja specijalizirane opreme neophodne za obuke;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi smještaja i osvježenja za učesnike obuke i predavače;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Putni troškovi za učesnike (unutar Bosne i Hercegovine).</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Međunarodna putovanja;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Kupovina opreme.</li>
							</ul>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-6 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-9 ">Usavršavanje nove radne snage za potrebe MSP-a</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Trening i specijalizirane obuke;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi anganžmana eksperta i predavača;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi radnih materijala za obuke;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi iznajmljivanja specijalizirane opreme neophodne za obuke;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi smještaja i osvježenja za učesnike obuke i predavače;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Putni troškovi za učesnike (unutar Bosne i Hercegovine).</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Međunarodna putovanja;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Kupovina opreme.</li>
							</ul>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-268 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-9 ">Mentorstvo i usavršavanje za postojeću radnu snagu kroz praktični trening na radnom mjestu</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Praktična obuka na radnom mjestu;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi anganžmana mentora i predavača;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Putni troškovi za mentore i predavače iz Bosne i Hercegovine i inostranstva.</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Kupovina opreme.</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>

			<br></br>
			<br></br>

			<table id="table103 " className="No-Table-Style TableOverride-1 custom_width_table mt-50">
				<colgroup>
					<col className="_idGenTableRowColumn-174 " />
					<col className="_idGenTableRowColumn-269 " />
					<col className="_idGenTableRowColumn-270 " />
					<col className="_idGenTableRowColumn-16 " />
				</colgroup>
				<thead>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Prioritetna oblast 3</p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Aktivnost</p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-78 ">Prihvatljivi troškovi</span></p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-78 ">Neprihvatljivi troškovi</span></p>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-6 ">
						<td className="No-Table-Style CellOverride-47 _idGenCellOverride-4 " rowspan="3 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-12 ">Pristup tržištu i <br />promocija <br />proizvoda MSP-a</span></p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ">Sudjelovanje na međunarodnim sajmovima van Bosne i Hercegovine</p>
						</td>
						<td className="No-Table-Style CellOverride-47 _idGenCellOverride-7 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi zakupa sajamskog prostora do 15m2;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Najam, uređivanje i vođenje štanda pri sudjelovanju poduzetnika na određenom sajmu ili izložbi s ciljem predstavljanja proizvoda koji je nastao kao rezultat investicije za koju je odobrena podrška;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi vanjskih konsultanata za izradu promotivnih/izvoznih materijala i strategija MSP-a;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi smještaja i dnevnica tokom boravka na sajmu u iznosu do 50% predviđenog troška; </li>
								<li className="tekst-u-tabelama--bullet ">Putni troškovi za MSP u inostranstvu u iznosu do 50% predviđenog troška.</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-47 _idGenCellOverride-7 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi vezani za učešće na više od 2 sajma u godini dana. </li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi sudjelovanja na sajmovima izvan Evrope.</li>
							</ul>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-147 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ">Izrada i distribucija promotivnih materijala MSP-a</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi izrade i štampanja promotivnih materijala;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi izrade web stranice, video spota itd;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi organizacije manjih promotivnih događaja (pres konferencije, prezentacije na biznis forumima itd.);</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi iznajmljivanja specijalizirane opreme neophodne za promotivne događaje;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi prevoda.</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Međunarodna putovanja;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Kupovina opreme.</li>
							</ul>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ">Sudjelovanje na Business to Business skupovima</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Troškovi učešća za MSP;</li>
								<li className="tekst-u-tabelama--bullet list-bullet">Putni troškovi u Bosni i Hercegovini.</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<ul>
								<li className="tekst-u-tabelama--bullet list-bullet">Međunarodna putovanja.</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>


		<p className="content_text mt-50"><b>Napomena: Troškovi bankovnih garancija, kako je objašnjeno u sekciji 2.4, su prihvatljivi troškovi, koji se mogu finansirati isključivo iz vlastitog sufinansiranja.</b></p>
		<p className="content_text"><b>Korisnik sredstava će biti dužan pravdati trošak ukupnih sredstava predviđenih za realizaciju projekta, kako vlastitih tako i sredstava grant šeme.</b></p>
		<p className="content_text">Pravdanje će se vršiti sa odgovarajućom dokumentacijom, čiji datumi mogu biti samo poslije datuma potpisivanja Ugovora. Za sva neopravdana sredstva FMRPO će pokrenuti proceduru povrata, i to u iznosu koji je
		proporcionalan učešću sredstava grant šeme u realizaciji ukupnog projekta.</p>
		
		<p className="content_text mt-50"><b><u>Ostali neprihvatljivi troškovi:</u></b></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Troškovi zaposlenika (plate);  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Nadoknade za projektne partnere koji su angažirani na implementaciji aktivnosti;  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Dugovanja i rezerve za gubitke; </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Dugovanja po kamati;  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Troškovi režija i održavanja (voda, struja, grijanje, komunalni otpad, telekomunikacije, upravljanje
		zgradom, internet i sl.);  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Kupovina objekata i zemljišta;  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Adaptacija, obnova, rekonstrukcija, modernizacija objekata za ličnu upotrebu;  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Adaptacija obnova, rekonstrukcija, modernizacija objekata za najam ili prodaju; </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Izvršavanje radova ili osiguravanje robe, usluga, zemljišta i nekretnina za koje nije izvršeno plaćanje
		u gotovini, potkrijepljeno računima ili dokumentima iste dokazne vrijednosti;  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Doprinosi za dobrovoljna zdravstvena ili penzijska osiguranja koji nisu obavezni prema
		zakonodavstvu; </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Kazne i troškovi sudskog spora;  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Plaćanja neoporezivih bonusa zaposlenima;  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Bankovni troškovi za otvaranje i vođenje računa, naknade za finansijske transfere i drugi troškovi
		u potpunosti finansijske prirode;  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Kamate;  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Trošak police osiguranja imovine (uključuje i imovinu nabavljenu iz projekta);  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Ostali troškovi nespomenuti kao prihvatljivi;  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Studijske posjete;  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Stavke već finansirane u sklopu nekog drugog projekta;  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Kupovina i iznajmljivanje zemljišta i postojećih zgrada;  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Troškovi konverzije, nadoknada ili gubici prilikom konverzije valuta;
	  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Kreditiranje trećih osoba.  </span></p>
		
		<p className="content_text"><b>Budžet koji uključuje neprihvatljive troškove se neće smatrati validnim te prijava neće biti dalje
		razmatrana.</b></p>

		
		<p className="content_heading blue"><b>2.4 ZAHTJEVI KOJI SE ODNOSE NA KAPACITETE PODNOSITELJA PRIJAVE, EFIKASNO KORIŠTENJE SREDSTAVA I ODRŽIVOST REZULTATA PROJEKTA</b></p>

		<p className="content_text">Podnositelji prijave trebaju osigurati efikasnu upotrebu sredstava u skladu s načelima ekonomičnosti
		i djelotvornosti</p>

		<p className="content_text">U slučaju da korisnik grant šeme ne ostvari planirane rezultate, FMRPO ima pravo zatražiti povrat
		udjela isplaćenih sredstava razmjerno neostvarenim rezultatima.</p>	

		<p className="content_text">Po odobrenju projekata, MSP korisnici će <b>>morati dostaviti bankovnu garanciju </b> na ukupan iznos
		finansiranja od grant šeme. Bankovna garancija treba uključiti traženi iznos sufinansiranja i PDV-a.
		Bankovna garancija se treba izdati na period od 9 mjeseci (pokriva period trajanja projekta i jedan
		dodatni mjesec) od dana potpisivanja ugovora o dodjeli grant sredstava. Na primjer, ukoliko je
		iznos projekta 130.000 KM, gdje grant šema sufinansira 65.000 KM, iznos bankovne garancije
		će iznositi 65.000 KM sa PDV-om. Troškovi bankovnih garancija se mogu finansirati isključivo iz
		vlastitog sufinansiranja.</p>	

		<p className="content_text">Korisnici grant sredstava moraju osigurati <b>održivost projekta </b>, odnosno, tokom razdoblja od 2
		godine od završetka provedbe projekta moraju osigurati:</p>	


		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />održavanje broja uposlenih koji su zaposleni kao rezultat podrške grant šeme;  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />vlasništvo nad materijalnom ili nematerijalnom imovinom nabavljenoj kao rezultat podrške grant
		šeme ostaje nepromijenjeno, tj MSP ne smije prodavati, otuđivati, prebacivati ili na bilo koji drugi
		način izvršiti transfer nad vlasništvom nabavljenih stvari prema trećim fizičkim i pravnim licima;.  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />održavanje opreme i druge imovine nabavljene tokom projekta.  </span></p>


		<p className="content_text">Korisnici grant sredstava moraju dostavljati izvještaje FMRPO-u na godišnjem nivou u naredne
		2 godine, kako bi bio omogućen monitoring razvojnih efekata u MSP-u. U slučaju odstupanja ili
		nepoštivanja odredbi grant šeme, FMRPO će pokrenuti postupak za povrat sredstava.</p>	


		<p className="content_text"><b>Odobrena sredstva grant šeme se uplaćuju korisnicima sredstava iz jednog dijela u iznosu od
		100% odobrenih sredstava po potpisu Ugovora i izdavanja bankovne garancije.</b></p>	
		
		<h2 className="blue_overlay_heading">3. NAČINI PRIJAVE PRIJEDLOGA PROJEKTA:</h2>

		<p className="content_text blue"> 3.1 OBRAZAC PROJEKTNOG PRIJEDLOGA </p>	
		
		<p className="content_text">Prijava treba da sadrži sljedeće dokumente i priloge:</p>

		<p className="content_text">1. <span className="ml-15">Obrazac projektnog prijedloga;</span></p>

		<p className="content_text">2. <span className="ml-15">Budžet sa narativnim dijelom;</span></p>

		<p className="content_text">3. <span className="ml-15">Plan aktivnosti;</span></p>

		<p className="content_text">4. <span className="ml-15">Pismo namjere o minimalnom iznosu sufinansiranja;</span></p>

		<p className="content_text">5. <span className="ml-15">Sudsko rješenje o registraciji (prva registracija i aktuelni izvod), <em><u>Prilog I; </u></em></span></p>

		<p className="content_text">6. <span className="ml-15">Uvjerenje od Porezne uprave FBiH o izmirenju direktnih poreza i doprinosa (PIO/MIO i
		zdravstveno osiguranje) za sve uposlene, sa brojem ili popisom osiguranih osoba <em><u>Prilog II;</u></em></span></p>

		<p className="content_text">7. <span className="ml-15">Uvjerenje o poreznoj registraciji <em><u>Prilog III;</u></em> </span></p>

		<p className="content_text">8. <span className="ml-15">Obavijest o razvrstavanju subjekta prema djelatnosti (FBiH Zavod za statistiku) - <em><u>Prilog IV;</u></em></span></p>

		<p className="content_text">9. <span className="ml-15">Uvjerenje o izmirenim obavezama po osnovu PDV-a (ukoliko je obveznik po Zakonu o porezu
		na dodanu vrijednost u sistemu, ukoliko nije PDV obveznik, dostaviti Izjavu da pravno lice nije
		u sistemu PDV-a, ovjerenu od nadležnog upravnog organa) - <em><u>Prilog V;</u></em></span></p>

		<p className="content_text">10. <span className="ml-15">Bilans stanja i bilans uspjeha za 2016. g odinu ovjereni od nadležnog organa – <em><u>Prilog VI;</u></em></span></p>

		<p className="content_text">11. <span className="ml-15">Izjava o neosuđivanosti i nevođenju postupka po osnovu privrednog kriminala fizičke, odnosno,
		odgovorne osobe u pravnoj osobi <em><u>Prilog VII;</u></em> </span></p>

		<p className="content_text">12. <span className="ml-15">Izjava o nesudjelovanju u koruptivnim radnjama –  <em><u>Prilog VIII;</u></em> </span></p>

		<p className="content_text">13. <span className="ml-15">Izjava o korištenim potporama male vrijednosti <em><u>Prilog IX;</u></em> </span></p>

		<p className="content_text">14. <span className="ml-15">Potvrda o prebivalištu za odgovornu osobu (CIPS) – <em><u>Prilog X;</u></em>  </span></p>

		<p className="content_text">15. <span className="ml-15">Uvjerenje o državljanstvu Bosne i Hercegovine za većinskog vlasnika/vlasnike – <em><u>Prilog XI;</u></em> </span></p>

		<p className="content_text">16. <span className="ml-15">Potvrdu o izvršenom izvozu proizvoda ili usluge u 2016. godini od nadležne institucije (ukoliko
		je podnositelj prijave izvoznik) – <em><u>Prilog XII;</u></em></span></p>

		<div className="yellow_box">

			<p className="content_text">Sve izjave sa tekstom koje je sačinio FMRPO treba da budu potpisane od odgovorne
			osobe – podnositelja zahtjeva. Sve izjave, izuzev Izjave o korištenim potporama male
			vrijednosti, trebaju biti ovjerene i od nadležnih organa.</p>

			<p className="content_text">Svi priloženi dokumenti moraju biti izdani od nadležnih sudskih ili upravnih organa.
			Priloženi dokumenti ne smiju biti stariji od 3 (tri) mjeseca od dana objavljivanja
			ovog javnog konkursa u „Službenim novinama Federacije BiH“, osim dokumenata
			koji se izdaju jednom (uvjerenje o poreznom broju itd.). Ukoliko se radi o kopijama
			priloženih dokumenata, one moraju biti ovjerene od nadležnih organa. U slučaju da
			dostavljena dokumentacija nije ovjerena u skladu sa ovom uputom, prijava će biti
			odbačena.</p>

			<p className="content_text">Ukoliko se ista dokumentacija odnosi na više prioritetnih oblasti, podnositelji prijave
			trebaju dostaviti samo jedan primjerak tražene dokumentacije.</p>

		</div>
		
		<p className="content_text">Dodatno, podnositelji prijava trebaju uzeti u obzir da za svaku prioritetnu oblast moraju podnijeti <b>nekoliko dodatnih obaveznih dokumenata sastavni dio projektnog prijedloga (original ili
		kopija). To su:</b></p>

		<p className="content_text blue"><b>3.1.1 Dokumenti za prijave u okviru Prioritetne oblasti 1: Tehnološko unapređenje MSP-a,
		unapređenje kvaliteta proizvoda i standardizacija poslovanja i proizvodnih procesa</b></p>
		
		<p className="content_text">Pored dokumenatcije i priloga opisanih u sekciji 3.1, podnositelji prijava moraju podnijeti sljedeće
		dokumente kao sastavni dio projektnog prijedloga:</p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />u slučaju nabavke opreme, mašina i profesionalnih vozila: i) odgovarajuća ponuda ili predračun od
		dobavljača sa uključenom tehničkom specifikacijom;  </span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />u slučaju nabavke hardvera i softvera: i) odgovarajuća ponuda ili predračun od dobavljača sa
		uključenom tehničkom specifikacijom;  </span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />u slučaju nabavke patenata: i) odgovarajuća ponuda ili predračun od vlasnika patenta;  </span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />u slučaju adaptacije poslovnih/proizvodnih prostorija: i) predmjer i predračun radova;  </span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />u slučaju uvođenja sistema upravljanja kvalitetom i međunarodnih standarda: i) ponuda i
		predračun od certifikacijske i/ili konsultantske kuće.  </span></p>




		<p className="content_text blue"><b>3.1.2 Dokumenti za prijave u okviru Prioritetne oblasti 2: Prekvalifikacija i dokvalifikacija
		radnika radi postizanja višeg stepena produktivnosti i kreiranja radnih mjesta</b></p>

		<p className="content_text">Pored dokumenatcije i priloga opisanih u sekciji 3.1, podnositelji prijava moraju podnijeti sljedeće
		dokumente kao sastavni dio projektnog prijedloga:</p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />za internu obuku: interni pravilnik o obuci, program obuke i troškovnik za prekvalifikaciju ili
		dokvalifikaciju radne snage;  </span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> za vanjsku obuku: odgovarajuća ponuda i predračun za obuku sa programom obuke od relevantnog
		eksperta/organizacije; </span></p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />u slučaju iznajmljivanja opreme: i) odgovarajuća ponuda ili predračun od dobavljača sa uključenom
		tehničkom specifikacijom.  </span></p>



		<p className="content_text blue"><b>3.1.3 Dokumenti za prijave u okviru Prioritetne oblasti 3: Pristup tržištu i promocija
		proizvoda</b></p>

		<p className="content_text">Pored dokumenatcije i priloga opisanih u sekciji 3.1, podnositelji prijava moraju podnijeti sljedeće
		dokumente kao sastavni dio projektnog prijedloga:</p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> predračun troškova zakupa sajamskog prostora i/ili  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> u slučaju izrade i štampanja promotivnih materijala, web stranice, video spota itd: i) odgovarajuća
		ponuda ili predračun od dobavljača, sa uključenom tehničkom specifikacijom. </span></p>



		<div className="yellow_box mt-40">
			<p className="content_text">Prijedlozi projekata se podnose na jednom od službenih jezika Bosne i Hercegovine.
			Rukom pisane prijave i nepotpune prijave se neće uzeti u razmatranje. Prijave koje nisu
			kompletno popunjene i koje ne sadrže zahtijevanu dokumentaciju, bit će odbačene
			i neće biti predmet daljnjeg razmatranja Komisije za izbor korisnika sredstava grant
			šeme (u daljnjem tekstu: Komisija).</p>
		</div>

		<p className="content_heading blue mt-40"><b>3.2. NAČIN PODNOŠENJA APLIKACIJE</b></p>
		
		<p className="content_text mt-25">Popunjen obrazac projektnog prijedloga s pratećom dokumentacijom dostaviti <b>u originalnom
		primjerku</b> A4 formata i <b>uvezano, te jedan elektronski primjerak </b> na USB stiku.</p>

		<p className="content_text">Prijave moraju biti dostavljene u zatvorenoj koverti preporučenom poštom na sljedeću adresu:</p>

		<p className="content_text ta-c">Federalno ministarstvo razvoja, poduzetništva i obrta</p>
		<p className="content_text ta-c">Ulica Ante Starčevića b.b. (Hotel “ERO”)</p>
		<p className="content_text ta-c">88 000 Mostar</p>


		<p className="content_text">s naznakom: </p>

		<p className="content_text"><b>Ne otvarati – po Javnom konkursu za odabir korisnika grant šeme „Jačanje konkurentnosti
		MSP-a, prioritetna oblast XX (navesti prioritetnu/e oblast/i)“</b></p>

		<p className="content_text">Na poleđini koverte obavezno navesti:</p>
		<p className="content_text d-flex d-flex-aac ml-25"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>naziv subjekta;</b>  </span></p>
		<p className="content_text d-flex d-flex-aac ml-25"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>adresu i kontakt telefon;</b>  </span></p>
		<p className="content_text d-flex d-flex-aac ml-25"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>ovjeriti pečatom. </b>  </span></p>


		<p className="content_text">Prijave dostavljene drugim putem (npr. putem faksa ili e-maila), neće biti uzete u razmatranje.
		Prijave se ne mogu dostaviti lično.</p>

		<p className="content_heading blue"><b>3.3 KRAJNJI ROK ZA PODNOŠENJE PRIJAVA</b></p>
		
		<p className="content_text mt-25">Krajnji rok za podnošenje prijava je <b><u>petak, 05. maj, 2017. godine,</u></b> što potvrđuje datum na otpremnici,
		poštanski žig ili priznanica. Prijave podnesene nakon isteka roka se neće uzeti u razmatranje.
		Naknadne dopune projektnog prijedloga, objašnjenja, obrazloženja i sl. dostavljeni nakon isteka
		roka se neće uzimati u obzir.</p>

		<p className="content_heading blue"><b>3.4 DODATNE INFORMACIJE</b></p>

		<p className="content_text mt-25">Sva dodatna pitanja u vezi s ovim Javnim konkursom se mogu dostaviti putem elektronske pošte,
		najkasnije u roku od <b>7 dana</b> prije isteka roka za podnošenje prijava, sa jasno naznačenim nazivom
		konkursa u predmetu poruke, i to na sljedeću e-mail adresu <b>grant2017@fmrpo.gov.ba.</b></p>

		<p className="content_text">Svi odgovori na pitanja koja mogu biti relevantna i za ostale podnositelje prijava, redovno će se
		objavljivati na web stranici FMRPO-a: <a href="http://fmrpo.gov.ba" className="blue">http://www.fmrpo.gov.ba</a> .</p>

		<p id="epp" className="content_text">FMRPO će organizirati informativne dane za sve zainteresirane MSP-ove. Termini i lokacije
		održavanja informativnih dana bit će objavljeni na web stranici FMRPO-a:  <a href="http://fmrpo.gov.ba" className="blue">http://www.fmrpo.gov.ba</a> </p>


		<h2 className="blue_overlay_heading mt-70">4. EVALUACIJA PRIJEDLOGA PROJEKATA</h2>

		<p className="content_text">Sve dostavljene prijave će se ocjenjivati u dva koraka, u skladu sa ispod propisanim kriterijima, pri
		čemu je prvi korak eliminatoran, dok se u drugom koraku ocjenjuje kvalitet podnesenog prijedloga
		te kapaciteti MSP-a za njegovu realizaciju.</p>

		<p className="content_text">Podnesene projektne prijedloge provjerava i ocjenjuje Komisija, sastavljena od imenovanih
		predstavnika FMRPO-a.</p>

		<p className="content_heading blue mt-40"><b>4.1 ISPUNJENOST ADMINISTRATIVNIH ZAHTJEVA</b></p>

		<p className="content_text mt-25">U sklopu ovog koraka, po prijemu i ot varanju prijava, ocjenjuju se sljedeći elementi:</p>

		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Da li je prijedlog projekta stigao u utvrđenom roku. Ukoliko nije, prijedlog se automatski odbija.  </span></p>
		<p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Da li prijedlog projekta ispunjava sve <b>administrativne kriterije</b> navedene u listi ispod. Ukoliko ne
		zadovoljava i ukoliko je odgovor na jedno od pitanja ispod „NE“, prijedlog projekta neće biti dalje
		razmatran.</span></p>


		<p className="content_text">Administrativni kriteriji koje prijedlozi projekata moraju zadovoljiti za svaku prioritetnu oblast su
		navedeni u sljedećim odjeljcima.</p>


		<p className="content_text blue mt-50"><b>4.1.1 Administrativni kriteriji za Prioritetnu oblast 1: </b></p>


		<table id="table104 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-271 " />
					<col className="_idGenTableRowColumn-272 " />
					<col className="_idGenTableRowColumn-168 " />
					<col className="_idGenTableRowColumn-168 " />
				</colgroup>
				<thead>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-182 _idGenCellOverride-5 " colspan="4 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">ISPUNJENOST ADMINISTRATIVNIH ZAHTJEVA I KRITERIJA ZA PRIORITETNU OBLAST 1</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="blue CharOverride-15 ">Ispunjenost administrativnih zahtjeva:</span></p>
						</td>
						<td className="No-Table-Style CellOverride-183 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="blue CharOverride-15 ">DA</span></p>
						</td>
						<td className="No-Table-Style CellOverride-176 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="blue CharOverride-15 ">NE</span></p>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Podnositelj je pravno lice/privredne društvo (d.o.o. ili d.d. unutar definiranih oblasti djelatnosti)</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body "><span className="CharOverride-65 ">&#160;</span></p>
						</td>
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body "><span className="CharOverride-65 ">&#160;</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Podnositelj ima sjedište na području FBiH</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">MSP se nalazi u većinskom privatnom vlasništvu državljana Bosne i Hercegovine</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">MSP posluje najmanje 1 godinu</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body "><span className="CharOverride-65 ">&#160;</span></p>
						</td>
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body "><span className="CharOverride-65 ">&#160;</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">MSP ima pozitivno poslovanje u 2016. godini</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">MSP ima najmanje 5 zaposlenih na neodređeno vrijeme na dan 31.03.2017. godine</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Traženi budžet prijedloga projekta koji će se finansirati iz grant šeme ne prelazi 65.000 KM</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Podnositelj prijave je podnio 1 projektni prijedlog</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Popunjen Obrazac projektnog prijedloga</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body "><span className="CharOverride-65 ">&#160;</span></p>
						</td>
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body "><span className="CharOverride-65 ">&#160;</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Popunjen budžet projektnog prijedoga, koji uključuje minimalni iznos za sufinansiranje</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Budžet projekta sadrži isključivo prihvatljive troškove i bez grešaka kalkuliranja</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Popunjen Plan aktivnosti u traženom formatu</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body "><span className="CharOverride-65 ">&#160;</span></p>
						</td>
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body "><span className="CharOverride-65 ">&#160;</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Popunjeno pismo namjere o minimalnom iznosu sufinansiranja </p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Trajanje aktivnosti je u skladu sa propisanim maksimalnim trajanjem do 8 mjeseci</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Sudsko rješenje o registraciji (prva registarcija i aktuelni izvod)</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Uvjerenje od porezne uprave Federacije BiH o izmirenju poreza i doprinosa za sve uposlene, sa brojem ili popisom osiguranih osoba </p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Uvjerenje o poreznoj registraciji</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Obavijest o razvrstavanju subjekta prema djelatnosti</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">Uvjerenje o izmirenim obavezama po osnovu PDV-a (ukoliko je obveznik po Zakonu o porezu na dodanu vrijednost ili izjava da pravno lice nije u sistemu PDV-a)</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">Bilans stanja i bilans uspjeha za 2016. godinu ovjereni od nadležnog organa</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">Izjava o neosuđivanosti i nevođenju postupka po osnovu privrednog kriminala fizičke, odnosno, odgovorne osobe u pravnoj osobi</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">Izjava o nesudjelovanju u koruptivnim radnjama</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">Izjava o korištenim potporama male vrijednosti</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">Potvrda o prebivalištu za odgovornu osobu</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">Uvjerenje o državljanstvu za većinskog vlasnika/e</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">Potvrdu o izvršenom izvozu proizvoda ili usluge u 2016. godini od nadležne institucije (ukoliko je podnositelj prijave izvoznik)</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-273 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">U slučaju nabavke opreme, mašina i profesionalnih vozila, podnositelj je dostavio: i) odgovarajuću ponudu ili predračun od dobavljača, sa uključenom tehničkom specifikacijom</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">U slučaju nabavke hardvera i softvera, podnositelj je dostavio: i) odgovarajuću ponudu ili predračun od dobavljača sa uključenom tehničkom specifikacijom</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">U slučaju nabavke patenata, podnositelj je dostavio: i) odgovarajuću ponudu ili predračun od relevantne institucije</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">U slučaju adaptacije poslovnih/proizvodnih prostorija, podnositelj je dostavio: i) predmjer i predračun radova </p>
						</td>
						<td className="No-Table-Style CellOverride-187 " />
						<td className="No-Table-Style CellOverride-188 " />
						<td className="No-Table-Style CellOverride-188 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-273 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">U slučaju uvođenja sistema upravljanja kvalitetom i međunarodnih standarda, podnositelj je dostavio: i) ponudu i predračun od certifikacijske ili konsultantske kuće</p>
						</td>
						<td className="No-Table-Style CellOverride-187 " />
						<td className="No-Table-Style CellOverride-188 " />
						<td className="No-Table-Style CellOverride-188 " />
					</tr>
				</tbody>
			</table>

		
		<p className="content_text blue mt-50"><b>4.1.2 Administrativni kriteriji za prioritetnu oblast 2:</b></p>


		<table id="table105 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-274 " />
					<col className="_idGenTableRowColumn-272 " />
					<col className="_idGenTableRowColumn-185 " />
					<col className="_idGenTableRowColumn-165 " />
				</colgroup>
				<thead>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-179 " colspan="4 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">ISPUNJENOST ADMINISTRATIVNIH ZAHTJEVA I KRITERIJA ZA PRIORITETNU OBLAST 2</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="blue CharOverride-15 ">Ispunjenost administrativnih zahtjeva:</span></p>
						</td>
						<td className="No-Table-Style CellOverride-183 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="blue CharOverride-15 ">DA</span></p>
						</td>
						<td className="No-Table-Style CellOverride-176 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="blue CharOverride-15 ">NE</span></p>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Podnositelj je pravno lice/privredne društvo (d.o.o. ili d.d. unutar definiranih oblasti djelatnosti)</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">&#160;</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Podnositelj ima sjedište na području FBiH</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">MSP se nalazi u većinskom privatnom vlasništvu državljana Bosne i Hercegovine</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">MSP posluje najmanje 1 godinu</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">&#160;</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">MSP ima pozitivno poslovanje u 2016. godini</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">MSP ima najmanje 5 zaposlenih na neodređeno vrijeme na dan 31.03.2017. godine</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Traženi budžet prijedloga projekta, koji će se finansirati iz grant šeme, ne prelazi 65.000 KM</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Podnositelj prijave je podnio 1 projektni prijedlog</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Popunjen Obrazac projektnog prijedloga</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">&#160;</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Popunjen budžet projektnog prijedoga koji uključuje minimalni iznos za sufinansiranje</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Budžet projekta sadrži isključivo prihvatljive troškove i bez grešaka kalkuliranja</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Popunjen Plan aktivnosti u traženom formatu</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">&#160;</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Popunjeno pismo namjere o minimalnom iznosu sufinansiranja </p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Trajanje aktivnosti je u skladu sa propisanim maksimalnim trajanjem do 8 mjeseci</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Sudsko rješenje o registraciji (prva registarcija i aktuelni izvod)</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Uvjerenje od porezne uprave Federacije BiH o izmirenju poreza i doprinosa za sve uposlene, sa brojem ili popisom osiguranih osoba </p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Uvjerenje o poreznoj registraciji</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Obavijest o razvrstavanju subjekta prema djelatnosti</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Uvjerenje o izmirenim obavezama po osnovu PDV-a (ukoliko je obveznik po Zakonu o porezu na dodanu vrijednost ili izjava da pravno lice nije u sistemu PDV-a)</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Bilans stanja i bilans uspjeha za 2016. godinu ovjereni od nadležnog organa</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Izjava o neosuđivanosti i nevođenju postupka po osnovu privrednog kriminala fizičke, odnosno, odgovorne osobe u pravnoj osobi</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Izjava o nesudjelovanju u koruptivnim radnjama</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Izjava o korištenim potporama male vrijednosti</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Potvrda o prebivalištu odgovorne osobe</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Uvjerenje o državljanstvu za većinskog vlasnika/e</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Potvrda o izvršenom izvozu proizvoda ili usluge u 2016. godini od nadležne institucije (ukoliko je podnositelj prijave izvoznik)</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Podnositelj je dostavio interni pravilnik o obuci, program obuke i troškovnik za prekvalifikaciju ili dokvalifikaciju radne snage</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ">Podnositelj je dostavio odgovarajuću ponudu i predračun za obuku od relevantnog eksperta/organizacije</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">U slučaju iznajmljivanja opreme, podnositelj je dostavio: i) odgovarajuću ponudu ili predračun od dobavljača, sa uključenom tehničkom specifikacijom</p>
						</td>
						<td className="No-Table-Style CellOverride-187 " />
						<td className="No-Table-Style CellOverride-188 " />
						<td className="No-Table-Style CellOverride-188 " />
					</tr>
				</tbody>
			</table>



		<p className="content_text blue mt-50"><b>4.1.3 Administrativni kriteriji za Prioritetnu oblast 3: </b></p>

		<table id="table106 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-274 " />
					<col className="_idGenTableRowColumn-272 " />
					<col className="_idGenTableRowColumn-275 " />
					<col className="_idGenTableRowColumn-185 " />
				</colgroup>
				<thead>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-189 _idGenCellOverride-2 " colspan="4 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">ISPUNJENOST ADMINISTRATIVNIH ZAHTJEVA I KRITERIJA ZA PRIORITETNU OBLAST 3</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-190 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="blue CharOverride-15 ">Ispunjenost administrativnih zahtjeva:</span></p>
						</td>
						<td className="No-Table-Style CellOverride-191 " />
						<td className="No-Table-Style CellOverride-190 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="blue CharOverride-15 ">DA</span></p>
						</td>
						<td className="No-Table-Style CellOverride-190 ">
							<p className="nalovi-u-tabelama ParaOverride-2 "><span className="blue CharOverride-15 ">NE</span></p>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">Podnositelj je pravno lice/privredne društvo (d.o.o. ili d.d. unutar definiranih oblasti djelatnosti)</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">&#160;</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">Podnositelj ima sjedište na području FBiH</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">MSP se nalazi u većinskom privatnom vlasništvu državljana Bosne i Hercegovine</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">MSP posluje najmanje 1 godinu</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">&#160;</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">MSP ima pozitivno poslovanje u 2016. godini</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">MSP ima najmanje 5 zaposlenih na neodređeno vrijeme na dan 31.03.2017. godine</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">Traženi budžet prijedloga projekta, koji će se finansirati iz grant šeme, ne prelazi 65.000 KM</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">Podnositelj prijave je podnio 1 projektni prijedlog</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">Popunjen Obrazac projektnog prijedloga</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">&#160;</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">Popunjen budžet projektnog prijedloga koji uključuje minimalni iznos za sufinansiranje</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">Budžet projekta sadrži isključivo prihvatljive troškove i i bez grešaka kalkuliranja</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">Popunjen Plan aktivnosti u traženom formatu</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">&#160;</p>
						</td>
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">&#160;</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">Popunjeno pismo namjere o minimalnom iznosu sufinansiranja </p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">Trajanje aktivnosti je u skladu sa propisanim maksimalnim trajanjem do 8 mjeseci</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ">Sudsko rješenje o registraciji (prva registarcija i aktuelni izvod)</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ParaOverride-13 ">Uvjerenje od porezne uprave Federacije BiH o izmirenju poreza i doprinosa za sve uposlene, sa brojem ili popisom osiguranih osoba </p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ParaOverride-13 ">Uvjerenje o poreznoj registraciji</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ParaOverride-13 ">Obavijest o razvrstavanju subjekta prema djelatnosti</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ParaOverride-13 ">Uvjerenje o izmirenim obavezama po osnovu PDV-a (ukoliko je obveznik po Zakonu o porezu na dodanu vrijednost ili izjava da pravno lice nije u sistemu PDV-a)</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-186 ">
							<p className="body ParaOverride-13 ">Bilans stanja i bilans uspjeha za 2016. godinu ovjereni od nadležnog organa</p>
						</td>
						<td className="No-Table-Style CellOverride-185 " />
						<td className="No-Table-Style CellOverride-186 " />
						<td className="No-Table-Style CellOverride-186 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">Izjava o neosuđivanosti i nevođenju postupka po osnovu privrednog kriminala fizičke, odnosno, odgovorne osobe u pravnoj osobi</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">Izjava o nesudjelovanju u koruptivnim radnjama</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">Izjava o korištenim potporama male vrijednosti</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">Potvrda o prebivalištu za odgovornu osobu</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">Uvjerenje o državljanstvu za većinskog vlasnika/e</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">Potvrda o izvršenom izvozu proizvoda ili usluge u 2016. godini od nadležne institucije (ukoliko je podnositelj prijave izvoznik)</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">Podnositelj je dostavio predračun troškova zakupa sajamskog prostora</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-273 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="body ParaOverride-13 ">U slučaju izrade i štampanja promotivnih materijala, web stranice, video spota, podnositelj je dostavio: i) odgovarajuću ponudu ili predračun od dobavljača sa uključenom tehničkom specifikacijom</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 " />
						<td className="No-Table-Style CellOverride-176 " />
					</tr>
				</tbody>
			</table>



		<p className="content_text blue mt-60"><b>4.2 EVALUACIJA PRIJEDLOGA</b></p>

		<p className="content_text">Evaluaciju prijedloga projekata koji su prošli administrativnu provjeru će vršiti Komisija. Evaluacija
		prijedloga uključuje ocjenu relevantnosti, njegovog doprinosa i učinkovitosti, te provodljivosti i
		održivosti, a sve u skladu s kri terijima za ocjenjivanje.</p>

		<p className="content_text"><b>Evaluacijski kriteriji </b>omogućuju da se kvalitet prijavljenih prijedloga projekata ocjenjuje u odnosu
		na ciljeve i prioritete postavljene u Javnom konkursu, te da se sredstva iz grant šeme dodjeljuju za
		aktivnosti koje doprinose jačanju konkurentnosti MSP-a u Federaciji BiH.</p>


		<p className="content_text">Svi prijedlozi, bez obzira da li se odnose na jednu ili više prioritetnih oblasti, će biti evaluirani na
		osnovu jedinstvene skale, prikazane u nastavku.</p>

		<p className="content_text">Evaluacijski kriteriji su podijeljeni na kategorije i podkategorije, gdje se svaka podkategorija boduje
		u rasponu od 1 do 5, pri čemu je: 0 = vrlo loše; 1 = loše, 2 = zadovoljavajuće; 3 = dobro, 4 = vrlo
		dobro; 5 = odlično.</p>



		<p className="content_text"><b>Evaluacijska skala </b></p>

		<table id="table107 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-276 " />
					<col className="_idGenTableRowColumn-231 " />
					<col className="_idGenTableRowColumn-250 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-179  pos-rel" rowspan="2 ">
							<p className="nalovi-u-tabelama ParaOverride-2 pos-rel">Osnovni kriteriji: relevantnost prijedloga</p>
							<span className="CharOverride-11 "> <Toolpit text="Projekti koji ne dobiju minimalno 10 bodova u kategoriji „osnovni kriteriji: relevantnost prijedloga“ za svaku prioritetnu oblast
							za koju se podnosi prijedlog se neće uzimati u daljnje razmatranje."></Toolpit> </span>
						</td>
						<td className="No-Table-Style CellOverride-192 " />
						<td className="No-Table-Style CellOverride-179 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Maksimalan broj bodova</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-192 " />
						<td className="No-Table-Style CellOverride-179 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">40</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Da li će predviđeno unapređenje tehnološke opremljenosti omogućiti kvantitativno poboljšanje proizvodnog procesa i pristup novim tržištima? (Prioritetna oblast 1)</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">DA - 5; NE - 0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Da li će planirana investicija u nove tehnologije ostvariti povećanje prihoda u iznosu od minimalno 3% godišnje u 12 mjeseci od nabavke? (Prioritetna oblast 1)</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">DA - 5; NE - 0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Da li planirana investicija u unapređenje tehnološke opremljenosti neće rezultirati smanjenem broja zaposlenih? (Prioritetna oblast 1)</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">DA - 5; NE - 0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Da li će predviđene obuke za potrebnu radnu snagu osigurati zapošljavanje minimalno 5 osoba koje su prošle specifičnu obuku sufinansiranu kroz grant šemu? (Prioritetna oblast 2)</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 pos-rel">DA – 2x5<span className="CharOverride-11 "> <Toolpit text="S obzirom na važnost ovog kriterija, maksimalni broj bodova će se uduplati."></Toolpit></span>; NE - 0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Da li će MSP kroz projekt otvoriti nova tržišta ili proširiti prisustvo na postojećim tržištima? (Prioritetna oblast 3) </p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">DA - 5; NE - 0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Da li će MSP povećati trenutni nivo izvoza u poređenju sa 2016. finansijskom godinom? (Prioritetna oblast 3)</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">DA - 5; NE - 0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Da li će MSP stvoriti nove ili unaprijediti postojeće kanale komunikacije i alate za pristup tržištu i promociju na inostranim tržištima? (Prioritetna oblast 3)</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">DA - 5; NE - 0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-179 " rowspan="2 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Osnovni kriteriji: kvalitet i logika prijedloga</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-179 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Maksimalan broj bodova</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-179 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">25</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Da li je prijedlog projekta jasan, logičan i relevantan u odnosu na očekivane rezultate? </p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">0 - 5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Da li je plan aktivnosti izvodljiv i logičan? </p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">0 - 5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Da li su očekivani rezultati izvodljivi, dobro opisani (mjerljivi i kvantificirani) te logički povezani?  </p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">0 - 5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Da li su indikatori praćenja dobro definirani i logički povezani s očekivanim rezultatima i ishodima? </p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">0 - 5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Da li prijedlog projekta predviđa logičku vidljivost i promotivne aktivnosti? </p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">0 - 5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-179 " rowspan="2 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Osnovni kriterij: održivost prijedloga</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-179 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Maksimalan broj bodova</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-179 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Da li je prijedlog projekta održiv u smislu nastavka funkcioniranja/finansiranja relevantnih aktivnosti i nakon završetka implementacije? (Dati jasne naznake održivosti, tj. jasno obrazloženje)</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">0 - 5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-179 " rowspan="2 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Osnovni kriterij: budžet prijedloga</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-179 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Maksimalan broj bodova</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-179 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">10</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-6 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Da li je predloženi budžet realan, jasan i povezan s očekivanim rezultatima? </p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">Budžet je jasan, realan, u skladu sa ponudom, predračunom i tehničkom specifikacijom i u skladu sa izjavom o minimalnom sufinansiranju;</li>
								<li className="tekst-u-tabelama--bullet ">Budžet je nepotpuno jasan, realan, u skladu sa ponudom i tehničkom specifikacijom i u skladu sa izjavom o minimalnom sufinansiranju;</li>
								<li className="tekst-u-tabelama--bullet ">Budžet je nedefiniran, nejasan i nepovezan s očekivanim rezultatima.</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">3</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Da li budžet sadrži narativna pojašnjenja stavki?</p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">Budžet sadrži detaljna narativna pojašnjenja stavki;</li>
								<li className="tekst-u-tabelama--bullet ">Budžet sadrži djelomična narativna pojašnjenja stavki;</li>
								<li className="tekst-u-tabelama--bullet ">Budžet ne sadrži narativna pojašnjenja stavki ili su nerazumljiva.</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">3</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-44 ">
						<td className="No-Table-Style CellOverride-179 " rowspan="2 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Dodatni kriteriji</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-179 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Maksimalan broj bodova</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-179 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">70</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">MSP izvozi 50% i više prometa:</p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">80% i više;</li>
								<li className="tekst-u-tabelama--bullet ">50% - 80%;</li>
								<li className="tekst-u-tabelama--bullet ">Ispod 50%.</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">3</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-147 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Predviđeno vlastito učešće MSP-a u ukupnoj vrijednosti projekta iznosi više od minimalno zahtijevanih 50%:</p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">80% i više;</li>
								<li className="tekst-u-tabelama--bullet ">60% - 80%;</li>
								<li className="tekst-u-tabelama--bullet ">51% - 60%.</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">3</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">1</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-147 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">MSP ima 6 i više zaposlenih:</p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">6 – 9 zaposlenih;</li>
								<li className="tekst-u-tabelama--bullet ">10 - 30 zaposlenih;</li>
								<li className="tekst-u-tabelama--bullet ">31 – 80 zaposlenih;</li>
								<li className="tekst-u-tabelama--bullet ">81 – 249 zaposlenih.</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">1</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">4</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">3</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">MSP je u većinskom vlasništvu osoba mlađih od 35 godina ili u vlasništvu žena</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">DA - 5; NE - 0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-176 ">
							<ul>
								<li className="tekst-u-tabelama--bullet ">MSP posluje u jedinicama lokalne samouprave koje su izrazito nerazvijene ili nedovoljno razvijene</li>
								<li className="tekst-u-tabelama--bullet ">izrazito nerazvijene;</li>
								<li className="tekst-u-tabelama--bullet ">nedovoljno razvijene.</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">3</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">MSP do sada nije bio korisnik poticajnih grant sredstava FMRPO-a</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">DA - 5; NE - 0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-4 ">MSP će kroz projekt unapređenja tehnološke opremljenosti doprinijeti jačanju sljedećih lanaca vrijednosti: proizvodnja mašina, dijelova i materijala za autoindustriju; proizvodnja namještaja od masivnog drveta; proizvodnja lične zaštitne odjeće; programiranje i IT aplikacije; proizvodnja finalnih prehrambenih proizvoda.</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">DA - 5; NE - 0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-147 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">MSP obavlja pretežnu djelatnost proizvodnje proizvoda sa visokim stepenom finalizacije (finalni funkcionalni priozvod)</p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">Primarna prerada</li>
								<li className="tekst-u-tabelama--bullet ">Proizvodnja poluproizvoda (elemenata ili dijelova funkcionalnih proizvoda)</li>
								<li className="tekst-u-tabelama--bullet ">Proizvodnja finalnih funkcionalnih proizvoda</li>
							</ul>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">0</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">3</p>
							<p className="tekst-u-tabelama ParaOverride-2 ">5</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-35 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Predviđeno unapređenje tehnološke opremljenosti će za rezultat imati novo zapošljavanje:</p>
							<ul>
								<li className="tekst-u-tabelama--bullet ">5 i više novozaposlenih;</li>
								<li className="tekst-u-tabelama--bullet ">4 novozaposlena;</li>
								<li className="tekst-u-tabelama--bullet ">3 novozaposlena;</li>
								<li className="tekst-u-tabelama--bullet ">2 novozaposlena;</li>
								<li className="tekst-u-tabelama--bullet ">1 novozaposleni.</li>
							</ul>
							<p className="tekst-u-tabelama ">Ovaj kriterij se zbog važnosti boduje duplim bodovima.</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 pos-rel ">10 (2x5) <span className="CharOverride-11 "> <Toolpit text="S obzirom na važnost ovog kriterija, maksimalni broj bodova će se uduplat"></Toolpit> </span></p>
							<p className="tekst-u-tabelama ParaOverride-2 pos-rel">8 (2x4) <span className="CharOverride-11 "> <Toolpit text="S obzirom na važnost ovog kriterija, maksimalni broj bodova će se uduplat"></Toolpit> </span></p>
							<p className="tekst-u-tabelama ParaOverride-2 pos-rel">6 (2x3) <span className="CharOverride-11 "> <Toolpit text="S obzirom na važnost ovog kriterija, maksimalni broj bodova će se uduplat"></Toolpit> </span></p>
							<p className="tekst-u-tabelama ParaOverride-2 pos-rel">4 (2x2) <span className="CharOverride-11 "> <Toolpit text="S obzirom na važnost ovog kriterija, maksimalni broj bodova će se uduplat"></Toolpit> </span></p>
							<p className="tekst-u-tabelama ParaOverride-2 pos-rel">2 (2x1) <span className="CharOverride-11 "> <Toolpit text="S obzirom na važnost ovog kriterija, maksimalni broj bodova će se uduplat"></Toolpit> </span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-45 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Predviđeno unapređenje tehnološke opremljenosti doprinosi integraciji inovacija u poslovanje MSP-a</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">DA - 5; NE - 0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Predviđena obuka za potrebnu radnu snagu će rezultirati zapošljavanjem više od 5 osoba</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">DA - 5; NE - 0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-4 ">Predložena obuka za potrebnu radnu snagu predviđa zapošljavanje žena, mladih, povratnika i raseljenih osoba, osoba sa posebnim potrebama te ostalih socijalno ugroženih kategorija stanovništva (preko 70% od ukupnog broja osoba koje će biti zaposlene).</p>
						</td>
						<td className="No-Table-Style CellOverride-184 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">DA - 5; NE - 0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ">Da li projekt uključuje aktivnosti iz više od jedne prioritetne oblasti grant šeme?</p>
						</td>
						<td className="No-Table-Style CellOverride-192 " />
						<td className="No-Table-Style CellOverride-176 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">DA - 5; NE - 0</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-179 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Ukupan broj bodova:</p>
						</td>
						<td className="No-Table-Style CellOverride-192 " />
						<td className="No-Table-Style CellOverride-179 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">150</p>
						</td>
					</tr>
				</tbody>
			</table>


		<p className="content_text pos-rel mt-35">Nakon ocjenjivanja prijedloga u skladu sa navedenim kriterijima, definira se jedinstvena rang lista
		zaprimljenih projekata. Nakon rangiranja, Komisija će pripremiti raspodjelu raspoloživih sredstava
		po projektnim prijedlozima i prioritetnim oblastima. Na osnovu indikativne raspodjele sredstava
		iz dijela 1.5. za svaku prioritetnu oblast, Komisija će predložiti Projektnom odboru <Toolpit text="Članovi Projektnog odbora su predstavnici FMRPO-a i UNDP-a."></Toolpit> projekte na
		odobrenje u skladu sa rang listom i raspoloživim sredstvima grant šeme.</p>

		
		<p className="content_text">FMRPO zadržava pravo da izvrši preraspodjelu sredstava iz jedne prioritetne oblasti u drugu
		ukoliko u jednoj oblasti nema dovoljno kvalitetnih projektnih prijedloga.</p>

		<h2 className="blue_overlay_heading mt-60">OBAVIJEST O REZULTATIMA KONKURSA ZA PODNOŠENJE PRIJEDLOGA</h2>

		<p className="content_text">Podnositelji prijava će biti obaviješteni o rezultatima Javnog konkursa putem web stranice
		FMRPO-a.</p>
	
		<p className="content_text">Predviđeni <b>indikativni</b> vremenski okvir za finalizaciju procesa odabira je sljedeći:</p>

		<table id="table108 " className="No-Table-Style TableOverride-1 mt-40">
				<colgroup>
					<col className="_idGenTableRowColumn-277 " />
					<col className="_idGenTableRowColumn-133 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-8 ">
						<td className="No-Table-Style CellOverride-27 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">AKTIVNOST</p>
						</td>
						<td className="No-Table-Style CellOverride-27 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">DATUM</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-193 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Objava konkursa </p>
						</td>
						<td className="No-Table-Style CellOverride-193 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">05.04.2017</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-193 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Krajni rok za dodatne upite i pojašnjenja</p>
						</td>
						<td className="No-Table-Style CellOverride-193 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">28.04.2017</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-193 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Rok za podnošenje prijedloga</p>
						</td>
						<td className="No-Table-Style CellOverride-193 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">05.05.2017</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-193 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Obavijest o konačnim rezultatima konkursa</p>
						</td>
						<td className="No-Table-Style CellOverride-193 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">07.06.2017</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-10 ">
						<td className="No-Table-Style CellOverride-193 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">Potpisivanje ugovora sa odabranim MSP-ovima</p>
						</td>
						<td className="No-Table-Style CellOverride-193 ">
							<p className="tekst-u-tabelama ParaOverride-2 ">03.07.2017 </p>
						</td>
					</tr>
				</tbody>
			</table>

		<p className="content_text mt-40">Nakon Odluke o dodjeli sredstava ovog javnog konkursa, odabranim MSP-ovima će se ponuditi
		Ugovor na potpis, na osnovu pravila i procedura FMRPO-a, u kojem će se definirati obaveze te
		rokovi realizacije samog projekta.</p>

			</div>
        </ContentPage>
    )
}
