import React from 'react';
import FazeLanding from '../FazeLanding/';

const NotFound = () => {
    return (
        <FazeLanding style={{ transform: 'translate3d(0,0,0)' }} notLanding>
            <h4 className="ta-c white not_found">Greška 404</h4>
            <h5 className="ta-c white not_found">Stranica nije pronadjena</h5>
        </FazeLanding>
    )
}

export default NotFound;