import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Page_0_1, Page_0_2, Page_1_1, Page_1_2, Page_1_3, Page_1_4, Page_1_5, Page_1_6, Page_1_7, Page_1_8, Page_1_9, Page_1_10, Page_2_1, Page_2_2, Page_2_3, Page_2_4, Page_2_5, Page_2_6, Page_2_7, Page_3_1, Page_3_2, Page_3_3, Page_3_4, AdditionalTools_1, AdditionalTools_2, Dictionary } from './pages/ContentPage/exports';
import Page_0_0 from './pages/ContentPage/Page_0_0';
import Page_1_0 from './pages/ContentPage/Page_1_0';
import Page_2_0 from './pages/ContentPage/Page_2_0';
import Page_3_0 from './pages/ContentPage/Page_3_0';
import AdditionalTools_1_intro from './pages/ContentPage/AdditionalTools_1_intro';
import AdditionalTools_2_intro from './pages/ContentPage/AdditionalTools_2_intro';
import Dictionary_intro from './pages/ContentPage/Dictionary-intro';

import EntryPage from './pages/EntryPage';
import ResponsiveMenu from './common/ResponsiveMenu';
import AdditionalMenu from './common/AdditionalMenu';
import Menu from './common/Menu';
import Landing from './pages/Landing';
import ContentPage from './pages/ContentPage';
import NotFound from './pages/NotFound'
import AboutUs from './pages/AboutUs';

import IfBrowserIE from '../include/IfBrowserIE';

const routes = [
    {
        path: `${process.env.PUBLIC_URL}/page=0&subpage=0`,
        component: Page_0_0
    },
    {
        path: `${process.env.PUBLIC_URL}/page=0&subpage=1`,
        component: Page_0_1
    },
    {
        path: `${process.env.PUBLIC_URL}/page=0&subpage=2`,
        component: Page_0_2
    },
    {
        path: `${process.env.PUBLIC_URL}/page=1&subpage=0`,
        component: Page_1_0
    },
    {
        path: `${process.env.PUBLIC_URL}/page=1&subpage=1`,
        component: Page_1_1
    },
    {
        path: `${process.env.PUBLIC_URL}/page=1&subpage=2`,
        component: Page_1_2
    },
    {
        path: `${process.env.PUBLIC_URL}/page=1&subpage=3`,
        component: Page_1_3
    },
    {
        path: `${process.env.PUBLIC_URL}/page=1&subpage=4`,
        component: Page_1_4
    },
    {
        path: `${process.env.PUBLIC_URL}/page=1&subpage=5`,
        component: Page_1_5
    },
    {
        path: `${process.env.PUBLIC_URL}/page=1&subpage=6`,
        component: Page_1_6
    },
    {
        path: `${process.env.PUBLIC_URL}/page=1&subpage=7`,
        component: Page_1_7
    },
    {
        path: `${process.env.PUBLIC_URL}/page=1&subpage=8`,
        component: Page_1_8
    },
    {
        path: `${process.env.PUBLIC_URL}/page=1&subpage=9`,
        component: Page_1_9
    },
    {
        path: `${process.env.PUBLIC_URL}/page=1&subpage=10`,
        component: Page_1_10
    },


    {
        path: `${process.env.PUBLIC_URL}/page=2&subpage=0`,
        component: Page_2_0
    },
    {
        path: `${process.env.PUBLIC_URL}/page=2&subpage=1`,
        component: Page_2_1
    },
    {
        path: `${process.env.PUBLIC_URL}/page=2&subpage=2`,
        component: Page_2_2
    },
    {
        path: `${process.env.PUBLIC_URL}/page=2&subpage=3`,
        component: Page_2_3
    },
    {
        path: `${process.env.PUBLIC_URL}/page=2&subpage=4`,
        component: Page_2_4
    },
    {
        path: `${process.env.PUBLIC_URL}/page=2&subpage=5`,
        component: Page_2_5
    },
    {
        path: `${process.env.PUBLIC_URL}/page=2&subpage=6`,
        component: Page_2_6
    },
    {
        path: `${process.env.PUBLIC_URL}/page=2&subpage=7`,
        component: Page_2_7
    },

    {
        path: `${process.env.PUBLIC_URL}/page=3&subpage=0`,
        component: Page_3_0
    },
    {
        path: `${process.env.PUBLIC_URL}/page=3&subpage=1`,
        component: Page_3_1
    },
    {
        path: `${process.env.PUBLIC_URL}/page=3&subpage=2`,
        component: Page_3_2
    },
    {
        path: `${process.env.PUBLIC_URL}/page=3&subpage=3`,
        component: Page_3_3
    },
    {
        path: `${process.env.PUBLIC_URL}/page=3&subpage=4`,
        component: Page_3_4
    },

    {
        path: `${process.env.PUBLIC_URL}/additional-tools`,
        component: AdditionalTools_1
    },
    {
        path: `${process.env.PUBLIC_URL}/additional-tools-2`,
        component: AdditionalTools_2
    },
    {
        path:  `${process.env.PUBLIC_URL}/dictionary`,
        component: Dictionary
    },
    {
        path:  `${process.env.PUBLIC_URL}/entry`,
        component: EntryPage
    },
    {
        path: `${process.env.PUBLIC_URL}/additional-tools-intro`,
        component: AdditionalTools_1_intro
    },
    {
        path: `${process.env.PUBLIC_URL}/additional-tools-2-intro`,
        component: AdditionalTools_2_intro
    },
    {
        path: `${process.env.PUBLIC_URL}/dictionary-intro`,
        component: Dictionary_intro
    },
    {
        path: `${process.env.PUBLIC_URL}/about-us`,
        component: AboutUs
    }
]

export default class RouterComponent extends Component {
    render(){
        return(
            <BrowserRouter>
                <IfBrowserIE />
                <Menu />
                <ResponsiveMenu />
                <AdditionalMenu />
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={Landing} />
                    <Route path={`${process.env.PUBLIC_URL}/faze_content/:page`} component={ContentPage} />
                    {routes.map(route => (
                        <Route 
                            key={route.path}
                            path={route.path}
                            component={route.component}
                        />
                    ))}

                    <Route path={'*'} component={NotFound} />
                </Switch>
            </BrowserRouter>
        );
    }
}