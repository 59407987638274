import React from 'react';
import FazeLanding from '../FazeLanding';
import { connect } from 'react-redux';
import { setCurrentPage } from '../../../actions/index';
import PreviousChapter from '../../common/PreviousChapter';
import NextChapter from '../../common/NextChapter';

import { HashLink as Link } from 'react-router-hash-link';

const Page_1_0 = props => {
    const index = 1;
    const list = ['Definicija faze', 'Prvi koraci', 'Formiranje tima za izradu grant šeme', 'Provjera relevantnosti politike', 'Uključivanje relevantnih aktera', 'Analiza problema, potreba, prilika i potražnje', 'Jasnoća pravca djelovanja', 'Ispitivanje predizvodljivosti', 'Strukturiranje koncepta šeme', 'Postavljanje parametara za kriterije odabira', 'Razrada podzakonskih akata'];
    const mainHeading = 'PRVA FAZA';
    const mainUnderheading = 'KONCEPTUALIZACIJA ŠEME';

    return (
        <FazeLanding notLanding className="pageFazeLanding">
            <div className="d-flex d-flex-jcsb list_name_wrap">
                <div className="d-flex-m-5_5 d-flex d-flex-aafe">
                <ul className="menu_list">
                    {list.map((e,i) => {
                    if(i === 0) {
                        return (
                            <li key={e} onClick={() => props.setCurrentPage(index, 1)} className="d-flex">{`${index}.${i}`} <Link to={`${process.env.PUBLIC_URL}/page=${index}&subpage=${1}#definition`}>{e}</Link></li>
                        )
                    }
                    if( i === 1) {
                        return (
                            <li key={e} onClick={() => props.setCurrentPage(index, 1)} className="d-flex">{`${index}.${i}`} <Link to={`${process.env.PUBLIC_URL}/page=${index}&subpage=${i}#first_steps`}>{e}</Link></li>
                        )
                    }
                    return (
                        <li key={e} onClick={() => props.setCurrentPage(index, i)} className="d-flex">{`${index}.${i}`} <Link to={`${process.env.PUBLIC_URL}/page=${index}&subpage=${i}`}>{e}</Link></li>
                    )
                    })}
                </ul>
            </div>
            <div className="d-flex-m-5_5">
                <h1 className="faze_landing_heading">{mainHeading}</h1>
            </div>
            </div>

            <div className="faze_landing_underheading d-flex d-flex-jcfe"><Link onClick={() => props.setCurrentPage(1, 1)} to={`${process.env.PUBLIC_URL}/page=${1}&subpage=${1}`}>{mainUnderheading}</Link></div>
            <PreviousChapter varient="white" />
            <NextChapter varient="white" />
        </FazeLanding>
    )
}

export default connect(null, { setCurrentPage })(Page_1_0)
