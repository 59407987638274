import React, { Component } from 'react';

import { HashLink as Link } from 'react-router-hash-link';

export default class MenuButtons extends Component {
    state = {
        dropdown_show: false
    }

    openMenu = () => {
        const menu = document.querySelector('.menu');
        menu.classList.add('active_menu');
        this.setState({
            dropdown_show: false
        })
    }

    showDropdown = () => {
        this.setState({
            dropdown_show: !this.state.dropdown_show
        })
    }

    render() {
        return (
            <div id="menu_items" {...this.props}>
                <button onClick={this.showDropdown}>
                    UVOD
                    <ul className={`dropdown_menu_content ${this.state.dropdown_show ? 'show_dropdown' : ''} `}>
                        <li className="dropdown-heading d-flex d-flex-jcsb"><h3>UVOD</h3> <img onClick={this.showDropdown} className="close_menu" src={`${process.env.PUBLIC_URL}/images/close_menu.svg`} alt="close menu" /></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/entry#svrha-prirucnika-i-osnovne-definicije`}>- Svrha Priručnika i osnovne definicije</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/entry#cemu-sluzi-ovaj-prirucnik`}>- Čemu služi ovaj priručnik</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/entry#regulatorni-okvir`}>- Regulatorni okvir za izradu javnih grant šema u Bosni i Hercegovini</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/entry#izazovi_i_problemi_u_izradi_sema`}>- Izazovi i pitanja u vezi s izradom grant šema u Bosni i Hercegovini</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/entry#kako-je-prirucnik-strukturiran`}>- Kako je Priručnik strukturiran</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/entry#kako-koristiti-ovaj-prirucnik`}>- Kako koristiti ovaj priručnik</Link></li>
                        <li className="responsive_close">
                            <h3>UVOD</h3>
                            <img onClick={this.showDropdown} className="close_menu" src={`${process.env.PUBLIC_URL}/images/close_menu.svg`} alt="close menu" />
                        </li>
                    </ul>
                </button>
                <button onClick={this.openMenu}>SADRŽAJ</button>
                <Link to={`${process.env.PUBLIC_URL}/about-us`}>O NAMA</Link>
            </div>
        )
    }
}