import React from 'react';

const IfBrowserIE = () => {
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    if(isIE) {
        return (
            <div className="browser-ie"><p>UPOZORENJE: Stranica sadrži elemente koji nisu podržani u Vašem pretraživaču, molimo Vas da koristite drugi pretraživač.</p></div>
        )
    }

    return null
}

export default IfBrowserIE;