import React from 'react';
import ContentPage from './';
import { HashLink as Link } from 'react-router-hash-link';

export const AdditionalTools_1 = () => {
    return (
        <ContentPage page={false} setPage={{ page:4, subpage: 1 }}>
            <div className="content">
            <div className="yellow_overlay_text">Lokacija: <b>Prilog 1: Alati i obrasci</b></div>
                <h2 className="blue_overlay_heading">PRILOG 1 - ALATI I OBRASCI</h2>
                <p className="content_text"><b>Tabela C: </b>Lista alata navedenih u priručniku:</p>

				<div className="custom_tables">                
				<table id="table005" class="No-Table-Style TableOverride-1">
                        <colgroup>
                            <col class="_idGenTableRowColumn-24" />
                            <col class="_idGenTableRowColumn-25" />
                            <col class="_idGenTableRowColumn-26" />
                        </colgroup>
                        <tbody>
                            <tr class="No-Table-Style _idGenTableRowColumn-7">
                                <td class="No-Table-Style CellOverride-19">
                                    <p class="nalovi-u-tabelama ParaOverride-2">Faza</p>
                                </td>
                                <td class="No-Table-Style CellOverride-19">
                                    <p class="nalovi-u-tabelama ParaOverride-2">Alat</p>
                                </td>
                                <td class="No-Table-Style CellOverride-19">
                                    <p class="nalovi-u-tabelama ParaOverride-2">Mjesto u priručniku</p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-7">
                                <td class="No-Table-Style CellOverride-20" rowspan="6">
                                    <p class="nalovi-u-tabelama ParaOverride-2"><span class="CharOverride-12">Prva faza</span></p>
                                </td>
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/page=1&subpage=3`}>Provjera relevantnosti politike/strategije </Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U tekstu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-1-provjera-relevantnosti-politike.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-7">
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/additional-tools#anchor_alat2`}>Struktura izjave o politici</Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U prilogu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-2-struktura-izjave-o-politici.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-7">
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/page=1&subpage=4`}>Alat za utvrđivanje relevantnih aktera</Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U tekstu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-3-određivanje-ključnih-aktera.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-27">
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/additional-tools#anchor_alat4`}>Analiza problema i potražnje</Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U prilogu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-4-analiza-problema-i-potražnje.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-28">
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/page=1&subpage=6#anchor_alat5`}>Kontrolna lista mogućih korisnika i aplikanata, moguće vrste radnji, rezultata i ishoda </Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U tekstu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-5-kontrolna-lista-mogucih-korisnika-i-aplikanata-vrsta-aktivnosti-izlaznih-rezultata-i-ishoda.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-7">
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/page=1&subpage=8#anchor_alat5`}>Matrica za planiranje grant šeme</Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U tekstu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-6-matrica-za-planiranje-grant-sheme.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-9">
                                <td class="No-Table-Style CellOverride-20" rowspan="4">
                                    <p class="nalovi-u-tabelama ParaOverride-2"><span class="CharOverride-12">Druga faza</span></p>
                                </td>
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/page=2&subpage=4#anchor_2_4`}>Provjera prihvatljivosti</Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U tekstu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-7-pitanja-o-usklađenosti.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-9">
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/page=2&subpage=4#anchor_2_4_2`}>Tablice za procjenu (relevantnosti) </Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U tekstu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-8-tablica-bodovanja-relevantnost.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-7">
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/page=2&subpage=5`}>Scenarij realizacije</Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U tekstu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-9-scenarij-za-implementaciju.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-5">
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={`${process.env.PUBLIC_URL}/page=2&subpage=6`}>Kontrolna lista za provjeru izvodljivosti</Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U tekstu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-10-kontrolna-lista-za-provjeru-izvodljivosti.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-10">
                                <td class="No-Table-Style CellOverride-20" rowspan="2">
                                    <p class="nalovi-u-tabelama ParaOverride-2"><span class="CharOverride-12">Treća faza</span></p>
                                </td>
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={'/page=3&subpage=2#anchor_tools_11'}>Obrazac prijave</Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U tekstu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-11-primjer-obrasca-prijave-primjer-1.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                            <tr class="No-Table-Style _idGenTableRowColumn-11">
                                <td class="No-Table-Style CellOverride-21">
                                    <p class="tekst-u-tabelama-link-podcrtan"><Link to={'/additional-tools#anchor_alat12'}>Matrica za planiranje projekata</Link></p>
                                </td>
                                <td class="No-Table-Style CellOverride-22">
                                    <p class="body ParaOverride-2 d-flex d-flex-jcsb pl-10 pr-10">U prilogu <a className="download_in_content" href={`${process.env.PUBLIC_URL}/pdf/alat-12-matrica-za-planiranje-projekta-grant-sheme.docx`} download><img src={`${process.env.PUBLIC_URL}/images/download.svg`} alt="dwnld" /> Preuzmi</a></p>
                                </td>
                            </tr>
                        </tbody>
                    </table></div>
        
            <h3 className="content_heading_big blue mt-50">PREGLED DODATNIH ALATA KOJI NISU U TEKSTU PRIRUČNIKA</h3>

            <h3 id="anchor_alat2" className="content_heading mb-25 mt-25 blue d-flex no-wrap d-flex-aac"><img src={`${process.env.PUBLIC_URL}/images/settings.svg`} alt="Settings"/><span><b>ALAT 2:</b>  STRUKTURA IZJAVE O POLITICI (KORAK 1.
            3) </span></h3>

            <p className="content_text blue"><b>O izjavi o politici </b></p>

            <p className="content_text">Izjava o politici je standardizirana obavijest (dopis/memo) koju obično šalje službenik ili ekspert
            nižeg nivoa (unutrašnji ili vanjski) nekom višem nivou. Svrha ovog dokumenta je da:</p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> pruži bitne informacije, analize, opcije i preporuke koje će nadležnima omogućiti da donesu odluke.</span></p>
            
            <p className="content_text">Kao takva, izjava o politici sadrži strukturirane informacije i namjensko je sredstvo kojim oni koji imaju
            znanje i ekspertizu (i vrijeme) pomažu onima koji imaju nadležnost i ovlast da donesu odgovarajuće odluke.</p>

            <p className="content_text">U kontekstu izrade javne politike ili šeme, „izjava o politici“ treba biti shvaćena kao standardno
            sredstvo koje se često koristi da se već na početku donositelji odluka koncizno informiraju o svim bitnim aspektima kako bi se osigurali uvjeti za kasnije postepeno donošenje adekvatnih odluka,
            korak po korak.</p>

            <p className="content_text"><b>Važno je naglasiti:</b> donositelji odluka nemaju vremena da čitaju dugačke dokumente niti mogu „usvojiti“
            dokumente ako nemaju konciznu, a opet dovoljno elaboriranu predstavu o pitanjima kojima se bave.</p>

            <p className="content_text">Stoga, sposobnost izrade prijedloga izjave o politici ključna je sposobnost svakog službenika
            ministarstva koji radi u oblasti programa ili politika.</p>

            <p className="content_text">I na kraju, da ponovimo najvažnije - Izjava o politici treba biti:</p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>strukturirana, kratka, jasna, praktična i nikad duža od 3 stranice.</b></span></p>

            <p className="content_text">Format u nastavku teksta predstavlja standardnu strukturu i pomoći će vam da se, postavljanjem
            određenih pitnja i dobijanjem odgovora, fokusirate na ono što je bitno. Međutim, ne koristite pitanja
            mehanički, NEMOJTE jednostavno zapisati set odgovora na pitanja.<b> Izjavu napišite TEK KADA
            završite koncept. Bit će to neka vrsta kratke priče u kojoj objašnjavate i opavdavate način rada.</b></p>

            <h2 className="content_heading_big blue mb-50 mt-50"><b>IZJAVA O POLITICI </b> (pokazni primjer u formi obavijesti/
            dopisa, strukturiran u skladu s EU praksama i
            primjenjiv u bh. praksi)</h2>

            <p className="content_text blue"><b> Svrha:</b> </p>

            <p className="content_text">Svrha ove izjave je da:</p>
            
            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />informira XXXX o pripremnim aktivnostima koje se poduzimaju kao odgovor na odluku/instrukciju
            XXXX o pokretanju rada na trogodišnjem planu rada za godinu N+2, uključujući i najmanje jednu
            grant šemu (poziv za dostavu projektnih prijedloga) u sektoru X. </span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />traži ovlaštenje za nastavak pripremnih aktivnosti na izradi i razradi grant šeme, koju će XX moći
            objaviti N godine.</span></p>
           
            <p className="content_text blue"><b>Obrazloženje:</b></p>

            <p className="content_text">U okviru ciklusa godišnjeg planiranja i budžetiranja (unutar šireg, trogodišnjeg ciklusa), od ovog
            organa uprave/upravne organizacije je zatraženo da XXXX predloži koncept moguće intervencije,
            koja će podrazumijevati direktnu finansijsku podršku u formi granta za pokretanje mikropreduzeća
            u proizvodnom i sektoru međunarodne trgovine. Predloženi instrument je grant šema koja bi bila
            usklađena s programom razvoja malih i srednjih preduzeća i sljedećim ciljevima:</p>


            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>Cilj 2: </b> povećati stopu zaposlenosti u proizvodnom i sektoru usluga;</span></p>
            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>Cilj 3: </b> povećati stepen pokretanja preduzeća u sektorima proizvodnje i međunarodne trgovine, naročito u ciljanim sektorima i među ciljanim grupama.</span></p>
            
            <p className="content_text">Iako nema tačne procjene raspoloživih budžetskih sredstava, na temelju iskustva i ukupnog
            trogodišnjeg budžetskog plana napravljeni su preliminarni planovi. Shodno tome, organ uprave/
            upravna organizacija svoje preliminarne planove temelji na pretpostavci da javna finansijska
            sredstva potrebna za ovu mjeru ne bi prelazila 15 miliona KM</p>

            <p className="content_text">U protekla dva mjeseca, ad hoc radni tim za planiranje, koji je formiran unutar organa uprave/
            upravne organizacije i koji je konsultirao druge relevantne aktere (vidi priloženu listu), proveo je
            značajane pripremne aktivnosti koje prethode izradi šeme. Na bazi rezultata provedenih aktivnosti
            u ovoj izjavi, ističemo najvažnije elemente koji se odnose polazno stanje i kritična pitanja te
            navodimo nalaze i prijedloge koji zajedno čine početni (polazni) koncept za izradu šeme.</p>

            <p className="content_text blue"><b>Pitanja za razmatranje:</b></p>

            <p className="content_text">Već postoji šema pod nazivom „Podrška sektorima industrije i usluga“ (detalji dostupni na zahtjev),
            čiji ukupni budžet iznosi 40 miliona KM, od čega 35 miliona dolazi iz inostranih sredstava. Čini se
            da postoji namjera da se ova šema nastavi: imajući u vidu budžetska ograničenja, smatramo da se
            svaka nova šema treba fokusirati prvenstveno na cilj politike br. 3, koji je naveden u XXXX.</p>

            <p className="content_text">Održane su neformalne diskusije i konsultacije s potencijalnim korisnicima nove šeme (uglavnom
            ženama i braniteljima iz cijele zemlje, ali isto tako muškarcima, posebno iz kantona X, Y, Z i
            općina s prosječnim godišnjim dohotkom domaćinstva koji je manji od 50% prosjeka u zemlji).
            Ovi nalazi potvrđuju početnu zabrinutost da bi takva šema, iako opravdana potrebom, naišla na
            brojna ograničenja, koja bi spriječila adekvatan odziv ciljnih grupa, a time i adekvatan kvalitet
            dostavljenih projekata.</p>

            <p className="content_text">Općenito, brine nas da mnogi potencijalni aplikanti nemaju kapacitet da formuliraju dobre poslovne
            ideje, što za posljedicu može imati neadekvatno ili neracionalno korištenje finansijskih sredstava.</p>

            <p className="content_text">Također, nalazi drugih istraživanja i analiza, posebno onih koja rade strani donatori koji su prisutni
            među relevantnim grupama i područjima, potvrđuju našu zabrinutost, ali su nas istovremeno
            informirali o drugim mogućim i olakšavajućim rješenjima kroz koje bi se mogli uočeni rizici
            smanjiti do prihvatljivog nivoa za uspješnu realizaciju šeme</p>

            <p className="content_text">Potvrđeno je, također, da se od korisnika može tražiti skromno finansijsko učešće: u suprotnom,
            oni neće moći učestvovati u šemi.</p>

            <p className="content_text">Naša istraživanja i konsultacije ukazuju da su, u okviru ovakve šeme, najperspektivnija područja za
            sljedeće projektne inicijative:</p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Turizam – s fokusom na turizam malih razmjera (priroda, kultura, sport); posebno, adaptacija malih kulturnih i prirodnih objekata; marketing (naročito korištenjem interneta); obuka za male turističke
            subjekte i radnike (posebno u oblasti smještajnih kapaciteta, ugostiteljstva i upravljanja objektima). </span></p>
            
            <p className="content_text">Treba napomenuti da je potrebno poduzeti određene radnje u javnom sektoru kako bi se sektor
            turizma otvorio za privatni sektor i upravo zbog toga je opravdano određena finansijska sredstva
            učiniti dostupnim i javnom sektoru.</p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Lokalni ekonomski razvoj – posebno razvoj sektora rukotvorima (hrana, piće, turistički suveniri, kao što su keramika i umjetnička dijela), ali i sektora poslovnih savjetodavnih usluga. </span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Drugo – u osnovi, inicijative male proizvodnje ili usluga u drugim oblastima. </span></p>
            
            <p className="content_text blue"><b>Opcije</b></p>
            <p className="content_text">Tim je razmatrao dvije glavne opcije, uzimajući u obzir gore navedene (ali i druge manje značajne)
            činjenice i okolnosti:</p>
            
            <p className="content_text"><b>Opcija 1: </b></p>
            <p className="content_text">Prilikom izrade šeme osmisliti pristup da se od aplikanata traže projekti najboljeg mogućeg
            kvaliteta i stoga zahtijeva da svaki aplikant zadovolji minimalno sljedeće uvjete: najmanje 18 mjeseci
            poslovanja, višegodinji plan rada i finansijske izvještaje za najmanje jednu godinu, iz kojih je vidljiva
            ostvarena neto dobit.</p>
            <p className="content_text">Ovim će se eliminirati mnogi slabiji i problematični projekti i aplikanti, a time će se smanjiti i rizici.</p>
        
            <p className="content_text"><b>Opcija 2: </b></p>
            <p className="content_text">Šema iz dva dijela, gdje jedan uveliko korespondira s Opcijom 1, dok je drugi više otvoren za
            postojeće slabije kapacitete većine potencijalnih aplikanata poduzetnika u ciljnim grupama i
            područjima. Ova „komponenta“ bi zahtijevala od svakog aplikanta da prije zaključivanja ugovora i
            tokom realizacije projekta pohađa posebne obuke, kojima će se smanjiti rizik od neuspjeha. Stopa
            sufinansiranja će se postaviti na najnižu razinu (oko 40% za kapitalna ulaganja), dok će za sve
            „meke“ aktivnosti, uključujući i pomenute obuk e, stopa učešća biti samo 10%.</p>

            <p className="content_text">Namjera je da se najmanje 50% sredstava dodijeli kroz drugu komponentu. Ako se to pokaže
            nemogućim, onda bi se kroz proces procjene i odabira veći dio sredstava preusmjerio na prvu
            komponentu.</p>

            <p className="content_text">Razmatrajući obje predstavljene opcije, može se zaključiti da se Opcija 1 ne bavi ciljevima definirane
            javne politike na adekvatan način i da je u suštini dupliranje intervencije, ako se uzme u obzir
            postojeća slična šema/intervencija. Zaključno, premda je kod Opcije 1 rizik mali, u kontekstu
            provođenja gore navedene javne politike, ima ograničen značaj. S druge strane, Opcija 2 nosi veće rizike, ali nastoji njima upravljati, odnosno smanjiti rizike kroz tako osmišljen scenarij šeme
            da pruža bolju ravnotežu između očekivanih rezultata javne politike i izvodljivosti očekivanih
            rezultata šeme. Opcija 2 se, stoga, može predvidjeti kao „pilot“ šema, koja se u narednim godinama,
            kada se poboljšaju kapaciteti aplikanata iz ciljne grupe, može prilagoditi kako bi postepeno bolje
            obuhvatila ciljane grupe gore pomenute javne politike.</p>

            <p className="content_text blue"><b>Preporuka: </b></p>
            
            <p className="content_text">Preporučujemo koncept u prilogu i tražimo saglasnost kako bismo nastavili sa daljnjim aktivnostima
            na osmišljavanju i razradi pristupa te konačnoj izradi šeme.</p>

            <h2 className="blue_overlay_heading">PRILOG</h2>

            <h3 className="content_heading blue mt-50"><b>KONCEPT GRANT ŠEME</b></h3>
            <h3 className="content_text blue">NAZIV: PODRŠKA CILJANIM PODUZETNICIMA I NJIHOVIM DJELATNOSTIMA</h3>
    
             <p className="content_text blue"><b>Ciljevi: </b></p>  

             <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Povećati stopu osnivanja preduzeća u sektorima proizvodnje i međunarodne trgovine, posebno u
            ciljanim sektorima i među ciljanim grupama;</span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Potaći i pokrenuti poduzetnička preduzeća, posebno u ciljanim sektorima i među ciljanim grupama,
            s ciljem općeg povećanja srednjoročne dinamike privrednog razvoja.</span></p>

            <p className="content_text blue"><b>Glavne aktivnosti: </b></p>

            <p className="content_text blue"><b><em>Lokalne turističke usluge</em> </b></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Aktivnosti privatnog sektora (pojedinaca ili grupa) u smislu razvoja proizvoda, njegovog marketinga
            i brendiranja;</span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Aktivnosti privatnog sektora (pojedinaca ili grupa) u smislu unapređenja smještajnih i ugostiteljskih
            usluga (restorani, kafići);</span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Ulaganja javnog sektora u nadgradnju malih atrakcija za posjetitelje;</span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Podrška javnom sektoru u razvoju zajedničkih, javno-privatnih inicijativa u oblasti upravljanja
            turističkim odredištima (posebno, marketing i brendiranje mjesta) i izgradnji kapaciteta i
            osposobljavanju turističkog sektora, naročito žena u tom sektoru.</span></p>

            <p className="content_text blue"><b>Lokalni ekonomski razvoj</b></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Individualne ili kolektivne inicijative privatnog sektora u sektoru proizvodnje, npr. u oblasti malih zanata (turistički suveniri (keramika i druge umjetničke rukotvorine); lokalni kulinarski specijaliteti,
             posebna pića i sl.); </span></p>

             <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Individualne ili kolektivne inicijative privatnog sektora u sektoru trgovine uslugama (npr. IT,
            poslovno savjetovanje).</span></p>
            
            <p className="content_text blue"><b>Potencijalni korisnici </b></p>

            <p className="content_text">Potencijalni korisnici su svi poduzetnici organizirani (uspostavljeni) kao preduzeće ili grupa
            preduzeća (namjera je, međutim, osigurati da najmanje 50% svih podržanih privatnih inicijativa
            vode žene), a ideja je da se najmanje 60% projekata realizira u određenim „usko ciljanim područjima“.</p>

            <p className="content_text">Nadalje, mada će šemom biti obuhvaćena cijela zemlja, postavit će se uvjeti koje će biti lakše ispuniti
            za korisnike iz određenih (užih) ciljnih područja i grupa nego za ostale potencijalne korisnike.
            Međutim, uvjet za finansijsku podršku takvim korisnicima je da aktivno učestvuju u redovnom
            i fleksibilnom programu razvoja poduzetničkih kapaciteta (višestruke obuke; korištenje vanjske
            tehničke podrške). U slučajevima kada se utvrdi da uspješni aplikant nema adekvatan poslovni
            plan, u okviru tehničke podrške u sklopu programa razvoja kapaciteta poduzetnika, takvim će se
            aplikantima pružiti podrška u doradi plana.</p>

            <p className="content_text blue"><b>Finansiranje </b></p>

            <p className="content_text">Predviđeno je da se najmanje 50% sredstava (oko 15 miliona KM) usmjeri na određena (uža)
            ciljana područja i grupe. Dodatnih 5% ukupnih sredstava namijenjeno je za podršku određenim
            aktivnostima izgradnje poduzetničkih kapaciteta (obuke; tehnička podrška).</p>

            <p className="content_text blue"><b>Ishodi/rezultati (u ovoj fazi bez kvantifikacije):</b></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Poduzetnici ostvaruju ciljeve svog poslovnog plana kako je dogovoreno s ugovornim tijelom;</span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Povećan je broj kvalitetnih smještajnih turističkih kapaciteta (broj dostupnih ležaja koji zadovoljavaju
            standard);</span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Unaprijeđeni su mali turistički objekti;</span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Realizirana su javno-privatna partnerstva za promociju turizma;</span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Inicijative u oblasti malih zanata povećavaju promet (12 mjeseci od dodjele granta);</span></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Inicijative za pružanje usluga poslovnim subjektima dovode do povećavanja usluga poslovnim
            subjektima (u neto iznosima).</span></p>

            <p className="content_text blue"><b>Izlazni rezultati</b></p>

            <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Pružena pomoć poduzetnicima (u cijeloj zemlji, a posebno u ciljanim područjima);</span></p>

            <p id="anchor_alat4" className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Pružena pomoć ženama poduzetnicama (u cijeloj zemlji, a posebno u ciljanim područjima);</span></p>

            <p  className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" />Organi vlasti koriste finansijsku podršku kako bi se otvorili prema inicijativama privatnog sektora
            (u cijeloj zemlji, a posebno u ciljanim područjim</span></p>

            <h3  className="content_heading mb-40 mt-40 blue d-flex no-wrap d-flex-aac"><img src={`${process.env.PUBLIC_URL}/images/settings.svg`} alt="Settings"/><span><b>ALAT 4:</b>  ANALIZA PROBLEMA I POTRAŽNJE (KORAK 1.5)
            </span></h3>
            
            <p className="content_text"><b>Svrha: </b> Nakon što ste prvo definirali (konceptualno očekivane) izlazne rezultate projekata,
            provjerite potražnju (potencijalnih ciljnih grupa šeme) za podrškom koja će biti ponuđena u sklopu
            šeme, vodeći pri tome računa o opsegu grant šeme i njezinim ciljevima.</p>

            <p className="content_text"><b>Postupak: </b> Nekoliko dana prije sastanka sa relevatnim akterima, pošaljite svim učesnicima
            koji su potvrdili učešće na sastanku sažeti upitnik (koji možete pripremiti koristeći primjer iz
            nastavka teksta, a koji ćete prilagoditi specifičnim potrebama šeme koju pripremate), sa molbom
            da odgovore na pitanja iz upitnika najmanje 3-4 dana prije predviđenog sastanka. Zajedno sa
            anketom, učesnicima možete poslati sažeto pojašnjenje svrhe upitnika uz osnovne (konceptualne)
            informacije šeme kako bi se upoznali sa svrhom šema, ali i kako bi mogli da kvalitetnije odgovore
            na postavljena pitanja u upitniku. U pratećem pojašnjenju upitnika zamolite učesnike da (nakon što
            definiraju problem / priliku koju pokušavaju riješiti te formuliraju svoj prijedlog u smislu rješenja
            i ishoda), provjere logiku i adekvatnost predloženih rješenja na način da kritički razmotre rješenja
            iz perspektive svakog od postavljenih pitanja u upitniku i izvrše eventualne korekcije odgovora
            prije nego što dostave popunjeni upitnik. Nakon što učesnici dostave popunjene upitnike, sve
            prikupljene odgovore obradite na način da ih tabelarno organizirate tako da se za svako pitanje
            izlistaju svi prikupljeni odgovori koji se odnose na to pitanje. Nakon toga, nakon što analizirate
            prikupljene odgovore, pripremite sažetu listu pitanja koju želite detaljnije prodiskutirati s
            učesnicima sastanka na način kako je to preporučeno u Fazi 1, koraku 5, ovog priručnika.</p>

           <p className="yellow_box content_text"><b>Napomena: </b>Bez obzira koristite li alat za analizu problema i potražnje
            interno ili u radu s relevantnim akterima, trebate se fokusirati na probleme
            i potrebe potencijalnih ciljanih grupa, ali posmatrajući ih iz ugla potražnje,
            na način da preispitujete hoće li se potencijalni aplikanti prijaviti za šemu i
            hoće li dostaviti prave projekte. Ovdje postavljate vrlo konkretna pitanja, s
            ciljem da prepoznate da li potencijalna ciljna grupa ima interes da osmisli
            dobre projekte u sklopu ciljeva šeme, putem kojih će riješiti neki svoj
            problem ili potrebu. Dakle, vaša se pitanja fokusiraju na “njihove” potrebe i
            njihove “projektne ideje”. Jasno je da ćete kasnije morati pažljivo razmotriti
            ono što vam je rečeno i koliko se to uklapa u vaše namjere, ideje i ono što
            nalažu ciljevi javne politike koje putem šeme želite realizirati.</p>

            <div className="custom_tables">
            <table id="table071" class="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col class="_idGenTableRowColumn-193 " />
					<col class="_idGenTableRowColumn-194 " />
				</colgroup>
				<tbody>
					<tr class="No-Table-Style _idGenTableRowColumn-195 ">
						<td class="No-Table-Style CellOverride-125 _idGenCellOverride-5 " colspan="2 ">
							<p class="tekst-u-tabelama content_text"><span class="CharOverride-9 ">1. Opišite problem / priliku koju [vi, potencijalni aplikanti] želite riješiti</span></p>
							<p class="tekst-u-tabelama ">Pokušajte to definirati kvalitativno (kako se očituje u praksi) i kvantitativno (brojke, trendovi). </p>
						</td>
					</tr>
					<tr class="No-Table-Style _idGenTableRowColumn-196 ">
						<td class="No-Table-Style CellOverride-125 _idGenCellOverride-5 " colspan="2 ">
							<p class="tekst-u-tabelama "><span class="CharOverride-9 ">2. Koje rješenje predlažete [odnosno, vrste aktivnosti i projekata koji mogu pomoći da se riješe problemi/potrebe]?</span></p>
							<p class="tekst-u-tabelama ">Opišite svoju projektnu ideju na način da pokažete kako će ona riješiti problem/priliku, prije svega, u kvalitativnom i kvantitativnom smislu. </p>
						</td>
					</tr>
					<tr class="No-Table-Style _idGenTableRowColumn-197 ">
						<td class="No-Table-Style CellOverride-125 ">
							<p class="tekst-u-tabelama "><span class="CharOverride-9 ">3. Koji će biti konkretni ishodi [projekata] i ko će imati koristi od ishoda [projekata]?</span></p>
						</td>
						<td class="No-Table-Style CellOverride-125 ">
							<p class="tekst-u-tabelama "><span class="CharOverride-9 ">4. Ko će sve imati koristi [od ishoda projekta]? Koje su neposredne koristi od ishoda [projekata] za vaše lokalne zajednice i/ili društvo kao cjelinu? </span></p>
							<p class="tekst-u-tabelama "><span class="CharOverride-9 ">1.</span></p>
							<p class="tekst-u-tabelama "><span class="CharOverride-9 ">2.</span></p>
							<p class="tekst-u-tabelama "><span class="CharOverride-9 ">3.</span></p>
							<p class="tekst-u-tabelama "><span class="CharOverride-9 ">4.</span></p>
						</td>
					</tr>
					<tr class="No-Table-Style _idGenTableRowColumn-45 ">
						<td class="No-Table-Style CellOverride-125 _idGenCellOverride-5 " colspan="2 ">
							<p class="tekst-u-tabelama "><span class="CharOverride-9 ">5. Ostala pitanja (koja se koriste ili izbacuju zavisno od okolnosti)</span></p>
						</td>
					</tr>
					<tr class="No-Table-Style _idGenTableRowColumn-6 ">
						<td class="No-Table-Style CellOverride-125 _idGenCellOverride-5 " colspan="2 ">
							<p class="tekst-u-tabelama "><span class="CharOverride-9 ">Kako planirate osmisliti projekte koje imate na umu? Možete li to uraditi sami? Koja vam je pomoć potrebna? Kakvo iskustvo imate u izradi projekata ove vrste? </span></p>
						</td>
					</tr>
					<tr class="No-Table-Style _idGenTableRowColumn-6 ">
						<td class="No-Table-Style CellOverride-125 _idGenCellOverride-5 " colspan="2 ">
							<p class="tekst-u-tabelama "><span class="CharOverride-9 ">Ako se projekt tiče poslovnog ulaganja, imate li poslovni plan i je li ovo ulaganje sastavni dio tog plana? Jeste li svoj poslovni plan predstavili banci (ukoliko namjeravate tražiti kredit)?</span></p>
						</td>
					</tr>
					<tr class="No-Table-Style _idGenTableRowColumn-147 ">
						<td class="No-Table-Style CellOverride-125 _idGenCellOverride-5 " colspan="2 ">
							<p class="tekst-u-tabelama "><span class="CharOverride-9 ">Ako kao rezultat projekta nastane imovina koju je potrebno održavati, ko će to raditi i po kojoj cijeni? Na šta se možete obavezati u tom smislu? </span></p>
						</td>
					</tr>
					<tr class="No-Table-Style _idGenTableRowColumn-63 ">
						<td class="No-Table-Style CellOverride-125 _idGenCellOverride-5 " colspan="2 ">
							<p class="tekst-u-tabelama "><span class="CharOverride-9 ">Ako kao rezultat projekta nastane imovina za korištenje, onda je, po definiciji, projekt dobar onoliko koliko se koristi! U tom slučaju, ko će ga koristiti, koliko njih, kako i kada? Proizvodi li njegovo korištenje bilo kakve troškove (npr. uposlenici)? Ako da, kako ćete to riješiti?</span></p>
						</td>
					</tr>
					<tr class="No-Table-Style _idGenTableRowColumn-198 ">
						<td class="No-Table-Style CellOverride-125 _idGenCellOverride-5 " colspan="2 ">
							<p class="tekst-u-tabelama "><span class="CharOverride-9 ">Ako će projekt polučiti izlazne rezultate koji će omogućiti stvaranje neke nove usluge ili proizvoda, šta će to biti i koji je projicirani nivo korištenja tih usluga ili proizvoda u svakoj od prve tri godine? (Imajte na umu da odgovor na ovo pitanje može, u određenim okolnostima, biti isti kao i odgovor na prethodno pitanje).</span></p>
						</td>
					</tr>
					<tr class="No-Table-Style _idGenTableRowColumn-131 ">
						<td class="No-Table-Style CellOverride-126 _idGenCellOverride-5 " colspan="2 ">
							<p class="tekst-u-tabelama "><span class="CharOverride-9 ">Ako je vaš projekt neki poslovni projekt, kako tumačite njegove koristi – više proizvoda i usluga, veća prodaja, veća produktivnost, više zaposlenih i sl.? </span></p>
						</td>
					</tr>
				</tbody>
			</table>
            </div>
            
            <h3 id="anchor_alat12" className="content_heading mb-50 mt-50 blue d-flex no-wrap d-flex-aac"><img src={`${process.env.PUBLIC_URL}/images/settings.svg`} alt="Settings"/><span><b>ALAT 12:</b>  MATRICA ZA PLANIRANJE PROJEKTA / GRANT ŠEME
            </span></h3>

           <div className="custom_tables">
		   <table id="table072 " className="No-Table-Style TableOverride-1 ">
				<colgroup>
					<col className="_idGenTableRowColumn-199 " />
					<col className="_idGenTableRowColumn-200 " />
					<col className="_idGenTableRowColumn-55 " />
					<col className="_idGenTableRowColumn-190 " />
					<col className="_idGenTableRowColumn-42 " />
					<col className="_idGenTableRowColumn-201 " />
				</colgroup>
				<tbody>
					<tr className="No-Table-Style _idGenTableRowColumn-54 ">
						<td className="No-Table-Style CellOverride-33 _idGenCellOverride-2 " colspan="6 ">
							<p className="nalovi-u-tabelama ParaOverride-2 ">Naziv projekta:</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-22 ">
						<td className="No-Table-Style CellOverride-33 " colspan="2 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-52 ">Cilj(evi) projekta:</span></p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-52 ">Ciljevi programa finansiranja:</span></p>
						</td>
						<td className="No-Table-Style CellOverride-33 _idGenCellOverride-2 " colspan="3 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-52 ">Doprinos (utjecaj) <br />ostvarenju tih ciljeva:</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-98 ">
						<td className="No-Table-Style CellOverride-33 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-52 ">Mjerljivi rezultati / korisnici</span></p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-52 ">Mjerljivi izlazni rezultati: (konceptualizacija i izrada) </span></p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-52 ">Aktivnosti (Faza izrade)</span></p>
						</td>
						<td className="No-Table-Style CellOverride-33 _idGenCellOverride-2 " colspan="3 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-52 ">Inputi (faza razrade)</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-46 ">
							<p className="tekst-u-tabelama ">1.&#9;                   /</p>
						</td>
						<td className="No-Table-Style CellOverride-46 ">
							<p className="tekst-u-tabelama ">1.1.</p>
							<p className="tekst-u-tabelama ">1.2.&#9;</p>
							<p className="tekst-u-tabelama ">itd. …</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ">1.1.1.</p>
							<p className="tekst-u-tabelama ">1.1.2.</p>
							<p className="tekst-u-tabelama ">1.2.1. </p>
							<p className="tekst-u-tabelama ">itd. …</p>
						</td>
						<td className="No-Table-Style CellOverride-48 " colspan="2 " />
						<td className="No-Table-Style CellOverride-48 ">
							<p className="tekst-u-tabelama ">€</p>
							<p className="tekst-u-tabelama ">€</p>
							<p className="tekst-u-tabelama ">€</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-147 ">
						<td className="No-Table-Style CellOverride-46 ">
							<div className="_idGenObjectLayout-2 ">
							</div>
							<p className="tekst-u-tabelama ">2.&#9;                   /</p>
						</td>
						<td className="pos-rel No-Table-Style CellOverride-46 ">
							<div className="_idGenObjectLayout-2 ">
							</div>
							<div id="_idContainer197 " className="faze_2 _idGenObjectStyleOverride-12 ">
									<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-100 ">1.</span></p>
									<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-100 ">faza</span></p>
								</div>
							<p className="tekst-u-tabelama ">2.1.</p>
							<p className="tekst-u-tabelama ">2.2.</p>
							<p className="tekst-u-tabelama ">itd.</p>
						</td>
						<td className="pos-rel No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ">2.1.1.</p>
							<p className="tekst-u-tabelama ">2.2.1.</p>
							<p className="tekst-u-tabelama ">itd.…</p>
							<div id="_idContainer198 " className="faze_2 _idGenObjectStyleOverride-12 ">
									<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-100 ">2.</span></p>
									<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-100 ">faza</span></p>
								</div>
						</td>
						<td className="pos-rel No-Table-Style CellOverride-48 " colspan="2 ">
							<div className="_idGenObjectLayout-2 ">
							<div id="_idContainer199 " className="faze_2 _idGenObjectStyleOverride-12 ">
									<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-100 ">3.</span></p>
									<p className="nalovi-u-tabelama ParaOverride-2 "><span className="CharOverride-100 ">faza</span></p>
								</div>
							</div>
						</td>
						<td className="pos-rel No-Table-Style CellOverride-48 ">

							<p className="tekst-u-tabelama ">€</p>
							<p className="tekst-u-tabelama ">€</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-70 ">
						<td className="No-Table-Style CellOverride-46 ">
							<p className="tekst-u-tabelama ">Itd … </p>
						</td>
						<td className="No-Table-Style CellOverride-46 ">
							<p className="tekst-u-tabelama ">Itd …</p>
						</td>
						<td className="No-Table-Style CellOverride-47 ">
							<p className="tekst-u-tabelama ">Itd..</p>
						</td>
						<td className="No-Table-Style CellOverride-48 " colspan="2 " />
						<td className="No-Table-Style CellOverride-48 " />
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-27 ">
						<td className="No-Table-Style CellOverride-33 " />
						<td className="No-Table-Style CellOverride-33 " colspan="4 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-52 ">Ukupni budžet </span></p>
						</td>
						<td className="No-Table-Style CellOverride-33 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-52 ">€</span></p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-202 ">
						<td className="No-Table-Style CellOverride-33 " />
						<td className="No-Table-Style CellOverride-33 ">
							<p className="tekst-u-tabelama ParaOverride-2 "><span className="CharOverride-52 ">Analiza inputa</span></p>
						</td>
						<td className="No-Table-Style CellOverride-60 ">
							<p className="tekst-u-tabelama "><span className="CharOverride-53 ">Kapital</span>:</p>
							<p className="tekst-u-tabelama ">Uvrstiti zemljište, objekte, opremu i drugi kapital<br /></p>
							<p className="tekst-u-tabelama "><span className="CharOverride-9 ">Drugo </span></p>
							<p className="tekst-u-tabelama ">Uvrstiti troškove plaća uposlenika, konsultantnskih usluga i druge nekapitalne troškove</p>
						</td>
						<td className="No-Table-Style CellOverride-60 ">
							<p className="tekst-u-tabelama ">Direktni troškovi</p>
						</td>
						<td className="No-Table-Style CellOverride-60 ">
							<p className="tekst-u-tabelama ">Troškovi podugovaranja</p>
						</td>
						<td className="No-Table-Style CellOverride-60 ">
							<p className="tekst-u-tabelama ">Ukupno kapital</p>
							<p className="tekst-u-tabelama ">Ukupno prihod</p>
						</td>
					</tr>
					<tr className="No-Table-Style _idGenTableRowColumn-71 ">
						<td className="No-Table-Style CellOverride-33 " />
						<td className="No-Table-Style CellOverride-33 " />
						<td className="No-Table-Style CellOverride-60 " />
						<td className="No-Table-Style CellOverride-60 ">
							<p className="tekst-u-tabelama ">Ukupno direktni troškovi</p>
						</td>
						<td className="No-Table-Style CellOverride-60 ">
							<p className="tekst-u-tabelama ">Ukupno troškovi podugovaranja</p>
						</td>
						<td className="No-Table-Style CellOverride-60 " />
					</tr>
				</tbody>
			</table>
		   </div>


            
            </div>
        </ContentPage>
    )
}
