import React from 'react';
import FazeLanding from '../FazeLanding';
import { connect } from 'react-redux';
import { setCurrentPage } from '../../../actions/index';
import PreviousChapter from '../../common/PreviousChapter';
import NextChapter from '../../common/NextChapter';
import { Link } from 'react-router-dom';

const Page_0_0 = props => {
    const index = 0;
    const list = ['Šta morate znati prije poduzimanja bilo kakvih koraka', 'Trogodišnji budžetski ciklus'];
    const mainHeading = 'NULTA FAZA';
    const mainUnderheading = 'UVOD U GRANT ŠEME';

    return (
        <FazeLanding notLanding className="pageFazeLanding">
            <div className="d-flex d-flex-jcsb list_name_wrap">
                <div className="d-flex-m-5_5 d-flex d-flex-aafe">
                <ul className="menu_list">
                    {list.map((e,i) => {
                    return (
                        <li key={e} onClick={() => props.setCurrentPage(index, i + 1)} className="d-flex">{`${index}.${i + 1}`} <Link to={`${process.env.PUBLIC_URL}/page=${index}&subpage=${i + 1}`}>{e}</Link></li>
                    )
                    })}
                </ul>
            </div>
            <div className="d-flex-m-5_5">
                <h1 className="faze_landing_heading">{mainHeading}</h1>
            </div>
            </div>

            <div className="faze_landing_underheading d-flex d-flex-jcfe">{mainUnderheading}</div>
            <PreviousChapter varient="white" />
            <NextChapter varient="white" />
        </FazeLanding>
    )
}

export default connect(null, { setCurrentPage })(Page_0_0)
