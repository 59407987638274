import { SET_CURRENT_PAGE, REMOVE_LOADER } from '../actions/types';

const INITIAL_STATE = {
    page: 0,
    subpage: 0,
    loading: false
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_CURRENT_PAGE:
            return { ...state, page: action.payload.page, subpage: action.payload.subpage, loading: true };
        case REMOVE_LOADER:
            return { ...state, loading: false }
        default:
            return state;
    }
}