import React from 'react';
import ContentPage from './';
import Toolpit from "../../common/Toolpit";

import { HashLink as Link } from 'react-router-hash-link';

export const Page_3_3 = () => {
    return (
        <ContentPage page={true}>
            <div className="content">
                <div className="yellow_overlay_text">Lokacija: <b>TREĆA FAZA ( Razrada dokumenata u okviru šeme ) - Razrada smjernica za aplikante</b></div>
                <h2 className="blue_overlay_heading">
                    Korak 3.3 RAZRADA SMJERNICA ZA APLIKANTE
                </h2>
                <div className="custom_tables blue_yellow_table_2 d-flex">
                    <div className="d-flex-s-12 d-flex bb-white">
                        <div className="d-flex-s-3 d-flex d-flex-aac bg-darkblue p-10">
                            <h3 className="white">KADA?</h3>
                        </div>
                        <div className="d-flex-s-9 bg-yellow">
                            <p className="content_text mb-0 p-10">13/14-15/16. sedmica</p>
                        </div>
                    </div>
                    <div className="d-flex-s-12 d-flex bb-white">
                        <div className="d-flex-s-3 d-flex d-flex-aac bg-darkblue p-10">
                            <h3 className="white">KOLIKO DUGO?</h3>
                        </div>
                        <div className="d-flex-s-9 bg-yellow">
                            <p className="content_text mb-0 p-10">3 dana</p>
                        </div>
                    </div>
                </div>

                <p className="content_text">Smjernice za aplikante (ili engleski „Guidelines for Applicants“, koje se često označavaju engleskom skraćenicom GfA) su dokument u kojem predstavljate čitavu šemu potencijalnim aplikantima. U programima vanjske pomoći EU ovaj dokument je opširan (pogledati uređen primjer na kraju Faze 3, u dijelu <Link to={`${process.env.PUBLIC_URL}/additional-tools-2`}>„Studija slučaja“</Link>). U načelu, razlog tome je što Komisija EU insistira na tome da šeme podržane kroz pomoć EU trebaju biti što transparentnije.</p>

                <p className="content_text"><b>Osim toga, zabrinjavajuća je činjenica da je u mnogim zemljama proces osmišljavanja sveden samo na izradu smjernica za aplikante i njihovih priloga, bez prethodnog procesa planiranja i osmišljavanja koji se preporučuje u ovom priručniku.</b></p>

                <p className="content_text">Budući da vi slijedite čvrst proces osmišljavanja šeme, većina dijelova smjernica za aplikante se izrađuje na osnovu provedenih aktivnosti i izlaznih rezultata prethodnih faza i koraka. To ćete jasno vidjeti dok budete čitali o ovom koraku.</p>

                <p className="content_text">Važno je i napomenuti da se ipak može desiti da mali broj elemenata u smjernicama za aplikante ne bude izrađen u ranijim fazama: to su često standardni tipski obrasci i opći format koji predlaže Evropska unija za svoje grant šeme, što ćete vidjeti u priloženoj studiji slučaja, gdje je dat tipski obrazac koji se može standardizirati po potrebi. I u tome će vam pomoći ovaj dio priručnika.</p>

                <p className="content_text">U ovom <b>dijelu pokazujemo kako se izrađuju smjernice za aplikante na osnovu izlaznih rezultata prethodnih faza i koraka</b>. Preporučujemo da pogledate studiju slučaja <Link to={`${process.env.PUBLIC_URL}/additional-tools-2`}>„Kako se to može uraditi</Link>?</p>
                <p className="content_text"><Link to={`${process.env.PUBLIC_URL}/additional-tools-2`}>Priča iz sjeveroistočne Bugarske”</Link> na kraju ove faze, gdje je dat primjer relevantnog elementa, koji je izrađen za jednu stvarnu i uspješnu grant šemu. Također, pogledajte i ranije pomenutu <Link to={`${process.env.PUBLIC_URL}/additional-tools-2#studije-slucaja-7`}>studiju slučaja iz BiH</Link>, kao i smjernice za aplikante koje su izrađene za grant šemu Federalnog ministarstva razvoja, poduzetništva i obrta pod nazivom „Jačanje konkurentnosti malih i srednjih preduzeća“, koja je osmišljena uz pomoć UNDP-a. Poveznice na relevantni dio ovih smjernica date su u nastavku pored ikonice u obliku zastave BiH.</p>

                <h2 className="blue_overlay_heading">
                    SMJERNICE ZA APLIKANTE: KLJUČNI ELEMENTI
                </h2>

                <h3 className="content_heading blue mb-20">SMJERNICE NA NIVOU ŠEME
                </h3>

                <p className="content_text blue"><b>1. Osnovne informacije</b></p>
                <p className="content_text"><b>Ovdje se navode osnovne informacije o šemi, obično na najviše pola strane.</b></p>

                <ul className="content_text">
                    <li className="pos-rel content_text mb-10"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} className="mr-15" alt="arrow"/> Opisuje se kontekst politike i odgovara na pitanje: koje politike su osnov, odnosno podupiru ovu šemu i zašto?</li>
                    <li className="pos-rel content_text mb-10"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} className="mr-15" alt="arrow"/> Navedite nekoliko najvažnijih činjenica kojima se rezimiraju ili ističu ključna pitanja kojima će se šema baviti.</li>
                </ul>

                <p className="content_text"><span className="blue"><b>Napomena 1:</b></span> U šemi koja sadrži nekoliko komponenata, ovaj tekst će se kasnije malo produbiti, u opisu komponenti. Međutim, „osnovne informacije“ za svaku komponentu ne bi trebale biti duže od pola strane.</p>

                <p className="content_text"><span className="blue"><b>Napomena 2:</b></span> Nije potrebno da navodite opširnu logičku podlogu analize uvjeta šeme. Smjernice za aplikante nisu dokument politike: to je jednostavan vodič koji ljudima pomaže da apliciraju za vašu šemu.</p>

                <p className="content_text d-flex d-flex-aac"><img src={`${process.env.PUBLIC_URL}/images/arrow_yellow_round.svg`} alt="YellowArrow" className="mr-15" />Ključni tekst za ovaj dio može se izvući direktno iz izlaznih rezultata za:</p>

                <p className="content_text"><b>Fazu 1, korake 1.3; 1.5.</b></p>
                <p className="content_text"><b>Fazu 3, korak 1.1.</b></p>

                <p className="content_text d-flex d-flex-aac mt-60"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Idite na: „Studija slučaja“, Dio 1.1 „Osnovne informacije.“</b></p>


                <p className="content_text d-flex d-flex-aac"><img src={`${process.env.PUBLIC_URL}/images/bih_flag.svg`} alt="BihFlag" className="mr-15" /><b>Dio 1.1 Osnovne informacije o grant šemi „Jačanje konkurentnosti malih i srednjih preduzeća“</b></p>

                <p className="content_text blue mt-60"><b>2. Ciljevi grant šeme</b></p>

                <p class="content_text d-flex d-flex-aac"><img src={`${process.env.PUBLIC_URL}/images/arrow_yellow_round.svg`} alt="YellowArrow" className="mr-15" />Ovaj dio se direktno i od riječi do riječi uzima iz izlaznih rezultata za:</p>
                
                <p className="content_text"><b>Faze 1, korake 1.3; 1.8.</b></p>

                <p className="content_text d-flex d-flex-aac mt-60"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Idite na: </b>„Studija slučaja“, dio 1.2 „Ciljevi grant šeme i prioritetna pitanja.“ </p>

                <p className="content_text d-flex d-flex-aac"><img src={`${process.env.PUBLIC_URL}/images/bih_flag.svg`} alt="BihFlag" className="mr-15" /><b>Dio 1.2 Dio 1.2 „Ciljevi i prioritetne oblasti grant šeme“</b></p>

                <p className="content_text mt-60">Tekst Smjernica za aplikante, koji se odnosi na ciljeve grant šeme, treba biti <b>identičan tekstu
                podzakonskog akta.</b> </p>


                <p className="content_text blue"><b>3. Finansijska sredstva osigurava ...</b></p>

                <p className="content_text">Potrebno je navesti ukupan indikativni iznos koji je dostupan u okviru grant šeme prema:</p>

                <p className="content_text d-flex d-flex-aac"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Izvoru finansiranja (npr. struktura domaćih i/ili eksternih izvora finansiranja); </p>
                <p className="content_text d-flex d-flex-aac"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> Po komponenti (ako ima više od jedne komponente). </p>

                <p class="content_text d-flex d-flex-aac mt-60"><img src={`${process.env.PUBLIC_URL}/images/arrow_yellow_round.svg`} alt="YellowArrow" className="mr-15" /> Ključni tekst za ovaj dio može se izvući direktno iz izlaznih rezultata za: </p>
                
                <p className="content_text"><b>Fazu 1, korake 1.5</b></p>
                <p className="content_text"><b>Fazu 2: korak 2.1.</b></p> 

                <p className="content_text d-flex d-flex-aac mt-60"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>Idite na: </b> „Studija slučaja“, dio 1.3 „Finansijska sredstva koja osigurava Ministarstvo.“ </p>

                <p className="content_text d-flex d-flex-aac"><img src={`${process.env.PUBLIC_URL}/images/bih_flag.svg`} alt="BihFlag" className="mr-15" /> <b>Dio 1.5 „Izdvajanje sredstava putem grant šeme, iznos grantova i iznos sufinansiranja“ </b> </p>

                <p className="content_text mt-60">Tekst Smjernica za aplikante, koji se odnosi na finansijska sredstva, mora biti identičan tekstu
                podzakonskog akta (ako ste u podzakonskom aktu naveli ovaj nivo finansijskih podataka).</p>

                <p className="content_text blue"><b>4. Kriteriji prihvatljivosti</b></p>
                
                <p className="content_text">Ovdje se navode svi kriteriji prihvatljivosti: tj. oni ključni kriteriji koji se moraju ispuniti da bi neko
                bio „prihvatljiv“ za šemu. Ovdje navedite samo kriterije prihvatljivosti na „nivou šeme.“ Ako u šemi
                postoji nekoliko komponenti, poželjno bi bilo da u okviru svake komponente predstavite kriterije
                prihvatljivosti specifične za tu komponentu. </p>
                
                <p class="content_text d-flex d-flex-aac mt-60"><img src={`${process.env.PUBLIC_URL}/images/arrow_yellow_round.svg`} alt="YellowArrow" className="mr-15" />Ključni tekst za ovaj dio može se izvući direktno iz izlaznih rezultata za: </p>

                <p className="content_text"><b>Fazu 1, korake 1.9.</b></p>
                <p className="content_text"><b>Fazu 2: korake 2.2; 2.3; 2.4.</b></p>

                <p className="content_text d-flex d-flex-aac mt-60"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>Idite na:</b> „Studija slučaja“, dio 2.1 „Kriteriji prihvatljivosti.“</p>

                <p className="content_text d-flex d-flex-aac"><img src={`${process.env.PUBLIC_URL}/images/bih_flag.svg`} alt="BihFlag" className="mr-15" /> <b>Dio 2.1 „Kriteriji za ocjenjivanje prijedloga projekata“ </b> </p>

                <p className="content_text mt-60"> <b>Kriteriji prihvatljivosti se odnose na:</b></p>

                <p className="content_text"> <b>Organizacije </b>koje mogu zatražiti grant;</p>

                <p className="content_text"> <b>Aktivnosti </b>za koje se može dodijeliti grant;</p>

                <p className="content_text"> <b>Vrste troškova </b>koji se mogu uzeti u obzir kod utvrđivanja iznosa granta. </p>

                <p className="content_text">Za svako od navedenih, uključit ćete <b>pododjeljak u nastavku teksta koji se odnosi na kriterije prihvatljivosti.  </b></p>
                
                <p className="content_text d-flex d-flex-aac mt-60"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b> Idite na:</b> „Studija slučaja“, dijelovi 2.1.1 „Prihvatljivost aplikanata“; 2.1.3 „Prihvatljive aktivnosti“;
                2.1.4 „Prihvatljivost troškova.“</span></p>

                <p className="content_text d-flex d-flex-aac"><span><img src={`${process.env.PUBLIC_URL}/images/bih_flag.svg`} alt="BihFlag" className="mr-15" /><b>Dio 2.2.1 „Prihvatljive aktivnosti“; Dio 2.2.2 „Neprihvatljive aktivnosti“; Dio 2.3
                „Kategoriziranje prihvatljivih i neprihvatljivih troškova“ </b></span></p>

                <p className="content_text blue mt-60"><b>5. Prezentiranje budžeta</b></p>

                <p className="content_text">Čak i ako postoji nekoliko komponenti, najpogodnije je da struktura budžeta bude identična. Dakle,
                sada možete dati ključne upute za prezentiranje budžeta.</p>
                <p className="content_text">Format budžeta ćete predstaviti u Aneksu.</p>

                <p className="content_text d-flex d-flex-aac mt-60"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b> Idite na:</b> „Studija slučaja“, dio 2.1.5 „Upute za prezentiranje Aneksa B – Budžet.“</span></p>
              
                <p className="content_text"><em>(korišteni primjer je iz EU)</em></p>

                <h3 className="content_heading_big blue mb-20">SMJERNICE ZA NIVO KOMPONENTE</h3>

                <p className="content_text"><span className="blue">Napomena:</span> Ako šema uključuje više od jedne komponente, preporučujemo vam da usvojite sljedeću
                strukturu smjernica za svaku komponentu. </p>
                   
                <h2 className="content_heading blue mb-20">„NASLOV“ KOMPONENTE </h2>
                
                <p className="content_text blue"><b>1. Osnovne informacije</b></p>

                <p className="content_text">Slijedite isti pristup koji se preporučuje u navedenoj smjernici za nivo šeme.
                Najvažnija razlika je u tome što vi objašnjavate samo osnovne informacije i logičku podlogu
                komponente. Ako se radi o malim i srednjim preduzećima, govorit ćete samo o tom aspektu – ni
                o čemu više. </p>

                <p className="content_text d-flex d-flex-aac mt-60"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>Idite na:</b> „Studija slučaja“, dijelovi 2.1.6.1.1 „Poslovna infrastruktura – osnovne informacije“;
                2.1.6.3.1 „Integrirana podrška regionalnom /lokalnom turizmu – osnovne informacije.“</span></p>

                <p className="content_text blue"><b>2. Ciljevi</b></p>  
                <p className="content_text">Slijedite isti pristup koji se preporučuje u gore navedenom primjeru smjernica za nivo šeme.
                Ovaj tekst mora biti identičan tekstu podzakonskog akta (ako je tamo prezentiran cilj specifičan
                za komponentu).</p>
                
                <p className="content_text d-flex d-flex-aac mt-60"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>Idite na:</b> „Studija slučaja“, dijelovi 2.1.6.1.2 „Poslovna infrastruktura – ciljevi“; 2.1.6.3.2 „Integrirana
                podrška regionalnom /lokalnom turizmu – ciljevi.“</span> </p>

                <p className="content_text blue"><b>3. Vrsta prihvatljivih aktivnosti</b></p>
                <p className="content_text">Ovo su ključne informacije i one nisu date u smjernicama za nivo šeme. Moraju biti specifične za
                komponentu i slati jasan znak potencijalnim aplikantima o tome šta vi želite finansirati.</p>
                
                <p class="content_text d-flex d-flex-aac mt-60"><img src={`${process.env.PUBLIC_URL}/images/arrow_yellow_round.svg`} alt="YellowArrow" className="mr-15" />Ključni tekst za ovaj dio može se izvući iz: </p>
                <p className="content_text"><b>Faze 1, korak 1.6.</b></p>
                <p className="content_text"><b>Faze 2, korak 2.2.</b></p>

                <p className="content_text d-flex d-flex-aac mt-60"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /> <b>Idite na:</b> „Studija slučaja“, dijelovi 2.1.6.1.3 „Poslovna infrastruktura – vrsta prihvatljivih aktivnosti“;
                2.1.6.3.3 „Integrirana podrška regionalnom/lokalnom turizmu – vrsta prihvatljivih aktivnosti.“</span></p>
                 
                <p className="content_text blue"><b>4. Iznos grantova</b></p>
                <p className="content_text">Da ponovimo, ovo su ključne informacije i nisu date u smjernicama za nivo šeme. Moraju biti
                specifične za komponentu i slati jasan znak potencijalnim aplikantima o tome šta vi želite
                finansirati.</p>

                <p class="content_text d-flex d-flex-aac mt-60"><img src={`${process.env.PUBLIC_URL}/images/arrow_yellow_round.svg`} alt="YellowArrow" className="mr-15" />
                Ključni elementi za ovaj dio mogu se izvući iz: </p>
                <p className="content_text"><b>Faze 1, korak 1.5.</b></p>
                <p className="content_text"><b>Faze 2, korak 2.1.</b></p>
                
                <p className="content_text d-flex d-flex-aac mt-60"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Idite na:</b>„Studija slučaja“, dijelovi 2.1.6.1.4 „Poslovna infrastruktura – iznos grantova“; 2.1.6.3.4
                „Integrirana podrška regionalnom/lokalnom turizmu – iznos grantova.“ </span></p>

                <p className="content_text blue"><b>5. Prihvatljivost aplikanata</b></p>
                <p className="content_text">Da ponovimo, ovo su ključne informacije i nisu date u smjernicama za nivo šeme. Moraju biti
                specifične za komponentu i slati jasan znak potencijalnim aplikantima o tome šta vi želite
                finansirati. Morate konkretno navesti različite vrste aplikanata o kojima govorite (u zavisnosti od
                prirode komponente): npr. glavni aplikanti, koaplikanti (ili partneri) – ako ih ima itd.</p>

                <p class="content_text d-flex d-flex-aac mt-60"><img src={`${process.env.PUBLIC_URL}/images/arrow_yellow_round.svg`} alt="YellowArrow" className="mr-15" />
                Ključni elementi za ovaj dio mogu se izvući iz: </p>
                <p className="content_text"><b>Faze 1, korak 1.6.</b></p>
                <p className="content_text"><b>Faze 2, korak 2.2.</b></p>

                <p className="content_text d-flex d-flex-aac mt-60"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Idite na:</b> „Studija slučaja“, dijelovi 2.1.6.1.5 „Poslovna infrastruktura – prihvatljivost aplikanata“;
                2.1.6.3.5 „Integrirana podrška regionalnom/lokalnom turizmu – prihvatljivost aplikanata“.</span></p>   

                <p className="content_text blue"><b>6. Trajanje (finansiranih projekata)</b></p>

                <p className="content_text d-flex d-flex-aac mt-60"><img src={`${process.env.PUBLIC_URL}/images/arrow_yellow_round.svg`} alt="YellowArrow" className="mr-15" />
                Ključni elementi za ovaj dio mogu se izvući iz: </p>
                <p className="content_text"><b>Faze 2, koraci 2.1;2.5.</b></p>
                <p className="content_text"><b>Faze 2, korak 3.2.</b></p>

                <p className="content_text d-flex d-flex-aac mt-60"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15" /><b>Idite na:</b> „Studija slučaja“, dijelovi 2.1.6.1.7 „Poslovna infrastruktura – trajanje“; 2.1.6.3.7
                „Integrirana podrška regionalnom/lokalnom turizmu – trajanje“. </span></p>

                <p className="content_text"> Ovo možete uokviriti tako da aplikantima ponudite jasne savjete i upozorite ih na očigledne zamke
                (npr. podcjenjivanje vremena potrebnog za nabavke i druge procedure vezane za infrastrukturu).</p>

                <p className="content_text blue"><b>7. Prihvatljivi troškovi</b></p>

                <p class="content_text d-flex d-flex-aac mt-60"><img src={`${process.env.PUBLIC_URL}/images/arrow_yellow_round.svg`} alt="YellowArrow" className="mr-15" />
                Ključni elementi za ovaj dio mogu se izvući iz: </p>
                <p className="content_text"><b>Faze 2, korak 2.2.</b></p>

                <p className="content_text d-flex d-flex-aac mt-60"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15"/><b>Idite na:</b> „Studija slučaja“, dijelovi 2.1.6.1.8 „Poslovna infrastruktura – prihvatljivi troškovi“;
                2.1.6.3.8 „Integrirana podrška regionalnom/lokalnom turizmu – prihvatljivi troškovi“.</span></p>

                <p className="content_text blue"><b>8. Neprihvatljivi troškovi</b></p>

                <p class="content_text d-flex d-flex-aac mt-60"><img src={`${process.env.PUBLIC_URL}/images/arrow_yellow_round.svg`} alt="YellowArrow" className="mr-15" />
                Ključni elementi za ovaj dio mogu se izvući iz: </p>
                <p className="content_text"><b>Faze 2, korak 2.2.</b></p>

                <p className="content_text d-flex d-flex-aac mt-60"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15"/><b>Idite na:</b>„Studija slučaja“, dijelovi 2.1.6.1.9 „Poslovna infrastruktura – neprihvatljivi troškovi“;
                2.1.6.3.8 „Integrirana podrška regionalnom/lokalnom turizmu – neprihvatljivi troškovi“. </span></p>


               <h3 className="content_heading_big blue mb-20">NIVO ŠEME I/ILI NIVO KOMPONENTE</h3>
                <p className="content_text">Možda ćete smatrati da je potrebno dodati druge ključne informacije koje se odnose na cjelokupnu
                šemu ili na pojedine komponente, kao što su tehnički zahtjevi koji se odnose na određenu
                komponentu (ili Poziv za dostavljanje prijedloga).</p>

                <p className="content_text d-flex d-flex-aac mt-60"><span><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15"/><b>Idite na:</b>„Studija slučaja“, dio 2.1.7 „Ostali tehnički zahtjevi vezani za ovaj Poziv za dostavljanje
                prijedloga - spremnost projekata.“</span></p>

                <h3 className="content_heading_big blue mb-20">KAKO APLICIRATI I KOJE PROCEDURE SLIJEDITI</h3>
                <p className="content_text blue"><b>1. Obrazac prijave i popratni dokumenti</b></p>
                <p className="content_text pos-rel">U ovom dijelu <b>aplikantima trebate precizno objasniti kako mogu aplicirati.</b> Morate koristiti izuzetno <b>jasan i jednostavan</b> rječnik. Trebate jasno navesti da oni moraju dostaviti prijavu isključivo
                na obrascu koji je napravljen za tu svrhu i objasniti im gdje ga mogu pronaći. Morate tačno navesti <b>koje popratne dokumente prijava mora sadržavati.</b> Predlažemo da to posebno pažljivo istaknete,
                kako je to učinjeno u studiji slučaja. <Toolpit text="U ovom dijelu, također, trebate aplikantima objasniti proces procjenjivanja njihovih prijava, okvirne rokove i ključne faze
                procesa – zaprimanje prijave, administrativna provjera i kontrola prihvatljivosti, odabir/procjena kvaliteta, odluka o dodjeli granta,
                obavijest o dodjeli/obavijest o odbijanju, potpisivanje ugovora itd. Imajte na umu da je u slučaju odbijanja prijave važno navesti razloge
                u pismenoj formi. To je najbolje učiniti pozivanjem na glavne kriterije koje prijava nije zadovoljila. Objašnjenje razloga trebalo bi biti
                dovoljno jasno i precizno da bi ga aplikant mogao razumjeti. Ako odbijeni aplikanti ne prihvate razlog odbijanja, obično se predviđa
                jednostavan žalbeni postupak. U državama članicama EU se obično navede adresa elektronske pošte (e-mail) za podnošenje žalbi, što se
                mora učiniti u propisanom roku, npr. 30 dana od primitka obavijesti. Razumljivo je da finansijeri nerado ohrabruju takve formalne žalbe,
                a one su općenito rijetke, naročito u državama gdje postoji visok nivo povjerenja u javnu upravu. Ako se prime žalbe, podrazumijeva se
                da se mora poslati odgovor. Odobravanje granta nije isto što i proces javnih nabavki, gdje samo jedna kompanija ili grupa kompanija
                dobije tender. Iz tog razloga, odbijeni aplikant se često nada da će se kasnije prijaviti na neki drugi poziv, te se stoga obično ne želi žaliti
                na odluku, osim u slučaju kada sumnja u nesavjestan rad administracije."></Toolpit></p>

                <p className="content_text d-flex d-flex-aac"><img src={`${process.env.PUBLIC_URL}/images/arrow_yellow_round.svg`} alt="YellowArrow" className="mr-15" />
                Elementi za ovaj dio mogu se izvući iz: </p>
                <p className="content_text"><b>Faze 2, korak 2.4. - Kriteriji odabira.</b></p>

                <p className="content_text d-flex d-flex-aac"><img src={`${process.env.PUBLIC_URL}/images/black_arrow.svg`} alt="BlackArrow" className="mr-15"/>Ako želite vidjeti opći primjer zasnovan na praksi EU, idite na <b>Studiju slučaja, dijelovi 2.2.3
                „Potvrda primitka“; 2.3 „Ocjenjivanje i odabir aplikanata.“</b></p>

                <p className="content_text d-flex d-flex-aac mt-60"><span><img src={`${process.env.PUBLIC_URL}/images/bih_flag.svg`} alt="BihFlag" className="mr-15" /><b>Dio 4.1 „Poštivanje administrativnih zahtjeva“; Dio 4.2 „Ocjenjivanje prijedloga“.</b></span></p>
                
                <p className="content_text d-flex no-wrap"><img src={`${process.env.PUBLIC_URL}/images/document.svg`} alt="document" className="mr-15 image_width" /> <span><b>Do kraja ovog koraka:</b><br /><br />  ćete osmisliti i izraditi smjernice za aplikante na osnovu onoga
                što ste uradili u fazama konceptualizacije i osmišljavanja grant šeme.</span></p>

            </div>


        </ContentPage>
    )
}
