import React from 'react';
import { Link } from 'react-router-dom';

const Footer = props => {
    return (
        <footer className="footer">


            <div className="flex-footer"> 

                <div className="logos">
                    <img src={`${process.env.PUBLIC_URL}/images/swiss_agency.svg`} alt="swiss agency"/>
                    <img src={`${process.env.PUBLIC_URL}/images/undp.svg`} className="undpFooter" alt="swiss agency"/>
                </div>
            
                <div className="link-footer">
                   <div className="tools-flex">
                        <Link to={`${process.env.PUBLIC_URL}/additional-tools`} className="d-flex d-flex-aac">
                            <span>Alati i obrasci</span>
                        </Link>
                        <Link to={`${process.env.PUBLIC_URL}/additional-tools-2`} className="d-flex d-flex-aac">
                            <span>Studije slučaja</span>
                        </Link>
                        <Link to={`${process.env.PUBLIC_URL}/dictionary`} className="d-flex d-flex-aac">
                            <span>Rječnik ključnih pojmova</span>
                        </Link>
                        <Link to={`${process.env.PUBLIC_URL}/about-us`} className="d-flex d-flex-aac">
                            <span>O nama</span>
                        </Link>

                        
                        <a className="d-flex d-flex-aac" href={`${process.env.PUBLIC_URL}/pdf/Izrada-javnih-grant-shema-prirucnik-za-prakticare-iz-javnog-sektora.pdf`} download="Izrada-javnih-grant-shema-prirucnik-za-prakticare-iz-javnog-sektora.pdf"><span>Preuzmi dokument</span></a>
                        <a className="d-flex d-flex-aac" href={`${process.env.PUBLIC_URL}/pdf/Design of Public Grant Scheme for Public Sector Practitioners.pdf`} download="Design of Public Grant Scheme for Public Sector Practitioners.pdf"><span>Download document</span></a>
                    </div> 
                </div>


                <div className="contact">
                    <p> Kontakt informacije: </p>
                    <p>E-mail: <a href="mailto:info@javnegrantsheme.ba">info@javnegrantsheme.ba</a></p>
                </div>

                <div className="copy">
                    <p> <span>©</span> 2019 Projekat integriranog </p> 
                    <p> lokalnog razvoja (ILDP)</p>
                </div>


            </div>
        </footer>
    )
}

export default Footer;