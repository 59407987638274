import React from 'react';
import { Link } from 'react-router-dom';

import MenuButtons from '../common/MenuButtons';

const removectiveClass = () => {
    const topNavBar = document.getElementsByClassName('top_nav_bar');

    return topNavBar[0].classList.remove('showNavBar');
  }

const shiftToLanding = () => {
    removectiveClass();
    const landing = document.getElementById('landing');
    if(landing !== null) {
        landing.style.transform = 'translate3d(0,0,0)';
    }
}

const closeMenu = () => {
    const menu = document.querySelector('.menu');
    menu.classList.remove('active_menu');
}

const TopNavBar = props => {
    return (
        <div className={`top_nav_bar d-flex d-flex-jcsb d-flex-aac ${props.notLanding ? 'showNavBar' : ''}`}>
            {props.notLanding ? 
                <Link onClick={closeMenu} to={`${process.env.PUBLIC_URL}/`} className="d-flex d-flex-aac d-flex-s-5"><img src={`${process.env.PUBLIC_URL}/images/yellow_arrow.svg`} alt="arrow"/>NAZAD NA POČETNU</Link> 
                :  
                <p onClick={shiftToLanding} className="d-flex d-flex-aac d-flex-s-5"><img src={`${process.env.PUBLIC_URL}/images/yellow_arrow.svg`} alt="arrow"/>NAZAD NA POČETNU</p>}

            <MenuButtons className="white d-flex d-flex-aac" />
        </div>
    );
}

export default TopNavBar;